
import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { Content, PrincipalLayoutBox, SubTitle, Title, Zone, ZoneColumn, ZoneContent, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminProfilePrestatairePreview.module.scss';
import { useAdminProfilePrestataireQuery, useAdminRefuseProfileMutation, useAdminValidProfileMutation, useAdminRefreshCvParsingMutation, useAdminArchiveProfilePrestataireMutation, Profile_State, Role } from '../../../generated/graphql';
import { BlankLink, Link } from '../../../components/Link/Link';
import { FormattedSkill, useSovrenParser } from '../../../hooks/sovren';
import { DataContainer, DataLabelContainer, DataMissionContainer, DataRowContainer, experienceToText, Label, LabelColor, MoreMission, OuiOuNonIcon, Presentation } from '../../../components/DataPresentation/Presentation/Presentation';
import { Field } from '../../../components/Form/Field/Field';
import { getStorage } from '../../../FirebaseConfigurator';
import { ReactComponent as DownloadIcon } from '../../../assets/downloadIcon.svg';
import { resetCache } from '../../../apollo';
import { Input } from '../../../components/Form/Input/Input';
import { ValidateModal } from '../components/ValidateModal';
import { RefuseModal } from '../components/RefuseModal';
import { Button, IconButton } from '../../../components/Button/Button';
import { useUserType } from '../../../hooks/auth';
import ReactTooltip from 'react-tooltip';
import { BsPen } from 'react-icons/bs';

export const availabilityToText = (isAvailable: boolean | null, availabilityInMonths: number | null) => {
  if (isAvailable) {
    return 'Immédiate';
  }

  if (availabilityInMonths === 0) {
    return `Sous 1 mois`;
  }

  return `Dans ${availabilityInMonths} mois`;
}

export const distanceToText = (distance?: number | null) => ([
  '0km',
  '15km',
  '30km',
  '50km',
  '200km',
  '+200km',
][distance || 0]);
export const monthsToText = (distance?: number | null) => ([
  '',
  '1 mois',
  '2 mois',
  '3 mois',
  '6 mois',
  '12 mois et plus.',
][distance || 1]);

export const AdminProfilePrestatairePreview = () => {
  const history = useHistory();
  const { AdminContainer } = useUserType();
  const { id } = useParams<{ id: string }>();

  const result = useAdminProfilePrestataireQuery({
    variables: {
      id,
    },
  });
  const hasCv = !!result.data?.profilePrestataire?.cvName;

  const [formattedCv, formattedCvError] = useSovrenParser(result.data?.profilePrestataire?.parsedCv || '');
  const [validProfile] = useAdminValidProfileMutation();
  const [refuseProfile] = useAdminRefuseProfileMutation();
  const [archiveProfile, archiveProfileResponse] = useAdminArchiveProfilePrestataireMutation();
  const [refreshCvParsing, refreshCvParsingResponse] = useAdminRefreshCvParsingMutation();

  const [isValidating, setIsValidating] = useState(false);
  const onValidProfile = useCallback((salesEmail: string | null | undefined) => {
    setIsValidating(true);
    (async () => {
      await validProfile({
        variables: {
          id,
          salesEmail: !!salesEmail ? salesEmail : null,
        },
      });

      resetCache();
      result.refetch();
      setIsValidating(false);
    })();
  }, [id, result, validProfile]);

  const onRefuseProfile = useCallback(() => {
    (async () => {
      await refuseProfile({
        variables: {
          id,
        },
      });

      resetCache();
      result.refetch();
    })();
  }, [id, refuseProfile, result]);

  const onArchiveProfile = useCallback(() => {
    (async () => {
      await archiveProfile({
        variables: {
          id,
        },
      });

      history.goBack();
    })();
  }, [archiveProfile, history, id]);

  const profilePrestataire = result.data?.profilePrestataire!;
  const data = result.data?.profilePrestataire;

  const [cvUrl, setCvUrl] = useState<string>('');
  useEffect(() => {
    if (result.data && result.data.profilePrestataire === null) {
      history.push('/not-found');
      return;
    }

    (async () => {
      if (result.data && hasCv) {
        const storageRef = getStorage().ref();
        const fileRef = storageRef.child(`/all-cvs/${result.data.profilePrestataire?.owner?.id}/${result.data?.profilePrestataire?.cvName}`);
        setCvUrl(await fileRef.getDownloadURL());
      }
    })();
  }, [id, result.data, history, hasCv]);

  const [shouldShowAllMissions, setShowAllMissions] = useState(false);

  const [skillsFilter, setSkillsFilter] = useState<string>('');
  const [skills, setSkills] = useState<FormattedSkill[]>([]);

  useEffect(() => {
    if (formattedCv.data) {
      const splittedFilter = skillsFilter.split(',').filter(s => !!s);
      if (splittedFilter.length === 0) {
        setSkills(formattedCv.data.skills);
        return;
      }

      const filteredSkills = formattedCv.data.skills.map(s => {
        const hasSearchedSkillInIt = splittedFilter.some(f => s.skills.some(sk => sk.name.toLowerCase().indexOf(f) !== -1));
        if (hasSearchedSkillInIt) {
          return {
            ...s,
            skills: s.skills.filter(sk => splittedFilter.some(f => sk.name.toLowerCase().indexOf(f) !== -1)),
          };
        }

        return null;
      }).filter(s => s !== null);
      setSkills(filteredSkills as FormattedSkill[]);
    }
  }, [skillsFilter, formattedCv.data]);

  const refreshParsing = useCallback(() => {
    refreshCvParsing({
      variables: {
        id,
      },
    });
  }, [id, refreshCvParsing]);

  useEffect(() => {
    if (refreshCvParsingResponse.data) {
      result.refetch();
    }
  }, [refreshCvParsingResponse.data, result]);

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataire)} fullWidth={true}>
      <Title>Administration</Title>
      <SubTitle>Profil prestataire</SubTitle>
      <Content>
        {(result.loading || (formattedCv.isLoading && formattedCvError !== null)) && <ZoneLoadingState />}
        {formattedCvError && (
          <>
            <Zone
              title={profilePrestataire?.title!}>
              <ZoneContent>
                {formattedCvError}
              </ZoneContent>
            </Zone>
          </>
        )}
        {(!result.loading && !formattedCv.isLoading && result.data !== null) && (
          <>
            <Zone
              title={<span className={$.TitleWithIcon}>
                <OuiOuNonIcon value={!!profilePrestataire?.valid} /> {profilePrestataire?.title!}
                <span key={id}>
                  <IconButton data-tip data-for={id} onClick={() => {
                    history.push(data?.owner?.role === Role.Freelance ? `/admin/prestataires/freelance/edit/${data?.id!}` : `/admin/prestataires/enterprise/${data?.owner?.id}/${data?.id}`);
                  }}>
                    <BsPen size={25} />
                  </IconButton>
                  <ReactTooltip id={id} place="top" effect="solid">
                    Editer le profil prestataire
                  </ReactTooltip>
                </span>
              </span>}
              description={<Label revert color={data?.owner?.role === Role.Freelance ? LabelColor.Purple : LabelColor.Blue}>{data?.owner?.role}</Label>} className={$.ProfileInfosZone}>
              <AdminContainer>
                {
                  profilePrestataire?.valid === null && (
                    <div className={$.ModalContainer}>
                      <RefuseModal onSubmit={onRefuseProfile} />
                      <ValidateModal salesEmail={profilePrestataire?.salesEmail} onSubmit={onValidProfile} isLoading={isValidating} />
                    </div>
                  )
                }
                {profilePrestataire?.state === Profile_State.Refused && (
                  <div className={$.ButtonsZone}>
                    <Button onClick={onArchiveProfile} isLoading={archiveProfileResponse.loading}>Archiver</Button>
                  </div>
                )}
              </AdminContainer>
              {result.data?.profilePrestataire?.salesEmail && (
                <ZoneContent>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Suivi par</DataLabelContainer>
                          <DataContainer>{result.data?.profilePrestataire?.salesEmail}</DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                </ZoneContent>
              )}
              <ZoneContent>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Nom</DataLabelContainer>
                        <DataContainer>{profilePrestataire?.name || profilePrestataire?.owner?.name}</DataContainer>
                      </DataRowContainer>
                      {result.data?.profilePrestataire?.owner?.role === Role.Freelance && (
                        <>
                          <DataRowContainer>
                            <DataLabelContainer>Email</DataLabelContainer>
                            <DataContainer>
                              <Link style={{ display: 'inline' }} to={`/admin/users?filters={"email":"${result.data?.profilePrestataire?.owner?.email}"}`} data-tip data-for="gotouser">{result.data?.profilePrestataire?.owner?.email}</Link>
                              <ReactTooltip id="gotouser" place="top" effect="solid">
                                Voir l'utilisateur
                              </ReactTooltip>
                            </DataContainer>
                          </DataRowContainer>
                          <DataRowContainer>
                            <DataLabelContainer>Téléphone</DataLabelContainer>
                            <DataContainer>{result.data?.profilePrestataire?.owner?.phone}</DataContainer>
                          </DataRowContainer>
                        </>
                      )}
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Disponibilité</DataLabelContainer>
                        <DataContainer>{availabilityToText(profilePrestataire?.available!, profilePrestataire?.availabilityInMonths!)}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>TJM</DataLabelContainer>
                        <DataContainer>{profilePrestataire?.tjm} €</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Code postal</DataLabelContainer>
                        <DataContainer>{profilePrestataire?.postalCode}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Distance maximum</DataLabelContainer>
                        <DataContainer>{distanceToText(profilePrestataire?.maximumDistance)}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Durée minimum de mission</DataLabelContainer>
                        <DataContainer>{monthsToText(profilePrestataire?.minimumMonths)}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
              </ZoneContent>
              {(result.data?.profilePrestataire?.owner?.role === Role.Esn || result.data?.profilePrestataire?.owner?.role === Role.Enterprise) && (
                <ZoneContent title="Contact ESN">
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Nom</DataLabelContainer>
                          <DataContainer>{result.data?.profilePrestataire?.owner?.name}</DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Email</DataLabelContainer>
                          <DataContainer>
                            <Link style={{ display: 'inline' }} to={`/admin/users?filters={"email":"${result.data?.profilePrestataire?.owner?.email}"}`} data-tip data-for="gotouser">{result.data?.profilePrestataire?.owner?.email}</Link>
                            <ReactTooltip id="gotouser" place="top" effect="solid">
                              Voir l'utilisateur
                            </ReactTooltip>
                          </DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Téléphone</DataLabelContainer>
                          <DataContainer>{result.data?.profilePrestataire?.owner?.phone}</DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                </ZoneContent>
              )}
            </Zone>
            {!hasCv &&
              <Zone title="Informations du CV" className={$.CvInfosZone}>
                <ZoneContent>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataContainer>
                            Ce profil n'est pas complet. Aucun CV trouvé.
                          </DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                </ZoneContent>
              </Zone>
            }
            {hasCv &&
              <Zone title="Informations du CV" className={$.CvInfosZone}>
                <div className={$.DownloadLink}>
                  <AdminContainer>
                    <Button isLoading={refreshCvParsingResponse.loading} onClick={refreshParsing}>Rafraichir le parsing</Button>
                  </AdminContainer>
                  <BlankLink href={cvUrl}>
                    <span>Télécharger</span>
                    <DownloadIcon />
                  </BlankLink>
                </div>
                <ZoneContent>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Experience</DataLabelContainer>
                          <DataContainer>{experienceToText(formattedCv.data.monthsOfWorkExperience)}</DataContainer>
                        </DataRowContainer>
                        <DataRowContainer>
                          <DataLabelContainer>Experience manageriale</DataLabelContainer>
                          <DataContainer>{experienceToText(formattedCv.data.monthsOfManagementExperience)}</DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                </ZoneContent>
                <ZoneContent>
                  <ZoneColumn>
                    <DataRowContainer>
                      <DataLabelContainer>Missions</DataLabelContainer>
                    </DataRowContainer>
                    <Field>
                      {(shouldShowAllMissions ? formattedCv.data.positions : formattedCv.data.positions.slice(0, 5)).map(position => (
                        <DataMissionContainer key={position.id} position={position} />
                      ))}
                    </Field>
                    {formattedCv.data.positions.length > 5 && (
                      <Field>
                        <MoreMission showMore={!shouldShowAllMissions} onShowLess={() => setShowAllMissions(false)} onShowMore={() => setShowAllMissions(true)} />
                      </Field>
                    )}
                  </ZoneColumn>
                  <ZoneColumn>
                    <Field>
                      <Input label="Recherche une compétence" placeholder="Une ou plusieurs compétences, séparées par des virgules" onChange={(e: any) => setSkillsFilter(e.target.value.toLowerCase())} />
                    </Field>
                    <DataRowContainer>
                      <DataLabelContainer>Compétences</DataLabelContainer>
                    </DataRowContainer>
                    {skills.filter(s => s.skills.length > 0).map(
                      skill => (
                        <React.Fragment key={skill.id + skill.name}>
                          <Field>
                            <b>{skill.name}</b>
                          </Field>
                          <div style={{ marginBottom: '15px' }}>
                            {skill.skills.map(sk => <Label key={sk.id + sk.name + skill.id + skill.name} color={LabelColor.Grey}>
                              {sk.name}
                              {
                                sk.experienceInMonths && <span style={{ fontSize: '12px' }}>:{sk.experienceInMonths}m</span>
                              }
                            </Label>)}
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </ZoneColumn>
                </ZoneContent>
              </Zone>
            }
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
