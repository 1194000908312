import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import { Role, useAdminUsersForCompaniesAssociationLazyQuery } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Pagination } from '../../../components/Pagination/Pagination';
import { Field } from '../../../components/Form/Field/Field';
import { Select } from '../../../components/Form/Select/Select';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { offsetToCursor } from 'graphql-relay';
import { RoleLabel } from '../../../components/DataPresentation/Presentation/Presentation';
import { FiUserPlus } from "react-icons/fi";
import { Button, IconButton } from "../../../components/Button/Button";
import { Input } from "../../../components/Form/Input/Input";
import { Modal, RefModal } from "../../../components/Modal/Modal";
import { useFilters } from "../../../hooks/useFilters";
import $ from './UsersListingModal.module.scss';
import { Checkbox } from '../../../components/Form/Checkbox/Checkbox';

type Filters = {
  role: string | null;
  email: string | null;
  page: number;
}
const defaultFilters: Filters = {
  role: '',
  email: '',
  page: 0
};

type UsersListingModalProps = {
  onSubmit: (ids: string[]) => void;
  isLoading: boolean;
  dataForId: string;
  companyContainerId: string;
};
export const UsersListingModal = ({ onSubmit, isLoading, dataForId, companyContainerId }: UsersListingModalProps) => {
  const [filters, setFilters, debouncedFilters] = useFilters<Filters>(defaultFilters, {}, true);
  const offset = useMemo(() => filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1), [filters.page]);
  const [fetchList, list] = useAdminUsersForCompaniesAssociationLazyQuery({
    variables: {
      companyContainerId,
      after: offset,
      role: debouncedFilters.role as any,
      email: debouncedFilters.email,
    }
  });

  const onSave = useCallback((ids: string[]) => {
    onSubmit(ids);
    modalRef.current?.close();
  }, [onSubmit]);

  const [showOnlySavedAssociatedUsers, setShowOnlySavedAssociatedUsers] = useState(false);
  const [showOnlyWantedAssociatedUsers] = useState(false);

  const users = useMemo(() => {
    const d = list.data?.users?.edges! || [];
    const selectedUsers = list.data?.usersAssociatedToCompany! || [];

    if (showOnlySavedAssociatedUsers) {
      return selectedUsers.map((user) => ({
        id: user?.user?.id!,
        name: user?.user?.name!,
        role: user?.user?.role!,
        email: user?.user?.email!,
        canEdit: !!user?.canEdit,
      }));
    }

    return d.map((user) => ({
      id: user?.node?.id!,
      name: user?.node?.name!,
      role: user?.node?.role!,
      email: user?.node?.email!,
      canEdit: !!selectedUsers.find(u => u?.userId === user?.node?.id)?.canEdit,
    }));
  }, [list.data, showOnlySavedAssociatedUsers]);
  const [associatedUsers, setAssociatedUsers] = useState<{ [id: string]: boolean }>({});
  useEffect(() => {
    list.data?.usersAssociatedToCompany?.forEach((user) => {
      if (typeof associatedUsers[user?.userId!] === 'boolean') {
        return;
      }

      setAssociatedUsers({
        ...associatedUsers,
        [user?.userId!]: true,
      });
    });
  }, [associatedUsers, list]);

  const modalRef = useRef<RefModal>(null);

  type TableData = (typeof users)[number];

  return (
    <div className={$.UsersListingModal}>
      <Modal
        disclosure={
          <IconButton data-tip data-for={dataForId} onClick={() => fetchList()}>
            <FiUserPlus size={25} />
          </IconButton>
        }
        ref={modalRef}
        title="Validation"
        description="Veuillez renseigner les dates de démarrage et de fin de la mission"
        full
      >
        <>
          <div className={$.Filters}>
            <FormZone>
              <FormRow>
                <Field>
                  <Checkbox children="Ne montrer que les utilisateurs associés et sauvegardés" checked={showOnlySavedAssociatedUsers} onChange={(e: any) => setShowOnlySavedAssociatedUsers(e.target.checked)} />
                </Field>
              </FormRow>
              <FormRow>
                <Field>
                  <Input disabled={showOnlySavedAssociatedUsers || showOnlyWantedAssociatedUsers} label="Email" value={filters.email!} onChange={(e: any) => setFilters('email', e.target.value)} />
                </Field>
                <Field>
                  <Select
                    emptyLabel="Type"
                    onChange={e => setFilters('role', e.target.value)}
                    value={filters.role!}
                    disabled={showOnlySavedAssociatedUsers || showOnlyWantedAssociatedUsers}
                    options={[
                      { value: Role.Esn, label: 'ESN' },
                      { value: Role.Freelance, label: 'Freelance' },
                      { value: Role.Admin, label: 'Admin' },
                      { value: Role.AdminObserver, label: 'Admin obs.' },
                      { value: Role.Enterprise, label: 'Entreprise' },
                      { value: Role.Client, label: 'Client' },
                    ]}
                  />
                </Field>
              </FormRow>
            </FormZone>
          </div>
          {(list.loading) && <ZoneLoadingState />}
          {!list.loading && (
            <div className={$.UsersListingModal}>
              <Table<TableData> columns={[
                {
                  Header: 'Nom',
                  accessor: 'name',
                  width: 20,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  width: 15,
                },
                {
                  Header: 'Role',
                  accessor: row => <RoleLabel role={row.role} />,
                  width: 15,
                },
                {
                  Header: 'Associé',
                  accessor: row => {
                    return (
                      <Checkbox checked={associatedUsers[row.id]} onChange={(e: any) => {
                        setAssociatedUsers({
                          ...associatedUsers,
                          [row.id]: e.target.checked,
                        });
                      }} />
                    )
                  },
                  width: 10,
                },
              ]} data={users} />
              <Pagination current={filters.page} pageSize={10} total={list.data?.users?.total!} onClick={page => setFilters('page', page)} />
            </div>
          )}
          <br />
          <div className={$.ModalStartingButton}>
            <Button isLoading={isLoading} onClick={() => onSave(Object.keys(associatedUsers).filter(key => associatedUsers[key]))}>Sauvegarder</Button>
          </div>
        </>
      </Modal>
    </div >
  );
};
