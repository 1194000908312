
import React, { useState } from 'react';
import classnames from 'classnames';
import { Content, LinkTab, PrincipalLayoutBox, Tabs, Title } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminUserCreation.module.scss';
import { Mission_State, useAdminMissionsQuery, useSignupClientMutation, useSignupEsnMutation, useSignupFreelanceMutation } from '../../../generated/graphql';
import { Field } from '../../../components/Form/Field/Field';
import { useHistory } from 'react-router-dom';
import { Tab, TabList, TabPanel } from '../../../components/Form/Tab/Tab';
import { Radio, RadioGroup } from '../../../components/Form/Radio/Radio';
import { FreelanceSignupForm, FreelanceSignupFormSubmitValues } from '../../auth/components/FreelanceSignupForm/FreelanceSignupForm';
import { EsnSignupForm, EsnSignupFormSubmitValues } from '../../auth/components/EsnSignupForm/EsnSignupForm';
import { ClientSignupForm, ClientSignupFormSubmitValues } from '../../auth/components/ClientSignupForm/ClientSignupForm';
import { useRadioState, useTabState } from 'reakit';
import { translate } from '../../../translate';
import { useNotification } from '../../../hooks/notifications';

export const AdminUserCreation = () => {
  const history = useHistory();
  const { addNotification } = useNotification();
  const [error, setError] = useState<string | undefined>(undefined);

  const [signupClient] = useSignupClientMutation();
  const onSignupClient = async (payload: ClientSignupFormSubmitValues) => {
    try {
      const { data } = await signupClient({
        variables: payload
      });

      if (data?.signUpClient?.errors) {
        setError(data?.signUpClient?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push(`/admin/users?filters={"email":"${payload.email}"}`);
      addNotification(false, 'Compte crée, vous avez été redirigé vers la page utilisateur.');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
    }
    setError(undefined);
  };
  const [signupEsn] = useSignupEsnMutation();
  const onSignupEsn = async (payload: EsnSignupFormSubmitValues) => {
    try {
      const { data } = await signupEsn({
        variables: payload
      });

      if (data?.signUpEsn?.errors) {
        setError(data?.signUpEsn?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push(`/admin/users?filters={"email":"${payload.email}"}`);
      addNotification(false, 'Compte crée, vous avez été redirigé vers la page utilisateur.');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
    }
    setError(undefined);
  };
  const [signupFreelance] = useSignupFreelanceMutation();
  const onSignupFreelance = async (payload: FreelanceSignupFormSubmitValues) => {
    try {
      const { data } = await signupFreelance({
        variables: payload
      });

      if (data?.signUpFreelance?.errors) {
        setError(data?.signUpFreelance?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push(`/admin/users?filters={"email":"${payload.email}"}`);
      addNotification(false, 'Compte crée, vous avez été redirigé vers la page utilisateur.');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
      return;
    }

    setError(undefined);
  };


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState<'freelance' | 'esn'>('freelance');
  const tab = useTabState();
  const radio = useRadioState({ state: 'freelance' });

  const preSubmit = () => setIsSubmitting(true);
  const postSubmit = () => setIsSubmitting(false);
  return (
    <PrincipalLayoutBox className={classnames($.AdminUserCreation)}>
      <Title>Administration</Title>
      <Tabs>
        <LinkTab to="/admin/users/">Utilisateurs</LinkTab>
        <LinkTab to="/admin/users/creation">Créer un utilisateur</LinkTab>
      </Tabs>
      <Content>
        <div className={$.SignupPanelContent}>
          <TabList {...tab} aria-label="My tabs">
            <Tab {...tab} id="presta">Prestataire</Tab>
            <Tab {...tab} id="client">Client</Tab>
          </TabList>
          <TabPanel {...tab}>
            <>
              <Field>
                <RadioGroup {...radio} aria-label="type de compte" name="type" onChange={(e: any) => setType(e.target.value)}>
                  <Radio {...radio} value="freelance">{translate('Freelance')}</Radio>
                  <Radio {...radio} value="esn">{translate('ESN')}</Radio>
                </RadioGroup>
              </Field>
              {type === 'freelance' && <FreelanceSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupFreelance} isAdmin />}
              {type === 'esn' && <EsnSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupEsn} isAdmin />}
            </>
          </TabPanel>
          <TabPanel {...tab}>
            <ClientSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupClient} isAdmin />
          </TabPanel>
        </div>
      </Content>
    </PrincipalLayoutBox>
  );
};
