import React, { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/auth";
import { getCvFileUrl, getReadableCvFilename } from "../../../store/domains/profilePrestataire/ProfilePrestataire.service";

export const CvFileDownloadLink = ({ cvName }: { cvName: string }) => {
  const [cvUrl, setCvUrl] = useState('https://app.mydataspecialist.com');
  const auth = useAuth();
  useEffect(() => {
    (async () => {
      const cvUrl = await getCvFileUrl(auth?.firebaseUser?.uid!, cvName);
      setCvUrl(cvUrl);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvName]);

  return (
    <a href={cvUrl} target="_blank" rel="noreferrer"><b>{getReadableCvFilename(cvName)}</b></a>
  );
};
