import { Select, SelectProps } from './Select';
import { companyTranslations } from '../../../translate';
import React from 'react';

const companyTranslate = companyTranslations.translate;
export type AreaSelectProps = Omit<SelectProps, 'options'>;
export const AreaSelect = React.forwardRef<HTMLSelectElement, AreaSelectProps>((props: AreaSelectProps, ref) => (
  <Select
    ref={ref}
    options={[
      { value: 'banqueAssurance', label: companyTranslate('AreaBanqueAssurance') },
      { value: 'btp', label: companyTranslate('AreaBtp') },
      { value: 'chimiePharmacie', label: companyTranslate('AreaChimiePharmacie') },
      { value: 'commerceDistribution', label: companyTranslate('AreaCommerceDistribution') },
      { value: 'editionCommunication', label: companyTranslate('AreaEditionCommunication') },
      { value: 'electroniqueIndustrie', label: companyTranslate('AreaElectroniqueIndustrie') },
      { value: 'industrie', label: companyTranslate('AreaIndustrie') },
      { value: 'informatiqueTelecoms', label: companyTranslate('AreaInformatiqueTelecoms') },
      { value: 'santeSocial', label: companyTranslate('AreaSanteSocial') },
      { value: 'servicePublic', label: companyTranslate('AreaServicePublic') },
      { value: 'servicesAuxEntreprisesBToB', label: companyTranslate('AreaServicesAuxEntreprisesBToB') },
      { value: 'modeLuxe', label: companyTranslate('AreaModeLuxe') },
      { value: 'transportsLogistique', label: companyTranslate('AreaTransportsLogistique') },
      { value: 'autre', label: companyTranslate('AreaAutre') },
    ]}
    {...props}
  />
));
