import firebase from 'firebase/app';
import { ActionsUnion, createActionCreator } from '../../../redux-types';

export enum Roles {
  ADMIN = 'ADMIN',
  ADMIN_OBSERVER = 'ADMIN_OBSERVER',
  FREELANCE = 'FREELANCE',
  ESN = 'ESN',
  CLIENT = 'CLIENT',
  ENTERPRISE = 'ENTERPRISE',
};

export type UserPayload = {
  firebaseAuth: firebase.auth.Auth;
  firebaseUser: firebase.User;
  role: Roles;
  token: string | null;
  isProfileFilled: boolean;
  isProfilePrestataireFilled: boolean;
};

export const setAppIsReady = createActionCreator('SET_APP_IS_READY', (userPayload: UserPayload | null) => ({
  userPayload,
}));

export const profileFilled = createActionCreator('PROFILE_FILLED');
export const profilePrestataireFilled = createActionCreator('PROFILE_PRESTATAIRE_FILLED');

const ActionsCreators = {
  setAppIsReady,
  profileFilled,
  profilePrestataireFilled,
};

export type Actions = ActionsUnion<typeof ActionsCreators>;
