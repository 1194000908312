import React from 'react';
import classnames from 'classnames';
import { Button as ReakitButton, ButtonProps as ReakitButtonProps } from 'reakit/Button';
import $ from './Button.module.scss';
import { Spinner } from '../Spinner/Spinner';

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ButtonSize {
  Standard = 'Standard',
  Big = 'Big',
}
export type ButtonProps = ReakitButtonProps & {
  styleType?: ButtonType;
  isLoading?: boolean;
  size?: ButtonSize;
};
const getClassNameByType = (type?: ButtonType) => {
  switch (type) {
    case ButtonType.Secondary:
      return $.Secondary;
    case ButtonType.Primary:
    default:
      return $.Primary;
  }
};
const getClassNameBySize = (size?: ButtonSize) => {
  switch (size) {
    case ButtonSize.Big:
      return $.Big;
    case ButtonSize.Standard:
    default:
      return $.Standard;
  }
};
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, styleType, size, disabled, isLoading, children, ...props }: ButtonProps, ref) => (
  <ReakitButton ref={ref} {...props} disabled={disabled || isLoading} className={classnames(className, $.Button, getClassNameByType(styleType), getClassNameBySize(size), { [$.IsLoading]: isLoading })}>
    {children}
    <div className={$.LoadingIcon}>
      <Spinner />
    </div>
  </ReakitButton>
));

export const IconButton = ({ className, ...props }: ReakitButtonProps) => (
  <ReakitButton {...props} className={classnames(className, $.IconButton)} />
);
