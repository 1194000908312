import React, { useState } from 'react';
import classnames from 'classnames';
import $ from './Presentation.module.scss';
import { SovrenPosition } from '../../../hooks/sovren';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { Role } from '../../../generated/graphql';

export const experienceToText = (experience?: number | null) => {
  if (!experience) {
    return 'inconnue';
  }

  const years = Math.trunc(experience / 12);
  const months = experience % 12;

  let text = '';
  if (years > 0) {
    text = `${years} ${years === 1 ? 'an ' : 'ans '}`;
  }

  if (months > 0) {
    text += `${months} mois`;
  }

  return text;
};

export type DataContainerProps = {
  children: React.ReactNode;
};
export const DataContainer = ({ children }: DataContainerProps) => (
  <div className={$.DataContainer}>{children}</div>
);

export type DataLabelContainerProps = {
  children: React.ReactNode;
};
export const DataLabelContainer = ({ children }: DataLabelContainerProps) => (
  <div className={$.DataLabelContainer}>{children}</div>
);


export type DataRowContainerProps = {
  children: React.ReactNode;
};
export const DataRowContainer = ({ children }: DataRowContainerProps) => (
  <div className={$.DataRowContainer}>{children}</div>
);

const monthDiff = (d1: Date, d2: Date) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
export type DataDateContainerProps = {
  from: {
    isCurrent: boolean;
    date: string;
  };
  to: {
    isCurrent: boolean;
    date: string;
  }
  withGap?: boolean;
};
export const DataDateContainer = ({ from, to, withGap = false }: DataDateContainerProps) => {
  const format = new Intl.DateTimeFormat('fr', { year: 'numeric', month: 'short' });

  const fromText = from.date ? format.format(new Date(from.date)) : '?';
  const toText = to.isCurrent ? 'en cours' : format.format(new Date(to.date));
  const gap = monthDiff(new Date(from.date), to.isCurrent ? new Date() : new Date(to.date));
  return (
    <div className={$.DataDateContainer}>
      {fromText} - {toText} {from.isCurrent && 'current'}
      {withGap ? <div>{experienceToText(gap)}</div> : null}
    </div>
  );
};

type MoreMissionProps = {
  showMore: boolean;
  onShowMore: () => void;
  onShowLess: () => void;
};
export const MoreMission = ({ showMore, onShowMore, onShowLess }: MoreMissionProps) => (
  <div className="MoreMissionContainer">
    {showMore && (
      <a href="javascrip:void(0)" onClick={onShowMore}>Afficher plus d'experiences</a>
    )}
    {!showMore && (
      <a href="javascrip:void(0)" onClick={onShowLess}>Afficher moins d'experiences</a>
    )}
  </div>
);

export type DataMissionContainerProps = {
  position: SovrenPosition;
};
export const DataMissionContainer = ({ position }: DataMissionContainerProps) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className={$.DataMissionContainer}>
      <div>
        <b>{position.name}</b> chez <b>{position.employer}</b>
      </div>
      <DataDateContainer from={position.start} to={position.end} withGap />
      <div>Spécialité: <b>{position.principalSkill}, {position.subSkill}</b></div>
      {
        !showMore && (
          <p>
            <a href="javascrip:void(0)" onClick={() => setShowMore(true)}>(Afficher la description)</a>
          </p>
        )
      }
      {
        showMore && (
          <p>
            {position.description} <a href="javascrip:void(0)" onClick={() => setShowMore(false)}>(Afficher la description)</a>
          </p>
        )
      }
    </div>
  );
};

export type PresentationProps = {
  children: React.ReactNode;
};
export const Presentation = ({ children }: PresentationProps) => (
  <div className={classnames($.Presentation)}>
    {children}
  </div>
);


export enum LabelColor {
  Grey = 'var(--color-almost-white)',
  Purple = 'var(--color-primary)',
  Blue = 'var(--color-blue)',
  Green = 'var(--color-green)',
  Red = 'var(--color-red)',
  Orange = 'var(--color-orange)',
  Yellow = 'var(--color-yellow)',
};
export type LabelProps = {
  children: React.ReactNode;
  color: LabelColor;
  revert?: boolean;
};
export const Label = ({ children, color, revert }: LabelProps) => (
  <span className={classnames($.Label, { [$.LabelRevert]: revert })} style={{ backgroundColor: color }}>{children}</span>
);
export type RoleLabelProps = {
  role: Role;
};
export const RoleLabel = ({ role }: RoleLabelProps) => {
  let color = LabelColor.Grey;
  let revert = false;
  switch(role) {
    case Role.Admin: {
      color = LabelColor.Yellow;
      break;
    }
    case Role.AdminObserver: {
      color = LabelColor.Orange;
      revert = true;
      break;
    }
    case Role.Client: {
      color = LabelColor.Green;
      revert = true;
      break;
    }
    case Role.Enterprise: {
      color = LabelColor.Grey;
      break;
    }
    case Role.Esn: {
      color = LabelColor.Blue;
      revert = true;
      break;
    }
    case Role.Freelance: {
      color = LabelColor.Purple;
      revert = true;
      break;
    }
  }

  return (
    <Label color={color} revert={revert}>{role}</Label>
  );
};

export enum OuiOuNonColor {
  Red = 'var(--color-red)',
  Green = 'var(--color-green)',
};
export type OuiOuNonIconProps = {
  value: boolean;
};
export const OuiOuNonIcon = ({ value }: OuiOuNonIconProps) => {
  const color = value ? OuiOuNonColor.Green : OuiOuNonColor.Red;
  return (
    <span style={{ color }}>
      {value ? <CheckIcon /> : <CrossIcon />}
    </span>
  );
};
export type OuiOuNonProps = {
  value: boolean;
};
export const OuiOuNon = ({ value }: OuiOuNonProps) => {
  return (
    <div className={$.OuiOuNon}>
      <div >
        <OuiOuNonIcon value={value} />
      </div>
      <div>{value ? 'oui' : 'non'}</div>
    </div>
  );
};
