import React, { useState } from 'react';
import $ from './Signin.module.scss';
import { SigninPanel } from '../components/AuthPanel/SigninPanel';
import { getAuth } from '../../../FirebaseConfigurator';
import { AuthLayout } from '../../layout/AuthLayout/AuthLayout';

const Signin = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const signin = (email: string, password: string) => {
    setError(undefined);
    setIsSubmitting(true);
    setTimeout(async () => {
      try {
        await getAuth().signInWithEmailAndPassword(email, password);
        // const token = await result.user?.getIdToken();
      } catch (error) {
        const { code } = error;
        if (code === 'auth/wrong-password') {
          setError('Mot de passe invalide.');
        } else if (code === 'auth/user-not-found') {
          setError('Ce compte n\'existe pas.');
        } else {
          setError('Une erreur est survenue. Veuillez réessayer.')
        }

        setIsSubmitting(false);
      }
      // history.push('/home');
    }, 1000);
  };

  return (
    <AuthLayout className={$.Signin}>
      <SigninPanel
        error={error}
        isLoading={isSubmitting}
        onSignin={signin}
      />
    </AuthLayout>
  );
};

export default Signin;
