import React from 'react';
import classnames from 'classnames';
import {
  Radio as ReakitRadio,
  RadioGroup as ReakitRadioGroup,
  RadioProps as ReakitRadioProps,
  RadioGroupProps as ReakitRadioGroupProps,
} from 'reakit';
import $ from './Radio.module.scss';

export type RadioProps = ReakitRadioProps & { children: React.ReactNode; };
export const Radio = ({ className, children, ...props }: RadioProps) => (
  <label className={classnames($.Radio, className)}>
    <div className={$.RadioButtonContainer}>
      <ReakitRadio {...props} className={className} />
      <span className={$.RadioButtonChecked}></span>
      <span className={$.RadioButton}></span>
    </div>
    <span className={$.RadioLabel}>{children}</span>
  </label>
);

export type RadioGroupProps = ReakitRadioGroupProps & {};
export const RadioGroup = ({ className, ...props }: RadioGroupProps) => <ReakitRadioGroup {...props} className={classnames($.RadioGroup, className)} />;
