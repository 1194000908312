import React, { useState } from 'react';
import classnames from 'classnames';
import $ from './PrincipalLayout.module.scss';
import { BaseLayout } from '../BaseLayout/BaseLayout';
import { Menu } from '../../../components/Menu/Menu';
import { Spinner } from '../../../components/Spinner/Spinner';
import { getAuth } from '../../../FirebaseConfigurator';
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';
import { NavLink } from 'react-router-dom';

export type TitleProps = {
  children: React.ReactNode;
};
export const Title = ({ children }: TitleProps) => (
  <h1 className={$.Title}>{children}</h1>
);

export type SubTitleProps = {
  children: React.ReactNode;
};
export const SubTitle = ({ children }: SubTitleProps) => (
  <h2 className={$.SubTitle}>{children}</h2>
);

export type LinkTabProps = {
  children: React.ReactNode;
  to: string;
};
export const LinkTab = ({ children, to }: LinkTabProps) => (
  <NavLink to={to} className={$.LinkTab} exact activeClassName={$.LinkTabActive}>
    <h2 className={$.SubTitle}>{children}</h2>
  </NavLink>
);

export type TabsProps = {
  children: React.ReactNode;
};
export const Tabs = ({ children }: TabsProps) => (
  <div className={$.TabsContainer}>
    {children}
  </div>
);

export type ContentProps = {
  children: React.ReactNode;
};
export const Content = ({ children }: ContentProps) => (
  <div className={$.Content}>
    {children}
  </div>
);

export type ZoneProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  hollow?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
export const Zone = ({ style, children, title, description, hollow = false, className }: ZoneProps) => (
  <div style={style} className={classnames($.Zone, { [$.ZoneHollow]: hollow }, className)}>
    {title && <h3 className={$.ZoneTitle}>{title}</h3>}
    {description && (<p className={$.ZoneDescription}>{description}</p>)}
    {children}
  </div>
);

export type ZoneContentProps = {
  children: React.ReactNode;
  title?: string;
}
export const ZoneContent = ({ children, title }: ZoneContentProps) => (
  <div className={$.ZoneContent}>
    {title && <h4 className={$.ZoneContentTitle}>{title}</h4>}
    <div className={$.ZoneInnerContent}>
      {children}
    </div>
  </div>
);

export type ZoneColumnProps = {
  children?: React.ReactNode;
  size?: number;
};
export const ZoneColumn = ({ children, size = 1 }: ZoneColumnProps) => (
  <div className={$.ZoneColumn} style={{ flex: size }}>
    {children}
  </div>
);

export const ZoneLoadingState = () => (
  <div className={$.ZoneLoadingState}>
    <Spinner />
  </div>
);

export type WarningZoneProps = {
  title: string;
  children?: React.ReactNode;
}
export const WarningZone = ({ children, title }: WarningZoneProps) => (
  <div className={$.WarningZone}>
    <p className={$.WarningZoneTitle}>{title}</p>
    <p className={$.WarningZoneContent}>{children}</p>
  </div>
);

export type PrincipalLayoutBoxProps = React.InputHTMLAttributes<HTMLDivElement> & { fullWidth?: boolean; };
export const PrincipalLayoutBox = ({ children, className, fullWidth = false }: PrincipalLayoutBoxProps) => (<div className={classnames($.PrincipalLayoutBox, className, { [$.PrincipalLayoutBoxFullWidth]: fullWidth })}>{children}</div>);

export type PrincipalLayoutProps = {
  children: React.ReactNode;
  className?: string;
};
export const PrincipalLayout = ({ children, className }: PrincipalLayoutProps) => {
  const signout = () => getAuth().signOut();
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <BaseLayout className={classnames($.PrincipalLayout, className)}>
      <div className={$.SignoutContainer}>
        <button className={$.SignoutButton} onClick={signout} title="Se déconnecter">
          <LogoutIcon />
        </button>
      </div>
      <div className={$.OpenMenuContainer}>
        <button className={$.OpenMenuButton} onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </button>
      </div>
      <div className={classnames($.MenuContainer, { [$.MenuContainerOpen]: isMenuOpen })}>
        <Menu onClickOutside={() => setMenuOpen(false)} />
      </div>
      <div className={$.ContentContainer}>
        {children}
      </div>
    </BaseLayout>
  );
};
