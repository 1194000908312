import React from 'react';
import $ from './SigninPanel.module.scss';
import classnames from 'classnames';
import { Input } from '../../../../components/Form/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { Field } from '../../../../components/Form/Field/Field';
import { useForm } from 'react-hook-form';
import { translate } from '../../../../translate';
import { AuthPanel } from './AuthPanel';
import { Link } from '../../../../components/Link/Link';

type FormValues = {
  email: string;
  password: string;
};

const defaultValues: FormValues = {
  email: '',
  password: '',
};

export type SigninPanelProps = {
  onSignin: (email: string, password: string) => void;
  isLoading: boolean;
  error?: string;
};
export const SigninPanel = ({
  onSignin,
  isLoading,
  error,
}: SigninPanelProps) => {
  const onInternalSubmit = (formValues: FormValues) => {
    onSignin(formValues.email, formValues.password);
  };

  const { register, handleSubmit, errors } = useForm<FormValues>({ defaultValues });
  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return (
    <AuthPanel
      title={translate('authSigninTitle')}
      subTitle={<span>{translate('authSigninSubTitle') + ' '}<Link to="/signup">{translate('authSigninLinkSignup')}</Link></span>}
    >
      <div className={classnames($.SigninPanelContent)}>
        <form onSubmit={handleSubmit(onInternalSubmit)}>
          <Field>
            <Input displayLabelOnlyWhenFilled={true} label={translate('email')} placeholder={translate('email')} errored={!!errors.email} ref={register({ required: true, pattern: emailRegex })} name="email"></Input>
          </Field>
          <Field>
            <Input displayLabelOnlyWhenFilled={true} label={translate('password')} placeholder={translate('password')} errored={!!errors.password} type="password" ref={register({ required: true })} name="password"></Input>
          </Field>
          <Field>
            <Link to="/forgot">Mot de passe perdu</Link>
          </Field>
          <Field align="right">
            <Button isLoading={isLoading} disabled={!!errors.email || !!errors.password} type="submit">{translate('authSigninSigninButton')}</Button>
          </Field>
          {error && (
            <Field>
              <span className={$.Error}>{error}</span>
            </Field>
          )}
        </form>
      </div>
    </AuthPanel>
  );
};

