import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

// dev config
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(config);

let auth: firebase.auth.Auth | null = null;
export const getAuth = () => {
  if (!auth) {
    auth = firebase.app().auth();
  }

  return auth;
};

let storage: firebase.storage.Storage | null = null;
export const getStorage = () => {
  if (!storage) {
    storage = firebase.app().storage();
  }

  return storage;
};
