import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Form/Input/Input";
import { Textarea } from "../../../components/Form/Textarea/Textarea";
import { Modal, RefModal } from "../../../components/Modal/Modal";
import $ from './SearchByMissionModal.module.scss';

type SearchByMissionModalProps = {
  onSubmit: (title: string, description: string) => void;
  isLoading: boolean;
};

export const SearchByMissionModal = React.forwardRef<RefModal, SearchByMissionModalProps>(({ onSubmit, isLoading }: SearchByMissionModalProps, ref) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <div className={$.ValidButton}>
      <Modal
        ref={ref}
        buttonLabel="Matcher par mission"
        title="Validation"
        description="Veuillez renseigner les informations de la mission"
      >
        <>
          <Input
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            type="text"
            name="title"
            label="Titre"
          />
          <br />
          <Textarea label="Description" rows={5}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)} />
          <br />
          <div className={$.ModalStartingButton}>
            <Button isLoading={isLoading} disabled={!title || !description} onClick={() => onSubmit(title, description)}>Lancer le matching</Button>
          </div>
        </>
      </Modal>
    </div>
  );
});
