import React, { useMemo } from 'react';
import { useTypedController } from '@hookform/strictly-typed';
import { Control, useWatch } from 'react-hook-form';
import { useRadioState } from 'reakit';
import { Field } from '../../../components/Form/Field/Field';
import { Radio, RadioGroup } from '../../../components/Form/Radio/Radio';
import { Input } from '../../../components/Form/Input/Input';
import $ from './AvailabilityFields.module.scss';

type Props = {
  availableLabel: string;
  whenAvailableLabel: string;
  availableAriaLabel: string;
  whenAvailableAriaLabel: string;
  control: Control<Record<string, any>>;
  register: any;
  setAvailabilityDate: any;
};

export const AvailabilityFields = <FormValues extends { isAvailable: string; browserAvailabilityDate: string; },>
  ({ availableLabel, whenAvailableLabel, setAvailabilityDate, availableAriaLabel, whenAvailableAriaLabel, register, control }: Props) => {
  const isAvailable = useWatch<string>({
    control,
    name: 'isAvailable',
  });
  const { setState: _, ...availableRadio } = useRadioState();
  const { setState: setWhenAvailableRadio, ...whenAvailableRadio } = useRadioState();
  const Controller = useTypedController<FormValues>({ control });
  const minDate = useMemo(() => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  }, []);

  return (
    <>
      <Field>{availableLabel}</Field>
      <Field>
        <Controller
          name="isAvailable"
          render={({ onChange, value }) => {
            return (
              <RadioGroup {...availableRadio} aria-label={availableAriaLabel}>
                <Radio setState={() => onChange('oui')} {...availableRadio} checked={value === 'oui'} value="oui">Oui</Radio>
                <Radio setState={() => onChange('non')} {...availableRadio} checked={value === 'non'} value="non">Non</Radio>
              </RadioGroup>
            );
          }}
        />
      </Field>
      {isAvailable === 'non' && (
        <>
          <Field>{whenAvailableLabel}</Field>
          <Field>
            <RadioGroup {...whenAvailableRadio} aria-label={whenAvailableAriaLabel}>
              <Radio {...whenAvailableRadio} setState={(value) => {
                setAvailabilityDate(value);
                setWhenAvailableRadio(value);
              }} value={1}>1 mois</Radio>
              <Radio {...whenAvailableRadio} setState={(value) => {
                setAvailabilityDate(value);
                setWhenAvailableRadio(value);
              }} value={3}>3 mois</Radio>
              <Radio {...whenAvailableRadio} setState={(value) => {
                setAvailabilityDate(value);
                setWhenAvailableRadio(value);
              }} value={6}>6 mois</Radio>
            </RadioGroup>
            <div className={$.AvailabilityDateContainer}>
              <Input
                type="date"
                name="browserAvailabilityDate"
                ref={register({ required: true })}
                label=""
                min={minDate}
              />
            </div>
          </Field>
        </>
      )}
      <div style={{ marginBottom: '10px' }}></div>
    </>
  );
};
