import styled from 'styled-components';

export const Box = styled.div<{ hollow?: boolean }>`
  border-radius: 15px;
  padding: 25px;
  box-shadow: ${props => props.hollow ? 'none' : 'rgb(149 157 165 / 20%) 0px 8px 24px'};
  background: ${props => props.hollow ? 'transparent' : 'white'};

  & + & {
    margin-top: 30px;
  }

  * + & {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const ActionsBox = styled(Box)`
  text-align: right;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
