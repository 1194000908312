import React from 'react';
import $ from './ForgotPanel.module.scss';
import classnames from 'classnames';
import { Input } from '../../../../components/Form/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { Field } from '../../../../components/Form/Field/Field';
import { useForm } from 'react-hook-form';
import { translate } from '../../../../translate';
import { AuthPanel } from './AuthPanel';
import { Link } from '../../../../components/Link/Link';

type FormValues = {
  email: string;
};

const defaultValues: FormValues = {
  email: '',
};

export type ForgotPanelProps = {
  onSubmit: (email: string) => void;
  isLoading: boolean;
  error?: string;
};
export const ForgotPanel = ({
  onSubmit,
  isLoading,
  error,
}: ForgotPanelProps) => {
  const onInternalSubmit = (formValues: FormValues) => {
    onSubmit(formValues.email);
  };

  const { register, handleSubmit, errors } = useForm<FormValues>({ defaultValues });
  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return (
    <AuthPanel
      title="Mot de passe oublié"
      subTitle="Veuillez renseigner votre adresse email afin de recevoir un nouveau mot de passe."
    >
      <div className={classnames($.ForgotPanelContent)}>
        <form onSubmit={handleSubmit(onInternalSubmit)}>
          <Field>
            <Input displayLabelOnlyWhenFilled={true} label={translate('email')} placeholder={translate('email')} errored={!!errors.email} ref={register({ required: true, pattern: emailRegex })} name="email"></Input>
          </Field>
          <Field>
            <Link to="/signin">Me connecter</Link>
          </Field>
          <Field align="right">
            <Button isLoading={isLoading} disabled={!!errors.email} type="submit">Reinitialiser mon mot de passe</Button>
          </Field>
          {error && (
            <Field>
              <span className={$.Error}>{error}</span>
            </Field>
          )}
        </form>
      </div>
    </AuthPanel>
  );
};

