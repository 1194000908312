import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from '@react-hook/debounce';

type BaseFilters = { page?: number };
const getFiltersFromUrl = <Filters extends BaseFilters>(searchParams: string | null) => {
  const sanitized = searchParams || '{}';
  try {
    const parsed = JSON.parse(sanitized);
    const cleaned = {
      ...parsed,
    } as Filters;

    return cleaned;
  } catch (e) {
    return {};
  }
};
type Validators<T extends BaseFilters> = {
  [K in keyof T]?: (value: unknown) => unknown;
};
const defaultValidator = (value: unknown) => {
  if (value === '') {
    return null;
  }

  return value;
};
export const positiveNumberValidator = (value: unknown) => {
  if (typeof value === 'string') {
    if (value === '') {
      return null;
    }

    const converted = +value;
    if (isNaN(converted)) {
      return null;
    }

    return Math.abs(Math.round(converted));
  }
  if (typeof value === 'number') {
    return Math.abs(Math.round(value));
  }

  return null;
};
export const arrayValidator = (value: unknown) => {
  if (value instanceof Array) {
    return value;
  }

  return null;
};
export const useFilters = <Filters extends BaseFilters>(defaultFilters: Filters, validators: Validators<Filters> = {}, onlyMemory: boolean = false) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const filtersFromUrl = getFiltersFromUrl(query.get('filters'));
  const mergedFilters = useMemo(() => ({ ...defaultFilters, ...(onlyMemory ? {} : filtersFromUrl) }), [defaultFilters, filtersFromUrl, onlyMemory]);
  const [filters, setFilters] = useState(mergedFilters);
  const sanitizedFilters = useMemo(() => {
    return Object.keys(mergedFilters).reduce((acc, filterKey) => ({
      ...acc,
      [filterKey]: (validators[filterKey as keyof Filters] ?? defaultValidator)(mergedFilters[filterKey as keyof Filters])
    }), {});
  }, [mergedFilters, validators]);
  const [debouncedFilters, setDebouncedFilters] = useDebounce(sanitizedFilters as typeof mergedFilters, 500);

  const setFilterUrlValueAndSetState = <Key extends keyof Filters, Key2 extends keyof Filters>(name: Key, value: Filters[Key], name2?: Key2, value2?: Filters[Key2]) => {
    let newPageValue = name === 'page' ? value : 0;
    // Resetting page to 0 if filters are changing
    let newFilters = { ...filters, page: newPageValue, [name]: value };
    if (name2 !== undefined && value2 !== undefined) {
      newFilters = { ...newFilters, page: newPageValue, [name2]: value2 };
    }

    const stringified = JSON.stringify(newFilters);
    let newSanitizedFilters = { ...sanitizedFilters, [name]: (validators[name] ?? defaultValidator)(value) } as typeof newFilters;
    if (name2 !== undefined && value2 !== undefined) {
      newSanitizedFilters = { ...newSanitizedFilters, [name2]: (validators[name2] ?? defaultValidator)(value2) } as typeof newFilters;
    }

    if (!onlyMemory) {
      history.push({ search: `?filters=${stringified}` });
    }

    setFilters(newFilters);
    setDebouncedFilters(newSanitizedFilters);
  };

  return [
    filters,
    setFilterUrlValueAndSetState,
    debouncedFilters,
  ] as const;
};

export const useMemoryFilters = <Filters extends BaseFilters>(defaultFilters: Filters, validators: Validators<Filters> = {}) => {
  const mergedFilters = useMemo(() => ({ ...defaultFilters, ...{} }), [defaultFilters]);
  const [filters, setFilters] = useState(mergedFilters);
  const sanitizedFilters = useMemo(() => {
    return Object.keys(mergedFilters).reduce((acc, filterKey) => ({
      ...acc,
      [filterKey]: (validators[filterKey as keyof Filters] ?? defaultValidator)(mergedFilters[filterKey as keyof Filters])
    }), {});
  }, [mergedFilters, validators]);
  const [debouncedFilters, setDebouncedFilters] = useDebounce(sanitizedFilters as typeof mergedFilters, 500);

  const setFilterInMemory = <Key extends keyof Filters, Key2 extends keyof Filters>(name: Key, value: Filters[Key], name2?: Key2, value2?: Filters[Key2]) => {
    let newPageValue = name === 'page' ? value : 0;
    // Resetting page to 0 if filters are changing
    let newFilters = { ...filters, page: newPageValue, [name]: value };
    if (name2 !== undefined && value2 !== undefined) {
      newFilters = { ...newFilters, page: newPageValue, [name2]: value2 };
    }

    let newSanitizedFilters = { ...sanitizedFilters, [name]: (validators[name] ?? defaultValidator)(value) } as typeof newFilters;
    if (name2 !== undefined && value2 !== undefined) {
      newSanitizedFilters = { ...newSanitizedFilters, [name2]: (validators[name2] ?? defaultValidator)(value2) } as typeof newFilters;
    }

    setFilters(newFilters);
    setDebouncedFilters(newSanitizedFilters);
  };

  return [
    filters,
    setFilterInMemory,
    debouncedFilters,
  ] as const;
};
