export const experienceToText = (xp: number) => {
  switch (xp) {
    case 0:
      return '0-2 ans';
    case 2:
      return '2-7 ans';
    case 7:
      return '7 ans et +';
    case -1:
      return 'Peu importe';
    default:
      return 'inconnue';
  }
};
export const isExperienceBetween = (xp: number, from: number, to: number) => {
  if (xp === 0) {
    return from >= 0 && to <= 2;
  } else if (xp === 2) {
    return from >= 2 && to <= 7;
  } else if (xp === 7) {
    return from >= 7;
  } else {
    return true;
  }
}
export const remoteToText = (remote: number) => {
  switch (remote) {
    case 0:
      return 'Non';
    case 1:
      return 'À temps complet';
    case 2:
      return 'Partiel';
    default:
      return 'inconnu';
  }
};
export const needMovingToText = (needMoving: number) => {
  switch (needMoving) {
    case 0:
      return 'Pas déplacement';
    case 1:
      return 'Déplacements à prévoir';
    default:
      return 'inconnu';
  }
}

export const getAreas = () => {
  return [
    { value: 'banqueAssurance', labelKey: 'AreaBanqueAssurance' },
    { value: 'btp', labelKey: 'AreaBtp' },
    { value: 'chimiePharmacie', labelKey: 'AreaChimiePharmacie' },
    { value: 'commerceDistribution', labelKey: 'AreaCommerceDistribution' },
    { value: 'editionCommunication', labelKey: 'AreaEditionCommunication' },
    { value: 'electroniqueIndustrie', labelKey: 'AreaElectroniqueIndustrie' },
    { value: 'industrie', labelKey: 'AreaIndustrie' },
    { value: 'informatiqueTelecoms', labelKey: 'AreaInformatiqueTelecoms' },
    { value: 'santeSocial', labelKey: 'AreaSanteSocial' },
    { value: 'servicePublic', labelKey: 'AreaServicePublic' },
    { value: 'servicesAuxEntreprisesBToB', labelKey: 'AreaServicesAuxEntreprisesBToB' },
    { value: 'modeLuxe', labelKey: 'AreaModeLuxe' },
    { value: 'transportsLogistique', labelKey: 'AreaTransportsLogistique' },
    { value: 'autre', labelKey: 'AreaAutre' },
  ];
};
