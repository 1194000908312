import { useCallback, useContext } from 'react';
import { NotificationContext } from '../App';

export const useNotification = () => {
  const context = useContext(NotificationContext);
  const addNotification = useCallback((isError: boolean, message: string) => context && context.addNotification(isError, message), [context]);

  return {
    addNotification,
  };
};
