
import classnames from 'classnames';
import { Content, LinkTab, PrincipalLayoutBox, Tabs, Title, Zone, ZoneContent, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminConfiguration.module.scss';
import { Button } from '../../../components/Button/Button';
import { Field } from '../../../components/Form/Field/Field';
import { useNotification } from '../../../hooks/notifications';
import { useUserType } from '../../../hooks/auth';
import { useAdminConfigurationQuery, useAdminSetConfigurationMutation } from '../../../generated/graphql';
import { useCallback, useMemo } from 'react';

export const AdminConfiguration = () => {
  const { AdminContainer } = useUserType();

  const adminConfiguration = useAdminConfigurationQuery();
  const [setConfigurationFromHook, setConfigurationResponse] = useAdminSetConfigurationMutation();


  const isFitnetIntegrationEnabled = useMemo(() => {
    return adminConfiguration.data?.adminConfiguration?.isFitnetIntegrationEnabled;
  }, [adminConfiguration.data?.adminConfiguration?.isFitnetIntegrationEnabled]);
  const setConfiguration = useCallback(() => {
    setConfigurationFromHook({
      variables: {
        isFitnetIntegrationEnabled: !isFitnetIntegrationEnabled
      }
    }).then(() => adminConfiguration.refetch())
  }, [adminConfiguration, isFitnetIntegrationEnabled, setConfigurationFromHook]);

  return (
    <PrincipalLayoutBox className={classnames($.AdminConfiguration)}>
      <Title>Paramètres</Title>
      <Tabs>
        <LinkTab to="/settings">Compte</LinkTab>
        <AdminContainer>
          <LinkTab to="/admin/configuration">Configuration de la plateforme</LinkTab>
        </AdminContainer>
      </Tabs>
      <Content>

      {(adminConfiguration.loading) && <ZoneLoadingState />}
      {(!adminConfiguration.loading) && (
        <Zone
          title="Fitnet"
          description={`Cette zone vous permet de désactiver l'intégration fitnet de la solution.
Attention, si vous désactivez l'intégration. Toute donnée créee pendant le laps de temps de la désactivation ne sera plus synchronisé avec fitnet.`}>
          <ZoneContent>
              <Field>
                  <Button isLoading={setConfigurationResponse.loading} onClick={() => setConfiguration()}>
                    {isFitnetIntegrationEnabled ? `Cliquez ici pour désactiver l'intégration` : `Cliquez ici pour activer l'intégration`}
                  </Button>
              </Field>
          </ZoneContent>
        </Zone>
      )}
      </Content>
    </PrincipalLayoutBox >
  );
};
