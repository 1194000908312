
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, LinkTab, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './ProfilesPrestatairesInMissionEsn.module.scss';
import { profilesPrestatairesEsnTranslations } from '../../../translate';
import { useProfilesPrestatairesWithValidatedMissionsQuery } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Link } from '../../../components/Link/Link';
import { availabilityToText } from '../../admin/AdminProfilePrestatairePreview/AdminProfilePrestatairePreview';

const { translate } = profilesPrestatairesEsnTranslations;
export const ProfilesPrestatairesInMissionEsn = () => {
  const list = useProfilesPrestatairesWithValidatedMissionsQuery();
  const data = useMemo(() => {
    return (list.data?.profilesPrestataires?.edges || []).map(profilePrestataire => ({
      id: profilePrestataire?.node?.id,
      missionTitle: profilePrestataire?.node?.validatedAssociatedMissions![0]?.mission?.title,
      missionId: profilePrestataire?.node?.validatedAssociatedMissions![0]?.mission?.id,
      name: `${profilePrestataire?.node?.name} (${profilePrestataire?.node?.trigram})`,
    }));
  }, [list.data]);

  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox className={classnames($.ProfilesPrestatairesEsn)}>
      <Title>{translate('Title')}</Title>
      <Tabs>
        <LinkTab to="/esn">{translate('SubTitle')}</LinkTab>
        <LinkTab to="/esn/missions">Collaborateurs en mission</LinkTab>
      </Tabs>
      <Content>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && list.data?.profilesPrestataires?.total !== 0 && (
          <>
            <Table<TableData> columns={[
              {
                Header: 'Identité',
                accessor: (row) => <Link to={`/esn/profile/${row.id}`}>{row.name}</Link>,
                width: 30,
              },
              {
                Header: 'Titre',
                accessor: (row) => <Link to={`/esn/profile/${row.id}/cra/${row.missionId}`}>{row.missionTitle}</Link>,
                width: 30,
              },
            ]} data={data} />
          </>
        )}
        {!list.loading && list.data?.profilesPrestataires?.total === 0 && (
          <p>Vous n'avez pas encore de mission associée à vos collaborateurs</p>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
