
import React from 'react';
import classnames from 'classnames';
import { TextAuthLayout } from '../../layout/TextAuthLayout/TextAuthLayout';
import $ from './SignupSuccess.module.scss';
import { translate } from '../../../translate';

export const SignupSuccess = () => {
  return (
    <TextAuthLayout
      link={translate('clickHereToGoToSignin')}
      t1={translate('demandeOk')}
      t2={translate('checkEmailForVerified')}
      to="/signin"
      className={classnames($.SignupSuccess)}
    />
  );
};
