import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTypedController } from '@hookform/strictly-typed';
import { Content, PrincipalLayoutBox, Title, Zone, ZoneColumn, ZoneLoadingState, ZoneContent, WarningZone, Tabs, LinkTab } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminFreelanceProfilePrestataireEdition.module.scss';
import { translate, translateRich } from '../../../translate';
import { AdminGetFreelanceProfilePrestataireQuery, useAdminGetFreelanceProfilePrestataireQuery, useAdminSaveFreelanceProfilePrestataireMutation } from '../../../generated/graphql';
import { Field } from '../../../components/Form/Field/Field';
import { Input } from '../../../components/Form/Input/Input';
import { Button } from '../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/auth';
import { useNotification } from '../../../hooks/notifications';
import { Upload } from '../../../components/Upload/Upload';
import { getStorage } from '../../../FirebaseConfigurator';
import { profilePrestataireFilled } from '../../../store/domains/auth/Authentication.actions';
import { useDispatch } from 'react-redux';
import { useFieldAndInput } from '../../../components/Form/FieldAndInput/FieldAndInput';
import { Slider } from '../../../components/Slider/Slider';
import { DeepNonMaybe } from '../../../graphqlHelpers';
import { resetCache } from '../../../apollo';
import { uploadCvFile } from '../../../store/domains/profilePrestataire/ProfilePrestataire.service';
import { useSaveStateWatcher } from '../../../hooks/useSaveStateWatcher';
import { AvailabilityFields } from '../../infos/components/AvailabilityFields';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { useHistory, useParams } from 'react-router-dom';

const booleanToRadio = (bool: boolean) => bool ? 'oui' : 'non';
const radioToBoolean = (value: 'oui' | 'non') => value === 'oui';
const formatDateToInputDateValue = (strDate: string | null) => {
  if (!strDate) {
    return '';
  }

  const date = new Date(strDate);
  return date.toISOString().split('T')[0];
}
const profilePrestataireDataToForm = (profilePrestataire: AdminGetFreelanceProfilePrestataireQuery): FormValues => {
  const values = (profilePrestataire.profilePrestataire as any as DeepNonMaybe<AdminGetFreelanceProfilePrestataireQuery['profilePrestataire']>)!;
  return {
    cvName: values.cvName,
    shouldUploadCv: values.cvName === null,
    uploadInputCv: null,
    tjm: values.tjm === null ? '' : values.tjm + '',
    maximumDistance: values.maximumDistance || 0,
    minimumMonths: values.minimumMonths || 1,
    title: values.title || '',
    isAvailable: booleanToRadio(typeof values.isAvailable === 'boolean' ? values.isAvailable : true),
    postalCode: values.postalCode || '',
    browserAvailabilityDate: formatDateToInputDateValue(values.availabilityDate),
  };
};

type FormValues = {
  uploadInputCv: File | null;
  cvName: string | null;
  shouldUploadCv: boolean;
  tjm: string;
  maximumDistance: number;
  minimumMonths: number;
  title: string;
  isAvailable: 'oui' | 'non';
  postalCode: string;
  browserAvailabilityDate: string;
};

export const AdminFreelanceProfilePrestataireEdition = () => {
  const auth = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { addNotification } = useNotification();

  const dispatch = useDispatch();

  const profilePrestataire = useAdminGetFreelanceProfilePrestataireQuery({
    variables: {
      id,
    },
  });
  const [saveProfilePrestataire, saveProfilePrestataireResponse] = useAdminSaveFreelanceProfilePrestataireMutation();
  const [isSaving, setSaving] = useState(false);
  const onDataSavedSuccess = useCallback(() => {
    setSaving(false);
    dispatch(profilePrestataireFilled());
    resetCache();
    history.push('/');
  }, [dispatch, history]);
  const onDataSavedError = useCallback(() => setSaving(false), []);
  useSaveStateWatcher(
    saveProfilePrestataireResponse.data,
    saveProfilePrestataireResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );

  const [isLoading, setLoading] = useState(true);

  const [cvUrl, setCvUrl] = useState('');
  const [cvName, setCvName] = useState('');
  const [hasAlreadyFileUploaded, setHasAlreadyFileUploaded] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    let fileName = formValues.cvName;
    if (formValues.shouldUploadCv) {
      const uploadSucceed = await uploadCvFile(profilePrestataire?.data?.profilePrestataire?.owner?.id!, formValues.uploadInputCv!.name, formValues.uploadInputCv!);
      if (uploadSucceed === false) {
        setSaving(false);
        addNotification(true, 'Une erreur est survenue, veuillez réessayer.');
      } else {
        fileName = uploadSucceed;
      }
    }

    const isAvailable = radioToBoolean(formValues.isAvailable);
    const availabilityDate = isAvailable ? null : new Date(formValues.browserAvailabilityDate + 'T00:00:00Z').toISOString();
    saveProfilePrestataire({
      variables: {
        input: {
          id,
          isAvailable,
          availabilityDate,
          cvName: fileName!,
          tjm: +formValues.tjm,
          maximumDistance: formValues.maximumDistance,
          minimumMonths: formValues.minimumMonths,
          title: formValues.title,
          postalCode: formValues.postalCode,
        }
      }
    });
  };

  const { register, handleSubmit, errors, reset, control, setValue } = useForm<FormValues>({
    defaultValues: {
      cvName: '',
      tjm: '',
      uploadInputCv: null,
      isAvailable: booleanToRadio(true),
      maximumDistance: 0,
      minimumMonths: 1,
      title: '',
    }
  });
  const TypedController = useTypedController<FormValues>({ control });
  const FieldAndInput = useFieldAndInput<FormValues>({ errors, register });

  useEffect(() => {
    if (profilePrestataire.data) {
      (async function asyncFunc() {
        const data = profilePrestataireDataToForm(profilePrestataire.data!);
        reset(data);
        const hasAlreayFileUploaded = !!(data.cvName && data.cvName !== '');
        setHasAlreadyFileUploaded(hasAlreayFileUploaded);
        setShowUpload(!hasAlreayFileUploaded);
        if (hasAlreayFileUploaded) {
          try {
            const storageRef = getStorage().ref();
            const fileRef = storageRef.child(`/all-cvs/${profilePrestataire?.data?.profilePrestataire?.owner?.id}/${data.cvName}`);
            const url = await fileRef.getDownloadURL();
            setCvUrl(url);
            setCvName(data.cvName!);
          } catch (e) {
            addNotification(true, 'Le CV de ce profil est introuvable, veuillez lui demander de le mettre à jour.');
            setHasAlreadyFileUploaded(false);
            setShowUpload(true);
          }
        }

        setLoading(false);
      })();
      return;
    }

    if (!profilePrestataire.data && !profilePrestataire.loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePrestataire.data, reset]);

  const setBrowserAvailabilityDate = useCallback((months: number) => {
    const date = new Date();
    const dateInFuture = new Date(date.setMonth(date.getMonth() + months));
    setValue('browserAvailabilityDate', dateInFuture.toISOString().split('T')[0]);
  }, [setValue]);

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataireFreelance)}>
      <Title>Profil</Title>
      <Tabs>
        <LinkTab to="/">Edition</LinkTab>
      </Tabs>
      <Content>
        {(profilePrestataire.loading || isLoading) && <ZoneLoadingState />}
        {(!profilePrestataire.loading && !isLoading) && (
          <form onSubmit={handleSubmit(onInternalSubmit)}>
            {!auth?.isProfilePrestataireFilled && (
              <WarningZone title="Profil prestataire incomplet">
                Votre profil prestataire est incomplet, veuillez remplir ces informations.
              </WarningZone>
            )}
            <Zone
              title={translate('myProfilePresta')}
              description={translateRich('myProfilePrestaDescription')}>
              <ZoneContent>
                <FormZone>
                  <FormRow>
                    <AvailabilityFields<FormValues>
                      control={control}
                      availableLabel="Êtes vous disponible immédiatement ?"
                      availableAriaLabel="Disponible"
                      whenAvailableLabel="Dans combien de temps serez-vous disponible ?"
                      whenAvailableAriaLabel="Disponibilité dans le temps"
                      register={register}
                      setAvailabilityDate={(months: number) => setBrowserAvailabilityDate(months)}
                    />
                  </FormRow>
                  <FormRow customWidth={[3, 1]}>
                    <FieldAndInput
                      name="title"
                      registerOptions={{ required: true }}
                      labelKey="profilePrestataireTitle"
                      placeholderKey="profilePrestataireTitleDescription"
                    />
                    <FieldAndInput
                      name="postalCode"
                      registerOptions={{ required: true }}
                      labelKey="profilePrestatairePostalCode"
                    />
                  </FormRow>
                  <FormRow customWidth={[1, 3, 3]}>
                    <Field>
                      <Input
                        label={translate('profilePrestataireTJM')}
                        errored={!!errors.tjm}
                        ref={register({ required: true, valueAsNumber: true })}
                        name="tjm" />
                      <input type="hidden" name="cvName" ref={register()} />
                      <input type="hidden" name="shouldUploadCv" ref={register({ setValueAs: (value) => value === 'true' })} />
                    </Field>
                    <Field>
                      <TypedController
                        rules={{ required: true }}
                        name="maximumDistance"
                        render={({ onChange, value, ...rest }) => (
                          <Slider
                            label={translate('profilePrestataireMaximumDistance')}
                            marks={{
                              0: '0km',
                              1: '15km',
                              2: '30km',
                              3: '50km',
                              4: '200km',
                              5: '+200km',
                            }}
                            min={0}
                            max={5}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Field>
                    <Field>
                      <TypedController
                        rules={{ required: true }}
                        name="minimumMonths"
                        render={({ onChange, value, ...rest }) => (
                          <Slider
                            label={translate('profilePrestataireMinimumMonths')}
                            marks={{
                              1: '1m',
                              2: '2m',
                              3: '3m',
                              4: '6m',
                              5: '+12m',
                            }}
                            min={1}
                            max={5}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Field>
                  </FormRow>
                </FormZone>
              </ZoneContent>
              <ZoneContent>
                <ZoneColumn>
                  {hasAlreadyFileUploaded && (
                    <Field>
                      Votre CV : <a href={cvUrl} target="_blank" rel="noreferrer"><b>{cvName}</b></a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
                      <br /><br />Vous pouvez le mettre à jour en cliquant <a role="button" href="#" onClick={() => setShowUpload(true)}><b>ici</b></a>.
                    </Field>
                  )}
                  {showUpload && (
                    <Field>
                      <TypedController
                        name="uploadInputCv"
                        rules={{ required: !hasAlreadyFileUploaded }}
                        render={({ onChange, value }) => (
                          <Upload hasError={!!errors.uploadInputCv} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files) {
                              onChange(e.target.files[0]);
                              setValue('shouldUploadCv', true);
                            }
                          }} />
                        )}
                      />
                    </Field>
                  )}
                </ZoneColumn>
              </ZoneContent>
            </Zone>
            <Zone hollow={true}>
              <Field align="right">
                <Button isLoading={isSaving || saveProfilePrestataireResponse.loading} type="submit">{translate('save')}</Button>
              </Field>
            </Zone>
          </form>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
