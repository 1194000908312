import React, { useImperativeHandle, useMemo } from 'react';
import { Zone, ZoneContent } from '../../../layout/PrincipalLayout/PrincipalLayout';
import { missionTranslations, companyTranslations } from '../../../../translate';
import { Field } from '../../../../components/Form/Field/Field';
import { Button } from '../../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useFieldAndInput } from '../../../../components/Form/FieldAndInput/FieldAndInput';
import { FormRow, FormZone } from '../../../layout/PrincipalLayout/FormLayout';
import { Select } from '../../../../components/Form/Select/Select';
import { Textarea } from '../../../../components/Form/Textarea/Textarea';
import * as MissionHelpers from '../../../../helpers/mission';
import { Mission_State } from '../../../../generated/graphql';
import { AreaSelect } from '../../../../components/Form/Select/AreaSelect';

type FormValues = {
  area: string;
  description: string;
  postalCode: string;
  experience: string;
  meta: string;
  needMoving: string;
  remote: string;
  startDate: string;
  endDate: string;
  title: string;
  tjm: string;
  availabilityDate: string;
};

export type FormProps = {
  onSubmit: (values: FormValues) => void;
  isLoading: boolean;
  missionState: Mission_State | null;
  buttonLabelOverride?: string;
};
export type RefForm = { reset: (values: FormValues) => void };
const translate = missionTranslations.translate;
const companyTranslate = companyTranslations.translate;
export const Form = React.forwardRef<RefForm, FormProps>(({ onSubmit, isLoading, missionState, buttonLabelOverride }: FormProps, ref) => {
  const { register, handleSubmit, errors, reset } = useForm<FormValues>();
  const FieldAndInput = useFieldAndInput<FormValues>({ errors, register });
  const imperativeHandle = useMemo(() => ({ reset }), [reset]);
  useImperativeHandle(ref, () => imperativeHandle);

  let button: JSX.Element | null;
  let shouldReadonly: boolean;
  switch (missionState) {
    case null:
      shouldReadonly = false;
      button = (
        <Button isLoading={isLoading} type="submit">{buttonLabelOverride || translate('SaveNew')}</Button>
      );
      break;
    // also default state when new mision
    case Mission_State.WaitingValidation:
      shouldReadonly = false;
      button = (
        <Button isLoading={isLoading} type="submit">{buttonLabelOverride || translate('SaveExisting')}</Button>
      );
      break;
    case Mission_State.Refused:
    case Mission_State.Started:
    case Mission_State.Ended:
    case Mission_State.Validated:
    default:
      shouldReadonly = true;
      button = null;
      break;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Zone
        title=""
        description="">
        <ZoneContent>
          <FormZone>
            <FormRow customWidth={[4, 1]}>
              <FieldAndInput
                name="title"
                registerOptions={{ required: true }}
                label={translate('Form_Title')}
                disabled={shouldReadonly}
              />
              <FieldAndInput
                name="tjm"
                registerOptions={{ required: true, valueAsNumber: true }}
                label={translate('Form_TJM')}
                disabled={shouldReadonly}
              />
            </FormRow>
            <FormRow customWidth={[2, 2, 1]}>
              <FieldAndInput
                type="date"
                name="startDate"
                registerOptions={{ required: true }}
                label={translate('Form_StartDate')}
                disabled={shouldReadonly}
              />
              <FieldAndInput
                type="date"
                name="endDate"
                registerOptions={{ required: true }}
                label={translate('Form_EndDate')}
                disabled={shouldReadonly}
              />
            </FormRow>
            <FormRow customWidth={[1, 1]}>
              <Field>
                <Select
                  name="experience"
                  ref={register({ required: true, valueAsNumber: true, validate: value => value !== '' })}
                  emptyLabel={translate('Form_Experience') + '*'}
                  options={[
                    { value: '-1', label: MissionHelpers.experienceToText(-1) },
                    { value: '0', label: MissionHelpers.experienceToText(0) },
                    { value: '2', label: MissionHelpers.experienceToText(2) },
                    { value: '7', label: MissionHelpers.experienceToText(7) },
                  ]}
                  disabled={shouldReadonly}
                />
              </Field>
              <Field>
                <AreaSelect
                  name="area"
                  ref={register({ required: true, validate: value => value !== '' })}
                  emptyLabel={companyTranslate('Area') + '*'}
                  disabled={shouldReadonly}
                />
              </Field>
              <FieldAndInput
                name="postalCode"
                registerOptions={{ required: true }}
                label={translate('Form_PostalCode')}
                disabled={shouldReadonly}
              />
            </FormRow>
            <FormRow customWidth={[1, 1, 1]}>
              <Field>
                <Select
                  name="remote"
                  ref={register({ required: true, valueAsNumber: true, validate: value => value !== '' })}
                  emptyLabel={translate('Form_Remote') + '*'}
                  options={[
                    { value: '0', label: MissionHelpers.remoteToText(0) },
                    { value: '1', label: MissionHelpers.remoteToText(1) },
                    { value: '2', label: MissionHelpers.remoteToText(2) },
                  ]}
                  disabled={shouldReadonly}
                />
              </Field>
              <Select
                name="needMoving"
                ref={register({ required: true, valueAsNumber: true, validate: value => value !== '' })}
                emptyLabel={translate('Form_NeedMoving') + '*'}
                options={[
                  { value: '0', label: MissionHelpers.needMovingToText(0) },
                  { value: '1', label: MissionHelpers.needMovingToText(1) },
                ]}
                disabled={shouldReadonly}
              />
            </FormRow>
            <FormRow customWidth={[1, 3, 3]}>
            </FormRow>
            <FormRow>
              <Field>
                <Textarea
                  ref={register({ required: true })}
                  rows={20}
                  name="description"
                  label={translate('Form_Description') + ' *'}
                  readOnly={shouldReadonly}
                />
              </Field>
            </FormRow>
            <FormRow>
              <Field>
                <Textarea
                  ref={register({ required: false })}
                  rows={3}
                  name="meta"
                  label={translate('Form_Meta')}
                  readOnly={shouldReadonly}
                />
              </Field>
            </FormRow>
          </FormZone>
        </ZoneContent>
      </Zone>
      {button && (
        <Zone hollow={true}>
          <Field align="right">
            {button}
          </Field>
        </Zone>
      )}
    </form>
  );
});
