const daysOff = [
  '01/01/2025',
  '21/04/2025',
  '01/05/2025',
  '08/05/2025',
  '29/05/2025',
  '09/06/2025',
  '14/07/2025',
  '15/08/2025',
  '01/11/2025',
  '11/11/2025',
  '25/12/2025',
  '01/01/2024',
  '01/04/2024',
  '01/05/2024',
  '08/05/2024',
  '09/05/2024',
  '20/05/2024',
  '14/07/2024',
  '15/08/2024',
  '01/11/2024',
  '11/11/2024',
  '25/12/2024',
  '01/01/2023',
  '10/04/2023',
  '01/05/2023',
  '08/05/2023',
  '18/05/2023',
  '29/05/2023',
  '14/07/2023',
  '15/08/2023',
  '01/11/2023',
  '11/11/2023',
  '25/12/2023',
  '01/01/2022',
  '18/04/2022',
  '01/05/2022',
  '08/05/2022',
  '26/05/2022',
  '06/06/2022',
  '14/07/2022',
  '15/08/2022',
  '01/11/2022',
  '11/11/2022',
  '25/12/2022',
  '01/01/2021',
  '05/04/2021',
  '01/05/2021',
  '08/05/2021',
  '13/05/2021',
  '24/05/2021',
  '14/07/2021',
  '15/08/2021',
  '01/11/2021',
  '11/11/2021',
  '25/12/2021',
  '01/01/2020',
  '13/04/2020',
  '01/05/2020',
  '08/05/2020',
  '21/05/2020',
  '01/06/2020',
  '14/07/2020',
  '15/08/2020',
  '01/11/2020',
  '11/11/2020',
  '25/12/20'
];

export default daysOff;
/*const daysOff = {
  2021: {
    1: [1],
    4: [5],
    5: [1, 8, 13, 24],
    7: [14],
    8: [15],
    11: [1, 11],
    12: [25],
  },
  2022: {
    1: [1],
    4: [18],
    5: [1, 8, 26],
    6: [6],
    7: [14],
    8: [15],
    11: [1, 11],
    12: [25],
  },
  2023: {
    1: [1],
    4: [10],
    5: [1, 8, 18, 29],
    7: [14],
    8: [15],
    11: [1, 11],
    12: [25],
  },
  2024: {
    1: [1],
    4: [1],
    5: [1, 8, 9, 20],
    7: [14],
    8: [15],
    11: [1, 11],
    12: [25],
  },
  2025: {
    1: [1],
    4: [21],
    5: [1, 8, 29],
    6: [9],
    7: [14],
    8: [15],
    11: [1, 11],
    12: [25],
  },
};

export default daysOff;*/
