import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Content, PrincipalLayoutBox, Title, Tabs, LinkTab, ZoneLoadingState } from '../../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminUpsertMission.module.scss';
import { useAdminSaveExistingMissionMutation, useAdminSaveNewMissionMutation, useAdminMissionLazyQuery, AdminMissionQuery } from '../../../../generated/graphql';
import { resetCache } from '../../../../apollo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSaveStateWatcher } from '../../../../hooks/useSaveStateWatcher';
import { Form, RefForm } from '../../../infos/MissionPreview/components/Form';
import { DeepNonMaybe } from '../../../../graphqlHelpers';

const missionDataToForm = (mission: AdminMissionQuery): FormValues => {
  const values = (mission.mission as any as DeepNonMaybe<AdminMissionQuery['mission']>)!;

  const [startDate] = values.startDate.split('T');
  const [endDate] = values.endDate.split('T');
  return {
    startDate,
    endDate,
    area: values.area,
    description: values.description,
    experience: (values.experience) + '',
    meta: values.meta,
    needMoving: (values.needMoving) + '',
    postalCode: values.postalCode,
    remote: (values.remote) + '',
    title: values.title,
    tjm: (values.tjm) + '',
    availabilityDate: '',
  };
};

type FormValues = {
  area: string;
  description: string;
  postalCode: string;
  experience: string;
  meta: string;
  needMoving: string;
  remote: string;
  startDate: string;
  endDate: string;
  title: string;
  tjm: string;
  availabilityDate: string;
};

export const AdminUpsertMission = () => {
  const history = useHistory();

  const { id, userId } = useRouteMatch<{ id: string; userId: string; }>().params;
  const isNew = id === 'new';

  const [fetchMission, mission] = useAdminMissionLazyQuery({
    variables: {
      id,
    }
  });

  const [saveExistingMission, saveExistingMissionResponse] = useAdminSaveExistingMissionMutation();
  const [saveNewMission, saveNewMissionResponse] = useAdminSaveNewMissionMutation();
  const [isSaving, setSaving] = useState(false);
  const onDataSavedSuccess = useCallback(() => {
    setSaving(false);
    resetCache();
    history.push('/admin/missions');
  }, [history]);
  const onDataSavedError = useCallback(() => setSaving(false), []);
  useSaveStateWatcher(
    saveExistingMissionResponse.data,
    saveExistingMissionResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );
  useSaveStateWatcher(
    saveNewMissionResponse.data,
    saveNewMissionResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );

  const [isLoading, setLoading] = useState(true);

  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    if (isNew) {
      saveNewMission({
        variables: {
          userId,
          input: {
            area: formValues.area,
            description: formValues.description,
            postalCode: formValues.postalCode,
            experience: +formValues.experience,
            meta: formValues.meta || '',
            needMoving: +formValues.needMoving,
            remote: +formValues.remote,
            startDate: formValues.startDate,
            endDate: formValues.endDate,
            title: formValues.title,
            tjm: +formValues.tjm,
          }
        }
      });
    } else {
      saveExistingMission({
        variables: {
          userId,
          input: {
            id,
            area: formValues.area,
            description: formValues.description,
            postalCode: formValues.postalCode,
            experience: +formValues.experience,
            meta: formValues.meta || '',
            needMoving: +formValues.needMoving,
            remote: +formValues.remote,
            startDate: formValues.startDate,
            endDate: formValues.endDate,
            title: formValues.title,
            tjm: +formValues.tjm,
          }
        }
      });
    }
  };

  const formRef = useRef<RefForm | null>(null);

  useEffect(() => {
    if (mission.data) {
      const data = missionDataToForm(mission.data!);
      formRef.current?.reset(data);
      setLoading(false);

      return;
    }

    if (!mission.data && !mission.loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission.data, formRef]);

  useEffect(() => {
    if (!isNew) {
      fetchMission();
    }
  }, [fetchMission, formRef, isNew]);

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataireFreelance)}>
      <Title>MISSIONS</Title>
      <Content>
        {(mission.loading || isLoading) && <ZoneLoadingState />}
        {/* Enforcing missionState to null to stop the form from enforcing disabled inputs */}
        {(!mission.loading && !isLoading) && (
          <Form isLoading={isSaving || saveExistingMissionResponse.loading} onSubmit={onInternalSubmit} ref={formRef} missionState={null} buttonLabelOverride="Sauvegarder" />
        )}
      </Content>
    </PrincipalLayoutBox >
  );
};
