import React, { useState } from 'react';
import $ from './Signup.module.scss';
import { SignupPanel } from '../components/AuthPanel/SignupPanel';
import { ClientSignupFormSubmitValues } from '../components/ClientSignupForm/ClientSignupForm';
import { useSignupClientMutation, useSignupEsnMutation, useSignupFreelanceMutation } from '../../../generated/graphql';
import { EsnSignupFormSubmitValues } from '../components/EsnSignupForm/EsnSignupForm';
import { FreelanceSignupFormSubmitValues } from '../components/FreelanceSignupForm/FreelanceSignupForm';
import { useHistory } from 'react-router-dom';
import { AuthLayout } from '../../layout/AuthLayout/AuthLayout';

const Signup = () => {
  const history = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);

  const [signupClient] = useSignupClientMutation();
  const cbSignupClient = async (payload: ClientSignupFormSubmitValues) => {
    try {
      const { data } = await signupClient({
        variables: payload
      });

      if (data?.signUpClient?.errors) {
        setError(data?.signUpClient?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push('/signup-success');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
    }
    setError(undefined);
  };
  const [signupEsn] = useSignupEsnMutation();
  const cbSignupEsn = async (payload: EsnSignupFormSubmitValues) => {
    try {
      const { data } = await signupEsn({
        variables: payload
      });

      if (data?.signUpEsn?.errors) {
        setError(data?.signUpEsn?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push('/signup-success');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
    }
    setError(undefined);
  };
  const [signupFreelance] = useSignupFreelanceMutation();
  const cbSignupFreelance = async (payload: FreelanceSignupFormSubmitValues) => {
    try {
      const { data } = await signupFreelance({
        variables: payload
      });

      if (data?.signUpFreelance?.errors) {
        setError(data?.signUpFreelance?.errors.some(([_, message]) => message && message.indexOf('The email address is already')) ? 'Cette adresse e-mail est déjà utilisée.' : 'Une erreur est survenue, veuillez réessayer.');
        return;
      }

      history.push('/signup-success');
    } catch (e) {
      setError('Une erreur est survenue, veuillez réessayer.');
      return;
    }

    setError(undefined);
  };

  return (
    <AuthLayout className={$.Signup}>
      <SignupPanel
        onSignupClient={cbSignupClient}
        onSignupEsn={cbSignupEsn}
        onSignupFreelance={cbSignupFreelance}
        error={error}
      />
    </AuthLayout>
  );
};

export default Signup;
