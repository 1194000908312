
import React, { useState } from 'react';
import classnames from 'classnames';
import { Content, LinkTab, PrincipalLayoutBox, Tabs, Title, Zone, ZoneColumn, ZoneContent } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './Settings.module.scss';
import { translate, translateRich } from '../../../translate';
import { Button } from '../../../components/Button/Button';
import { Field, FieldError } from '../../../components/Form/Field/Field';
import { Input } from '../../../components/Form/Input/Input';
import { useNotification } from '../../../hooks/notifications';
import { useForm, useWatch } from 'react-hook-form';
import { PasswordStrengthValidator, validatePassword } from '../../auth/components/AuthPanel/PasswordStrengthValidator/PasswordStrengthValidator';
import { useAuth, useUserType } from '../../../hooks/auth';
import app from 'firebase/app';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const Settings = () => {
  const { addNotification } = useNotification();
  const auth = useAuth();
  const { AdminContainer } = useUserType();

  const [isSaving, setSaving] = useState(false);
  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    const credentials = app.auth.EmailAuthProvider.credential(auth?.firebaseUser.email!, formValues.oldPassword);
    try {
      await auth?.firebaseUser.reauthenticateWithCredential(credentials);
      await auth?.firebaseUser.updatePassword(formValues.newPassword);
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        addNotification(true, 'Votre ancien mot de passe est invalide, veuillez réessayer plus tard.');
        setSaving(false);
        return;
      } else if (e.code === 'auth/too-many-requests') {
        addNotification(true, 'Trop de tentatives, veuillez réessayer plus tard.');
        setSaving(false);
        return;
      }

      addNotification(true, 'Une erreur est survenue, veuillez réessayer.');
      setSaving(false);
      return;
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      addNotification(false, 'Changement de mot de passe effectué avec succés.');
      reset();
      setSaving(false);
    }, 500);
  };

  const { register, handleSubmit, errors, control, reset } = useForm<FormValues>({
    defaultValues: {
      newPassword: '',
      oldPassword: '',
      repeatNewPassword: '',
    }
  });
  const newPasswordValue = useWatch<string>({
    control,
    name: 'newPassword',
  });

  return (
    <PrincipalLayoutBox className={classnames($.Settings)}>
      <Title>Paramètres</Title>
      <Tabs>
        <LinkTab to="/settings">Compte</LinkTab>
        <AdminContainer>
          <LinkTab to="/admin/configuration">Configuration de la plateforme</LinkTab>
        </AdminContainer>
      </Tabs>
      <Content>
        <form onSubmit={handleSubmit(onInternalSubmit)}>
          <Zone
            title={translate('settingsTitle')}
            description={translateRich('settingsDescription')}>
            <ZoneContent>
              <ZoneColumn>
                <Field>
                  <Input type="password" label={translate('oldPassword')} errored={!!errors.oldPassword} ref={register({ required: true })} name="oldPassword"></Input>
                  {errors.oldPassword && <FieldError>{translate('errorOldPassword')}</FieldError>}
                </Field>
                <Field>
                  <Input type="password" label={translate('newPassword')} errored={!!errors.newPassword} ref={register({ required: true, validate: (value) => validatePassword(value).isValid })} name="newPassword"></Input>
                </Field>
                <Field>
                  <PasswordStrengthValidator value={newPasswordValue || ''} />
                </Field>
                <Field>
                  <Input type="password" label={translate('newPasswordRepeat')} errored={!!errors.repeatNewPassword} ref={register({ required: true, validate: (value) => value === newPasswordValue })} name="repeatNewPassword"></Input>
                </Field>
              </ZoneColumn>
              <ZoneColumn />
            </ZoneContent>
          </Zone>
          <Zone hollow={true}>
            <Field align="right">
              <Button isLoading={isSaving} type="submit">{translate('valid')}</Button>
            </Field>
          </Zone>
        </form>
      </Content>
    </PrincipalLayoutBox >
  );
};
