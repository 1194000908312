import { ActionWithoutPayload, Action } from './Action';

export default function createAction<T extends string>(
  type: T,
): ActionWithoutPayload<T>;
export default function createAction<T extends string, P extends object>(
  type: T,
  payload: P,
): Action<T, P>;

export default function createAction<T extends string, P extends object>(
  type: T,
  payload?: P,
) {
  if (typeof payload === 'undefined') {
    return { type };
  }

  return { type, payload };
}
