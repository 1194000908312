import { ActionWithoutPayload, Action } from './Action';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Func = (...args: any[]) => any;

export type ActionCreator<
  ActionType extends string,
  PayloadCreator extends Func
> = {
  (...args: Parameters<PayloadCreator>): Action<
    ActionType,
    ReturnType<PayloadCreator>
  >;
  type: ActionType;
};

export type ActionCreatorWithoutPayload<ActionType extends string> = {
  (): ActionWithoutPayload<ActionType>;
  type: ActionType;
};

export function type<ActionType extends string>(
  actionCreator: ActionCreatorWithoutPayload<ActionType>,
): ActionType;
export function type<ActionType extends string, PayloadCreator extends Func>(
  actionCreator: ActionCreator<ActionType, PayloadCreator>,
): ActionType;
export function type(
  actionCreator:
    | ActionCreatorWithoutPayload<string>
    | ActionCreator<string, Func>,
) {
  return actionCreator.type;
}
