import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import $ from './Upload.module.scss';
import { useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'currentColor',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export type UploadProps = React.HTMLAttributes<HTMLInputElement> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError: boolean;
};
export const Upload = ({ onChange, hasError }: UploadProps) => {
  const onDrop = useCallback((acceptedFiles) => {
    onChange({ target: { files: acceptedFiles } } as any);
  }, [onChange]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf',
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive && !hasError ? activeStyle : {}),
    ...(isDragAccept && !hasError ? acceptStyle : {}),
    ...(isDragReject || hasError ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    hasError,
  ]);

  const file = acceptedFiles[0];
  const explainText = false ? (
    <p>Votre CV est déjà sur la plateforme, vous pouvez le remplacer en glissant-déposer ou cliquant ici.</p>
  ) : (
      <p>Glissez-déposez votre CV ici, ou cliquez pour choisir votre fichier.</p>
    );
  return (
    <div className={classnames($.Upload)}>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ onChange })} />
          {file ? (<p>CV selectionné: {' '}{file.name}</p>) : explainText}
        </div>
      </div>
    </div >
  );
};
