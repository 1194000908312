
import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, Content, ZoneLoadingState, ZoneContent, Zone, ZoneColumn } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './StartedMissionCraPreview.module.scss';
import { Cra_State_Enum, useAcceptCraMutation, useMissionWithCraQuery, useRefuseCraMutation } from '../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import { CraAgenda } from '../../../components/CraAgenda/CraAgenda';
import { Button, ButtonType } from '../../../components/Button/Button';
import { Field } from '../../../components/Form/Field/Field';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNotification } from '../../../hooks/notifications';
import { ActionsBox, Box } from '../../layout/PrincipalLayout/components/Box';

export const StartedMissionCraPreview = () => {
  const { id } = useRouteMatch<{ id: string; }>().params;

  const month = useMemo(() => (new Date()).getMonth() + 1, []);
  const year = useMemo(() => (new Date()).getFullYear(), []);
  const [monthState, setMonthState] = useState(month);
  const [yearState, setYearState] = useState(year);
  const missionQuery = useMissionWithCraQuery({
    variables: {
      id,
      month: monthState,
      year: yearState,
    }
  });
  const mission = useMemo(() => {
    return missionQuery.data?.mission;
  }, [missionQuery.data]);
  const cras = useMemo(() => (mission?.craState?.cras || []).map(c => ({ value: c?.value!, date: new Date(c?.date!), disabled: c?.disabled! })), [mission]);
  const [draftCras, setDraftCras] = useState(cras);
  useEffect(() => {
    setDraftCras(cras);
  }, [cras]);

  const onValueChange = useCallback((day: Date, value: number) => {
    const existingCraIndex = draftCras.findIndex(cra => cra.date.getDate() === day.getDate());
    const newCras = [...draftCras];
    if (existingCraIndex !== -1) {
      newCras[existingCraIndex] = { date: day, value, disabled: false };
    } else {
      newCras.push({ date: day, value, disabled: false });
    }

    setDraftCras(newCras);
  }, [draftCras]);

  const notifications = useNotification();

  const [acceptCra, acceptCraResponse] = useAcceptCraMutation();

  const accept = useCallback(async () => {
    await acceptCra({
      variables: {
        month: monthState,
        year: yearState,
        missionId: id,
      }
    });

    await missionQuery.refetch();
    notifications.addNotification(false, 'Merci, vous recevrez sous peu une demande de signature.');

  }, [acceptCra, id, missionQuery, monthState, notifications, yearState]);

  const [refuseCra, refuseCraResponse] = useRefuseCraMutation();

  const refuse = useCallback(async () => {
    await refuseCra({
      variables: {
        month: monthState,
        year: yearState,
        missionId: id,
      }
    });

    await missionQuery.refetch();
    notifications.addNotification(false, 'Merci, votre prestataire a été notifié.');

  }, [refuseCra, id, missionQuery, monthState, notifications, yearState]);

  const changeDate = useCallback((add: boolean) => {
    let nextMonth = monthState;
    let nextYear = yearState;
    if (add) {
      if (nextMonth === 12) {
        nextMonth = 1;
        nextYear += 1;
      } else {
        nextMonth += 1;
      }
    } else {
      if (nextMonth === 0) {
        nextMonth = 12;
        nextYear -= 1;
      } else {
        nextMonth -= 1;
      }
    }

    setMonthState(nextMonth);
    setYearState(nextYear);
  }, [monthState, yearState]);

  useEffect(() => {
    if (missionQuery.data) {
      setHasLoaded(true);
    }
  }, [missionQuery.data]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const {
    canAccept,
    canRefuse,
  } = useMemo(() => {
    return {
      canAccept: mission?.craState?.state === Cra_State_Enum.ToValidateByClient,
      canRefuse: mission?.craState?.state === Cra_State_Enum.ToValidateByClient,
    };
  }, [mission?.craState?.state]);
  return (
    <PrincipalLayoutBox className={classnames($.CraManager)}>
      <Title>Compte rendu d'activité</Title>
      <Tabs>
        <br />
      </Tabs>
      <Content>
        {(missionQuery.loading && !hasLoaded) && <ZoneLoadingState />}
        {!(missionQuery.loading && !hasLoaded) && (
          <>
            <Zone
              className={$.ButtonsContainer}
              title={mission?.title!}>
              <ZoneContent>
                <div className={$.Buttons}>
                  <button onClick={() => changeDate(false)}>Précedent</button>
                  <button onClick={() => changeDate(true)}>Suivant</button>
                </div>
                <ZoneColumn>
                  <Field>
                    {missionQuery.loading && <ZoneLoadingState />}
                    {!missionQuery.loading && <CraAgenda enforceDisabled month={monthState} year={yearState} data={draftCras} onValueChange={onValueChange} />}
                  </Field>
                </ZoneColumn>
              </ZoneContent>
            </Zone>
            {
              !missionQuery.loading &&
              (
                <ActionsBox hollow>
                  {canRefuse && <Button styleType={ButtonType.Secondary} onClick={() => refuse()} isLoading={refuseCraResponse.loading}>Demander des modifications</Button>}
                  {canAccept && <Button styleType={ButtonType.Primary} onClick={() => accept()} isLoading={acceptCraResponse.loading}>Accepter ce CRA</Button>}
                </ActionsBox>
              )
            }
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
