
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, LinkTab, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './ProfilesPrestatairesEsn.module.scss';
import { profilesPrestatairesEsnTranslations } from '../../../translate';
import { useProfilesPrestatairesQuery } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Link } from '../../../components/Link/Link';
import { availabilityToText } from '../../admin/AdminProfilePrestatairePreview/AdminProfilePrestatairePreview';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { offsetToCursor } from 'graphql-relay';

type Filters = {
  page: number;
}
const defaultFilters: Filters = {
  page: 0
};
const getFiltersFromUrl = (searchParams: string | null) => {
  const sanitized = searchParams || '{}';
  const parsed = JSON.parse(sanitized);
  const cleaned = {
    ...defaultFilters,
    ...parsed,
  } as Filters;

  return cleaned;
};
const { translate } = profilesPrestatairesEsnTranslations;
export const ProfilesPrestatairesEsn = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const filters = getFiltersFromUrl(query.get('filters'));
  const updateQueryParam = <T extends keyof Filters,>(name: T, value: Filters[T]) => {
    const newFilters = { ...filters, [name]: value };
    const stringified = JSON.stringify(newFilters);

    history.push({ search: `?filters=${stringified}` });
  };
  const offset = filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1);
  const list = useProfilesPrestatairesQuery({
    variables: {
      after: offset,
    }
  });
  const data = useMemo(() => {
    return (list.data?.profilesPrestataires?.edges || []).map(profilePrestataire => ({
      id: profilePrestataire?.node?.id,
      tjm: profilePrestataire?.node?.tjm,
      title: profilePrestataire?.node?.title,
      available: profilePrestataire?.node?.available!,
      name: `${profilePrestataire?.node?.name} (${profilePrestataire?.node?.trigram})`,
      availabilityInMonths: profilePrestataire?.node?.availabilityInMonths!
    }));
  }, [list.data]);

  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox className={classnames($.ProfilesPrestatairesEsn)}>
      <Title>{translate('Title')}</Title>
      <Tabs>
        <LinkTab to="/esn">{translate('SubTitle')}</LinkTab>
        <LinkTab to="/esn/missions">Collaborateurs en mission</LinkTab>
      </Tabs>
      <Content>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <>
            <div className={$.AddCollab}>
              <Link as="button" to="/esn/profile/new">Nouveau collaborateur</Link>
            </div>
            <Table<TableData> columns={[
              {
                Header: 'Identité',
                accessor: (row) => <Link to={`/esn/profile/${row.id}`}>{row.name}</Link>,
                width: 30,
              },
              {
                Header: 'Titre',
                accessor: 'title',
                width: 30,
              },
              {
                Header: 'TJM',
                accessor: 'tjm',
                width: 10,
              },
              {
                Header: 'Disponibilité',
                accessor: (row) => availabilityToText(row.available, row.availabilityInMonths),
                width: 20,
              },
            ]} data={data} />
            <Pagination current={filters.page} pageSize={10} total={list.data?.profilesPrestataires?.total!} onClick={page => updateQueryParam('page', page)} />
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
