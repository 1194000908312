
import React from 'react';
import classnames from 'classnames';
import { TextAuthLayout } from '../../layout/TextAuthLayout/TextAuthLayout';
import $ from './NotVerified.module.scss';
import { translate } from '../../../translate';
import { useAuth } from '../../../hooks/auth';

export const NotVerified = () => {
  const auth = useAuth();

  auth?.firebaseAuth.signOut();

  return (
    <TextAuthLayout
      link={translate('clickHereToGoToSignin')}
      t1={translate('notVerified')}
      t2={translate('checkEmailForVerified')}
      to="/signin"
      className={classnames($.NotVerified)}
    />
  );
};
