import React, { createContext, useMemo, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import classnames from 'classnames';
import Signin from './views/auth/Signin/Signin';
import Signup from './views/auth/Signup/Signup';
import { NotAuthenticatedRoute, PrivateRoute } from './router/Routes';
import { useStoreSelector } from './hooks/redux';
import { NotVerified } from './views/auth/NotVerified/NotVerified';
import { SignupSuccess } from './views/auth/SignupSuccess/SignupSuccess';
import { Home } from './views/infos/Home/Home';
import { Profile } from './views/infos/Profile/Profile';
import { Validate } from './views/auth/Validate/Validate';
import { Action } from './views/auth/Action/Action';
import $ from './App.module.scss';
import { Spinner } from './components/Spinner/Spinner';
import { ProfilePrestataireFreelance } from './views/infos/ProfilePrestataireFreelance/ProfilePrestataireFreelance';
import { PrincipalLayout } from './views/layout/PrincipalLayout/PrincipalLayout';
import { Settings } from './views/infos/Settings/Settings';
import { Help } from './views/infos/Help/Help';
import Forgot from './views/auth/Forgot/Forgot';
import { useUserType } from './hooks/auth';
import { AdminProfilePrestataireListing } from './views/admin/AdminProfilePrestataireListing/AdminProfilePrestataireListing';
import { AdminUserListing } from './views/admin/AdminUserListing/AdminUserListing';
import { AdminProfilePrestatairePreview } from './views/admin/AdminProfilePrestatairePreview/AdminProfilePrestatairePreview';
import { ProfilesPrestatairesEsn } from './views/infos/ProfilesPrestatairesEsn/ProfilesPrestatairesEsn';
import { ProfilePrestatairePreviewEsn } from './views/infos/ProfilePrestatairePreviewEsn/ProfilePrestatairePreviewEsn';
import { MissionsListing } from './views/infos/MissionsListing/MissionsListing';
import { MissionPreview } from './views/infos/MissionPreview/MissionPreview';
import { StartedMissionCraPreview } from './views/infos/MissionPreview/StartedMissionCraPreview';
import { AdminMissionsListing } from './views/admin/AdminMissionsListing/AdminMissionsListing';
import { AdminMissionPreview } from './views/admin/AdminMissionPreview/AdminMissionPreview';
import { PrestatairesListing } from './views/infos/PrestatairesListing/PrestatairesListing';
import { AdminDashboard } from './views/admin/AdminDashboard/AdminDashboard';
import { AdminCompaniesListing } from './views/admin/AdminCompaniesListing/AdminCompaniesListing';
import { FreelanceMissionsListing } from './views/infos/FreelanceMissionsListing.tsx/FreelanceMissionsListing';
import { ProfilesPrestatairesInMissionEsn } from './views/infos/ProfilesPrestatairesInMissionEsn/ProfilesPrestatairesInMissionEsn';
import { AdminUserCreation } from './views/admin/AdminUserCreation/AdminUserCreation';
import { CraManager } from './views/infos/CraManager/CraManager';
import { ThemeProvider } from 'styled-components';
import { theme } from './Theme';
import { AdminUserEdition } from './views/admin/AdminUserEdition/AdminUserEdition';
import { AdminFreelanceProfilePrestataireEdition } from './views/admin/AdminProfilePrestataireEdition/AdminFreelanceProfilePrestataireEdition';
import { AdminEnterpriseProfilePrestataireEdition } from './views/admin/AdminProfilePrestataireEdition/AdminEnterpriseProfilePrestataireEdition';
import { AdminUpsertMission } from './views/admin/missions/AdminMissionUpsert/AdminUpsertMission';
import { AdminConfiguration } from './views/admin/AdminConfiguration/AdminConfiguration';

type NotificationContextType = {
  addNotification: (isError: boolean, message: string) => void;
};
export const NotificationContext = createContext<NotificationContextType | null>(null);

const App = () => {
  const {
    StandardUserContainer,
    SuperUserContainer,
  } = useUserType();

  const [notification, setNotification] = useState<{ isError: boolean, message: string } | null>(null);
  const notificationContext = useMemo<NotificationContextType>(() => ({
    addNotification: (isError, message) => {
      setNotification({ isError, message });
      setTimeout(() => {
        setNotification(null);
      }, 6000);
    }
  }), []);

  const isAppReady = useStoreSelector(state => state.root.isAppReady);
  if (!isAppReady) {
    return (
      <div className={$.AppLoader}>
        <div className={$.AppLoaderContainer}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <NotificationContext.Provider value={notificationContext}>
        {!!notification && <div className={classnames($.Notification, { [$.NotificationError]: notification.isError })}>{notification.message}</div>}
        <Router>
          <Switch>
            <Route path="/not-found" exact>
              <div>Cette page n'existe pas</div>
              <a href="/">Retourner à la page d'accueil</a>
            </Route>
            <Route path="/action" exact>
              <Action />
            </Route>
            <Route path="/signup-success" exact>
              <SignupSuccess />
            </Route>
            <Route path="/not-verified" exact>
              <NotVerified />
            </Route>
            <Route path="/validate" exact>
              <Validate />
            </Route>
            <NotAuthenticatedRoute path="/forgot" exact>
              <Forgot />
            </NotAuthenticatedRoute>
            <NotAuthenticatedRoute path="/signin" exact>
              <Signin />
            </NotAuthenticatedRoute>
            <NotAuthenticatedRoute path="/signup" exact>
              <Signup />
            </NotAuthenticatedRoute>
            <Route path={[
              '/missions',
              '/missions/waiting',
              '/missions/started',
              '/mission/:id',
              '/mission/:id/cra',
              '/profile',
              '/profile/prestataire',
              '/my/cra/:missionId',
              '/my/missions',
              '/esn',
              '/esn/missions',
              '/esn/profile/:id',
              '/esn/profile/:profilePrestataireId/cra/:missionId',
              '/',
              '/help',
              '/settings',
              '/sovren',
              '/admin/prestataires/freelance/edit/:id',
              '/admin/prestataires/enterprise/:userId/:id',
              '/admin/prestataire/:id',
              '/admin/prestataires',
              '/admin/missions',
              '/admin/mission/:id',
              '/admin/missions/enterprise/:userId/:id',
              '/admin/users/creation',
              '/admin/users/edit/:id',
              '/admin/users/:cursor',
              '/admin/users',
              '/admin/companies',
              '/admin/companies/:cursor',
              '/admin/configuration',
              '/prestataires',
              '/prestataire/:id'
            ]} exact>
              <PrincipalLayout>
                <Switch>
                  <PrivateRoute path="/admin/prestataire/:id" exact>
                    <AdminProfilePrestatairePreview />
                  </PrivateRoute>
                  <PrivateRoute path="/settings" exact>
                    <Settings />
                  </PrivateRoute>
                  <PrivateRoute path="/help" exact>
                    <Help />
                  </PrivateRoute>
                  <PrivateRoute path="/profile" forceIdentity={userType => userType.isStandardUser} exact>
                    <Profile />
                  </PrivateRoute>
                  <PrivateRoute path="/profile/prestataire" forceIdentity={userType => userType.isFreelance} exact>
                    <ProfilePrestataireFreelance />
                  </PrivateRoute>
                  <PrivateRoute path="/my/cra/:missionId" forceIdentity={userType => userType.isFreelance} exact>
                    <CraManager />
                  </PrivateRoute>
                  <PrivateRoute path="/my/missions" forceIdentity={userType => userType.isFreelance} exact>
                    <FreelanceMissionsListing />
                  </PrivateRoute>
                  <PrivateRoute path="/esn" forceIdentity={userType => userType.isESN} exact>
                    <ProfilesPrestatairesEsn />
                  </PrivateRoute>
                  <PrivateRoute path="/esn/profile/:id" forceIdentity={userType => userType.isESN} exact>
                    <ProfilePrestatairePreviewEsn />
                  </PrivateRoute>
                  <PrivateRoute path="/esn/profile/:profilePrestataireId/cra/:missionId" forceIdentity={userType => userType.isESN} exact>
                    <CraManager />
                  </PrivateRoute>
                  <PrivateRoute path="/esn/missions" forceIdentity={userType => userType.isESN} exact>
                    <ProfilesPrestatairesInMissionEsn />
                  </PrivateRoute>
                  <PrivateRoute path="/missions" forceIdentity={userType => userType.isClient}>
                    <Switch>
                      <Route path="/missions/waiting">
                        <MissionsListing />
                      </Route>
                      <Route path="/missions/started">
                        <MissionsListing />
                      </Route>
                      <Route path="/missions">
                        <MissionsListing />
                      </Route>
                    </Switch>
                  </PrivateRoute>
                  <PrivateRoute path="/mission/:id" forceIdentity={userType => userType.isClient} exact>
                    <MissionPreview />
                  </PrivateRoute>
                  <PrivateRoute path="/mission/:id/cra" forceIdentity={userType => userType.isClient} exact>
                    <StartedMissionCraPreview />
                  </PrivateRoute>
                  <PrivateRoute path="/prestataires" forceIdentity={userType => userType.isClient} exact>
                    <PrestatairesListing />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/missions" forceIdentity={userType => userType.isSuperUser} exact>
                    <AdminMissionsListing />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/prestataires/freelance/edit/:id" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminFreelanceProfilePrestataireEdition />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/prestataires/enterprise/:userId/:id" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminEnterpriseProfilePrestataireEdition />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/prestataires" forceIdentity={userType => userType.isSuperUser} exact>
                    <AdminProfilePrestataireListing />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/mission/:id" forceIdentity={userType => userType.isSuperUser} exact>
                    <AdminMissionPreview />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/missions/enterprise/:userId/:id" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminUpsertMission />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/users/creation" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminUserCreation />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/users/edit/:id" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminUserEdition />
                  </PrivateRoute>
                  <PrivateRoute path={["/admin/users/:cursor", "/admin/users"]} forceIdentity={userType => userType.isSuperUser} exact>
                    <AdminUserListing />
                  </PrivateRoute>
                  <PrivateRoute path={["/admin/companies/:cursor", "/admin/companies"]} forceIdentity={userType => userType.isSuperUser} exact>
                    <AdminCompaniesListing />
                  </PrivateRoute>
                  <PrivateRoute path="/admin/configuration" forceIdentity={userType => userType.isAdmin} exact>
                    <AdminConfiguration/>
                  </PrivateRoute>
                  <PrivateRoute path="/" exact>
                    <StandardUserContainer>
                      <Home />
                    </StandardUserContainer>
                    <SuperUserContainer>
                      <AdminDashboard />
                    </SuperUserContainer>
                  </PrivateRoute>
                </Switch>
              </PrincipalLayout>
            </Route>
            <Redirect to="/not-found" />
          </Switch>
        </Router >
      </NotificationContext.Provider>
    </ThemeProvider>
  );
};

export default App;
