import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Checkbox as ReakitCheckbox, CheckboxProps as ReakitCheckboxProps } from 'reakit/Checkbox'
import $ from './Checkbox.module.scss';


export type CheckboxProps = {
  children?: React.ReactNode;
  small?: boolean;
} & ReakitCheckboxProps;
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ children, className, small, ...props }, ref) => (
  <label className={classnames($.Checkbox, { [$.NoLabel]: !children, [$.Small]: small }, className)}>
    <div className={$.CheckboxButtonContainer}>
      <ReakitCheckbox ref={ref} {...props} className={className} />
      <span className={$.CheckboxButtonChecked}>✔</span>
      <span className={$.CheckboxButtonIndeterminate}>-</span>
      <span className={$.CheckboxButton}></span>
    </div>
    <span className={$.CheckboxLabel}>{children}</span>
  </label>
));
