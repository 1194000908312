
import React from 'react';
import classnames from 'classnames';
import { Content, PrincipalLayoutBox, SubTitle, Title, Zone } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './Help.module.scss';
import { MailTo } from '../../../components/Link/Link';

export const Help = () => {
  return (
    <PrincipalLayoutBox className={classnames($.Help)}>
      <Title>Aide</Title>
      <SubTitle>Nous contacter</SubTitle>
      <Content>
        <Zone title="Contactez-nous" description={(
          <>
            <p>
              Vous avez un problème ou une question au sujet de votre compte ?
            </p>
            <p>N’hésitez pas à nous adresser un e-mail, nos équipes seront ravies de vous aider.</p>
            <p>Nous tenterons d’y répondre dans les meilleurs délais.</p>
            <p>Contactez-nous à <MailTo href="mailto:contact@mydataspecialist.com">contact@mydataspecialist.com</MailTo></p>
          </>
        )} />
      </Content>
    </PrincipalLayoutBox >
  );
};
