import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Content, PrincipalLayoutBox, Title, Zone, Tabs, LinkTab, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './MissionPreview.module.scss';
import { missionTranslations } from '../../../translate';
import { useMissionLazyQuery, MissionQuery, useSaveExistingMissionMutation, useChooseProfilePrestataireForMissionMutation, Mission_State, Profile_Prestataire_Associated_To_Mission_State, useMissionAssociatedProfilePrestataireLazyQuery } from '../../../generated/graphql';
import { resetCache } from '../../../apollo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSaveStateWatcher } from '../../../hooks/useSaveStateWatcher';
import { DeepNonMaybe } from '../../../graphqlHelpers';
import { PrestataireList } from '../../../components/PrestataireList/PrestataireList';
import { Form, RefForm } from './components/Form';

const missionDataToForm = (mission: MissionQuery): FormValues => {
  const values = (mission.mission as any as DeepNonMaybe<MissionQuery['mission']>)!;

  const [startDate] = values.startDate.split('T');
  const [endDate] = values.endDate.split('T');
  return {
    startDate,
    endDate,
    area: values.area,
    description: values.description,
    experience: (values.experience) + '',
    meta: values.meta,
    needMoving: (values.needMoving) + '',
    postalCode: values.postalCode,
    remote: (values.remote) + '',
    title: values.title,
    tjm: (values.tjm) + '',
    availabilityDate: '',
  };
};

type FormValues = {
  area: string;
  description: string;
  postalCode: string;
  experience: string;
  meta: string;
  needMoving: string;
  remote: string;
  startDate: string;
  endDate: string;
  title: string;
  tjm: string;
  availabilityDate: string;
};

const translate = missionTranslations.translate;

export const ExistingMissionPreview = () => {
  const history = useHistory();

  const { id } = useRouteMatch<{ id: string; }>().params;

  const [fetchMission, mission] = useMissionLazyQuery({
    variables: {
      id,
    }
  });

  const [saveExistingMission, saveExistingMissionResponse] = useSaveExistingMissionMutation();
  const [isSaving, setSaving] = useState(false);
  const onDataSavedSuccess = useCallback(() => {
    setSaving(false);
    resetCache();
    history.push('/missions/waiting');
  }, [history]);
  const onDataSavedError = useCallback(() => setSaving(false), []);
  useSaveStateWatcher(
    saveExistingMissionResponse.data,
    saveExistingMissionResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );

  const missionState = useMemo(() => {
    return mission.data?.mission?.state || null;
  }, [mission.data]);

  const [isLoading, setLoading] = useState(true);

  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    saveExistingMission({
      variables: {
        input: {
          id,
          area: formValues.area,
          description: formValues.description,
          postalCode: formValues.postalCode,
          experience: +formValues.experience,
          meta: formValues.meta || '',
          needMoving: +formValues.needMoving,
          remote: +formValues.remote,
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          title: formValues.title,
          tjm: +formValues.tjm,
        }
      }
    });
  };

  const formRef = useRef<RefForm | null>(null);

  useEffect(() => {
    if (mission.data) {
      const data = missionDataToForm(mission.data!);
      formRef.current?.reset(data);
      setLoading(false);

      return;
    }

    if (!mission.data && !mission.loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission.data, formRef]);

  useEffect(() => {
    fetchMission();
  }, [fetchMission, formRef]);

  const profilesList = useMemo(() => {
    const d = mission.data?.mission?.associatedProfilesPrestataires || [];
    const mapped = d.map((presta) => ({
      id: presta?.profilePrestataire?.id!,
      available: presta?.profilePrestataire?.available!,
      availabilityInMonths: presta?.profilePrestataire?.availabilityInMonths!,
      tjm: presta?.profilePrestataire?.tjm!,
      skills: (presta?.profilePrestataire?.skills! || []).map(a => ({ name: a?.name!, weight: a?.weight! })),
      maximumDistance: presta?.profilePrestataire?.maximumDistance!,
      minimumMonths: presta?.profilePrestataire?.minimumMonths!,
      postalCode: presta?.profilePrestataire?.postalCode!,
      title: presta?.profilePrestataire?.title!,
      cityName: presta?.profilePrestataire?.city?.name!,
      state: presta?.state!,
    }));

    return {
      waiting: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.WaitingSelection),
      selected: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Selected),
      validated: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Validated),
      refused: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Refused),
      appointed: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Appointed),
    }
  }, [mission.data]);

  const [choose] = useChooseProfilePrestataireForMissionMutation();
  const onSelect = useCallback((profileId: string) => {
    choose({
      variables: {
        missionId: id,
        profilePrestataireId: profileId
      }
    });
    fetchMission();
  }, [id, choose, fetchMission]);

  const [fetchAssociatedProfilePrestataire, rawAssociatedProfilePrestataire] = useMissionAssociatedProfilePrestataireLazyQuery();
  const associatedProfilePrestataire = useMemo(() => {
    const array = rawAssociatedProfilePrestataire.data?.mission?.associatedProfilesPrestataires;
    if (array?.length) {
      return {
        ...array[0]?.profilePrestataire!,
        state: array[0]?.state!
      }
    }

    return null;
  }, [rawAssociatedProfilePrestataire.data]);

  const onClickDetails = (profilePrestataireId: string) => {
    fetchAssociatedProfilePrestataire({
      variables: {
        missionId: id,
        profilePrestataireId: profilePrestataireId,
      },
    });
  };

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataireFreelance)}>
      <Title>MISSIONS</Title>
      <Tabs>
        {missionState === Mission_State.WaitingValidation && <LinkTab to={`/mission/${id}`}>{translate('TitleExisting')}</LinkTab>}
        {missionState === Mission_State.Validated && <LinkTab to={`/mission/${id}`}>{translate('TitleValidated')}</LinkTab>}
        {missionState === Mission_State.Started && <LinkTab to={`/mission/${id}`}>{translate('TitleStarted')}</LinkTab>}
        {missionState === Mission_State.Started && <LinkTab to={`/mission/${id}/cra`}>CRAs</LinkTab>}
      </Tabs>
      <Content>
        {(mission.loading || isLoading) && <ZoneLoadingState />}
        {(!mission.loading && !isLoading) && (
          <>
            <Form isLoading={isSaving || saveExistingMissionResponse.loading} onSubmit={onInternalSubmit} ref={formRef} missionState={missionState} />
            {missionState === Mission_State.Validated && (
              <>
                {!!profilesList.appointed.length &&
                  <Zone title="Prestataires en rendez-vous" style={{ marginTop: '15px' }}>
                    <PrestataireList
                      isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                      profileDetails={associatedProfilePrestataire}
                      kind="CLIENT_MISSION"
                      missionId={id}
                      list={profilesList.appointed}
                      onClickDetails={onClickDetails}
                      onSelect={onSelect} />
                  </Zone>
                }
                {!!profilesList.selected.length &&
                  <Zone title="Prestataires que vous avez sélectionné" style={{ marginTop: '15px' }}>
                    <PrestataireList
                      isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                      profileDetails={associatedProfilePrestataire}
                      kind="CLIENT_MISSION"
                      missionId={id}
                      list={profilesList.selected}
                      onClickDetails={onClickDetails}
                      onSelect={onSelect} />
                  </Zone>
                }
                {!!profilesList.waiting.length &&
                  <Zone title="Prestataires qui matchent votre mission" style={{ marginTop: '15px' }}>
                    <PrestataireList
                      isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                      profileDetails={associatedProfilePrestataire}
                      kind="CLIENT_MISSION"
                      missionId={id}
                      list={profilesList.waiting}
                      onClickDetails={onClickDetails}
                      onSelect={onSelect} />
                  </Zone>}
              </>
            )}
          </>
        )}
      </Content>
    </PrincipalLayoutBox >
  );
};
