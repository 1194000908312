import { combineReducers } from 'redux';
import { Reducer } from 'redux';
import { type } from '../../redux-types/index';

import {
  Actions, setAppIsReady, UserPayload, profileFilled, profilePrestataireFilled
} from './auth/Authentication.actions';

export interface RootState {
  isAppReady: boolean;
  userPayload: UserPayload | null;
}

const defaultState: RootState = {
  isAppReady: false,
  userPayload: null,
};

const rootReducer: Reducer<RootState, Actions> = (state = defaultState, action): RootState => {
  switch (action.type) {
    case type(setAppIsReady): {
      return {
        ...state,
        userPayload: action.payload.userPayload,
        isAppReady: true,
      };
    }

    case type(profileFilled): {
      if (state.userPayload) {
        return {
          ...state,
          userPayload: {
            ...state.userPayload,
            isProfileFilled: true,
          },
        };
      }

      return state;
    }

    case type(profilePrestataireFilled): {
      if (state.userPayload) {
        return {
          ...state,
          userPayload: {
            ...state.userPayload,
            isProfilePrestataireFilled: true,
          },
        };
      }

      return state;
    }

    default:
      return {
        ...state,
      };
  }
};

export default combineReducers({
  root: rootReducer,
});
