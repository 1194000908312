import { eventChannel } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { CallReturnType } from '../../../redux-types/Saga';
import { getAuth } from '../../../FirebaseConfigurator';
import { Roles, setAppIsReady } from './Authentication.actions';
import { setToken } from './Authentication.service';
import { apolloClient, resetCache } from '../../../apollo';
import * as Sentry from '@sentry/react';
import { MeDocument, GetFreelanceProfilePrestataireDocument, GetFreelanceProfilePrestataireQuery, MeQuery } from '../../../generated/graphql';

type OnAuthStateChanged = {
  user: firebase.User | null;
};

const createOnAuthStateChangedChannel = (auth: firebase.auth.Auth) => {
  return eventChannel<OnAuthStateChanged>((emit) => {
    return auth.onAuthStateChanged((user) => {
      emit({ user });
    });
  });
};

export function* ListenAuthStateChanged() {
  const authResult: firebase.auth.Auth = yield call(getAuth);
  const onAuthStateChangedChannel: CallReturnType<
    typeof createOnAuthStateChangedChannel
  > = yield call(createOnAuthStateChangedChannel, authResult);

  yield takeEvery(onAuthStateChangedChannel, function* ({ user }) {
    resetCache();
    if (user === null) {
      yield put(setAppIsReady(null));
      setToken(null);
      Sentry.configureScope(scope => scope.setUser(null));
    } else {
      const idTokenResult: firebase.auth.IdTokenResult = yield call(() => user.getIdTokenResult());

      let role: Roles | null = null;
      switch (idTokenResult.claims.role) {
        case Roles.ADMIN:
          role = Roles.ADMIN;
          break;
        case Roles.ADMIN_OBSERVER:
          role = Roles.ADMIN_OBSERVER;
          break;
        case Roles.ESN:
          role = Roles.ESN;
          break;
        case Roles.FREELANCE:
          role = Roles.FREELANCE;
          break;
        case Roles.CLIENT:
          role = Roles.CLIENT;
          break;
        case Roles.ENTERPRISE:
          role = Roles.ENTERPRISE;
          break;
        default:
          role = null;
          break;
      }

      if (!role) {
        yield put(setAppIsReady(null));
        return;
      }

      setToken(idTokenResult.token);
      Sentry.setUser({ id: user.uid, email: user.email || '' });

      if (role === Roles.ADMIN || role === Roles.ADMIN_OBSERVER) {
        yield put(setAppIsReady({
          role,
          isProfilePrestataireFilled: true,
          isProfileFilled: true,
          firebaseAuth: authResult,
          firebaseUser: user,
          token: idTokenResult.token,
        }));
        return;
      }

      type UnwrapPromise<T> = T extends Promise<infer U> ? U : never;
      const executeQuery = () => apolloClient.query<MeQuery>({
        query: MeDocument,
      });

      let profile: UnwrapPromise<ReturnType<typeof executeQuery>> | null;
      try {
        profile = yield call(apolloClient.query, {
          query: MeDocument,
        });
      } catch (e) {
        profile = null;
      }

      let isProfilePrestataireFilled: boolean = true;

      if (role === Roles.FREELANCE) {
        const executeProfilePrestataireQuery = () => apolloClient.query<GetFreelanceProfilePrestataireQuery>({
          query: GetFreelanceProfilePrestataireDocument,
        });
        try {
          const profilePrestataire: UnwrapPromise<ReturnType<typeof executeProfilePrestataireQuery>> | null = yield call(apolloClient.query, {
            query: GetFreelanceProfilePrestataireDocument,
          });

          isProfilePrestataireFilled = !!(profilePrestataire && profilePrestataire.data && profilePrestataire.data.getProfilePrestataire?.isFilled);
        } catch (e) {
          isProfilePrestataireFilled = false;
        }
      }

      yield put(setAppIsReady({
        role,
        isProfilePrestataireFilled,
        isProfileFilled: !!(profile?.data.me?.isFilled),
        firebaseAuth: authResult,
        firebaseUser: user,
        token: idTokenResult.token,
      }));
    }
  });
}

export default function* allSaga() {
  yield all([call(ListenAuthStateChanged)]);
}
