import React from 'react';
import classnames from 'classnames';
import { AuthLayout } from '../AuthLayout/AuthLayout';
import $ from './TextAuthLayout.module.scss';
import { Link } from '../../../components/Link/Link';

export type TextAuthLayoutProps = {
  className?: string;
  t1: React.ReactNode;
  t2?: React.ReactNode;
  to: string;
  link?: string;
  children?: React.ReactNode;
};
export const TextAuthLayout = ({ className, t1, t2, to, link, children }: TextAuthLayoutProps) => (
  <AuthLayout className={classnames($.TextAuthLayout, className)}>
    <div className={$.t1}>{t1}</div>
    {t2 && <div className={$.t2}>{t2}</div>}
    {children}

    {link && <Link to={to}>{link}</Link>}
  </AuthLayout>
);
