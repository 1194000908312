import {
  ActionCreatorWithoutPayload,
  ActionCreator,
  Func,
} from './ActionCreator';

import createAction from './createAction';

function createActionCreatorWithoutPayload<ActionType extends string>(
  type: ActionType,
): ActionCreatorWithoutPayload<ActionType> {
  const actionCreator = () => createAction(type);

  actionCreator.type = type;
  return actionCreator;
}

function createActionCreatorWithPayload<
  ActionType extends string,
  PayloadCreator extends Func
>(
  type: ActionType,
  payloadCreator: PayloadCreator,
): ActionCreator<ActionType, PayloadCreator> {
  const actionCreator = (...args: Parameters<PayloadCreator>) =>
    createAction(type, payloadCreator(...args));

  actionCreator.type = type;
  return actionCreator;
}

export default function createActionCreator<ActionType extends string>(
  type: ActionType,
): ActionCreatorWithoutPayload<ActionType>;
export default function createActionCreator<
  ActionType extends string,
  PayloadCreator extends Func
>(
  type: ActionType,
  payloadCreator: PayloadCreator,
): ActionCreator<ActionType, PayloadCreator>;
export default function createActionCreator<
  ActionType extends string,
  PayloadCreator extends Func
>(type: ActionType, payloadCreator?: PayloadCreator) {
  if (!payloadCreator) {
    return createActionCreatorWithoutPayload(type);
  }

  return createActionCreatorWithPayload(type, payloadCreator);
}
