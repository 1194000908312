import { getStorage } from "../../../FirebaseConfigurator";

export const getReadableCvFilename = (fileName: string | undefined) => fileName ? fileName.split(/-(.+)/)[1] : '';
export const createUniqueCvFilename = (fileName: string) => `${(+new Date())}-${fileName}`;
export const createCvFilePath = (userId: string, fileName: string) => `/all-cvs/${userId}/${fileName}`;

export const uploadCvFile = async (userId: string, fileName: string, file: File) => {
  const storageRef = getStorage().ref();
  const filePath = createCvFilePath(userId, createUniqueCvFilename(fileName));
  const fileRef = storageRef.child(filePath);

  try {
    const cvUploadTask = fileRef.put(file);
    const cvUploadResult = await cvUploadTask;
    return cvUploadResult.ref.name;
  } catch (e) {
    return false;
  }
};

export const getCvFileUrl = async (userId: string, fileName: string) => {
  const storageRef = getStorage().ref();
  const filePath = createCvFilePath(userId, fileName);
  const fileRef = storageRef.child(filePath);
  const url = await fileRef.getDownloadURL();
  return url;
};
