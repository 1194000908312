import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from './store/domains/auth/Authentication.service';

const url = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const httpLink = createHttpLink({
  uri: url,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  if (token === null) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      authorization: token === null ? '' : `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache();

export const resetCache = () => cache.reset();

export const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
});
