
import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, Content, ZoneLoadingState, ZoneContent, Zone, ZoneColumn } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './CraManager.module.scss';
import { Cra_State_Enum, useMyMissionQuery, useUpdateCraMutation } from '../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import { CraAgenda } from '../../../components/CraAgenda/CraAgenda';
import { Button, ButtonType } from '../../../components/Button/Button';
import { Field } from '../../../components/Form/Field/Field';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNotification } from '../../../hooks/notifications';
import { ActionsBox, Box } from '../../layout/PrincipalLayout/components/Box';

export const CraManager = () => {
  const { missionId, profilePrestataireId } = useRouteMatch<{ missionId: string; profilePrestataireId: string; }>().params;

  const month = useMemo(() => (new Date()).getMonth() + 1, []);
  const year = useMemo(() => (new Date()).getFullYear(), []);
  const [monthState, setMonthState] = useState(month);
  const [yearState, setYearState] = useState(year);
  const missionQuery = useMyMissionQuery({
    variables: {
      id: missionId,
      profilePrestataireId,
      month: monthState,
      year: yearState,
    }
  });
  const mission = useMemo(() => {
    return missionQuery.data?.me?.profilePrestataire?.validatedAssociatedMission?.mission;
  }, [missionQuery.data]);
  const cras = useMemo(() => (mission?.craState?.cras || []).map(c => ({ value: c?.value!, date: new Date(c?.date!), disabled: c?.disabled! })), [mission]);
  const [draftCras, setDraftCras] = useState(cras);
  useEffect(() => {
    setDraftCras(cras);
  }, [cras]);

  const onValueChange = useCallback((day: Date, value: number) => {
    const existingCraIndex = draftCras.findIndex(cra => cra.date.getDate() === day.getDate());
    const newCras = [...draftCras];
    if (existingCraIndex !== -1) {
      newCras[existingCraIndex] = { date: day, value, disabled: false };
    } else {
      newCras.push({ date: day, value, disabled: false });
    }

    setDraftCras(newCras);
  }, [draftCras]);

  const [updateCras, updateCrasResponse] = useUpdateCraMutation();

  const notifications = useNotification();
  const save = useCallback(async (submit: boolean) => {
    await updateCras({
      variables: {
        submit,
        profilePrestataireId,
        month: monthState,
        year: yearState,
        cras: draftCras.map(({ value, date }) => ({ value, date: date.toISOString() })),
        missionId: missionId,
      }
    });

    if (submit) {
      await missionQuery.refetch();
      notifications.addNotification(false, 'Votre CRA a été soumis');
      return;
    }

    notifications.addNotification(false, 'Votre CRA a été sauvegardé');
  }, [draftCras, missionId, missionQuery, monthState, notifications, profilePrestataireId, updateCras, yearState]);

  const changeDate = useCallback((add: boolean) => {
    let nextMonth = monthState;
    let nextYear = yearState;
    if (add) {
      if (nextMonth === 12) {
        nextMonth = 1;
        nextYear += 1;
      } else {
        nextMonth += 1;
      }
    } else {
      if (nextMonth === 0) {
        nextMonth = 12;
        nextYear -= 1;
      } else {
        nextMonth -= 1;
      }
    }

    setMonthState(nextMonth);
    setYearState(nextYear);
  }, [monthState, yearState]);

  useEffect(() => {
    if (missionQuery.data) {
      setHasLoaded(true);
    }
  }, [missionQuery.data]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const {
    canCorrect,
    canSave,
    canSubmit,
  } = useMemo(() => {
    return {
      canSave: mission?.craState?.state === Cra_State_Enum.ToFill,
      canCorrect: mission?.craState?.state === Cra_State_Enum.ToFix,
      // Can submit if the cra is still open (TO_FILL) and the CRA is not in the future
      canSubmit: mission?.craState?.state === Cra_State_Enum.ToFill && monthState <= (new Date().getMonth() + 1),
    };
  }, [mission?.craState?.state, monthState]);
  return (
    <PrincipalLayoutBox className={classnames($.CraManager)}>
      <Title>Compte rendu d'activité</Title>
      <Tabs>
        <br />
      </Tabs>
      <Content>
        {(missionQuery.loading && !hasLoaded) && <ZoneLoadingState />}
        {!(missionQuery.loading && !hasLoaded) && (
          <>
            <Zone
              className={$.ButtonsContainer}
              title={mission?.title!}>
              <ZoneContent>
                <div className={$.Buttons}>
                  <button onClick={() => changeDate(false)}>Précedent</button>
                  <button onClick={() => changeDate(true)}>Suivant</button>
                </div>
                <ZoneColumn>
                  <Field>
                    {missionQuery.loading && <ZoneLoadingState />}
                    {!missionQuery.loading && <CraAgenda month={monthState} year={yearState} data={draftCras} onValueChange={onValueChange} />}
                  </Field>
                </ZoneColumn>
              </ZoneContent>
            </Zone>
            {
              !missionQuery.loading &&
              (
                <ActionsBox hollow>
                  {canSubmit && <Button styleType={ButtonType.Secondary} onClick={() => save(true)} isLoading={updateCrasResponse.loading}>Sauvegarder et Soumettre</Button>}
                  {canCorrect && <Button styleType={ButtonType.Secondary} onClick={() => save(true)} isLoading={updateCrasResponse.loading}>Sauvegarder et soumettre la correction</Button>}
                  {canSave && <Button onClick={() => save(false)} isLoading={updateCrasResponse.loading}>Sauvegarder</Button>}
                </ActionsBox>
              )
            }
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
