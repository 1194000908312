
import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Content, LinkTab, PrincipalLayoutBox, Tabs, Title, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminMissionsListing.module.scss';
import { Table } from '../../../components/Table/Table';
import { Mission_State, useAdminDeleteMissionMutation, useAdminMissionsQuery } from '../../../generated/graphql';
import { Link } from '../../../components/Link/Link';
import { Label, LabelColor } from '../../../components/DataPresentation/Presentation/Presentation';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { Input } from '../../../components/Form/Input/Input';
import * as MissionHelpers from '../../../helpers/mission';
import { Select } from '../../../components/Form/Select/Select';
import { Field } from '../../../components/Form/Field/Field';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton } from '../../../components/Button/Button';
import { BsPen, BsTrash } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

const format = new Intl.DateTimeFormat('fr', { year: 'numeric', month: 'short', day: 'numeric' });
const stateToWord = (state: Mission_State) => {
  switch (state) {
    case Mission_State.Validated:
      return 'Validé';
    case Mission_State.WaitingValidation:
      return 'À valider';
    case Mission_State.Refused:
      return 'Refusé';
    case Mission_State.Started:
      return 'Démarrée';
    default:
      return 'Inconnue';
  }
}

type StateProps = {
  state: Mission_State;
};
const State = ({ state }: StateProps) => {
  switch (state) {
    case Mission_State.Started:
    case Mission_State.Validated:
      return <Label revert color={LabelColor.Green}>{stateToWord(state)}</Label>;
    case Mission_State.WaitingValidation:
      return <Label revert color={LabelColor.Orange}>{stateToWord(state)}</Label>;
    case Mission_State.Refused:
    default:
      return <Label revert color={LabelColor.Yellow}>{stateToWord(state)}</Label>;
  }
}

type Filters = {
  titleFilterValue: string;
  stateFilterValue: string;
  experienceFilterValue: string;
}
const defaultFilters: Filters = {
  titleFilterValue: '',
  stateFilterValue: Mission_State.WaitingValidation,
  experienceFilterValue: '',
};
const getFiltersFromUrl = (searchParams: string | null) => {
  const sanitized = searchParams || '{}';
  const parsed = JSON.parse(sanitized);
  const cleaned = {
    ...defaultFilters,
    ...parsed,
  } as Filters;

  return cleaned;
};
export const AdminMissionsListing = () => {
  const list = useAdminMissionsQuery();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [deleteMissionFromHook] = useAdminDeleteMissionMutation();
  const deleteMission = useCallback((id: string) => {
    deleteMissionFromHook({
      variables: {
        id,
      }
    }).then(() => {
      list.refetch();
    });
  }, []);

  const filters = getFiltersFromUrl(query.get('filters'));
  const updateQueryParam = <T extends keyof Filters,>(name: T, value: Filters[T]) => {
    const newFilters = { ...filters, [name]: value };
    const stringified = JSON.stringify(newFilters);

    history.push({ search: `?filters=${stringified}` });
  };

  const data = useMemo(() => {
    let data = (list.data?.missions?.edges || []).map((mission) => ({
      id: mission!.node?.id!,
      title: mission!.node?.title!,
      experience: mission!.node?.experience!,
      remote: mission!.node?.remote!,
      state: mission!.node?.state!,
      createdAt: mission!.node?.createdAt!,
      companyName: mission!.node?.owner?.companyInformations?.companyContainer?.name!,
      ownerId: mission!.node?.owner?.id,
    }));
    if (filters.titleFilterValue !== '') {
      data = data.filter(d => d.title !== null && (d.title.toLowerCase().indexOf(filters.titleFilterValue.toLowerCase()) !== -1 || d.companyName.toLowerCase().indexOf(filters.titleFilterValue.toLowerCase()) !== -1));
    }
    if (filters.stateFilterValue !== '') {
      data = data.filter(d => d.state === filters.stateFilterValue);
    }
    if (filters.experienceFilterValue !== '') {
      data = data.filter(d => d.experience === +filters.experienceFilterValue);
    }

    return data;
  }, [list.data?.missions?.edges, filters.titleFilterValue, filters.stateFilterValue, filters.experienceFilterValue]);


  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataire)}>
      <Title>Administration</Title>
      <Tabs>
        <LinkTab to="/admin/missions">Missions</LinkTab>
      </Tabs>
      <Content>
        <div className={$.Filters}>
          <FormZone>
            <FormRow>
              <Field>
                <Select
                  emptyLabel="État"
                  onChange={e => updateQueryParam('stateFilterValue', e.target.value)}
                  value={filters.stateFilterValue}
                  options={[
                    { value: Mission_State.WaitingValidation, label: stateToWord(Mission_State.WaitingValidation) },
                    { value: Mission_State.Validated, label: stateToWord(Mission_State.Validated) },
                    { value: Mission_State.Started, label: stateToWord(Mission_State.Started) },
                    { value: Mission_State.Refused, label: stateToWord(Mission_State.Refused) },
                  ]}
                />
              </Field>
              <Input label="Titre ou Nom" placeholder="Filtrer" value={filters.titleFilterValue} onChange={(e: any) => updateQueryParam('titleFilterValue', e.target.value)} />
              <Field>
                <Select
                  name="experience"
                  emptyLabel="Experience"
                  onChange={e => updateQueryParam('experienceFilterValue', e.target.value)}
                  value={filters.experienceFilterValue}
                  options={[
                    { value: '0', label: MissionHelpers.experienceToText(0) },
                    { value: '2', label: MissionHelpers.experienceToText(2) },
                    { value: '7', label: MissionHelpers.experienceToText(7) },
                  ]}
                />
              </Field>
            </FormRow>
          </FormZone>
        </div>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <Table<TableData> columns={[
            {
              Header: 'État',
              accessor: (row) => <State state={row.state} />,
              width: 15,
            },
            {
              Header: 'Titre & Nom',
              accessor: row => <Link to={`/admin/mission/${row.id}`}>
                <p>{row.title}</p>
                <p>{row.companyName}</p>
              </Link>,
              width: 30,
            },
            {
              Header: 'Experience',
              accessor: row => MissionHelpers.experienceToText(row.experience!),
              width: 20,
            },
            {
              Header: 'Télétravail',
              accessor: row => MissionHelpers.remoteToText(row.remote!),
              width: 20,
            },
            {
              Header: 'Création',
              accessor: (row) => format.format(new Date(row.createdAt)),
              width: 15,
            },
            {
              Header: 'Actions',
              accessor: row => {
                const actions = [];
                const deleteId = `${row.id}-delete`;
                actions.push(
                  <span key={deleteId}>
                    <IconButton data-tip data-for={deleteId} onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      const result = confirm(`Vous êtes sur le point de supprimer une mission, toutes ses informations affiliées seront supprimées, êtes vous sûr ?`);
                      if (result) {
                        deleteMission(row.id);
                      }
                    }}>
                      <BsTrash size={25} />
                    </IconButton>
                    <ReactTooltip id={deleteId} place="top" effect="solid">
                      Supprimer la mission
                    </ReactTooltip>
                  </span>
                );
                const editId = `${row.id}-edit`;
                actions.push(
                  <span key={editId}>
                    <IconButton data-tip data-for={editId} onClick={() => {
                      history.push(`/admin/missions/enterprise/${row.ownerId}/${row.id}`);
                    }}>
                      <BsPen size={25} />
                    </IconButton>
                    <ReactTooltip id={editId} place="top" effect="solid">
                      Editer la mission
                    </ReactTooltip>
                  </span>
                );

                return actions;
              },
              width: 10,
            },
          ]} data={data} />
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
