
import React, { useCallback, useMemo } from 'react';
import { PrincipalLayoutBox, Title, Tabs, LinkTab, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import { translate } from '../../../translate';
import { Role, useAdminUsersQuery, useAdminDisableEnterpriseModeMutation, useAdminActivateEnterpriseModeMutation, useAdminEnforceUserEmailVerificationMutation, useAdminDeleteUserMutation } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Pagination } from '../../../components/Pagination/Pagination';
import $ from './AdminUserListing.module.scss';
import { Field } from '../../../components/Form/Field/Field';
import { Select } from '../../../components/Form/Select/Select';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { IconButton } from '../../../components/Button/Button';
import { offsetToCursor } from 'graphql-relay';
import { RoleLabel } from '../../../components/DataPresentation/Presentation/Presentation';
import ReactTooltip from 'react-tooltip';
import { BsLightningFill, BsLightning, BsUnlock, BsPen, BsTrash } from 'react-icons/bs';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import { CgUserList, CgUserAdd } from 'react-icons/cg';
import { Input } from '../../../components/Form/Input/Input';
import { useFilters } from '../../../hooks/useFilters';
import { Link } from '../../../components/Link/Link';
import { useHistory } from 'react-router';
import { useAuth } from '../../../hooks/auth';
import { useNotification } from '../../../hooks/notifications';

type Filters = {
  role: string | null;
  email: string | null;
  page: number;
}
const defaultFilters: Filters = {
  role: '',
  email: '',
  page: 0
};
export const AdminUserListing = () => {
  const { addNotification } = useNotification();
  const auth = useAuth();
  const history = useHistory();
  const [filters, setFilters, debouncedFilters] = useFilters<Filters>(defaultFilters);
  const offset = useMemo(() => filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1), [filters.page]);
  const list = useAdminUsersQuery({
    variables: {
      after: offset,
      role: debouncedFilters.role as any,
      email: debouncedFilters.email,
    }
  });
  const [activateEnterpriseMode] = useAdminActivateEnterpriseModeMutation();
  const [disableEnterpriseMode] = useAdminDisableEnterpriseModeMutation();
  const [enforceUserEmailVerification] = useAdminEnforceUserEmailVerificationMutation();
  const [deleteUserFromHook] = useAdminDeleteUserMutation();
  const deleteUser = useCallback((id: string) => {
    // eslint-disable-next-line no-restricted-globals
    const shouldDelete = confirm('Êtes vous sûr de vouloir supprimer cet utilisateur ainsi que toutes ses resources (profiles prestataires, missions etc...) ?');
    if (!shouldDelete) {
      return;
    }

    deleteUserFromHook({
      variables: {
        id,
      }
    }).then(() => {
      list.refetch();
    });
  }, [deleteUserFromHook, list]);
  const data = useMemo(() => {
    const d = list.data?.users?.edges! || [];
    return d.map((user) => ({
      id: user?.node?.id!,
      name: user?.node?.name!,
      role: user?.node?.role!,
      email: user?.node?.email!,
      profilePrestataireId: user?.node?.profilePrestataire?.id,
    }));
  }, [list.data]);

  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox>
      <Title>{translate('userProfileFirstName')}</Title>
      <Tabs>
        <LinkTab to="/admin/users/">Utilisateurs</LinkTab>
        <LinkTab to="/admin/users/creation">Créer un utilisateur</LinkTab>
      </Tabs>
      <Content>
        <div className={$.Filters}>
          <FormZone>
            <FormRow>
              <Field>
                <Input label="Email" value={filters.email!} onChange={(e: any) => setFilters('email', e.target.value)} />
              </Field>
              <Field>
                <Select
                  emptyLabel="Type"
                  onChange={e => setFilters('role', e.target.value)}
                  value={filters.role!}
                  options={[
                    { value: Role.Esn, label: 'ESN' },
                    { value: Role.Freelance, label: 'Freelance' },
                    { value: Role.Admin, label: 'Admin' },
                    { value: Role.AdminObserver, label: 'Admin obs.' },
                    { value: Role.Enterprise, label: 'Entreprise' },
                    { value: Role.Client, label: 'Client' },
                  ]}
                />
              </Field>
            </FormRow>
          </FormZone>
        </div>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <>
            <Table<TableData> columns={[
              {
                Header: 'Nom',
                accessor: (row) => (
                  <span>
                    {(() => {
                      switch (row.role) {
                        case Role.Freelance:
                          return (
                            <>
                              <Link style={{ display: 'inline' }} to={`/admin/prestataire/${row.profilePrestataireId}`} data-tip data-for={row.id}>{row.name}</Link>
                              <ReactTooltip id={row.id} place="top" effect="solid">
                                Voir le profil prestataire
                              </ReactTooltip>
                            </>
                          );
                        case Role.Enterprise:
                        case Role.Esn:
                          return (
                            <>
                              <Link style={{ display: 'inline' }} to={`/admin/prestataires?filters={"ownerId":"${row.id}","state":null}`} data-tip data-for={row.id}>{row.name}</Link>
                              <ReactTooltip id={row.id} place="top" effect="solid">
                                Voir les profils prestataires
                              </ReactTooltip>
                            </>
                          );
                        default:
                          return row.name
                      }
                    })()}
                  </span>
                ),
                width: 20,
              },
              {
                Header: 'Email',
                accessor: 'email',
                width: 15,
              },
              {
                Header: 'Role',
                accessor: row => <RoleLabel role={row.role} />,
                width: 10,
              },
              {
                Header: 'Actions',
                accessor: row => {
                  const verifId = `${row.id}-verification`;
                  const editId = `${row.id}-edit`;
                  const actions = [
                    (
                      <span key={editId}>
                        <IconButton data-tip data-for={editId} onClick={() => {
                          history.push(`/admin/users/edit/${row.id}`);
                        }}>
                          <BsPen size={25} />
                        </IconButton>
                        <ReactTooltip id={editId} place="top" effect="solid">
                          Editer l'utilisateur et son entreprise
                        </ReactTooltip>
                      </span>
                    ),
                    (
                      <span key={verifId}>
                        <IconButton data-tip data-for={verifId} onClick={() => {
                          enforceUserEmailVerification({
                            variables: {
                              id: row.id,
                            }
                          }).then(() => {
                            addNotification(false, 'Le compte a été vérifier avec succès');
                            list.refetch();
                          })
                        }}>
                          <BsUnlock size={25} />
                        </IconButton>
                        <ReactTooltip id={verifId} place="top" effect="solid">
                          Forcer la verification du compte
                        </ReactTooltip>
                      </span>
                    ),
                  ];
                  if (row.role === Role.Freelance) {
                    const id = `${row.id}-editppfreelance`;
                    actions.push(
                      <span key={id}>
                        <IconButton data-tip data-for={id} onClick={() => {
                          history.push(`/admin/prestataires/freelance/edit/${row.profilePrestataireId}`);
                        }}>
                          <CgUserList size={25} />
                        </IconButton>
                        <ReactTooltip id={id} place="top" effect="solid">
                          Editer le profil prestataire
                        </ReactTooltip>
                      </span>
                    );
                  }

                  if (row.role === Role.Esn || row.role === Role.Client) {
                    const id = `${row.id}-enterprise`;
                    actions.push(
                      <span key={id}>
                        <IconButton data-tip data-for={id} onClick={() => {
                          activateEnterpriseMode({
                            variables: {
                              id: row.id,
                            }
                          }).then(() => list.refetch())
                        }}>
                          <BsLightningFill size={25} />
                        </IconButton>
                        <ReactTooltip id={id} place="top" effect="solid">
                          Passer en entreprise
                        </ReactTooltip>
                      </span>
                    );
                  } else if (row.role === Role.Enterprise) {
                    const id = `${row.id}-enterprise`;
                    actions.push(
                      <span key={id}>
                        <IconButton data-tip data-for={id} onClick={() => {
                          disableEnterpriseMode({
                            variables: {
                              id: row.id,
                            }
                          }).then(() => list.refetch())
                        }}>
                          <BsLightning size={25} />
                        </IconButton>
                        <ReactTooltip id={id} place="top" effect="solid">
                          Passer l'ancien role
                        </ReactTooltip>
                      </span>
                    );
                  }
                  if (row.role === Role.Enterprise || row.role === Role.Esn) {
                    const id = `${row.id}-addProfile`;
                    actions.push(
                      <span key={id}>
                        <IconButton data-tip data-for={id} onClick={() => {
                          history.push(`/admin/prestataires/enterprise/${row.id}/new`);
                        }}>
                          <CgUserAdd size={25} />
                        </IconButton>
                        <ReactTooltip id={id} place="top" effect="solid">
                          Ajouter un profile prestataire
                        </ReactTooltip>
                      </span>
                    );
                  }

                  if (row.role === Role.Client || row.role === Role.Enterprise) {
                    const id = `${row.id}-addMission`;
                    actions.push(
                      <span key={id}>
                        <IconButton data-tip data-for={id} onClick={() => {
                          history.push(`/admin/missions/enterprise/${row.id}/new`);
                        }}>
                          <HiOutlineDocumentAdd size={25} />
                        </IconButton>
                        <ReactTooltip id={id} place="top" effect="solid">
                          Ajouter une mission
                        </ReactTooltip>
                      </span>
                    );
                  }

                  if (row.id !== auth?.firebaseUser.uid) {
                    const deleteId = `${row.id}-delete`;
                    actions.push(
                      (
                        <span key={deleteId}>
                          <IconButton data-tip data-for={deleteId} onClick={() => {
                            deleteUser(row.id);
                          }}>
                            <BsTrash size={25} />
                          </IconButton>
                          <ReactTooltip id={deleteId} place="top" effect="solid">
                            Supprimer
                          </ReactTooltip>
                        </span>
                      )
                    );
                  }

                  return actions;
                },
                width: 15,
              },
            ]} data={data} />
            <Pagination current={filters.page} pageSize={10} total={list.data?.users?.total!} onClick={page => setFilters('page', page)} />
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
