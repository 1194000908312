import React from 'react';
import classnames from 'classnames';
import $ from './Slider.module.scss';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

export type SliderProps = {
  label: string;
  max: number;
  min: number
  value: number;
  onChange: (value: number) => void;
  marks: Record<number, string | number>;
};
export const Slider = ({ max, min, value, onChange, marks, label }: SliderProps) => (
  <div className={classnames($.Slider)}>
    <div className={$.SliderLabel}>
      <span>{label}</span>
    </div>
    <RcSlider
      step={null}
      min={min}
      max={max}
      marks={marks}
      onChange={onChange}
      value={value} />
  </div>
);
