import React from 'react';
import classnames from 'classnames';
import $ from './FormLayout.module.scss';

type WithChildren = { children: React.ReactNode };
type FormZoneProps = WithChildren;
export const FormZone = ({ children }: FormZoneProps) => (
  <div className={$.Form}>
    {children}
  </div>
);

type FormRowProps = WithChildren & { customWidth?: number[] };
export const FormRow = ({ children, customWidth = [] }: FormRowProps) => (
  <div className={$.Row}>
    <div className={classnames($.RowInner)}>
      {React.Children.map(children, (child, i) => <div className={$.RowInnerElementContainer} style={{ flex: customWidth[i] ?? 1 }}>{child}</div>)}
    </div>
  </div>
);
