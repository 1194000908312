import React from 'react';
import classnames from 'classnames';
import $ from './PasswordStrengthValidator.module.scss';

const translations = {
  chars: '8 caractères',
  maj: '1 majuscule',
  chiffre: '1 chiffre',
  special: '1 caractère spécial'
};

type DotProps = {
  children: string;
  invalid: boolean;
};
const Dot = ({ children, invalid }: DotProps) => (<div className={classnames($.Dot, { [$.DotInvalid]: !invalid })}><span className={$.DotIcon}></span>{children}</div>)

export const validatePassword = (value: string) => {
  const charsValid = /.{8,}/.test(value);
  const majValid = /[A-Z]/.test(value);
  const chiffreValid = /[1-9]/.test(value);
  const specialValid = /[@$!%*?&#]/.test(value);

  return {
    charsValid,
    majValid,
    chiffreValid,
    specialValid,
    isValid: charsValid && majValid && chiffreValid && specialValid,
  };
}

export type PasswordStrengthValidatorProps = {
  value: string;
};
export const PasswordStrengthValidator = ({ value }: PasswordStrengthValidatorProps) => {
  const {
    charsValid,
    chiffreValid,
    majValid,
    specialValid,
  } = validatePassword(value);
  return (
    <div className={classnames($.PasswordStrengthValidator)}>
      <Dot invalid={charsValid}>{translations.chars}</Dot>
      <Dot invalid={majValid}>{translations.maj}</Dot>
      <Dot invalid={chiffreValid}>{translations.chiffre}</Dot>
      <Dot invalid={specialValid}>{translations.special}</Dot>
    </div>
  );
}
