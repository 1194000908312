
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, LinkTab, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './MissionsListing.module.scss';
import { missionsListingTranslations } from '../../../translate';
import { Mission_State, useMissionsQuery } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Link } from '../../../components/Link/Link';
import * as MissionHelpers from '../../../helpers/mission';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { offsetToCursor } from 'graphql-relay';

const { translate } = missionsListingTranslations;
const getCurrentRouteState = () => {
  const urlContains = (str: string) => document.location.href.indexOf(str) !== -1;
  if (urlContains('waiting')) {
    return Mission_State.WaitingValidation
  } else if (urlContains('started')) {
    return Mission_State.Started;
  } else {
    return Mission_State.Validated;
  }
};
type Filters = {
  page: number;
}
const defaultFilters: Filters = {
  page: 0
};
const getFiltersFromUrl = (searchParams: string | null) => {
  const sanitized = searchParams || '{}';
  const parsed = JSON.parse(sanitized);
  const cleaned = {
    ...defaultFilters,
    ...parsed,
  } as Filters;

  return cleaned;
};
export const MissionsListing = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const filters = getFiltersFromUrl(query.get('filters'));
  const updateQueryParam = <T extends keyof Filters,>(name: T, value: Filters[T]) => {
    const newFilters = { ...filters, [name]: value };
    const stringified = JSON.stringify(newFilters);

    history.push({ search: `?filters=${stringified}` });
  };
  const offset = filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1);
  const state = getCurrentRouteState();
  const list = useMissionsQuery({
    variables: {
      state,
      after: offset,
    }
  });
  const data = useMemo(() => {
    const d = list.data?.missions?.edges! || [];
    return d.map((mission) => ({
      id: mission?.node?.id,
      experience: mission?.node?.experience,
      title: mission?.node?.title,
    }));
  }, [list.data]);

  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox className={classnames($.missionListing)}>
      <Title>{translate('Title')}</Title>
      <Tabs>
        <LinkTab to="/missions">{translate('ListingValidatedTab')}</LinkTab>
        <LinkTab to="/missions/waiting">{translate('ListingToValidateTab')}</LinkTab>
        <LinkTab to="/missions/started">{translate('ListingToStartedTab')}</LinkTab>
      </Tabs>
      <Content>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <>
            <div className={$.AddMission}>
              <Link as="button" to="/mission/new">Nouvelle mission</Link>
            </div>
            <Table<TableData> columns={[
              {
                Header: 'Titre',
                accessor: (row) => <Link to={`/mission/${row.id}`}>{row.title}</Link>,
                width: 30,
              },
              {
                Header: 'Experience',
                accessor: row => MissionHelpers.experienceToText(row.experience!),
                width: 20,
              },
            ]} data={data} />
            <Pagination current={filters.page} pageSize={10} total={list.data?.missions?.total!} onClick={page => updateQueryParam('page', page)} />
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
