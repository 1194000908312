
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Content, PrincipalLayoutBox, Title, ZoneLoadingState, Tabs, LinkTab } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './Profile.module.scss';
import { MeQuery, useMeQuery, useSaveProfileMutation } from '../../../generated/graphql';
import { useAuth, useUserType } from '../../../hooks/auth';
import { useNotification } from '../../../hooks/notifications';
import { useDispatch } from 'react-redux';
import { profileFilled } from '../../../store/domains/auth/Authentication.actions';
import { DeepNonMaybe } from '../../../graphqlHelpers';
import { resetCache } from '../../../apollo';
import { ProfileForm, ProfileFormValues, RefProfileForm } from '../../shared/Profile/ProfileForm';

const meQueryDataToFormValues = (profile: MeQuery): ProfileFormValues => {
  const values = (profile.me as any as DeepNonMaybe<MeQuery['me']>)!;
  let billingContactEmail = values.companyInformations.companyContainer.billingContactEmail;
  let billingContactFirstName = values.companyInformations.companyContainer.billingContactFirstName;
  let billingContactLastName = values.companyInformations.companyContainer.billingContactLastName;
  let billingContactJobTitle = values.companyInformations.companyContainer.billingContactJobTitle;
  let billingContactPhone = values.companyInformations.companyContainer.billingContactPhone;

  return {
    billingContactEmail,
    billingContactFirstName,
    billingContactLastName,
    billingContactJobTitle,
    billingContactPhone,
    companyName: values.companyInformations.companyContainer.name,
    companyCompanyInCreation: values.companyInformations.companyContainer.companyInCreation === null ? false : values.companyInformations.companyContainer.companyInCreation,
    companySiret: values.companyInformations.companyContainer.siret,
    companySize: values.companyInformations.companyContainer.size || '',
    companyArea: values.companyInformations.companyContainer.area || '',
    companyAddress1: values.companyInformations.companyContainer.companyAddress1,
    companyAddress2: values.companyInformations.companyContainer.companyAddress2,
    companyPostalCode: values.companyInformations.companyContainer.companyPostalCode,
    companyCity: values.companyInformations.companyContainer.companyCity,
    userProfileEmail: values.email,
    userProfileFirstName: values.firstName,
    userProfileLastName: values.lastName,
    userProfileJobTitle: values.jobTitle,
    userProfilePhone: values.phone,
  };
};

export const Profile = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { addNotification } = useNotification();

  const profile = useMeQuery();
  const [saveProfile, saveProfileResponse] = useSaveProfileMutation();
  useEffect(() => {
    if (saveProfileResponse.error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      addNotification(true, 'Une erreur est survenue.');
    } else if (saveProfileResponse.data) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      addNotification(false, 'Les informations ont été mises à jour.');
      dispatch(profileFilled());
      resetCache();
    }
  }, [addNotification, dispatch, profile, saveProfileResponse.data, saveProfileResponse.error]);

  const onInternalSubmit = async (formValues: ProfileFormValues) => {
    saveProfile({
      variables: {
        billingContact: {
          sameAsProfile: false,
          email: formValues.billingContactEmail || '',
          firstName: formValues.billingContactFirstName || '',
          lastName: formValues.billingContactLastName || '',
          jobTitle: formValues.billingContactJobTitle || '',
          phone: formValues.billingContactPhone || '',
        },
        company: {
          name: formValues.companyName,
          companyInCreation: formValues.companyCompanyInCreation,
          siret: formValues.companySiret,
          size: formValues.companySize as any,
          area: formValues.companyArea as any,
          address1: formValues.companyAddress1,
          address2: formValues.companyAddress2,
          postalCode: formValues.companyPostalCode,
          city: formValues.companyCity,
        },
        userProfile: {
          firstName: formValues.userProfileFirstName,
          lastName: formValues.userProfileLastName,
          jobTitle: formValues.userProfileJobTitle,
          phone: formValues.userProfilePhone,
        }
      }
    });
  };

  const { FreelanceContainer } = useUserType();

  const profileFormRef = useRef<RefProfileForm>(null);

  return (
    <PrincipalLayoutBox className={classnames($.Profile)}>
      <Title>Profil</Title>
      <Tabs>
        <LinkTab to="/profile">Informations</LinkTab>
        <FreelanceContainer>
          <LinkTab to="/profile/prestataire">CV</LinkTab>
        </FreelanceContainer>
      </Tabs>
      <Content>
        {(profile.loading) && <ZoneLoadingState />}
        {(!profile.loading) && (
          <ProfileForm
            ref={profileFormRef}
            shouldDisplayProfileIncompleteWarning={!auth?.isProfileFilled}
            defaultProfileFormValues={meQueryDataToFormValues(profile.data!)}
            isSaving={saveProfileResponse.loading}
            onSubmit={onInternalSubmit}
          />
        )}
      </Content >
    </PrincipalLayoutBox >
  );
};
