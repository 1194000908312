import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTypedController } from '@hookform/strictly-typed';
import { Content, PrincipalLayoutBox, Title, Zone, ZoneContent, Tabs, LinkTab } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './ProfilePrestatairePreviewEsn.module.scss';
import { profilePrestataireEsnTranslations } from '../../../translate';
import { useCreateEnterpriseProfilePrestataireMutation } from '../../../generated/graphql';
import { Field } from '../../../components/Form/Field/Field';
import { Button } from '../../../components/Button/Button';
import { useForm, useWatch } from 'react-hook-form';
import { useAuth } from '../../../hooks/auth';
import { useNotification } from '../../../hooks/notifications';
import { Upload } from '../../../components/Upload/Upload';
import { useFieldAndInput } from '../../../components/Form/FieldAndInput/FieldAndInput';
import { Slider } from '../../../components/Slider/Slider';
import { resetCache } from '../../../apollo';
import { useHistory } from 'react-router-dom';
import { useSaveStateWatcher } from '../../../hooks/useSaveStateWatcher';
import { uploadCvFile } from '../../../store/domains/profilePrestataire/ProfilePrestataire.service';
import { AvailabilityFields } from '../components/AvailabilityFields';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';

const booleanToRadio = (bool: boolean) => bool ? 'oui' : 'non';
const radioToBoolean = (value: 'oui' | 'non') => value === 'oui';

type FormValues = {
  uploadInputCv: File | null;
  cvName: string | null;
  shouldUploadCv: boolean;
  tjm: string;
  maximumDistance: number;
  minimumMonths: number;
  title: string;
  isAvailable: 'oui' | 'non';
  postalCode: string;
  firstName: string;
  lastName: string;
  trigram: string;
  browserAvailabilityDate: string;
};

const translate = profilePrestataireEsnTranslations.translate;

export const NewProfilePrestatairePreviewEsn = () => {
  const auth = useAuth();
  const { addNotification } = useNotification();
  const history = useHistory();

  const [saveProfilePrestataire, saveProfilePrestataireResponse] = useCreateEnterpriseProfilePrestataireMutation();
  const [isSaving, setSaving] = useState(false);
  const onDataSavedSuccess = useCallback(() => {
    setSaving(false);
    resetCache();
    history.push('/esn');
  }, [history]);
  const onDataSavedError = useCallback(() => setSaving(false), []);
  useSaveStateWatcher(
    saveProfilePrestataireResponse.data,
    saveProfilePrestataireResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );

  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    let fileName = formValues.cvName;
    if (formValues.shouldUploadCv) {
      const uploadSucceed = await uploadCvFile(auth?.firebaseUser.uid!, formValues.uploadInputCv!.name, formValues.uploadInputCv!);
      if (uploadSucceed === false) {
        setSaving(false);
        addNotification(true, 'Une erreur est survenue, veuillez réessayer.');
      } else {
        fileName = uploadSucceed;
      }
    }


    const isAvailable = radioToBoolean(formValues.isAvailable);
    const availabilityDate = isAvailable ? null : new Date(formValues.browserAvailabilityDate + 'T00:00:00Z').toISOString();
    saveProfilePrestataire({
      variables: {
        input: {
          id: null,
          isAvailable,
          availabilityDate,
          cvName: fileName!,
          tjm: +formValues.tjm,
          maximumDistance: formValues.maximumDistance,
          minimumMonths: formValues.minimumMonths,
          title: formValues.title,
          postalCode: formValues.postalCode,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          trigram: formValues.trigram,
        }
      }
    });
  };

  const { register, handleSubmit, errors, control, setValue } = useForm<FormValues>({
    defaultValues: {
      cvName: '',
      tjm: '',
      uploadInputCv: null,
      isAvailable: booleanToRadio(true),
      maximumDistance: 0,
      minimumMonths: 1,
      title: '',
      trigram: '',
      lastName: '',
      firstName: '',
      postalCode: '',
      browserAvailabilityDate: '',
    }
  });
  const TypedController = useTypedController<FormValues>({ control });
  const FieldAndInput = useFieldAndInput<FormValues>({ errors, register });

  const firstName = useWatch<string>({
    control,
    name: 'firstName',
  });

  const lastName = useWatch<string>({
    control,
    name: 'lastName',
  });

  const trigram = useWatch<string>({
    control,
    name: 'trigram',
  });

  useEffect(() => {
    if (!firstName || !lastName) {
      return;
    }

    if (firstName !== '' && lastName !== '' && firstName.length > 2 && lastName.length > 2 && trigram === '') {
      const [letter1] = firstName!.split('');
      const [letter2, letter3] = lastName!.split('');

      setValue('trigram', [letter1, letter2, letter3].join('').toUpperCase());
    }
  }, [firstName, lastName, setValue, trigram]);

  const setBrowserAvailabilityDate = useCallback((months: number) => {
    const date = new Date();
    const dateInFuture = new Date(date.setMonth(date.getMonth() + months));
    setValue('browserAvailabilityDate', dateInFuture.toISOString().split('T')[0]);
  }, [setValue]);

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataireFreelance)}>
      <Title>MES COLLABORATEURS</Title>
      <Tabs>
        <LinkTab to="/esn/profile/new">Nouveau collaborateur</LinkTab>
      </Tabs>
      <Content>
        <form onSubmit={handleSubmit(onInternalSubmit)}>
          <Zone
            title={translate('ProfilePrestataire')}
            description={translate('ProfilePrestataireDescription')}>
            <ZoneContent>
              <FormZone>
                <FormRow>
                  <AvailabilityFields<FormValues>
                    control={control}
                    availableLabel={translate('Form_Available')}
                    availableAriaLabel={translate('Form_AvailableAria')}
                    whenAvailableLabel={translate('Form_WhenAvailable')}
                    whenAvailableAriaLabel={translate('Form_WhenAvailableAria')}
                    register={register}
                    setAvailabilityDate={(months: number) => setBrowserAvailabilityDate(months)}
                  />
                </FormRow>
                <FormRow customWidth={[4, 4, 1]}>
                  <FieldAndInput
                    name="firstName"
                    registerOptions={{ required: true }}
                    label={translate('Form_FirstName')}
                    errorKey="profilePrestataireEsnForm_Error_FirstName"
                  />
                  <FieldAndInput
                    name="lastName"
                    registerOptions={{ required: true }}
                    label={translate('Form_LastName')}
                    errorKey="profilePrestataireEsnForm_Error_LastName"
                  />
                  <FieldAndInput
                    name="trigram"
                    registerOptions={{ required: true }}
                    label={translate('Form_Trigram')}
                  />
                </FormRow>
                <FormRow customWidth={[4, 1]}>
                  <FieldAndInput
                    name="title"
                    registerOptions={{ required: true }}
                    placeholder={translate('Form_TitlePlaceholder')}
                    label={translate('Form_Title')}
                  />
                  <FieldAndInput
                    name="postalCode"
                    registerOptions={{ required: true }}
                    label={translate('Form_PostalCode')}
                  />
                </FormRow>
                <FormRow customWidth={[1, 3, 3]}>
                  <Field>
                    <FieldAndInput
                      name="tjm"
                      registerOptions={{ required: true, valueAsNumber: true }}
                      label={translate('Form_TJM')} />
                    <input type="hidden" name="cvName" ref={register()} />
                    <input type="hidden" name="shouldUploadCv" ref={register({ setValueAs: (value) => value === 'true' })} />
                  </Field>
                  <Field>
                    <TypedController
                      rules={{ required: true }}
                      name="maximumDistance"
                      render={({ onChange, value, ...rest }) => (
                        <Slider
                          label={translate('Form_MaximumDistance')}
                          marks={{
                            0: '0km',
                            1: '15km',
                            2: '30km',
                            3: '50km',
                            4: '200km',
                            5: '+200km',
                          }}
                          min={0}
                          max={5}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Field>
                  <Field>
                    <TypedController
                      rules={{ required: true }}
                      name="minimumMonths"
                      render={({ onChange, value, ...rest }) => (
                        <Slider
                          label={translate('Form_MinimumMonths')}
                          marks={{
                            1: '1m',
                            2: '2m',
                            3: '3m',
                            4: '6m',
                            5: '+12m',
                          }}
                          min={1}
                          max={5}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Field>
                </FormRow>
                <FormRow>
                  <Field>
                    <TypedController
                      name="uploadInputCv"
                      rules={{ required: true }}
                      render={({ onChange, value }) => (
                        <Upload hasError={!!errors.uploadInputCv} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files) {
                            onChange(e.target.files[0]);
                            setValue('shouldUploadCv', true);
                          }
                        }} />
                      )}
                    />
                  </Field>
                </FormRow>
              </FormZone>
            </ZoneContent>
          </Zone>
          <Zone hollow={true}>
            <Field align="right">
              <Button isLoading={isSaving || saveProfilePrestataireResponse.loading} type="submit">{translate('save')}</Button>
            </Field>
          </Zone>
        </form>
      </Content>
    </PrincipalLayoutBox>
  );
};
