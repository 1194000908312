import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Content, PrincipalLayoutBox, Title, Tabs, LinkTab } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './MissionPreview.module.scss';
import { missionTranslations } from '../../../translate';
import { useSaveNewMissionMutation } from '../../../generated/graphql';
import { resetCache } from '../../../apollo';
import { useHistory } from 'react-router-dom';
import { useSaveStateWatcher } from '../../../hooks/useSaveStateWatcher';
import { Form } from './components/Form';

type FormValues = {
  area: string;
  description: string;
  postalCode: string;
  experience: string;
  meta: string;
  needMoving: string;
  remote: string;
  startDate: string;
  endDate: string;
  title: string;
  tjm: string;
};

const translate = missionTranslations.translate;

export const NewMissionPreview = () => {
  const history = useHistory();

  const [saveNewMission, saveNewMissionResponse] = useSaveNewMissionMutation();
  const [isSaving, setSaving] = useState(false);
  const onDataSavedSuccess = useCallback(() => {
    setSaving(false);
    resetCache();
    history.push('/missions');
  }, [history]);
  const onDataSavedError = useCallback(() => setSaving(false), []);
  useSaveStateWatcher(
    saveNewMissionResponse.data,
    saveNewMissionResponse.error,
    onDataSavedSuccess,
    onDataSavedError,
  );

  const onInternalSubmit = async (formValues: FormValues) => {
    setSaving(true);

    saveNewMission({
      variables: {
        input: {
          area: formValues.area,
          description: formValues.description,
          postalCode: formValues.postalCode,
          experience: +formValues.experience,
          meta: formValues.meta || '',
          needMoving: +formValues.needMoving,
          remote: +formValues.remote,
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          title: formValues.title,
          tjm: +formValues.tjm,
        }
      }
    });
  };

  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataireFreelance)}>
      <Title>MISSIONS</Title>
      <Tabs>
        <LinkTab to="/mission/new">{translate('TitleNew')}</LinkTab>
      </Tabs>
      <Content>
        <Form isLoading={isSaving || saveNewMissionResponse.loading} onSubmit={onInternalSubmit} missionState={null} />
      </Content>
    </PrincipalLayoutBox>
  );
};
