import { useEffect, useState } from "react";

const formatPositions = (value: any[] = [], skills: ReturnType<typeof formatSkills>) => {
  return value.map(position => {
    return ({
      start: {
        isCurrent: position?.StartDate?.IsCurrentDate || null,
        date: position?.StartDate?.Date || null
      },
      end: {
        isCurrent: position?.EndDate?.IsCurrentDate || null,
        date: position?.EndDate?.Date || null
      },
      jobLevel: position?.JobLevel,
      description: position?.Description,
      employer: position?.Employer?.Name?.Raw || 'Inconnue',
      name: position?.JobTitle?.Raw,
      isSelfEmployed: position?.IsSelfEmployed === true,
      isCurrent: position?.IsCurrent === true,
      principalSkill: position?.TaxonomyName,
      subSkill: position?.SubTaxonomyName,
      id: position?.Id,
      skills: skills.filter(s => s.skills.some((sk) => sk.foundIn.includes(position?.Id)))
    });
  });
};
export type SovrenPosition = ReturnType<typeof formatPositions>[number];
export type FormattedSkill = {
  name: string;
  id: string;
  percent: number;
  skills: {
    id: string;
    name: string;
    foundIn: string[];
    lastUsed: string;
    experienceInMonths: number;
  }[];
};
const formatSkills = (value: any[] = []) => {
  return value.map(s => s.Taxonomies)
    .flat()
    .filter(s => s.Id === '10')
    .map(s => s.SubTaxonomies)
    .flat()
    .map<FormattedSkill>(s => ({
      name: s.SubTaxonomyName,
      id: s.SubTaxonomyId,
      percent: s.PercentOfOverall,
      skills: (s.Skills || []).map((sk: any) => ({
        id: sk.Id,
        name: sk.Name,
        foundIn: (sk.FoundIn || []).map((f: any) => f.Id),
        lastUsed: sk.LastUsed?.Value,
        experienceInMonths: sk?.MonthsExperience?.Value || null,
      })),
    }));
};
const format = (value: any) => {
  const resumeData = value?.Value?.ResumeData;
  const skills = formatSkills(resumeData?.SkillsData);
  return {
    skills,
    success: value?.Info?.Code === 'Success',
    monthsOfWorkExperience: resumeData?.EmploymentHistory?.ExperienceSummary?.MonthsOfWorkExperience,
    monthsOfManagementExperience: resumeData?.EmploymentHistory?.ExperienceSummary?.MonthsOfManagementExperience,
    positions: formatPositions(resumeData?.EmploymentHistory?.Positions, skills),
  };
};

type StateType = {
  isLoading: true;
  data: null;
} | {
  isLoading: false;
  data: ReturnType<typeof format>;
};

export const useSovrenParser = (value: string | null | undefined) => {
  const [formatted, setFormatted] = useState<StateType>({ isLoading: true, data: null });
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (!value) {
      return;
    }

    try {
      const jsonParsed = JSON.parse(value);
      const formatted = format(jsonParsed);
      setFormatted({ isLoading: false, data: formatted });
    } catch (e) {
      setError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [formatted, error] as const;
};
