import React, { useState } from 'react';
import classnames from 'classnames';
import $ from './FreelanceSignupForm.module.scss';
import { Input } from '../../../../components/Form/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { Field, FieldError } from '../../../../components/Form/Field/Field';
import { useForm, useWatch } from 'react-hook-form';
import { Checkbox } from '../../../../components/Form/Checkbox/Checkbox';
import { translate, translateRich } from '../../../../translate';
import { PasswordStrengthValidator, validatePassword } from '../AuthPanel/PasswordStrengthValidator/PasswordStrengthValidator';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export type FreelanceSignupFormSubmitValues = FormValues & { captchaToken: string; };
export type FreelanceSignupFormProps = {
  preSubmit: () => void;
  postSubmit: () => void;
  onSubmit: (payload: FreelanceSignupFormSubmitValues) => Promise<void>;
  isLoading: boolean;
  error?: string;
  isAdmin?: boolean;
};
export const FreelanceSignupForm = ({ onSubmit, isLoading, preSubmit, postSubmit, error, isAdmin }: FreelanceSignupFormProps) => {
  const [cguChecked, setCguChecked] = useState(false);
  const { control, register, handleSubmit, errors } = useForm<FormValues>({ defaultValues });
  const passwordValue = useWatch<string>({
    control,
    name: 'password',
  });
  //const [isSubmitting, setIsSubmitting] = useState(false);

  const onInternalSubmit = (formValues: FormValues) => {
    preSubmit();
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LeiLiMaAAAAAKTnsqKz2MvIZrm_QMvve3b33zyN', { action: 'submit' }).then(async function (captchaToken) {
        await onSubmit({ ...formValues, captchaToken });

        postSubmit();
      });
    });
  };

  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return (
    <div className={classnames($.FreelanceSignupForm)}>
      <form onSubmit={handleSubmit(onInternalSubmit)}>
        <Field>
          <Input displayLabelOnlyWhenFilled={true} label={translate('prenom')} placeholder={translate('prenom') + '*'} errored={!!errors.firstName} ref={register({ required: true, maxLength: 250 })} name="firstName"></Input>
          {errors.firstName && <FieldError>{translate('errorLengthAndRequired')}</FieldError>}
        </Field>
        <Field>
          <Input displayLabelOnlyWhenFilled={true} label={translate('nom')} placeholder={translate('nom') + '*'} errored={!!errors.lastName} ref={register({ required: true, maxLength: 250 })} name="lastName"></Input>
          {errors.lastName && <FieldError>{translate('errorLengthAndRequired')}</FieldError>}
        </Field>
        <Field>
          <Input displayLabelOnlyWhenFilled={true} label={translate('email')} placeholder={translate('email') + '*'} errored={!!errors.email} ref={register({ required: true, pattern: emailRegex })} name="email"></Input>
          {errors.email && <FieldError>{translate('errorEmail')}</FieldError>}
        </Field>
        {!isAdmin && (
          <>
            <Field>
              <Input displayLabelOnlyWhenFilled={true} label={translate('password')} placeholder={translate('password') + '*'} errored={!!errors.password} type="password" ref={register({ required: true, validate: (value) => validatePassword(value).isValid })} name="password"></Input>
            </Field>
            <Field>
              <PasswordStrengthValidator value={passwordValue || ''} />
            </Field>
            <Field>
              <Checkbox
                onChange={(e: any) => setCguChecked(e.target.checked)}
                checked={cguChecked}
              >{translateRich('cgu')}</Checkbox>
            </Field>
          </>
        )}
        <Field align="right">
          <Button isLoading={isLoading} disabled={!cguChecked && !isAdmin} type="submit">{isAdmin ? translate('authSignupSignupButtonForAdmin') : translate('authSignupSignupButton')}</Button>
        </Field>
        {error && (
          <Field>
            <span className={$.Error}>{error}</span>
          </Field>
        )}
      </form>
    </div>
  );
};
