import React from 'react';
import classnames from 'classnames';
import { Link as RLink, LinkProps as RLinkProps } from 'react-router-dom';
import $ from './Link.module.scss';

export type LinkProps = RLinkProps & {
  as?: 'button' | 'link';
};
export const Link = ({ className, children, as = 'link', ...props }: LinkProps) => (
  <RLink className={classnames(className, $.Link, { [$.LinkAsButton]: as === 'button' })} {...props}>{children}</RLink>
);
export const MailTo = ({ className, children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a className={classnames(className, $.Link)} {...props}>{children}</a>
);
export const BlankLink = ({ className, children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a target="_blank" rel="noopener" className={classnames(className, $.Link)} {...props}>{children}</a>
);
