import { useEffect, RefObject } from 'react';

export default function useOnClickOutside(ref: RefObject<HTMLDivElement>, handler: (ev: Event) => void) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current, handler]
  );
}
