import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { NewProfilePrestatairePreviewEsn } from './NewProfilePrestatairePreviewEsn';
import { ExistingProfilePrestatairePreviewEsn } from './ExistingProfilePrestatairePreviewEsn';

const useGetIdOrNew = () => {
  const { id } = useRouteMatch<{ id: string; }>().params;

  return {
    id,
    isNew: id === 'new'
  };
};

export const ProfilePrestatairePreviewEsn = () => {
  const { isNew } = useGetIdOrNew();

  return (
    isNew ? (
      <NewProfilePrestatairePreviewEsn />
    ) : (
        <ExistingProfilePrestatairePreviewEsn />
      )
  );
};
