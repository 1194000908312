
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { TextAuthLayout } from '../../layout/TextAuthLayout/TextAuthLayout';
import $ from './Action.module.scss';
import { translate } from '../../../translate';
import { getAuth } from '../../../FirebaseConfigurator';
import { Spinner } from '../../../components/Spinner/Spinner';
import { Field } from '../../../components/Form/Field/Field';
import { Input } from '../../../components/Form/Input/Input';
import { Button } from '../../../components/Button/Button';
import { PasswordStrengthValidator, validatePassword } from '../components/AuthPanel/PasswordStrengthValidator/PasswordStrengthValidator';
import { useForm, useWatch } from 'react-hook-form';
import { useNotification } from '../../../hooks/notifications';
import { useSendEmailVerificationMutation } from '../../../generated/graphql';

type FormValues = {
  newPassword: string;
  repeatNewPassword: string;
};

export const Action = () => {
  const notification = useNotification();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const oobCode = query.get('oobCode');
  const mode = query.get('mode');
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onInternalSubmit = async (formValues: FormValues) => {
    setIsLoading(true);
    await getAuth().confirmPasswordReset(oobCode!, formValues.newPassword);
    setIsLoading(false);
    notification.addNotification(false, 'Votre de mot de passe est changé.');
    history.push('/signin');
  };

  const { register, handleSubmit, errors, control } = useForm<FormValues>({
    defaultValues: {
      newPassword: '',
      repeatNewPassword: '',
    }
  });
  const newPasswordValue = useWatch<string>({
    control,
    name: 'newPassword',
  });
  const [sendEmailVerification] = useSendEmailVerificationMutation();

  const [isSendingEmailVerification, setIsSendingEmailVerification] = useState(false);
  const onSendEmailVerification = async () => {
    const continueUrl = decodeURIComponent(query.get('continueUrl') || '');
    const queryFromContinueUrl = new URLSearchParams('?' + continueUrl.split('?')[1]);
    const email = queryFromContinueUrl.get('email');
    if (!email || isSendingEmailVerification) {
      notification.addNotification(true, 'Vous avez déjà fait une demande.');
      return;
    }

    setIsSendingEmailVerification(true);
    await sendEmailVerification({
      variables: {
        email,
      }
    });
    notification.addNotification(false, 'Un email vient de vous être envoyé.');
  };

  useEffect(() => {
    if (!oobCode || !mode) {
      return;
    }

    (async () => {
      try {
        if (mode === 'resetPassword') {
          const actionType = await getAuth().checkActionCode(oobCode);
          const email = await getAuth().verifyPasswordResetCode(oobCode);
          if (email !== actionType.data.email) {
            history.push('/signin');
          }
        } else if (mode === 'verifyEmail') {
          await getAuth().applyActionCode(oobCode);
        } else {
          setHasError(true);
        }
      } catch (e) {
        setHasError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = 'Erreur';
  let content = (
    <div className={$.SpinnerContainer}>
      <Spinner />
    </div>
  );

  let subTitle = '';
  if (mode === 'resetPassword') {
    title = translate('resetPasswordTitle');
    if (!hasError) {
      content = (
        <div className={$.FormContainer}>
          <p>{translate('resetPasswordSubTitle')}</p>
          <form onSubmit={handleSubmit(onInternalSubmit)} style={{marginTop: '20px' }}>
            <Field>
              <Input type="password" label={translate('newPassword')} placeholder={translate('newPassword')} errored={!!errors.newPassword} ref={register({ required: true, validate: (value) => validatePassword(value).isValid })} name="newPassword"></Input>
            </Field>
            <Field>
              <PasswordStrengthValidator value={newPasswordValue || ''} />
            </Field>
            <Field>
              <Input type="password" label={translate('newPasswordRepeat')} placeholder={translate('newPasswordRepeat')} errored={!!errors.repeatNewPassword} ref={register({ required: true, validate: (value) => value === newPasswordValue })} name="repeatNewPassword"></Input>
            </Field>
            <Field align="right">
              <Button isLoading={isLoading} type="submit">Valider</Button>
            </Field>
          </form>
        </div>
      );
    } else {
      content = (
        <p className={$.text}>Votre lien de validation n'est pas valide.</p>
      );
    }
  } else if (mode === 'verifyEmail') {
    title = 'Validation du compte.';
    content = (
      <p className={$.text}>Félicitations ! Votre adresse email est validée.</p>
    );

    if (hasError) {
      content = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <p className={$.text}>Votre lien de validation n'est pas valide. Cliquez <a href="#" onClick={onSendEmailVerification}>ici</a> afin de recevoir un nouveau mail de validation.</p>
      );
    }
  } else {
    content = <p className={$.text}>Votre lien de validation n'est pas valide.</p>;
  }

  return (
    <TextAuthLayout
      link={translate('clickHereToGoToSignin')}
      t1={title}
      t2={subTitle}
      to="/signin"
      className={classnames($.Action)}
    >
      {content}
    </TextAuthLayout>
  );
};
