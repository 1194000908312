import React from 'react';
import classnames from 'classnames';
import {
  Tab as ReakitTab,
  TabList as ReakitTabList,
  TabPanel as ReakitTabPanel,
  TabProps as ReakitTabProps,
  TabListProps as ReakitTabListProps,
  TabPanelProps as ReakitTabPanelProps,
} from "reakit/Tab";
import $ from './Tab.module.scss';

export type TabProps = Omit<ReakitTabProps, 'id'> & { id: string };
export const Tab = ({ className, id, selectedId, ...props }: TabProps) => <ReakitTab {...props} id={id} selectedId={selectedId} className={classnames($.Tab, { [$.TabSelected]: id === selectedId }, className)} />;

export type TabListProps = ReakitTabListProps & {};
export const TabList = ({ className, ...props }: TabListProps) => <ReakitTabList {...props} className={classnames($.TabList, className)} />;

export type TabPanelProps = ReakitTabPanelProps & {};
export const TabPanel = ({ className, ...props }: TabPanelProps) => <ReakitTabPanel {...props} className={classnames($.TabPanel, className)} />;
