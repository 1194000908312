import React from "react";
import { Button } from "../../../components/Button/Button";
import { Modal } from "../../../components/Modal/Modal";
import $ from './RefuseModal.module.scss';

type RefuseModalProps = {
  onSubmit: () => void;
};
export const RefuseModal = ({ onSubmit }: RefuseModalProps) => {
  return (
    <div className={$.RefuseButton}>
      <Modal
        buttonLabel="Refuser"
        title="Refus"
        description="Vous êtes sur le point de refuser un candidat. Êtes vous sûr ?"
      >
        <>
          <div className={$.ModalRefuseButton}>
            <Button onClick={() => onSubmit()}>Refuser</Button>
          </div>
        </>
      </Modal>
    </div>
  );
};
