
import React from 'react';
import classnames from 'classnames';
import { TextAuthLayout } from '../../layout/TextAuthLayout/TextAuthLayout';
import $ from './Validate.module.scss';
import { translate } from '../../../translate';

export const Validate = () => {
  return (
    <TextAuthLayout
      link={translate('clickHereToGoToSignin')}
      t1={translate('congrats')}
      t2={translate('emailVerified')}
      to="/signin"
      className={classnames($.Validate)}
    />
  );
};
