import React from 'react';
import { Roles } from '../store/domains/auth/Authentication.actions';
import { useStoreSelector } from './redux';

export const useAuth = () => {
  return useStoreSelector((state) => state.root.userPayload);
};

type ContainerProps = {
  children: React.ReactNode;
};
export type UserTypePayload = {
  isAdmin: boolean,
  isAdminObserver: boolean,
  isSuperUser: boolean,

  isFreelance: boolean,
  isESN: boolean,
  isClient: boolean,
  isEnterprise: boolean,

  isStandardUser: boolean,

  FreelanceContainer: (props: ContainerProps) => JSX.Element | null;
  ESNContainer: (props: ContainerProps) => JSX.Element | null;
  ClientContainer: (props: ContainerProps) => JSX.Element | null;
  EnterpriseContainer: (props: ContainerProps) => JSX.Element | null;

  StandardUserContainer: (props: ContainerProps) => JSX.Element | null;

  SuperUserContainer: (props: ContainerProps) => JSX.Element | null;
  AdminContainer: (props: ContainerProps) => JSX.Element | null;
  AdminObserverContainer: (props: ContainerProps) => JSX.Element | null;
};
export const useUserType = (): UserTypePayload => {
  const identity = useAuth();
  if (!identity) {
    return {
      isAdmin: false,
      isAdminObserver: false,
      isSuperUser: false,

      isFreelance: false,
      isESN: false,
      isClient: false,
      isEnterprise: false,

      isStandardUser: false,

      FreelanceContainer: () => null,
      ESNContainer: () => null,
      ClientContainer: () => null,
      EnterpriseContainer: () => null,

      StandardUserContainer: () => null,

      SuperUserContainer: () => null,
      AdminContainer: () => null,
      AdminObserverContainer: () => null,
    };
  }


  const isAdminObserver = identity.role === Roles.ADMIN_OBSERVER;
  const isAdmin = identity.role === Roles.ADMIN;
  const isSuperUser = isAdminObserver || isAdmin;

  const isEnterprise = identity.role === Roles.ENTERPRISE;
  const isFreelance = identity.role === Roles.FREELANCE;
  const isESN = identity.role === Roles.ESN || isEnterprise;
  const isClient = identity.role === Roles.CLIENT || isEnterprise;

  const isStandardUser = isFreelance || isESN || isClient;

  return {
    isAdminObserver,
    isAdmin,
    isSuperUser,

    isFreelance,
    isESN,
    isClient,
    isEnterprise,

    isStandardUser,

    FreelanceContainer: ({ children }) => isFreelance ? <>{children}</> : null,
    ESNContainer: ({ children }) => isESN ? <>{children}</> : null,
    ClientContainer: ({ children }) => isClient ? <>{children}</> : null,
    EnterpriseContainer: ({ children }) => isEnterprise ? <>{children}</> : null,

    StandardUserContainer: ({ children }) => isStandardUser ? <>{children}</> : null,

    SuperUserContainer: ({ children }) => isSuperUser ? <>{children}</> : null,
    AdminContainer: ({ children }) => isAdmin ? <>{children}</> : null,
    AdminObserverContainer: ({ children }) => isAdminObserver ? <>{children}</> : null,
  }
};
