import { useEffect } from "react";
import { useNotification } from "./notifications";

/**
 * Listen any data and error object and trigger callbacks based on it
 * It's usually used on hooks mutation response to allow triggering behaviour when saving done
 */
export const useSaveStateWatcher = (data: unknown, error: unknown, onSuccess: () => void, onError: () => void) => {
  const { addNotification } = useNotification();
  useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      addNotification(true, 'Une erreur est survenue.');
      onError();
    } else if (data) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      addNotification(false, 'Sauvegarde effectuée avec succés.');
      onSuccess();
    }
  }, [addNotification, data, error, onError, onSuccess]);
};
