import React from 'react';
import classnames from 'classnames';
import $ from './Field.module.scss';

export type FieldProps = {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
};
export const Field = ({ children, align = 'left' }: FieldProps) => (
  <div className={classnames($.Field, {
    [$.FieldLeft]: align === 'left',
    [$.FieldRight]: align === 'right',
    [$.FieldCenter]: align === 'center',
  })}>
    {children}
  </div>
);

export type FieldErrorProps = {
  children: React.ReactNode;
};
export const FieldError = ({ children }: FieldErrorProps) => (
  <div className={$.FieldError}>{children}</div>
);
