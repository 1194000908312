import React, { useRef } from 'react';
import classnames from 'classnames';
import $ from './Menu.module.scss';
import LogoMono from '../../assets/logo-monochrome.svg';
import { menuTranslations } from '../../translate';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as HelpIcon } from '../../assets/help.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as WorkIcon } from '../../assets/work.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useUserType } from '../../hooks/auth';

type MenuItemProps = {
  children: React.ReactNode;
  to: string;
  icon: React.ReactNode;
  onClicked: () => void;
  alignBottom?: boolean;
  exact?: boolean;
};
const MenuItem = ({ children, to, icon, onClicked, alignBottom, exact = true }: MenuItemProps) => (
  <NavLink className={classnames($.MenuItem, { [$.MenuItemBottom]: alignBottom })} to={to} activeClassName={$.MenuItemActive} exact={exact} onClick={onClicked}>
    <div className={$.MenuItemIcon}>{icon}</div><div className={$.MenuItemText}>{children}</div>
  </NavLink>
);

const { translate } = menuTranslations;

export type MenuProps = {
  onClickOutside: () => void;
};
export const Menu = ({ onClickOutside }: MenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClickOutside);

  const { SuperUserContainer, StandardUserContainer, ESNContainer, ClientContainer, FreelanceContainer } = useUserType();

  return (
    <>
      <div className={$.FullscreenBackdrop}></div>
      <div className={classnames($.Menu)} ref={ref}>
        <img src={LogoMono} alt="" className={$.Logo} />
        <hr className={$.Spacer} />
        <MenuItem to="/" icon={<HomeIcon />} onClicked={onClickOutside}>
          {translate('Home')}
        </MenuItem>
        <SuperUserContainer>
          <MenuItem to="/admin/prestataires" icon={<ProfileIcon />} exact={false} onClicked={onClickOutside}>
            {translate('Prestataires')}
          </MenuItem>
          <MenuItem to="/admin/missions" icon={<WorkIcon />} exact={false} onClicked={onClickOutside}>
            {translate('ClientMissions')}
          </MenuItem>
          <MenuItem to="/admin/users" icon={<ProfileIcon />} exact={false} onClicked={onClickOutside}>
            Utilisateurs
          </MenuItem>
          <MenuItem to="/admin/companies" icon={<ProfileIcon />} exact={false} onClicked={onClickOutside}>
            Entreprises
          </MenuItem>
        </SuperUserContainer>
        <StandardUserContainer>
          <MenuItem to="/profile" icon={<ProfileIcon />} exact={false} onClicked={onClickOutside}>
            {translate('Profile')}
          </MenuItem>
        </StandardUserContainer>
        <FreelanceContainer>
          <MenuItem to="/my/missions" icon={<WorkIcon />} exact={true} onClicked={onClickOutside}>
            {translate('ClientMissions')}
          </MenuItem>
        </FreelanceContainer>
        <ESNContainer>
          <MenuItem to="/esn" icon={<ProfileIcon />} onClicked={onClickOutside}>
            {translate('EsnProfiles')}
          </MenuItem>
        </ESNContainer>
        <ClientContainer>
          <MenuItem to="/missions" icon={<WorkIcon />} exact={false} onClicked={onClickOutside}>
            {translate('ClientMissions')}
          </MenuItem>
          <MenuItem to="/prestataires" icon={<ProfileIcon />} exact={false} onClicked={onClickOutside}>
            {translate('Prestataires')}
          </MenuItem>
        </ClientContainer>
        <MenuItem to="/help" icon={<HelpIcon />} onClicked={onClickOutside} alignBottom>
          {translate('Help')}
        </MenuItem>
        <MenuItem to="/settings" icon={<SettingsIcon />} onClicked={onClickOutside}>
          {translate('Settings')}
        </MenuItem>
      </div>
    </>
  );
};
