import React from 'react';
import classnames from 'classnames';
import { Input as ReakitInput, InputProps as ReakitInputProps } from 'reakit/Input';
import $ from './Textarea.module.scss';

export type TextareaProps = ReakitInputProps & { rows: number, errored?: boolean, label: string; displayLabelOnlyWhenFilled?: boolean; };
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ rows, className, errored, placeholder, displayLabelOnlyWhenFilled = false, label, ...props }, ref) => {
  return (
    <div className={classnames($.TextareaContainer, { [$.TextareaFilled]: !displayLabelOnlyWhenFilled })}>
      { !!label && (
        <div className={$.TextareaLabel}>
          <span>{label}</span>
        </div>
      )}
      <ReakitInput as="textarea" rows={rows} {...props} placeholder={placeholder} ref={ref} className={classnames(className, $.Textarea, { [$.Disabled]: props.disabled, [$.Errored]: errored })} />
    </div>
  );
});
