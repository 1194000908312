
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Content, PrincipalLayoutBox, SubTitle, Title, Zone, ZoneColumn, ZoneContent, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminMissionPreview.module.scss';
import { Mission_State, useAdminMissionQuery, useAdminRefuseMissionMutation, useAdminStartMissionMutation, useAdminValidMissionMutation, Profile_Prestataire_Associated_To_Mission_State, useMissionAssociatedProfilePrestataireLazyQuery, useAdminAppointProfilePrestataireForMissionMutation, useAdminValidateProfilePrestataireForMissionMutation } from '../../../generated/graphql';
import { DataContainer, DataLabelContainer, DataRowContainer, Presentation } from '../../../components/DataPresentation/Presentation/Presentation';
import { Field } from '../../../components/Form/Field/Field';
import { resetCache } from '../../../apollo';
import { ValidateModal } from '../components/ValidateModal';
import { Textarea } from '../../../components/Form/Textarea/Textarea';
import { experienceToText, getAreas, needMovingToText, remoteToText } from '../../../helpers/mission';
import { companyTranslations } from '../../../translate';
import { RefuseModal } from '../components/RefuseModal';
import { PrestataireList } from '../../../components/PrestataireList/PrestataireList';
import { useUserType } from '../../../hooks/auth';
import { StartModal } from '../components/StartModal';

const companyTranslate = companyTranslations.translate;
export const AdminMissionPreview = () => {
  const { AdminContainer } = useUserType();
  const { id } = useParams<{ id: string }>();

  const result = useAdminMissionQuery({
    variables: {
      id,
    },
  });
  const [validMission] = useAdminValidMissionMutation();
  const [refuseMission] = useAdminRefuseMissionMutation();
  const [startMission, startMissionData] = useAdminStartMissionMutation();

  const onValidMission = useCallback((salesEmail: string | undefined | null) => {
    (async () => {
      await validMission({
        variables: {
          id,
          salesEmail: !!salesEmail ? salesEmail : null,
        },
      });

      resetCache();
      result.refetch();
    })();
  }, [id, result, validMission]);

  const onRefuseMission = useCallback(() => {
    (async () => {
      await refuseMission({
        variables: {
          id,
        },
      });

      resetCache();
      result.refetch();
    })();
  }, [id, refuseMission, result]);

  const onStartMission = useCallback((startDate: string, endDate: string, tjm: number) => {
    (async () => {
      await startMission({
        variables: {
          id,
          startDate: `${startDate}T00:00:00Z`,
          endDate: `${endDate}T00:00:00Z`,
          tjm,
        },
      });

      resetCache();
      result.refetch();
    })();
  }, [id, startMission, result]);

  const mission = result.data?.mission!;
  const profilesList = useMemo(() => {
    const d = result.data?.mission?.associatedProfilesPrestataires || [];
    const mapped = d.map((presta) => ({
      id: presta?.profilePrestataire?.id!,
      available: presta?.profilePrestataire?.available!,
      availabilityInMonths: presta?.profilePrestataire?.availabilityInMonths!,
      role: presta?.profilePrestataire?.owner?.role!,
      tjm: presta?.profilePrestataire?.tjm!,
      skills: (presta?.profilePrestataire?.skills! || []).map(a => ({ name: a?.name!, weight: a?.weight! })),
      maximumDistance: presta?.profilePrestataire?.maximumDistance!,
      minimumMonths: presta?.profilePrestataire?.minimumMonths!,
      postalCode: presta?.profilePrestataire?.postalCode!,
      title: presta?.profilePrestataire?.title!,
      cityName: presta?.profilePrestataire?.city?.name!,
      state: presta?.state!,
      name: presta?.profilePrestataire?.owner?.name!,
    }));

    return {
      waiting: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.WaitingSelection),
      selected: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Selected),
      validated: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Validated),
      refused: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Refused),
      appointed: mapped.filter(m => m.state === Profile_Prestataire_Associated_To_Mission_State.Appointed),
    }
  }, [result.data]);

  const formatDate = (date: string) => date.split('T')[0].split('-').reverse().join('/');
  const getArea = (area: string) => getAreas().find(a => a.value === area);

  const [appoint] = useAdminAppointProfilePrestataireForMissionMutation();
  const onAppoint = useCallback(async (profileId: string) => {
    await appoint({
      variables: {
        missionId: id,
        profilePrestataireId: profileId
      }
    });
    resetCache();
    result.refetch();
  }, [id, appoint, result]);
  const [validate] = useAdminValidateProfilePrestataireForMissionMutation();
  const onValidate = useCallback(async (profileId: string) => {
    await validate({
      variables: {
        missionId: id,
        profilePrestataireId: profileId
      }
    });
    resetCache();
    result.refetch();
  }, [id, validate, result]);

  const [fetchAssociatedProfilePrestataire, rawAssociatedProfilePrestataire] = useMissionAssociatedProfilePrestataireLazyQuery();
  const associatedProfilePrestataire = useMemo(() => {
    const array = rawAssociatedProfilePrestataire.data?.mission?.associatedProfilesPrestataires;
    if (array?.length) {
      return {
        ...array[0]?.profilePrestataire!,
        state: array[0]?.state!
      }
    }

    return null;
  }, [rawAssociatedProfilePrestataire.data]);


  const onClickDetails = (profilePrestataireId: string) => {
    fetchAssociatedProfilePrestataire({
      variables: {
        missionId: id,
        profilePrestataireId: profilePrestataireId,
      },
    });
  };

  return (
    <PrincipalLayoutBox className={classnames($.AdminMissionPreview)} fullWidth={true}>
      <Title>Administration</Title>
      <SubTitle>Mission</SubTitle>
      <Content>
        {result.loading && <ZoneLoadingState />}
        {(!result.loading && result.data !== null) && (
          <>
            <Zone
              title={<span className={$.TitleWithIcon}>{mission.title!}</span>} className={$.ProfileInfosZone}>
              <AdminContainer>
                {
                  mission?.state === Mission_State.WaitingValidation && (
                    <div className={$.ModalContainer}>
                      <RefuseModal onSubmit={onRefuseMission} />
                      <ValidateModal salesEmail={mission?.salesEmail} onSubmit={onValidMission} isLoading={false} />
                    </div>
                  )
                }
                {
                  mission?.state === Mission_State.Validated && profilesList.validated.length === 1 && (
                    <div className={$.ModalContainer}>
                      <StartModal startingDate={mission?.startDate?.split('T')[0]!} endingDate={mission?.endDate?.split('T')[0]!} tjm={mission?.tjm!} onSubmit={onStartMission} isLoading={startMissionData.loading} />
                    </div>
                  )
                }
              </AdminContainer>
              {mission.salesEmail && (
                <ZoneContent>
                  <ZoneColumn>
                    <Field>
                      <Presentation>
                        <DataRowContainer>
                          <DataLabelContainer>Suivi par</DataLabelContainer>
                          <DataContainer>{mission.salesEmail}</DataContainer>
                        </DataRowContainer>
                      </Presentation>
                    </Field>
                  </ZoneColumn>
                </ZoneContent>
              )}
              <ZoneContent title={`Client: ${mission.owner?.companyInformations?.companyContainer?.name}`}>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Nom</DataLabelContainer>
                        <DataContainer>{mission.owner?.firstName} {mission.owner?.lastName}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Email</DataLabelContainer>
                        <DataContainer>{mission.owner?.email}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Téléphone</DataLabelContainer>
                        <DataContainer>{mission.owner?.phone}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
              </ZoneContent>
              <ZoneContent title="Mission">
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>TJM</DataLabelContainer>
                        <DataContainer>{mission.tjm} €</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Date de démarrage</DataLabelContainer>
                        <DataContainer>{formatDate(mission.startDate!)}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Date de fin</DataLabelContainer>
                        <DataContainer>{formatDate(mission.endDate!)}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Code postal</DataLabelContainer>
                        <DataContainer>{mission.postalCode}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Experience</DataLabelContainer>
                        <DataContainer>{experienceToText(mission?.experience!)}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Secteur</DataLabelContainer>
                        <DataContainer>{companyTranslate(getArea(mission?.area!)?.labelKey as any)}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Télétravail</DataLabelContainer>
                        <DataContainer>{remoteToText(mission?.remote!)}</DataContainer>
                      </DataRowContainer>
                      <DataRowContainer>
                        <DataLabelContainer>Déplacement</DataLabelContainer>
                        <DataContainer>{needMovingToText(mission?.needMoving!)}</DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
              </ZoneContent>
              <ZoneContent>
                <ZoneColumn>
                  <Field>
                    <Presentation>
                      <DataRowContainer>
                        <DataLabelContainer>Description</DataLabelContainer>
                        <DataContainer>
                          <Textarea readOnly value={mission.description!} label="" rows={20}></Textarea>
                        </DataContainer>
                      </DataRowContainer>
                    </Presentation>
                  </Field>
                </ZoneColumn>
              </ZoneContent>
            </Zone>
          </>
        )
        }
        {
          profilesList.validated.length > 0 && (
            <>
              <h2 className={$.ProfilesTitle} style={{ paddingLeft: '30px' }}>
                Validés
              </h2>
              <PrestataireList
                isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                profileDetails={associatedProfilePrestataire}
                missionId={id}
                kind="ADMIN_MISSION"
                list={profilesList.validated}
                onClickDetails={onClickDetails} />
            </>
          )
        }
        {
          profilesList.appointed.length > 0 && (
            <>
              <h2 className={$.ProfilesTitle} style={{ paddingLeft: '30px' }}>
                Rendez-vous en cours
              </h2>
              <PrestataireList
                isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                profileDetails={associatedProfilePrestataire}
                missionId={id}
                kind="ADMIN_MISSION"
                list={profilesList.appointed}
                onValidate={onValidate}
                onClickDetails={onClickDetails} />
            </>
          )
        }
        {
          profilesList.selected.length > 0 && (
            <>
              <h2 className={$.ProfilesTitle} style={{ paddingLeft: '30px' }}>
                Selectionnés par le client
              </h2>
              <PrestataireList
                isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                profileDetails={associatedProfilePrestataire}
                missionId={id}
                kind="ADMIN_MISSION"
                list={profilesList.selected}
                onAppoint={onAppoint}
                onClickDetails={onClickDetails} />
            </>
          )
        }
        {
          profilesList.waiting.length > 0 && (
            <>
              <h2 className={$.ProfilesTitle} style={{ paddingLeft: '30px' }}>
                En attente
              </h2>
              <PrestataireList
                isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                profileDetails={associatedProfilePrestataire}
                missionId={id}
                kind="ADMIN_MISSION"
                list={profilesList.waiting}
                onClickDetails={onClickDetails} />
            </>
          )
        }
        {
          profilesList.refused.length > 0 && (
            <>
              <h2 className={$.ProfilesTitle} style={{ paddingLeft: '30px' }}>
                Refusés
              </h2>
              <PrestataireList
                isLoadingDetails={rawAssociatedProfilePrestataire.loading}
                profileDetails={associatedProfilePrestataire}
                missionId={id}
                kind="ADMIN_MISSION"
                list={profilesList.refused}
                onClickDetails={onClickDetails} />
            </>
          )
        }
      </Content>
    </PrincipalLayoutBox >
  );
};
