import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Date: string;
  UUID: string;
};




export type Root = {
  __typename?: 'Root';
  getProfilePrestataire?: Maybe<GetProfilePrestataire>;
  matchMission?: Maybe<Array<Maybe<MatchingMissionProfilePrestataire>>>;
  me?: Maybe<User2>;
  user?: Maybe<User2>;
  users?: Maybe<User2Connection>;
  mission?: Maybe<Mission>;
  usersAssociatedToCompany?: Maybe<Array<Maybe<UserCompanyContainer>>>;
  companies?: Maybe<CompanyContainerConnection>;
  adminConfiguration?: Maybe<AdminConfiguration>;
  dashboard?: Maybe<MissionsMetadata>;
  missions?: Maybe<MissionConnection>;
  anonymousProfilesPrestataires?: Maybe<ProfilePrestataire3Connection>;
  anonymousProfilePrestataire?: Maybe<ProfilePrestataire3>;
  profilesPrestataires?: Maybe<ProfilePrestataire3Connection>;
  profilePrestataire?: Maybe<ProfilePrestataire3>;
};


export type RootGetProfilePrestataireArgs = {
  id?: Maybe<Scalars['String']>;
};


export type RootMatchMissionArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type RootUserArgs = {
  id: Scalars['String'];
};


export type RootUsersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  role?: Maybe<Role>;
  email?: Maybe<Scalars['String']>;
};


export type RootMissionArgs = {
  id: Scalars['UUID'];
};


export type RootUsersAssociatedToCompanyArgs = {
  companyContainerId: Scalars['UUID'];
};


export type RootCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  billingContactEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
};


export type RootMissionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  state?: Maybe<Mission_State>;
};


export type RootAnonymousProfilesPrestatairesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootAnonymousProfilePrestataireArgs = {
  id: Scalars['UUID'];
};


export type RootProfilesPrestatairesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  onlyWithValidatedMissions?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Profile_State>;
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tjmMin?: Maybe<Scalars['Int']>;
  tjmMax?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  availabilityInMonths?: Maybe<Scalars['Int']>;
  role?: Maybe<Role>;
  ownerId?: Maybe<Scalars['String']>;
  skillsOr?: Maybe<Array<Scalars['String']>>;
  skillsAnd?: Maybe<Array<Scalars['String']>>;
};


export type RootProfilePrestataireArgs = {
  id: Scalars['UUID'];
};

export type GetProfilePrestataire = {
  __typename?: 'GetProfilePrestataire';
  postalCode?: Maybe<Scalars['String']>;
  isAvailable: Scalars['Boolean'];
  availabilityDate?: Maybe<Scalars['DateTime']>;
  maximumDistance?: Maybe<Scalars['Int']>;
  minimumMonths?: Maybe<Scalars['Int']>;
  tjm?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  trigram?: Maybe<Scalars['String']>;
  cvName?: Maybe<Scalars['String']>;
  isFilled: Scalars['Boolean'];
};

export type MatchingMissionProfilePrestataire = {
  __typename?: 'MatchingMissionProfilePrestataire';
  associatedProfilesPrestataires?: Maybe<ProfilePrestataire3>;
  score?: Maybe<Scalars['Int']>;
};

export type ProfilePrestataire3 = {
  __typename?: 'ProfilePrestataire3';
  id?: Maybe<Scalars['UUID']>;
  skills?: Maybe<Array<Maybe<Skill>>>;
  title?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  tjm?: Maybe<Scalars['Int']>;
  state?: Maybe<Profile_State>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cvName?: Maybe<Scalars['String']>;
  trigram?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  salesEmail?: Maybe<Scalars['String']>;
  maximumDistance?: Maybe<Scalars['Int']>;
  minimumMonths?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  parsedCv?: Maybe<Scalars['String']>;
  availabilityDate?: Maybe<Scalars['DateTime']>;
  availabilityInMonths?: Maybe<Scalars['Int']>;
  owner?: Maybe<User2>;
  city?: Maybe<City>;
  validatedAssociatedMissions?: Maybe<Array<Maybe<AssociatedMission>>>;
  validatedAssociatedMission?: Maybe<AssociatedMission>;
};


export type ProfilePrestataire3ValidatedAssociatedMissionArgs = {
  id: Scalars['UUID'];
};

export type Skill = {
  __typename?: 'Skill';
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export enum Profile_State {
  Validated = 'validated',
  Incomplete = 'incomplete',
  Refused = 'refused',
  Waiting = 'waiting'
}

export type User2 = {
  __typename?: 'User2';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isFilled?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  companyInformations?: Maybe<UserCompanyContainer>;
  profilePrestataire?: Maybe<ProfilePrestataire3>;
};


export type User2ProfilePrestataireArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export enum Role {
  Admin = 'ADMIN',
  AdminObserver = 'ADMIN_OBSERVER',
  Client = 'CLIENT',
  Esn = 'ESN',
  Freelance = 'FREELANCE',
  Enterprise = 'ENTERPRISE'
}

export type UserCompanyContainer = {
  __typename?: 'UserCompanyContainer';
  canEdit?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User2>;
  companyContainer?: Maybe<CompanyContainer>;
};

export type CompanyContainer = {
  __typename?: 'CompanyContainer';
  id?: Maybe<Scalars['UUID']>;
  usersCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  companyInCreation?: Maybe<Scalars['Boolean']>;
  siret?: Maybe<Scalars['String']>;
  size?: Maybe<Company_Size2>;
  area?: Maybe<Company_Area2>;
  companyAddress1?: Maybe<Scalars['String']>;
  companyAddress2?: Maybe<Scalars['String']>;
  companyPostalCode?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyIsFilled?: Maybe<Scalars['Boolean']>;
  billingContactFirstName?: Maybe<Scalars['String']>;
  billingContactLastName?: Maybe<Scalars['String']>;
  billingContactPhone?: Maybe<Scalars['String']>;
  billingContactJobTitle?: Maybe<Scalars['String']>;
  billingContactEmail?: Maybe<Scalars['String']>;
  billingContactIsFilled?: Maybe<Scalars['Boolean']>;
};

export enum Company_Size2 {
  S200 = 's200',
  S500 = 's500',
  S1000 = 's1000',
  S1001 = 's1001'
}

export enum Company_Area2 {
  BanqueAssurance = 'banqueAssurance',
  Btp = 'btp',
  ChimiePharmacie = 'chimiePharmacie',
  CommerceDistribution = 'commerceDistribution',
  EditionCommunication = 'editionCommunication',
  ElectroniqueIndustrie = 'electroniqueIndustrie',
  Industrie = 'industrie',
  InformatiqueTelecoms = 'informatiqueTelecoms',
  SanteSocial = 'santeSocial',
  ServicePublic = 'servicePublic',
  ServicesAuxEntreprisesBToB = 'servicesAuxEntreprisesBToB',
  ModeLuxe = 'modeLuxe',
  TransportsLogistique = 'transportsLogistique',
  Autre = 'autre'
}

export type City = {
  __typename?: 'City';
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['Int']>;
};

export type AssociatedMission = {
  __typename?: 'AssociatedMission';
  mission?: Maybe<Mission>;
  state?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type Mission = {
  __typename?: 'Mission';
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  tjm?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  experience?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['String']>;
  needMoving?: Maybe<Scalars['Int']>;
  remote?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Mission_State>;
  salesEmail?: Maybe<Scalars['String']>;
  owner?: Maybe<User2>;
  associatedProfilesPrestataires?: Maybe<Array<Maybe<AssociatedProfilePrestataire>>>;
  craState?: Maybe<CraState>;
};


export type MissionAssociatedProfilesPrestatairesArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MissionCraStateArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export enum Mission_State {
  Validated = 'VALIDATED',
  Refused = 'REFUSED',
  WaitingValidation = 'WAITING_VALIDATION',
  Started = 'STARTED',
  Ended = 'ENDED'
}

export type AssociatedProfilePrestataire = {
  __typename?: 'AssociatedProfilePrestataire';
  profilePrestataire?: Maybe<ProfilePrestataire3>;
  state?: Maybe<Profile_Prestataire_Associated_To_Mission_State>;
  weight?: Maybe<Scalars['Int']>;
};

export enum Profile_Prestataire_Associated_To_Mission_State {
  WaitingSelection = 'WAITING_SELECTION',
  Selected = 'SELECTED',
  Appointed = 'APPOINTED',
  Validated = 'VALIDATED',
  Refused = 'REFUSED'
}

export type CraState = {
  __typename?: 'CraState';
  date?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Cra_State_Enum>;
  cras?: Maybe<Array<Maybe<Cra>>>;
};

export enum Cra_State_Enum {
  Impossible = 'IMPOSSIBLE',
  ToFill = 'TO_FILL',
  ToValidateByClient = 'TO_VALIDATE_BY_CLIENT',
  ToFix = 'TO_FIX',
  ToPay = 'TO_PAY',
  Paid = 'PAID'
}

export type Cra = {
  __typename?: 'Cra';
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type User2Connection = {
  __typename?: 'User2Connection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<User2Edge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type User2Edge = {
  __typename?: 'User2Edge';
  node?: Maybe<User2>;
  cursor: Scalars['String'];
};

export type CompanyContainerConnection = {
  __typename?: 'CompanyContainerConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CompanyContainerEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyContainerEdge = {
  __typename?: 'CompanyContainerEdge';
  node?: Maybe<CompanyContainer>;
  cursor: Scalars['String'];
};

export type AdminConfiguration = {
  __typename?: 'AdminConfiguration';
  isFitnetIntegrationEnabled?: Maybe<Scalars['Boolean']>;
};

export type MissionsMetadata = {
  __typename?: 'MissionsMetadata';
  totalMissions?: Maybe<Scalars['Int']>;
  validatedMissions?: Maybe<Scalars['Int']>;
  refusedMissions?: Maybe<Scalars['Int']>;
  waitingValidationMissions?: Maybe<Scalars['Int']>;
  startedMissions?: Maybe<Scalars['Int']>;
  endedMissions?: Maybe<Scalars['Int']>;
  totalProfilesPrestataires?: Maybe<Scalars['Int']>;
  validatedProfilesPrestataires?: Maybe<Scalars['Int']>;
  refusedProfilesPrestataires?: Maybe<Scalars['Int']>;
  waitingValidationProfilesPrestataires?: Maybe<Scalars['Int']>;
  incompleteProfilesPrestataires?: Maybe<Scalars['Int']>;
};

export type MissionConnection = {
  __typename?: 'MissionConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<MissionEdge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MissionEdge = {
  __typename?: 'MissionEdge';
  node?: Maybe<Mission>;
  cursor: Scalars['String'];
};

export type ProfilePrestataire3Connection = {
  __typename?: 'ProfilePrestataire3Connection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProfilePrestataire3Edge>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProfilePrestataire3Edge = {
  __typename?: 'ProfilePrestataire3Edge';
  node?: Maybe<ProfilePrestataire3>;
  cursor: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  signUpClient?: Maybe<SignUpClientArgs>;
  signUpEsn?: Maybe<SignUpEsnArgs>;
  signUpFreelance?: Maybe<SignUpFreelanceArgs>;
  forgotPassword?: Maybe<ForgotPasswordArgs>;
  sendEmailVerification?: Maybe<SendEmailVerificationArgs>;
  adminActivateEnterpriseMode?: Maybe<Scalars['Boolean']>;
  adminDisableEnterpriseMode?: Maybe<Scalars['Boolean']>;
  adminEnforceUserEmailVerification?: Maybe<Scalars['Boolean']>;
  saveProfile?: Maybe<Scalars['Boolean']>;
  adminRefreshCvParsing?: Maybe<Scalars['Boolean']>;
  chooseProfilePrestataireForMission?: Maybe<Scalars['Boolean']>;
  adminValidProfile?: Maybe<Scalars['Boolean']>;
  adminRefuseProfile?: Maybe<Scalars['Boolean']>;
  adminValidMission?: Maybe<Scalars['Boolean']>;
  adminRefuseMission?: Maybe<Scalars['Boolean']>;
  adminStartMission?: Maybe<Scalars['Boolean']>;
  adminAppointProfilePrestataireForMission?: Maybe<Scalars['Boolean']>;
  adminValidateProfilePrestataireForMission?: Maybe<Scalars['Boolean']>;
  adminArchiveProfilePrestataire?: Maybe<Scalars['Boolean']>;
  adminDeleteCompany?: Maybe<Scalars['Boolean']>;
  adminAssociateUsersToCompany?: Maybe<Scalars['Boolean']>;
  adminSetConfiguration?: Maybe<Scalars['Boolean']>;
  saveNewMission?: Maybe<Scalars['UUID']>;
  saveExistingMission?: Maybe<Scalars['UUID']>;
  acceptCra?: Maybe<Scalars['Boolean']>;
  refuseCra?: Maybe<Scalars['Boolean']>;
  updateCra?: Maybe<Scalars['Boolean']>;
  adminDeleteMission?: Maybe<Scalars['Boolean']>;
  adminSaveNewMission?: Maybe<Scalars['UUID']>;
  adminSaveExistingMission?: Maybe<Scalars['UUID']>;
  saveFreelanceProfilePrestataire?: Maybe<Scalars['Boolean']>;
  adminSaveFreelanceProfilePrestataire?: Maybe<Scalars['Boolean']>;
  saveEnterpriseProfilePrestataire?: Maybe<Scalars['Boolean']>;
  createEnterpriseProfilePrestataire?: Maybe<Scalars['UUID']>;
  adminSaveEnterpriseProfilePrestataire?: Maybe<Scalars['Boolean']>;
  adminCreateEnterpriseProfilePrestataire?: Maybe<Scalars['UUID']>;
  adminDeleteProfilePrestataire?: Maybe<Scalars['Boolean']>;
  deleteProfilePrestataire?: Maybe<Scalars['Boolean']>;
  adminDeleteUser?: Maybe<Scalars['Boolean']>;
};


export type MutationSignUpClientArgs = {
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  captchaToken: Scalars['String'];
};


export type MutationSignUpEsnArgs = {
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  captchaToken: Scalars['String'];
};


export type MutationSignUpFreelanceArgs = {
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  captchaToken: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSendEmailVerificationArgs = {
  email: Scalars['String'];
};


export type MutationAdminActivateEnterpriseModeArgs = {
  id: Scalars['String'];
};


export type MutationAdminDisableEnterpriseModeArgs = {
  id: Scalars['String'];
};


export type MutationAdminEnforceUserEmailVerificationArgs = {
  id: Scalars['String'];
};


export type MutationSaveProfileArgs = {
  input?: Maybe<ProfileInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationAdminRefreshCvParsingArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationChooseProfilePrestataireForMissionArgs = {
  profilePrestataireId: Scalars['UUID'];
  missionId: Scalars['UUID'];
};


export type MutationAdminValidProfileArgs = {
  id?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationAdminRefuseProfileArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationAdminValidMissionArgs = {
  id?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationAdminRefuseMissionArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationAdminStartMissionArgs = {
  id: Scalars['UUID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  tjm: Scalars['Int'];
};


export type MutationAdminAppointProfilePrestataireForMissionArgs = {
  profilePrestataireId: Scalars['UUID'];
  missionId: Scalars['UUID'];
};


export type MutationAdminValidateProfilePrestataireForMissionArgs = {
  profilePrestataireId: Scalars['UUID'];
  missionId: Scalars['UUID'];
};


export type MutationAdminArchiveProfilePrestataireArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationAdminDeleteCompanyArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationAdminAssociateUsersToCompanyArgs = {
  companyId: Scalars['UUID'];
  users: Array<Maybe<ToAssociateUser>>;
};


export type MutationAdminSetConfigurationArgs = {
  isFitnetIntegrationEnabled: Scalars['Boolean'];
};


export type MutationSaveNewMissionArgs = {
  input?: Maybe<MissionInput>;
};


export type MutationSaveExistingMissionArgs = {
  input?: Maybe<MissionInput>;
};


export type MutationAcceptCraArgs = {
  missionId: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationRefuseCraArgs = {
  missionId: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationUpdateCraArgs = {
  missionId: Scalars['UUID'];
  profilePrestataireId?: Maybe<Scalars['UUID']>;
  month: Scalars['Int'];
  year: Scalars['Int'];
  cras: Array<CraToUpdate>;
  submit: Scalars['Boolean'];
};


export type MutationAdminDeleteMissionArgs = {
  id: Scalars['UUID'];
};


export type MutationAdminSaveNewMissionArgs = {
  input?: Maybe<MissionInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationAdminSaveExistingMissionArgs = {
  input?: Maybe<MissionInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationSaveFreelanceProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationAdminSaveFreelanceProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationSaveEnterpriseProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationCreateEnterpriseProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationAdminSaveEnterpriseProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationAdminCreateEnterpriseProfilePrestataireArgs = {
  input?: Maybe<SaveProfilePrestataireInput>;
};


export type MutationAdminDeleteProfilePrestataireArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteProfilePrestataireArgs = {
  id: Scalars['UUID'];
};


export type MutationAdminDeleteUserArgs = {
  id: Scalars['String'];
};

export type SignUpClientArgs = {
  __typename?: 'SignUpClientArgs';
  user?: Maybe<User>;
  errors?: Maybe<Array<Array<Maybe<Scalars['String']>>>>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
};

export type SignUpEsnArgs = {
  __typename?: 'SignUpEsnArgs';
  user?: Maybe<User>;
  errors?: Maybe<Array<Array<Maybe<Scalars['String']>>>>;
};

export type SignUpFreelanceArgs = {
  __typename?: 'SignUpFreelanceArgs';
  user?: Maybe<User>;
  errors?: Maybe<Array<Array<Maybe<Scalars['String']>>>>;
};

export type ForgotPasswordArgs = {
  __typename?: 'ForgotPasswordArgs';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Array<Maybe<Scalars['String']>>>>;
};

export type SendEmailVerificationArgs = {
  __typename?: 'SendEmailVerificationArgs';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Array<Maybe<Scalars['String']>>>>;
};

export type ProfileInput = {
  userProfile: UserProfileInput;
  billingContact: BillingContactInput;
  company: CompanyInput;
};

export type UserProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  jobTitle: Scalars['String'];
};

export type BillingContactInput = {
  sameAsProfile: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  jobTitle: Scalars['String'];
  email: Scalars['String'];
};

export type CompanyInput = {
  name: Scalars['String'];
  companyInCreation: Scalars['Boolean'];
  siret?: Maybe<Scalars['String']>;
  size: Company_Size;
  area: Company_Area;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  city: Scalars['String'];
};

export enum Company_Size {
  S200 = 's200',
  S500 = 's500',
  S1000 = 's1000',
  S1001 = 's1001'
}

export enum Company_Area {
  BanqueAssurance = 'banqueAssurance',
  Btp = 'btp',
  ChimiePharmacie = 'chimiePharmacie',
  CommerceDistribution = 'commerceDistribution',
  EditionCommunication = 'editionCommunication',
  ElectroniqueIndustrie = 'electroniqueIndustrie',
  Industrie = 'industrie',
  InformatiqueTelecoms = 'informatiqueTelecoms',
  SanteSocial = 'santeSocial',
  ServicePublic = 'servicePublic',
  ServicesAuxEntreprisesBToB = 'servicesAuxEntreprisesBToB',
  ModeLuxe = 'modeLuxe',
  TransportsLogistique = 'transportsLogistique',
  Autre = 'autre'
}

export type ToAssociateUser = {
  id: Scalars['String'];
  canEdit: Scalars['Boolean'];
};

export type MissionInput = {
  id?: Maybe<Scalars['UUID']>;
  area: Scalars['String'];
  description: Scalars['String'];
  postalCode: Scalars['String'];
  experience: Scalars['Int'];
  meta: Scalars['String'];
  needMoving: Scalars['Int'];
  remote: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  title: Scalars['String'];
  tjm: Scalars['Int'];
};

export type CraToUpdate = {
  date?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type SaveProfilePrestataireInput = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['String']>;
  cvName: Scalars['String'];
  tjm: Scalars['Int'];
  isAvailable: Scalars['Boolean'];
  maximumDistance: Scalars['Int'];
  minimumMonths: Scalars['Int'];
  title: Scalars['String'];
  postalCode: Scalars['String'];
  availabilityDate?: Maybe<Scalars['DateTime']>;
  trigram?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminValidProfileMutationVariables = Exact<{
  id: Scalars['UUID'];
  salesEmail?: Maybe<Scalars['String']>;
}>;


export type AdminValidProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminValidProfile'>
);

export type AdminRefuseProfileMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminRefuseProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminRefuseProfile'>
);

export type AdminValidMissionMutationVariables = Exact<{
  id: Scalars['UUID'];
  salesEmail?: Maybe<Scalars['String']>;
}>;


export type AdminValidMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminValidMission'>
);

export type AdminRefuseMissionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminRefuseMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminRefuseMission'>
);

export type AdminStartMissionMutationVariables = Exact<{
  id: Scalars['UUID'];
  tjm: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type AdminStartMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminStartMission'>
);

export type AdminRefreshCvParsingMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminRefreshCvParsingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminRefreshCvParsing'>
);

export type AdminActivateEnterpriseModeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminActivateEnterpriseModeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminActivateEnterpriseMode'>
);

export type AdminDisableEnterpriseModeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminDisableEnterpriseModeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDisableEnterpriseMode'>
);

export type AdminAppointProfilePrestataireForMissionMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  profilePrestataireId: Scalars['UUID'];
}>;


export type AdminAppointProfilePrestataireForMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminAppointProfilePrestataireForMission'>
);

export type AdminValidateProfilePrestataireForMissionMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  profilePrestataireId: Scalars['UUID'];
}>;


export type AdminValidateProfilePrestataireForMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminValidateProfilePrestataireForMission'>
);

export type AdminEnforceUserEmailVerificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminEnforceUserEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminEnforceUserEmailVerification'>
);

export type AdminArchiveProfilePrestataireMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminArchiveProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminArchiveProfilePrestataire'>
);

export type AdminDeleteCompanyMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminDeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteCompany'>
);

export type AdminAssociateUsersToCompanyMutationVariables = Exact<{
  companyId: Scalars['UUID'];
  users: Array<Maybe<ToAssociateUser>> | Maybe<ToAssociateUser>;
}>;


export type AdminAssociateUsersToCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminAssociateUsersToCompany'>
);

export type AdminSaveProfileMutationVariables = Exact<{
  userProfile: UserProfileInput;
  billingContact: BillingContactInput;
  company: CompanyInput;
  userId: Scalars['String'];
}>;


export type AdminSaveProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveProfile'>
);

export type AdminDeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteUser'>
);

export type AdminDeleteProfilePrestataireMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminDeleteProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteProfilePrestataire'>
);

export type AdminDeleteMissionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminDeleteMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteMission'>
);

export type AdminSaveNewMissionMutationVariables = Exact<{
  input: MissionInput;
  userId: Scalars['String'];
}>;


export type AdminSaveNewMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSaveNewMission'>
);

export type AdminSaveExistingMissionMutationVariables = Exact<{
  input: MissionInput;
  userId: Scalars['String'];
}>;


export type AdminSaveExistingMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSaveExistingMission'>
);

export type AdminSetConfigurationMutationVariables = Exact<{
  isFitnetIntegrationEnabled: Scalars['Boolean'];
}>;


export type AdminSetConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSetConfiguration'>
);

export type SaveNewMissionMutationVariables = Exact<{
  input: MissionInput;
}>;


export type SaveNewMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveNewMission'>
);

export type SaveExistingMissionMutationVariables = Exact<{
  input: MissionInput;
}>;


export type SaveExistingMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveExistingMission'>
);

export type UpdateCraMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  cras: Array<CraToUpdate> | CraToUpdate;
  month: Scalars['Int'];
  year: Scalars['Int'];
  profilePrestataireId?: Maybe<Scalars['UUID']>;
  submit: Scalars['Boolean'];
}>;


export type UpdateCraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCra'>
);

export type AcceptCraMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type AcceptCraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptCra'>
);

export type RefuseCraMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type RefuseCraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'refuseCra'>
);

export type SaveProfileMutationVariables = Exact<{
  userProfile: UserProfileInput;
  billingContact: BillingContactInput;
  company: CompanyInput;
}>;


export type SaveProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveProfile'>
);

export type ChooseProfilePrestataireForMissionMutationVariables = Exact<{
  missionId: Scalars['UUID'];
  profilePrestataireId: Scalars['UUID'];
}>;


export type ChooseProfilePrestataireForMissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'chooseProfilePrestataireForMission'>
);

export type AdminSaveFreelanceProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type AdminSaveFreelanceProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSaveFreelanceProfilePrestataire'>
);

export type AdminCreateEnterpriseProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type AdminCreateEnterpriseProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminCreateEnterpriseProfilePrestataire'>
);

export type AdminSaveEnterpriseProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type AdminSaveEnterpriseProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSaveEnterpriseProfilePrestataire'>
);

export type SaveFreelanceProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type SaveFreelanceProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveFreelanceProfilePrestataire'>
);

export type SaveEnterpriseProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type SaveEnterpriseProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveEnterpriseProfilePrestataire'>
);

export type CreateEnterpriseProfilePrestataireMutationVariables = Exact<{
  input: SaveProfilePrestataireInput;
}>;


export type CreateEnterpriseProfilePrestataireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEnterpriseProfilePrestataire'>
);

export type SignupClientMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  phone: Scalars['String'];
  captchaToken: Scalars['String'];
}>;


export type SignupClientMutation = (
  { __typename?: 'Mutation' }
  & { signUpClient?: Maybe<(
    { __typename?: 'SignUpClientArgs' }
    & Pick<SignUpClientArgs, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type SignupEsnMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  phone: Scalars['String'];
  captchaToken: Scalars['String'];
}>;


export type SignupEsnMutation = (
  { __typename?: 'Mutation' }
  & { signUpEsn?: Maybe<(
    { __typename?: 'SignUpEsnArgs' }
    & Pick<SignUpEsnArgs, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type SignupFreelanceMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  captchaToken: Scalars['String'];
}>;


export type SignupFreelanceMutation = (
  { __typename?: 'Mutation' }
  & { signUpFreelance?: Maybe<(
    { __typename?: 'SignUpFreelanceArgs' }
    & Pick<SignUpFreelanceArgs, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordArgs' }
    & Pick<ForgotPasswordArgs, 'success' | 'errors'>
  )> }
);

export type SendEmailVerificationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailVerification?: Maybe<(
    { __typename?: 'SendEmailVerificationArgs' }
    & Pick<SendEmailVerificationArgs, 'success' | 'errors'>
  )> }
);

export type AdminUsersQueryVariables = Exact<{
  role?: Maybe<Role>;
  email?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type AdminUsersQuery = (
  { __typename?: 'Root' }
  & { users?: Maybe<(
    { __typename?: 'User2Connection' }
    & Pick<User2Connection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'User2Edge' }
      & Pick<User2Edge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'User2' }
        & Pick<User2, 'name' | 'id' | 'email' | 'role'>
        & { profilePrestataire?: Maybe<(
          { __typename?: 'ProfilePrestataire3' }
          & Pick<ProfilePrestataire3, 'id'>
        )> }
      )> }
    )>>> }
  )> }
);

export type AdminMissionsQueryVariables = Exact<{
  state?: Maybe<Mission_State>;
  after?: Maybe<Scalars['String']>;
}>;


export type AdminMissionsQuery = (
  { __typename?: 'Root' }
  & { missions?: Maybe<(
    { __typename?: 'MissionConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'MissionEdge' }
      & { node?: Maybe<(
        { __typename?: 'Mission' }
        & Pick<Mission, 'createdAt' | 'remote' | 'state' | 'id' | 'tjm' | 'title' | 'experience'>
        & { owner?: Maybe<(
          { __typename?: 'User2' }
          & Pick<User2, 'id'>
          & { companyInformations?: Maybe<(
            { __typename?: 'UserCompanyContainer' }
            & { companyContainer?: Maybe<(
              { __typename?: 'CompanyContainer' }
              & Pick<CompanyContainer, 'name'>
            )> }
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type AdminMissionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminMissionQuery = (
  { __typename?: 'Root' }
  & { mission?: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'description' | 'startDate' | 'endDate' | 'title' | 'tjm' | 'valid' | 'id' | 'postalCode' | 'experience' | 'meta' | 'needMoving' | 'remote' | 'area' | 'state' | 'salesEmail'>
    & { owner?: Maybe<(
      { __typename?: 'User2' }
      & Pick<User2, 'firstName' | 'lastName' | 'email' | 'phone'>
      & { companyInformations?: Maybe<(
        { __typename?: 'UserCompanyContainer' }
        & { companyContainer?: Maybe<(
          { __typename?: 'CompanyContainer' }
          & Pick<CompanyContainer, 'name'>
        )> }
      )> }
    )>, associatedProfilesPrestataires?: Maybe<Array<Maybe<(
      { __typename?: 'AssociatedProfilePrestataire' }
      & Pick<AssociatedProfilePrestataire, 'state' | 'weight'>
      & { profilePrestataire?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'availabilityInMonths' | 'id' | 'available' | 'tjm' | 'maximumDistance' | 'minimumMonths' | 'postalCode' | 'state' | 'title'>
        & { owner?: Maybe<(
          { __typename?: 'User2' }
          & Pick<User2, 'email' | 'role' | 'name'>
        )>, skills?: Maybe<Array<Maybe<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'name' | 'weight'>
        )>>>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'postalCode'>
        )> }
      )> }
    )>>> }
  )> }
);

export type AdminDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDashboardQuery = (
  { __typename?: 'Root' }
  & { dashboard?: Maybe<(
    { __typename?: 'MissionsMetadata' }
    & Pick<MissionsMetadata, 'totalMissions' | 'validatedMissions' | 'refusedMissions' | 'waitingValidationMissions' | 'startedMissions' | 'endedMissions' | 'totalProfilesPrestataires' | 'validatedProfilesPrestataires' | 'refusedProfilesPrestataires' | 'waitingValidationProfilesPrestataires' | 'incompleteProfilesPrestataires'>
  )> }
);

export type AdminProfilesPrestatairesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tjmMax?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  availabilityInMonths?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  state?: Maybe<Profile_State>;
  ownerId?: Maybe<Scalars['String']>;
  skillsOr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  skillsAnd?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AdminProfilesPrestatairesQuery = (
  { __typename?: 'Root' }
  & { profilesPrestataires?: Maybe<(
    { __typename?: 'ProfilePrestataire3Connection' }
    & Pick<ProfilePrestataire3Connection, 'total'>
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfilePrestataire3Edge' }
      & { node?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'availabilityInMonths' | 'id' | 'state' | 'availabilityDate' | 'tjm' | 'title' | 'firstName' | 'lastName' | 'name' | 'postalCode' | 'available'>
        & { owner?: Maybe<(
          { __typename?: 'User2' }
          & Pick<User2, 'name' | 'role' | 'createdAt' | 'id'>
        )> }
      )> }
    )>>> }
  )> }
);

export type AdminProfilePrestataireQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { profilePrestataire?: Maybe<(
    { __typename?: 'ProfilePrestataire3' }
    & Pick<ProfilePrestataire3, 'id' | 'availabilityDate' | 'availabilityInMonths' | 'state' | 'tjm' | 'title' | 'firstName' | 'lastName' | 'name' | 'postalCode' | 'available' | 'cvName' | 'maximumDistance' | 'minimumMonths' | 'valid' | 'parsedCv' | 'trigram' | 'salesEmail'>
    & { owner?: Maybe<(
      { __typename?: 'User2' }
      & Pick<User2, 'name' | 'phone' | 'email' | 'id' | 'role' | 'createdAt'>
      & { companyInformations?: Maybe<(
        { __typename?: 'UserCompanyContainer' }
        & { companyContainer?: Maybe<(
          { __typename?: 'CompanyContainer' }
          & Pick<CompanyContainer, 'name' | 'billingContactPhone'>
        )> }
      )> }
    )> }
  )> }
);

export type AdminCompaniesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  billingContactEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
}>;


export type AdminCompaniesQuery = (
  { __typename?: 'Root' }
  & { companies?: Maybe<(
    { __typename?: 'CompanyContainerConnection' }
    & Pick<CompanyContainerConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyContainerEdge' }
      & { node?: Maybe<(
        { __typename?: 'CompanyContainer' }
        & Pick<CompanyContainer, 'id' | 'usersCount' | 'companyIsFilled' | 'billingContactIsFilled' | 'billingContactFirstName' | 'billingContactLastName' | 'billingContactEmail' | 'billingContactPhone' | 'companyPostalCode' | 'name'>
      )> }
    )>>> }
  )> }
);

export type AdminUsersForCompaniesAssociationQueryVariables = Exact<{
  role?: Maybe<Role>;
  email?: Maybe<Scalars['String']>;
  companyContainerId: Scalars['UUID'];
  after?: Maybe<Scalars['String']>;
}>;


export type AdminUsersForCompaniesAssociationQuery = (
  { __typename?: 'Root' }
  & { users?: Maybe<(
    { __typename?: 'User2Connection' }
    & Pick<User2Connection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'User2Edge' }
      & Pick<User2Edge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'User2' }
        & Pick<User2, 'name' | 'id' | 'email' | 'role'>
      )> }
    )>>> }
  )>, usersAssociatedToCompany?: Maybe<Array<Maybe<(
    { __typename?: 'UserCompanyContainer' }
    & Pick<UserCompanyContainer, 'canEdit' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User2' }
      & Pick<User2, 'name' | 'id' | 'email' | 'role'>
    )> }
  )>>> }
);

export type AdminUserByIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AdminUserByIdQuery = (
  { __typename?: 'Root' }
  & { user?: Maybe<(
    { __typename?: 'User2' }
    & Pick<User2, 'firstName' | 'lastName' | 'email' | 'isFilled' | 'jobTitle' | 'phone'>
    & { companyInformations?: Maybe<(
      { __typename?: 'UserCompanyContainer' }
      & Pick<UserCompanyContainer, 'canEdit'>
      & { companyContainer?: Maybe<(
        { __typename?: 'CompanyContainer' }
        & Pick<CompanyContainer, 'billingContactFirstName' | 'billingContactLastName' | 'billingContactPhone' | 'billingContactJobTitle' | 'billingContactEmail' | 'name' | 'companyInCreation' | 'siret' | 'size' | 'area' | 'companyAddress1' | 'companyAddress2' | 'companyPostalCode' | 'companyCity'>
      )> }
    )> }
  )> }
);

export type AdminGetFreelanceProfilePrestataireQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminGetFreelanceProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { profilePrestataire?: Maybe<(
    { __typename?: 'ProfilePrestataire3' }
    & Pick<ProfilePrestataire3, 'postalCode' | 'cvName' | 'isAvailable' | 'availabilityDate' | 'maximumDistance' | 'minimumMonths' | 'tjm' | 'title'>
    & { owner?: Maybe<(
      { __typename?: 'User2' }
      & Pick<User2, 'id'>
    )> }
  )> }
);

export type AdminConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminConfigurationQuery = (
  { __typename?: 'Root' }
  & { adminConfiguration?: Maybe<(
    { __typename?: 'AdminConfiguration' }
    & Pick<AdminConfiguration, 'isFitnetIntegrationEnabled'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Root' }
  & { me?: Maybe<(
    { __typename?: 'User2' }
    & Pick<User2, 'firstName' | 'lastName' | 'email' | 'isFilled' | 'jobTitle' | 'phone'>
    & { companyInformations?: Maybe<(
      { __typename?: 'UserCompanyContainer' }
      & Pick<UserCompanyContainer, 'canEdit'>
      & { companyContainer?: Maybe<(
        { __typename?: 'CompanyContainer' }
        & Pick<CompanyContainer, 'billingContactFirstName' | 'billingContactLastName' | 'billingContactPhone' | 'billingContactJobTitle' | 'billingContactEmail' | 'name' | 'companyInCreation' | 'siret' | 'size' | 'area' | 'companyAddress1' | 'companyAddress2' | 'companyPostalCode' | 'companyCity'>
      )> }
    )> }
  )> }
);

export type MissionsQueryVariables = Exact<{
  state?: Maybe<Mission_State>;
  after?: Maybe<Scalars['String']>;
}>;


export type MissionsQuery = (
  { __typename?: 'Root' }
  & { missions?: Maybe<(
    { __typename?: 'MissionConnection' }
    & Pick<MissionConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'MissionEdge' }
      & { node?: Maybe<(
        { __typename?: 'Mission' }
        & Pick<Mission, 'id' | 'tjm' | 'title' | 'experience'>
      )> }
    )>>> }
  )> }
);

export type MissionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MissionQuery = (
  { __typename?: 'Root' }
  & { mission?: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'description' | 'startDate' | 'endDate' | 'title' | 'tjm' | 'valid' | 'id' | 'postalCode' | 'experience' | 'meta' | 'needMoving' | 'remote' | 'area' | 'state'>
    & { associatedProfilesPrestataires?: Maybe<Array<Maybe<(
      { __typename?: 'AssociatedProfilePrestataire' }
      & Pick<AssociatedProfilePrestataire, 'state' | 'weight'>
      & { profilePrestataire?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'availabilityInMonths' | 'id' | 'available' | 'tjm' | 'maximumDistance' | 'minimumMonths' | 'postalCode' | 'state' | 'title'>
        & { skills?: Maybe<Array<Maybe<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'name' | 'weight'>
        )>>>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'postalCode'>
        )> }
      )> }
    )>>> }
  )> }
);

export type MissionWithCraQueryVariables = Exact<{
  id: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type MissionWithCraQuery = (
  { __typename?: 'Root' }
  & { mission?: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'id' | 'title'>
    & { craState?: Maybe<(
      { __typename?: 'CraState' }
      & Pick<CraState, 'date' | 'state'>
      & { cras?: Maybe<Array<Maybe<(
        { __typename?: 'Cra' }
        & Pick<Cra, 'id' | 'date' | 'value' | 'disabled'>
      )>>> }
    )> }
  )> }
);

export type MyMissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMissionsQuery = (
  { __typename?: 'Root' }
  & { me?: Maybe<(
    { __typename?: 'User2' }
    & { profilePrestataire?: Maybe<(
      { __typename?: 'ProfilePrestataire3' }
      & { validatedAssociatedMissions?: Maybe<Array<Maybe<(
        { __typename?: 'AssociatedMission' }
        & Pick<AssociatedMission, 'state' | 'weight'>
        & { mission?: Maybe<(
          { __typename?: 'Mission' }
          & Pick<Mission, 'id' | 'experience' | 'remote' | 'title'>
        )> }
      )>>> }
    )> }
  )> }
);

export type MyMissionQueryVariables = Exact<{
  id: Scalars['UUID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
  profilePrestataireId?: Maybe<Scalars['UUID']>;
}>;


export type MyMissionQuery = (
  { __typename?: 'Root' }
  & { me?: Maybe<(
    { __typename?: 'User2' }
    & { profilePrestataire?: Maybe<(
      { __typename?: 'ProfilePrestataire3' }
      & { validatedAssociatedMission?: Maybe<(
        { __typename?: 'AssociatedMission' }
        & { mission?: Maybe<(
          { __typename?: 'Mission' }
          & Pick<Mission, 'id' | 'title'>
          & { craState?: Maybe<(
            { __typename?: 'CraState' }
            & Pick<CraState, 'date' | 'state'>
            & { cras?: Maybe<Array<Maybe<(
              { __typename?: 'Cra' }
              & Pick<Cra, 'id' | 'date' | 'value' | 'disabled'>
            )>>> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type MatchMissionQueryVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
}>;


export type MatchMissionQuery = (
  { __typename?: 'Root' }
  & { matchMission?: Maybe<Array<Maybe<(
    { __typename?: 'MatchingMissionProfilePrestataire' }
    & Pick<MatchingMissionProfilePrestataire, 'score'>
    & { associatedProfilesPrestataires?: Maybe<(
      { __typename?: 'ProfilePrestataire3' }
      & Pick<ProfilePrestataire3, 'availabilityInMonths' | 'id' | 'state' | 'availabilityDate' | 'tjm' | 'title' | 'firstName' | 'lastName' | 'name' | 'postalCode' | 'available'>
      & { owner?: Maybe<(
        { __typename?: 'User2' }
        & Pick<User2, 'name' | 'role' | 'createdAt' | 'id'>
      )> }
    )> }
  )>>> }
);

export type ProfilesPrestatairesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type ProfilesPrestatairesQuery = (
  { __typename?: 'Root' }
  & { profilesPrestataires?: Maybe<(
    { __typename?: 'ProfilePrestataire3Connection' }
    & Pick<ProfilePrestataire3Connection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfilePrestataire3Edge' }
      & { node?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'name' | 'id' | 'cvName' | 'tjm' | 'available' | 'maximumDistance' | 'minimumMonths' | 'title' | 'postalCode' | 'availabilityDate' | 'trigram' | 'firstName' | 'lastName' | 'availabilityInMonths'>
      )> }
    )>>> }
  )> }
);

export type AnonymousProfilesPrestatairesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type AnonymousProfilesPrestatairesQuery = (
  { __typename?: 'Root' }
  & { anonymousProfilesPrestataires?: Maybe<(
    { __typename?: 'ProfilePrestataire3Connection' }
    & Pick<ProfilePrestataire3Connection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfilePrestataire3Edge' }
      & { node?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'name' | 'id' | 'cvName' | 'tjm' | 'available' | 'maximumDistance' | 'minimumMonths' | 'title' | 'postalCode' | 'availabilityDate' | 'trigram' | 'firstName' | 'lastName' | 'availabilityInMonths'>
        & { skills?: Maybe<Array<Maybe<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'name' | 'weight'>
        )>>>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type AnonymousProfilePrestataireDetailsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AnonymousProfilePrestataireDetailsQuery = (
  { __typename?: 'Root' }
  & { anonymousProfilePrestataire?: Maybe<(
    { __typename?: 'ProfilePrestataire3' }
    & Pick<ProfilePrestataire3, 'name' | 'id' | 'cvName' | 'tjm' | 'available' | 'maximumDistance' | 'minimumMonths' | 'title' | 'postalCode' | 'availabilityDate' | 'trigram' | 'firstName' | 'lastName' | 'availabilityInMonths' | 'parsedCv'>
    & { skills?: Maybe<Array<Maybe<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'name' | 'weight'>
    )>>>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'name'>
    )> }
  )> }
);

export type ProfilesPrestatairesWithValidatedMissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilesPrestatairesWithValidatedMissionsQuery = (
  { __typename?: 'Root' }
  & { profilesPrestataires?: Maybe<(
    { __typename?: 'ProfilePrestataire3Connection' }
    & Pick<ProfilePrestataire3Connection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfilePrestataire3Edge' }
      & { node?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'name' | 'id' | 'cvName' | 'tjm' | 'available' | 'maximumDistance' | 'minimumMonths' | 'title' | 'postalCode' | 'availabilityDate' | 'trigram' | 'firstName' | 'lastName' | 'availabilityInMonths'>
        & { validatedAssociatedMissions?: Maybe<Array<Maybe<(
          { __typename?: 'AssociatedMission' }
          & Pick<AssociatedMission, 'state' | 'weight'>
          & { mission?: Maybe<(
            { __typename?: 'Mission' }
            & Pick<Mission, 'id' | 'experience' | 'remote' | 'title'>
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ProfilePrestataireQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { profilePrestataire?: Maybe<(
    { __typename?: 'ProfilePrestataire3' }
    & Pick<ProfilePrestataire3, 'postalCode' | 'cvName' | 'available' | 'availabilityDate' | 'maximumDistance' | 'minimumMonths' | 'tjm' | 'title' | 'firstName' | 'lastName' | 'trigram' | 'availabilityInMonths' | 'id' | 'state'>
    & { skills?: Maybe<Array<Maybe<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'name' | 'weight'>
    )>>>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'name' | 'postalCode'>
    )> }
  )> }
);

export type MissionAssociatedProfilePrestataireQueryVariables = Exact<{
  missionId: Scalars['UUID'];
  profilePrestataireId: Scalars['UUID'];
}>;


export type MissionAssociatedProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { mission?: Maybe<(
    { __typename?: 'Mission' }
    & { associatedProfilesPrestataires?: Maybe<Array<Maybe<(
      { __typename?: 'AssociatedProfilePrestataire' }
      & Pick<AssociatedProfilePrestataire, 'state' | 'weight'>
      & { profilePrestataire?: Maybe<(
        { __typename?: 'ProfilePrestataire3' }
        & Pick<ProfilePrestataire3, 'availabilityInMonths' | 'id' | 'available' | 'tjm' | 'maximumDistance' | 'minimumMonths' | 'postalCode' | 'state' | 'parsedCv' | 'title'>
        & { skills?: Maybe<Array<Maybe<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'name' | 'weight'>
        )>>>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'postalCode'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetFreelanceProfilePrestataireQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFreelanceProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { getProfilePrestataire?: Maybe<(
    { __typename?: 'GetProfilePrestataire' }
    & Pick<GetProfilePrestataire, 'postalCode' | 'cvName' | 'isFilled' | 'isAvailable' | 'availabilityDate' | 'maximumDistance' | 'minimumMonths' | 'tjm' | 'title'>
  )> }
);

export type GetEsnProfilePrestataireQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEsnProfilePrestataireQuery = (
  { __typename?: 'Root' }
  & { getProfilePrestataire?: Maybe<(
    { __typename?: 'GetProfilePrestataire' }
    & Pick<GetProfilePrestataire, 'postalCode' | 'cvName' | 'isFilled' | 'isAvailable' | 'availabilityDate' | 'maximumDistance' | 'minimumMonths' | 'tjm' | 'title' | 'firstName' | 'lastName' | 'trigram'>
  )> }
);


export const AdminValidProfileDocument = gql`
    mutation adminValidProfile($id: UUID!, $salesEmail: String) {
  adminValidProfile(id: $id, email: $salesEmail)
}
    `;
export type AdminValidProfileMutationFn = Apollo.MutationFunction<AdminValidProfileMutation, AdminValidProfileMutationVariables>;

/**
 * __useAdminValidProfileMutation__
 *
 * To run a mutation, you first call `useAdminValidProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminValidProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminValidProfileMutation, { data, loading, error }] = useAdminValidProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      salesEmail: // value for 'salesEmail'
 *   },
 * });
 */
export function useAdminValidProfileMutation(baseOptions?: Apollo.MutationHookOptions<AdminValidProfileMutation, AdminValidProfileMutationVariables>) {
        return Apollo.useMutation<AdminValidProfileMutation, AdminValidProfileMutationVariables>(AdminValidProfileDocument, baseOptions);
      }
export type AdminValidProfileMutationHookResult = ReturnType<typeof useAdminValidProfileMutation>;
export type AdminValidProfileMutationResult = Apollo.MutationResult<AdminValidProfileMutation>;
export type AdminValidProfileMutationOptions = Apollo.BaseMutationOptions<AdminValidProfileMutation, AdminValidProfileMutationVariables>;
export const AdminRefuseProfileDocument = gql`
    mutation adminRefuseProfile($id: UUID!) {
  adminRefuseProfile(id: $id)
}
    `;
export type AdminRefuseProfileMutationFn = Apollo.MutationFunction<AdminRefuseProfileMutation, AdminRefuseProfileMutationVariables>;

/**
 * __useAdminRefuseProfileMutation__
 *
 * To run a mutation, you first call `useAdminRefuseProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRefuseProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRefuseProfileMutation, { data, loading, error }] = useAdminRefuseProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRefuseProfileMutation(baseOptions?: Apollo.MutationHookOptions<AdminRefuseProfileMutation, AdminRefuseProfileMutationVariables>) {
        return Apollo.useMutation<AdminRefuseProfileMutation, AdminRefuseProfileMutationVariables>(AdminRefuseProfileDocument, baseOptions);
      }
export type AdminRefuseProfileMutationHookResult = ReturnType<typeof useAdminRefuseProfileMutation>;
export type AdminRefuseProfileMutationResult = Apollo.MutationResult<AdminRefuseProfileMutation>;
export type AdminRefuseProfileMutationOptions = Apollo.BaseMutationOptions<AdminRefuseProfileMutation, AdminRefuseProfileMutationVariables>;
export const AdminValidMissionDocument = gql`
    mutation adminValidMission($id: UUID!, $salesEmail: String) {
  adminValidMission(id: $id, email: $salesEmail)
}
    `;
export type AdminValidMissionMutationFn = Apollo.MutationFunction<AdminValidMissionMutation, AdminValidMissionMutationVariables>;

/**
 * __useAdminValidMissionMutation__
 *
 * To run a mutation, you first call `useAdminValidMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminValidMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminValidMissionMutation, { data, loading, error }] = useAdminValidMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      salesEmail: // value for 'salesEmail'
 *   },
 * });
 */
export function useAdminValidMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminValidMissionMutation, AdminValidMissionMutationVariables>) {
        return Apollo.useMutation<AdminValidMissionMutation, AdminValidMissionMutationVariables>(AdminValidMissionDocument, baseOptions);
      }
export type AdminValidMissionMutationHookResult = ReturnType<typeof useAdminValidMissionMutation>;
export type AdminValidMissionMutationResult = Apollo.MutationResult<AdminValidMissionMutation>;
export type AdminValidMissionMutationOptions = Apollo.BaseMutationOptions<AdminValidMissionMutation, AdminValidMissionMutationVariables>;
export const AdminRefuseMissionDocument = gql`
    mutation adminRefuseMission($id: UUID!) {
  adminRefuseMission(id: $id)
}
    `;
export type AdminRefuseMissionMutationFn = Apollo.MutationFunction<AdminRefuseMissionMutation, AdminRefuseMissionMutationVariables>;

/**
 * __useAdminRefuseMissionMutation__
 *
 * To run a mutation, you first call `useAdminRefuseMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRefuseMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRefuseMissionMutation, { data, loading, error }] = useAdminRefuseMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRefuseMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminRefuseMissionMutation, AdminRefuseMissionMutationVariables>) {
        return Apollo.useMutation<AdminRefuseMissionMutation, AdminRefuseMissionMutationVariables>(AdminRefuseMissionDocument, baseOptions);
      }
export type AdminRefuseMissionMutationHookResult = ReturnType<typeof useAdminRefuseMissionMutation>;
export type AdminRefuseMissionMutationResult = Apollo.MutationResult<AdminRefuseMissionMutation>;
export type AdminRefuseMissionMutationOptions = Apollo.BaseMutationOptions<AdminRefuseMissionMutation, AdminRefuseMissionMutationVariables>;
export const AdminStartMissionDocument = gql`
    mutation adminStartMission($id: UUID!, $tjm: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  adminStartMission(id: $id, tjm: $tjm, startDate: $startDate, endDate: $endDate)
}
    `;
export type AdminStartMissionMutationFn = Apollo.MutationFunction<AdminStartMissionMutation, AdminStartMissionMutationVariables>;

/**
 * __useAdminStartMissionMutation__
 *
 * To run a mutation, you first call `useAdminStartMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminStartMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminStartMissionMutation, { data, loading, error }] = useAdminStartMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tjm: // value for 'tjm'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAdminStartMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminStartMissionMutation, AdminStartMissionMutationVariables>) {
        return Apollo.useMutation<AdminStartMissionMutation, AdminStartMissionMutationVariables>(AdminStartMissionDocument, baseOptions);
      }
export type AdminStartMissionMutationHookResult = ReturnType<typeof useAdminStartMissionMutation>;
export type AdminStartMissionMutationResult = Apollo.MutationResult<AdminStartMissionMutation>;
export type AdminStartMissionMutationOptions = Apollo.BaseMutationOptions<AdminStartMissionMutation, AdminStartMissionMutationVariables>;
export const AdminRefreshCvParsingDocument = gql`
    mutation adminRefreshCvParsing($id: UUID!) {
  adminRefreshCvParsing(id: $id)
}
    `;
export type AdminRefreshCvParsingMutationFn = Apollo.MutationFunction<AdminRefreshCvParsingMutation, AdminRefreshCvParsingMutationVariables>;

/**
 * __useAdminRefreshCvParsingMutation__
 *
 * To run a mutation, you first call `useAdminRefreshCvParsingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRefreshCvParsingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRefreshCvParsingMutation, { data, loading, error }] = useAdminRefreshCvParsingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRefreshCvParsingMutation(baseOptions?: Apollo.MutationHookOptions<AdminRefreshCvParsingMutation, AdminRefreshCvParsingMutationVariables>) {
        return Apollo.useMutation<AdminRefreshCvParsingMutation, AdminRefreshCvParsingMutationVariables>(AdminRefreshCvParsingDocument, baseOptions);
      }
export type AdminRefreshCvParsingMutationHookResult = ReturnType<typeof useAdminRefreshCvParsingMutation>;
export type AdminRefreshCvParsingMutationResult = Apollo.MutationResult<AdminRefreshCvParsingMutation>;
export type AdminRefreshCvParsingMutationOptions = Apollo.BaseMutationOptions<AdminRefreshCvParsingMutation, AdminRefreshCvParsingMutationVariables>;
export const AdminActivateEnterpriseModeDocument = gql`
    mutation adminActivateEnterpriseMode($id: String!) {
  adminActivateEnterpriseMode(id: $id)
}
    `;
export type AdminActivateEnterpriseModeMutationFn = Apollo.MutationFunction<AdminActivateEnterpriseModeMutation, AdminActivateEnterpriseModeMutationVariables>;

/**
 * __useAdminActivateEnterpriseModeMutation__
 *
 * To run a mutation, you first call `useAdminActivateEnterpriseModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminActivateEnterpriseModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminActivateEnterpriseModeMutation, { data, loading, error }] = useAdminActivateEnterpriseModeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminActivateEnterpriseModeMutation(baseOptions?: Apollo.MutationHookOptions<AdminActivateEnterpriseModeMutation, AdminActivateEnterpriseModeMutationVariables>) {
        return Apollo.useMutation<AdminActivateEnterpriseModeMutation, AdminActivateEnterpriseModeMutationVariables>(AdminActivateEnterpriseModeDocument, baseOptions);
      }
export type AdminActivateEnterpriseModeMutationHookResult = ReturnType<typeof useAdminActivateEnterpriseModeMutation>;
export type AdminActivateEnterpriseModeMutationResult = Apollo.MutationResult<AdminActivateEnterpriseModeMutation>;
export type AdminActivateEnterpriseModeMutationOptions = Apollo.BaseMutationOptions<AdminActivateEnterpriseModeMutation, AdminActivateEnterpriseModeMutationVariables>;
export const AdminDisableEnterpriseModeDocument = gql`
    mutation adminDisableEnterpriseMode($id: String!) {
  adminDisableEnterpriseMode(id: $id)
}
    `;
export type AdminDisableEnterpriseModeMutationFn = Apollo.MutationFunction<AdminDisableEnterpriseModeMutation, AdminDisableEnterpriseModeMutationVariables>;

/**
 * __useAdminDisableEnterpriseModeMutation__
 *
 * To run a mutation, you first call `useAdminDisableEnterpriseModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDisableEnterpriseModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDisableEnterpriseModeMutation, { data, loading, error }] = useAdminDisableEnterpriseModeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDisableEnterpriseModeMutation(baseOptions?: Apollo.MutationHookOptions<AdminDisableEnterpriseModeMutation, AdminDisableEnterpriseModeMutationVariables>) {
        return Apollo.useMutation<AdminDisableEnterpriseModeMutation, AdminDisableEnterpriseModeMutationVariables>(AdminDisableEnterpriseModeDocument, baseOptions);
      }
export type AdminDisableEnterpriseModeMutationHookResult = ReturnType<typeof useAdminDisableEnterpriseModeMutation>;
export type AdminDisableEnterpriseModeMutationResult = Apollo.MutationResult<AdminDisableEnterpriseModeMutation>;
export type AdminDisableEnterpriseModeMutationOptions = Apollo.BaseMutationOptions<AdminDisableEnterpriseModeMutation, AdminDisableEnterpriseModeMutationVariables>;
export const AdminAppointProfilePrestataireForMissionDocument = gql`
    mutation adminAppointProfilePrestataireForMission($missionId: UUID!, $profilePrestataireId: UUID!) {
  adminAppointProfilePrestataireForMission(
    missionId: $missionId
    profilePrestataireId: $profilePrestataireId
  )
}
    `;
export type AdminAppointProfilePrestataireForMissionMutationFn = Apollo.MutationFunction<AdminAppointProfilePrestataireForMissionMutation, AdminAppointProfilePrestataireForMissionMutationVariables>;

/**
 * __useAdminAppointProfilePrestataireForMissionMutation__
 *
 * To run a mutation, you first call `useAdminAppointProfilePrestataireForMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAppointProfilePrestataireForMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAppointProfilePrestataireForMissionMutation, { data, loading, error }] = useAdminAppointProfilePrestataireForMissionMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *   },
 * });
 */
export function useAdminAppointProfilePrestataireForMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminAppointProfilePrestataireForMissionMutation, AdminAppointProfilePrestataireForMissionMutationVariables>) {
        return Apollo.useMutation<AdminAppointProfilePrestataireForMissionMutation, AdminAppointProfilePrestataireForMissionMutationVariables>(AdminAppointProfilePrestataireForMissionDocument, baseOptions);
      }
export type AdminAppointProfilePrestataireForMissionMutationHookResult = ReturnType<typeof useAdminAppointProfilePrestataireForMissionMutation>;
export type AdminAppointProfilePrestataireForMissionMutationResult = Apollo.MutationResult<AdminAppointProfilePrestataireForMissionMutation>;
export type AdminAppointProfilePrestataireForMissionMutationOptions = Apollo.BaseMutationOptions<AdminAppointProfilePrestataireForMissionMutation, AdminAppointProfilePrestataireForMissionMutationVariables>;
export const AdminValidateProfilePrestataireForMissionDocument = gql`
    mutation adminValidateProfilePrestataireForMission($missionId: UUID!, $profilePrestataireId: UUID!) {
  adminValidateProfilePrestataireForMission(
    missionId: $missionId
    profilePrestataireId: $profilePrestataireId
  )
}
    `;
export type AdminValidateProfilePrestataireForMissionMutationFn = Apollo.MutationFunction<AdminValidateProfilePrestataireForMissionMutation, AdminValidateProfilePrestataireForMissionMutationVariables>;

/**
 * __useAdminValidateProfilePrestataireForMissionMutation__
 *
 * To run a mutation, you first call `useAdminValidateProfilePrestataireForMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminValidateProfilePrestataireForMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminValidateProfilePrestataireForMissionMutation, { data, loading, error }] = useAdminValidateProfilePrestataireForMissionMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *   },
 * });
 */
export function useAdminValidateProfilePrestataireForMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminValidateProfilePrestataireForMissionMutation, AdminValidateProfilePrestataireForMissionMutationVariables>) {
        return Apollo.useMutation<AdminValidateProfilePrestataireForMissionMutation, AdminValidateProfilePrestataireForMissionMutationVariables>(AdminValidateProfilePrestataireForMissionDocument, baseOptions);
      }
export type AdminValidateProfilePrestataireForMissionMutationHookResult = ReturnType<typeof useAdminValidateProfilePrestataireForMissionMutation>;
export type AdminValidateProfilePrestataireForMissionMutationResult = Apollo.MutationResult<AdminValidateProfilePrestataireForMissionMutation>;
export type AdminValidateProfilePrestataireForMissionMutationOptions = Apollo.BaseMutationOptions<AdminValidateProfilePrestataireForMissionMutation, AdminValidateProfilePrestataireForMissionMutationVariables>;
export const AdminEnforceUserEmailVerificationDocument = gql`
    mutation adminEnforceUserEmailVerification($id: String!) {
  adminEnforceUserEmailVerification(id: $id)
}
    `;
export type AdminEnforceUserEmailVerificationMutationFn = Apollo.MutationFunction<AdminEnforceUserEmailVerificationMutation, AdminEnforceUserEmailVerificationMutationVariables>;

/**
 * __useAdminEnforceUserEmailVerificationMutation__
 *
 * To run a mutation, you first call `useAdminEnforceUserEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEnforceUserEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEnforceUserEmailVerificationMutation, { data, loading, error }] = useAdminEnforceUserEmailVerificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminEnforceUserEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<AdminEnforceUserEmailVerificationMutation, AdminEnforceUserEmailVerificationMutationVariables>) {
        return Apollo.useMutation<AdminEnforceUserEmailVerificationMutation, AdminEnforceUserEmailVerificationMutationVariables>(AdminEnforceUserEmailVerificationDocument, baseOptions);
      }
export type AdminEnforceUserEmailVerificationMutationHookResult = ReturnType<typeof useAdminEnforceUserEmailVerificationMutation>;
export type AdminEnforceUserEmailVerificationMutationResult = Apollo.MutationResult<AdminEnforceUserEmailVerificationMutation>;
export type AdminEnforceUserEmailVerificationMutationOptions = Apollo.BaseMutationOptions<AdminEnforceUserEmailVerificationMutation, AdminEnforceUserEmailVerificationMutationVariables>;
export const AdminArchiveProfilePrestataireDocument = gql`
    mutation adminArchiveProfilePrestataire($id: UUID!) {
  adminArchiveProfilePrestataire(id: $id)
}
    `;
export type AdminArchiveProfilePrestataireMutationFn = Apollo.MutationFunction<AdminArchiveProfilePrestataireMutation, AdminArchiveProfilePrestataireMutationVariables>;

/**
 * __useAdminArchiveProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useAdminArchiveProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminArchiveProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminArchiveProfilePrestataireMutation, { data, loading, error }] = useAdminArchiveProfilePrestataireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminArchiveProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveProfilePrestataireMutation, AdminArchiveProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<AdminArchiveProfilePrestataireMutation, AdminArchiveProfilePrestataireMutationVariables>(AdminArchiveProfilePrestataireDocument, baseOptions);
      }
export type AdminArchiveProfilePrestataireMutationHookResult = ReturnType<typeof useAdminArchiveProfilePrestataireMutation>;
export type AdminArchiveProfilePrestataireMutationResult = Apollo.MutationResult<AdminArchiveProfilePrestataireMutation>;
export type AdminArchiveProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<AdminArchiveProfilePrestataireMutation, AdminArchiveProfilePrestataireMutationVariables>;
export const AdminDeleteCompanyDocument = gql`
    mutation adminDeleteCompany($id: UUID!) {
  adminDeleteCompany(id: $id)
}
    `;
export type AdminDeleteCompanyMutationFn = Apollo.MutationFunction<AdminDeleteCompanyMutation, AdminDeleteCompanyMutationVariables>;

/**
 * __useAdminDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useAdminDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteCompanyMutation, { data, loading, error }] = useAdminDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteCompanyMutation, AdminDeleteCompanyMutationVariables>) {
        return Apollo.useMutation<AdminDeleteCompanyMutation, AdminDeleteCompanyMutationVariables>(AdminDeleteCompanyDocument, baseOptions);
      }
export type AdminDeleteCompanyMutationHookResult = ReturnType<typeof useAdminDeleteCompanyMutation>;
export type AdminDeleteCompanyMutationResult = Apollo.MutationResult<AdminDeleteCompanyMutation>;
export type AdminDeleteCompanyMutationOptions = Apollo.BaseMutationOptions<AdminDeleteCompanyMutation, AdminDeleteCompanyMutationVariables>;
export const AdminAssociateUsersToCompanyDocument = gql`
    mutation adminAssociateUsersToCompany($companyId: UUID!, $users: [ToAssociateUser]!) {
  adminAssociateUsersToCompany(companyId: $companyId, users: $users)
}
    `;
export type AdminAssociateUsersToCompanyMutationFn = Apollo.MutationFunction<AdminAssociateUsersToCompanyMutation, AdminAssociateUsersToCompanyMutationVariables>;

/**
 * __useAdminAssociateUsersToCompanyMutation__
 *
 * To run a mutation, you first call `useAdminAssociateUsersToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssociateUsersToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssociateUsersToCompanyMutation, { data, loading, error }] = useAdminAssociateUsersToCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useAdminAssociateUsersToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssociateUsersToCompanyMutation, AdminAssociateUsersToCompanyMutationVariables>) {
        return Apollo.useMutation<AdminAssociateUsersToCompanyMutation, AdminAssociateUsersToCompanyMutationVariables>(AdminAssociateUsersToCompanyDocument, baseOptions);
      }
export type AdminAssociateUsersToCompanyMutationHookResult = ReturnType<typeof useAdminAssociateUsersToCompanyMutation>;
export type AdminAssociateUsersToCompanyMutationResult = Apollo.MutationResult<AdminAssociateUsersToCompanyMutation>;
export type AdminAssociateUsersToCompanyMutationOptions = Apollo.BaseMutationOptions<AdminAssociateUsersToCompanyMutation, AdminAssociateUsersToCompanyMutationVariables>;
export const AdminSaveProfileDocument = gql`
    mutation adminSaveProfile($userProfile: UserProfileInput!, $billingContact: BillingContactInput!, $company: CompanyInput!, $userId: String!) {
  saveProfile(
    input: {userProfile: $userProfile, billingContact: $billingContact, company: $company}
    userId: $userId
  )
}
    `;
export type AdminSaveProfileMutationFn = Apollo.MutationFunction<AdminSaveProfileMutation, AdminSaveProfileMutationVariables>;

/**
 * __useAdminSaveProfileMutation__
 *
 * To run a mutation, you first call `useAdminSaveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveProfileMutation, { data, loading, error }] = useAdminSaveProfileMutation({
 *   variables: {
 *      userProfile: // value for 'userProfile'
 *      billingContact: // value for 'billingContact'
 *      company: // value for 'company'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminSaveProfileMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveProfileMutation, AdminSaveProfileMutationVariables>) {
        return Apollo.useMutation<AdminSaveProfileMutation, AdminSaveProfileMutationVariables>(AdminSaveProfileDocument, baseOptions);
      }
export type AdminSaveProfileMutationHookResult = ReturnType<typeof useAdminSaveProfileMutation>;
export type AdminSaveProfileMutationResult = Apollo.MutationResult<AdminSaveProfileMutation>;
export type AdminSaveProfileMutationOptions = Apollo.BaseMutationOptions<AdminSaveProfileMutation, AdminSaveProfileMutationVariables>;
export const AdminDeleteUserDocument = gql`
    mutation adminDeleteUser($id: String!) {
  adminDeleteUser(id: $id)
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, baseOptions);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const AdminDeleteProfilePrestataireDocument = gql`
    mutation adminDeleteProfilePrestataire($id: UUID!) {
  adminDeleteProfilePrestataire(id: $id)
}
    `;
export type AdminDeleteProfilePrestataireMutationFn = Apollo.MutationFunction<AdminDeleteProfilePrestataireMutation, AdminDeleteProfilePrestataireMutationVariables>;

/**
 * __useAdminDeleteProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useAdminDeleteProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteProfilePrestataireMutation, { data, loading, error }] = useAdminDeleteProfilePrestataireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteProfilePrestataireMutation, AdminDeleteProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<AdminDeleteProfilePrestataireMutation, AdminDeleteProfilePrestataireMutationVariables>(AdminDeleteProfilePrestataireDocument, baseOptions);
      }
export type AdminDeleteProfilePrestataireMutationHookResult = ReturnType<typeof useAdminDeleteProfilePrestataireMutation>;
export type AdminDeleteProfilePrestataireMutationResult = Apollo.MutationResult<AdminDeleteProfilePrestataireMutation>;
export type AdminDeleteProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<AdminDeleteProfilePrestataireMutation, AdminDeleteProfilePrestataireMutationVariables>;
export const AdminDeleteMissionDocument = gql`
    mutation adminDeleteMission($id: UUID!) {
  adminDeleteMission(id: $id)
}
    `;
export type AdminDeleteMissionMutationFn = Apollo.MutationFunction<AdminDeleteMissionMutation, AdminDeleteMissionMutationVariables>;

/**
 * __useAdminDeleteMissionMutation__
 *
 * To run a mutation, you first call `useAdminDeleteMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteMissionMutation, { data, loading, error }] = useAdminDeleteMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteMissionMutation, AdminDeleteMissionMutationVariables>) {
        return Apollo.useMutation<AdminDeleteMissionMutation, AdminDeleteMissionMutationVariables>(AdminDeleteMissionDocument, baseOptions);
      }
export type AdminDeleteMissionMutationHookResult = ReturnType<typeof useAdminDeleteMissionMutation>;
export type AdminDeleteMissionMutationResult = Apollo.MutationResult<AdminDeleteMissionMutation>;
export type AdminDeleteMissionMutationOptions = Apollo.BaseMutationOptions<AdminDeleteMissionMutation, AdminDeleteMissionMutationVariables>;
export const AdminSaveNewMissionDocument = gql`
    mutation adminSaveNewMission($input: MissionInput!, $userId: String!) {
  adminSaveNewMission(input: $input, userId: $userId)
}
    `;
export type AdminSaveNewMissionMutationFn = Apollo.MutationFunction<AdminSaveNewMissionMutation, AdminSaveNewMissionMutationVariables>;

/**
 * __useAdminSaveNewMissionMutation__
 *
 * To run a mutation, you first call `useAdminSaveNewMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveNewMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveNewMissionMutation, { data, loading, error }] = useAdminSaveNewMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminSaveNewMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveNewMissionMutation, AdminSaveNewMissionMutationVariables>) {
        return Apollo.useMutation<AdminSaveNewMissionMutation, AdminSaveNewMissionMutationVariables>(AdminSaveNewMissionDocument, baseOptions);
      }
export type AdminSaveNewMissionMutationHookResult = ReturnType<typeof useAdminSaveNewMissionMutation>;
export type AdminSaveNewMissionMutationResult = Apollo.MutationResult<AdminSaveNewMissionMutation>;
export type AdminSaveNewMissionMutationOptions = Apollo.BaseMutationOptions<AdminSaveNewMissionMutation, AdminSaveNewMissionMutationVariables>;
export const AdminSaveExistingMissionDocument = gql`
    mutation adminSaveExistingMission($input: MissionInput!, $userId: String!) {
  adminSaveExistingMission(input: $input, userId: $userId)
}
    `;
export type AdminSaveExistingMissionMutationFn = Apollo.MutationFunction<AdminSaveExistingMissionMutation, AdminSaveExistingMissionMutationVariables>;

/**
 * __useAdminSaveExistingMissionMutation__
 *
 * To run a mutation, you first call `useAdminSaveExistingMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveExistingMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveExistingMissionMutation, { data, loading, error }] = useAdminSaveExistingMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminSaveExistingMissionMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveExistingMissionMutation, AdminSaveExistingMissionMutationVariables>) {
        return Apollo.useMutation<AdminSaveExistingMissionMutation, AdminSaveExistingMissionMutationVariables>(AdminSaveExistingMissionDocument, baseOptions);
      }
export type AdminSaveExistingMissionMutationHookResult = ReturnType<typeof useAdminSaveExistingMissionMutation>;
export type AdminSaveExistingMissionMutationResult = Apollo.MutationResult<AdminSaveExistingMissionMutation>;
export type AdminSaveExistingMissionMutationOptions = Apollo.BaseMutationOptions<AdminSaveExistingMissionMutation, AdminSaveExistingMissionMutationVariables>;
export const AdminSetConfigurationDocument = gql`
    mutation adminSetConfiguration($isFitnetIntegrationEnabled: Boolean!) {
  adminSetConfiguration(isFitnetIntegrationEnabled: $isFitnetIntegrationEnabled)
}
    `;
export type AdminSetConfigurationMutationFn = Apollo.MutationFunction<AdminSetConfigurationMutation, AdminSetConfigurationMutationVariables>;

/**
 * __useAdminSetConfigurationMutation__
 *
 * To run a mutation, you first call `useAdminSetConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetConfigurationMutation, { data, loading, error }] = useAdminSetConfigurationMutation({
 *   variables: {
 *      isFitnetIntegrationEnabled: // value for 'isFitnetIntegrationEnabled'
 *   },
 * });
 */
export function useAdminSetConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AdminSetConfigurationMutation, AdminSetConfigurationMutationVariables>) {
        return Apollo.useMutation<AdminSetConfigurationMutation, AdminSetConfigurationMutationVariables>(AdminSetConfigurationDocument, baseOptions);
      }
export type AdminSetConfigurationMutationHookResult = ReturnType<typeof useAdminSetConfigurationMutation>;
export type AdminSetConfigurationMutationResult = Apollo.MutationResult<AdminSetConfigurationMutation>;
export type AdminSetConfigurationMutationOptions = Apollo.BaseMutationOptions<AdminSetConfigurationMutation, AdminSetConfigurationMutationVariables>;
export const SaveNewMissionDocument = gql`
    mutation saveNewMission($input: MissionInput!) {
  saveNewMission(input: $input)
}
    `;
export type SaveNewMissionMutationFn = Apollo.MutationFunction<SaveNewMissionMutation, SaveNewMissionMutationVariables>;

/**
 * __useSaveNewMissionMutation__
 *
 * To run a mutation, you first call `useSaveNewMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewMissionMutation, { data, loading, error }] = useSaveNewMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveNewMissionMutation(baseOptions?: Apollo.MutationHookOptions<SaveNewMissionMutation, SaveNewMissionMutationVariables>) {
        return Apollo.useMutation<SaveNewMissionMutation, SaveNewMissionMutationVariables>(SaveNewMissionDocument, baseOptions);
      }
export type SaveNewMissionMutationHookResult = ReturnType<typeof useSaveNewMissionMutation>;
export type SaveNewMissionMutationResult = Apollo.MutationResult<SaveNewMissionMutation>;
export type SaveNewMissionMutationOptions = Apollo.BaseMutationOptions<SaveNewMissionMutation, SaveNewMissionMutationVariables>;
export const SaveExistingMissionDocument = gql`
    mutation saveExistingMission($input: MissionInput!) {
  saveExistingMission(input: $input)
}
    `;
export type SaveExistingMissionMutationFn = Apollo.MutationFunction<SaveExistingMissionMutation, SaveExistingMissionMutationVariables>;

/**
 * __useSaveExistingMissionMutation__
 *
 * To run a mutation, you first call `useSaveExistingMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExistingMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExistingMissionMutation, { data, loading, error }] = useSaveExistingMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveExistingMissionMutation(baseOptions?: Apollo.MutationHookOptions<SaveExistingMissionMutation, SaveExistingMissionMutationVariables>) {
        return Apollo.useMutation<SaveExistingMissionMutation, SaveExistingMissionMutationVariables>(SaveExistingMissionDocument, baseOptions);
      }
export type SaveExistingMissionMutationHookResult = ReturnType<typeof useSaveExistingMissionMutation>;
export type SaveExistingMissionMutationResult = Apollo.MutationResult<SaveExistingMissionMutation>;
export type SaveExistingMissionMutationOptions = Apollo.BaseMutationOptions<SaveExistingMissionMutation, SaveExistingMissionMutationVariables>;
export const UpdateCraDocument = gql`
    mutation updateCra($missionId: UUID!, $cras: [CraToUpdate!]!, $month: Int!, $year: Int!, $profilePrestataireId: UUID, $submit: Boolean!) {
  updateCra(
    missionId: $missionId
    cras: $cras
    month: $month
    year: $year
    profilePrestataireId: $profilePrestataireId
    submit: $submit
  )
}
    `;
export type UpdateCraMutationFn = Apollo.MutationFunction<UpdateCraMutation, UpdateCraMutationVariables>;

/**
 * __useUpdateCraMutation__
 *
 * To run a mutation, you first call `useUpdateCraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCraMutation, { data, loading, error }] = useUpdateCraMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      cras: // value for 'cras'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *      submit: // value for 'submit'
 *   },
 * });
 */
export function useUpdateCraMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCraMutation, UpdateCraMutationVariables>) {
        return Apollo.useMutation<UpdateCraMutation, UpdateCraMutationVariables>(UpdateCraDocument, baseOptions);
      }
export type UpdateCraMutationHookResult = ReturnType<typeof useUpdateCraMutation>;
export type UpdateCraMutationResult = Apollo.MutationResult<UpdateCraMutation>;
export type UpdateCraMutationOptions = Apollo.BaseMutationOptions<UpdateCraMutation, UpdateCraMutationVariables>;
export const AcceptCraDocument = gql`
    mutation acceptCra($missionId: UUID!, $month: Int!, $year: Int!) {
  acceptCra(missionId: $missionId, month: $month, year: $year)
}
    `;
export type AcceptCraMutationFn = Apollo.MutationFunction<AcceptCraMutation, AcceptCraMutationVariables>;

/**
 * __useAcceptCraMutation__
 *
 * To run a mutation, you first call `useAcceptCraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCraMutation, { data, loading, error }] = useAcceptCraMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAcceptCraMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCraMutation, AcceptCraMutationVariables>) {
        return Apollo.useMutation<AcceptCraMutation, AcceptCraMutationVariables>(AcceptCraDocument, baseOptions);
      }
export type AcceptCraMutationHookResult = ReturnType<typeof useAcceptCraMutation>;
export type AcceptCraMutationResult = Apollo.MutationResult<AcceptCraMutation>;
export type AcceptCraMutationOptions = Apollo.BaseMutationOptions<AcceptCraMutation, AcceptCraMutationVariables>;
export const RefuseCraDocument = gql`
    mutation refuseCra($missionId: UUID!, $month: Int!, $year: Int!) {
  refuseCra(missionId: $missionId, month: $month, year: $year)
}
    `;
export type RefuseCraMutationFn = Apollo.MutationFunction<RefuseCraMutation, RefuseCraMutationVariables>;

/**
 * __useRefuseCraMutation__
 *
 * To run a mutation, you first call `useRefuseCraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefuseCraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refuseCraMutation, { data, loading, error }] = useRefuseCraMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRefuseCraMutation(baseOptions?: Apollo.MutationHookOptions<RefuseCraMutation, RefuseCraMutationVariables>) {
        return Apollo.useMutation<RefuseCraMutation, RefuseCraMutationVariables>(RefuseCraDocument, baseOptions);
      }
export type RefuseCraMutationHookResult = ReturnType<typeof useRefuseCraMutation>;
export type RefuseCraMutationResult = Apollo.MutationResult<RefuseCraMutation>;
export type RefuseCraMutationOptions = Apollo.BaseMutationOptions<RefuseCraMutation, RefuseCraMutationVariables>;
export const SaveProfileDocument = gql`
    mutation saveProfile($userProfile: UserProfileInput!, $billingContact: BillingContactInput!, $company: CompanyInput!) {
  saveProfile(
    input: {userProfile: $userProfile, billingContact: $billingContact, company: $company}
  )
}
    `;
export type SaveProfileMutationFn = Apollo.MutationFunction<SaveProfileMutation, SaveProfileMutationVariables>;

/**
 * __useSaveProfileMutation__
 *
 * To run a mutation, you first call `useSaveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProfileMutation, { data, loading, error }] = useSaveProfileMutation({
 *   variables: {
 *      userProfile: // value for 'userProfile'
 *      billingContact: // value for 'billingContact'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useSaveProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveProfileMutation, SaveProfileMutationVariables>) {
        return Apollo.useMutation<SaveProfileMutation, SaveProfileMutationVariables>(SaveProfileDocument, baseOptions);
      }
export type SaveProfileMutationHookResult = ReturnType<typeof useSaveProfileMutation>;
export type SaveProfileMutationResult = Apollo.MutationResult<SaveProfileMutation>;
export type SaveProfileMutationOptions = Apollo.BaseMutationOptions<SaveProfileMutation, SaveProfileMutationVariables>;
export const ChooseProfilePrestataireForMissionDocument = gql`
    mutation chooseProfilePrestataireForMission($missionId: UUID!, $profilePrestataireId: UUID!) {
  chooseProfilePrestataireForMission(
    missionId: $missionId
    profilePrestataireId: $profilePrestataireId
  )
}
    `;
export type ChooseProfilePrestataireForMissionMutationFn = Apollo.MutationFunction<ChooseProfilePrestataireForMissionMutation, ChooseProfilePrestataireForMissionMutationVariables>;

/**
 * __useChooseProfilePrestataireForMissionMutation__
 *
 * To run a mutation, you first call `useChooseProfilePrestataireForMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseProfilePrestataireForMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseProfilePrestataireForMissionMutation, { data, loading, error }] = useChooseProfilePrestataireForMissionMutation({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *   },
 * });
 */
export function useChooseProfilePrestataireForMissionMutation(baseOptions?: Apollo.MutationHookOptions<ChooseProfilePrestataireForMissionMutation, ChooseProfilePrestataireForMissionMutationVariables>) {
        return Apollo.useMutation<ChooseProfilePrestataireForMissionMutation, ChooseProfilePrestataireForMissionMutationVariables>(ChooseProfilePrestataireForMissionDocument, baseOptions);
      }
export type ChooseProfilePrestataireForMissionMutationHookResult = ReturnType<typeof useChooseProfilePrestataireForMissionMutation>;
export type ChooseProfilePrestataireForMissionMutationResult = Apollo.MutationResult<ChooseProfilePrestataireForMissionMutation>;
export type ChooseProfilePrestataireForMissionMutationOptions = Apollo.BaseMutationOptions<ChooseProfilePrestataireForMissionMutation, ChooseProfilePrestataireForMissionMutationVariables>;
export const AdminSaveFreelanceProfilePrestataireDocument = gql`
    mutation adminSaveFreelanceProfilePrestataire($input: SaveProfilePrestataireInput!) {
  adminSaveFreelanceProfilePrestataire(input: $input)
}
    `;
export type AdminSaveFreelanceProfilePrestataireMutationFn = Apollo.MutationFunction<AdminSaveFreelanceProfilePrestataireMutation, AdminSaveFreelanceProfilePrestataireMutationVariables>;

/**
 * __useAdminSaveFreelanceProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useAdminSaveFreelanceProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveFreelanceProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveFreelanceProfilePrestataireMutation, { data, loading, error }] = useAdminSaveFreelanceProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSaveFreelanceProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveFreelanceProfilePrestataireMutation, AdminSaveFreelanceProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<AdminSaveFreelanceProfilePrestataireMutation, AdminSaveFreelanceProfilePrestataireMutationVariables>(AdminSaveFreelanceProfilePrestataireDocument, baseOptions);
      }
export type AdminSaveFreelanceProfilePrestataireMutationHookResult = ReturnType<typeof useAdminSaveFreelanceProfilePrestataireMutation>;
export type AdminSaveFreelanceProfilePrestataireMutationResult = Apollo.MutationResult<AdminSaveFreelanceProfilePrestataireMutation>;
export type AdminSaveFreelanceProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<AdminSaveFreelanceProfilePrestataireMutation, AdminSaveFreelanceProfilePrestataireMutationVariables>;
export const AdminCreateEnterpriseProfilePrestataireDocument = gql`
    mutation adminCreateEnterpriseProfilePrestataire($input: SaveProfilePrestataireInput!) {
  adminCreateEnterpriseProfilePrestataire(input: $input)
}
    `;
export type AdminCreateEnterpriseProfilePrestataireMutationFn = Apollo.MutationFunction<AdminCreateEnterpriseProfilePrestataireMutation, AdminCreateEnterpriseProfilePrestataireMutationVariables>;

/**
 * __useAdminCreateEnterpriseProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useAdminCreateEnterpriseProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateEnterpriseProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateEnterpriseProfilePrestataireMutation, { data, loading, error }] = useAdminCreateEnterpriseProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateEnterpriseProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateEnterpriseProfilePrestataireMutation, AdminCreateEnterpriseProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<AdminCreateEnterpriseProfilePrestataireMutation, AdminCreateEnterpriseProfilePrestataireMutationVariables>(AdminCreateEnterpriseProfilePrestataireDocument, baseOptions);
      }
export type AdminCreateEnterpriseProfilePrestataireMutationHookResult = ReturnType<typeof useAdminCreateEnterpriseProfilePrestataireMutation>;
export type AdminCreateEnterpriseProfilePrestataireMutationResult = Apollo.MutationResult<AdminCreateEnterpriseProfilePrestataireMutation>;
export type AdminCreateEnterpriseProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<AdminCreateEnterpriseProfilePrestataireMutation, AdminCreateEnterpriseProfilePrestataireMutationVariables>;
export const AdminSaveEnterpriseProfilePrestataireDocument = gql`
    mutation adminSaveEnterpriseProfilePrestataire($input: SaveProfilePrestataireInput!) {
  adminSaveEnterpriseProfilePrestataire(input: $input)
}
    `;
export type AdminSaveEnterpriseProfilePrestataireMutationFn = Apollo.MutationFunction<AdminSaveEnterpriseProfilePrestataireMutation, AdminSaveEnterpriseProfilePrestataireMutationVariables>;

/**
 * __useAdminSaveEnterpriseProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useAdminSaveEnterpriseProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveEnterpriseProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveEnterpriseProfilePrestataireMutation, { data, loading, error }] = useAdminSaveEnterpriseProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSaveEnterpriseProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveEnterpriseProfilePrestataireMutation, AdminSaveEnterpriseProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<AdminSaveEnterpriseProfilePrestataireMutation, AdminSaveEnterpriseProfilePrestataireMutationVariables>(AdminSaveEnterpriseProfilePrestataireDocument, baseOptions);
      }
export type AdminSaveEnterpriseProfilePrestataireMutationHookResult = ReturnType<typeof useAdminSaveEnterpriseProfilePrestataireMutation>;
export type AdminSaveEnterpriseProfilePrestataireMutationResult = Apollo.MutationResult<AdminSaveEnterpriseProfilePrestataireMutation>;
export type AdminSaveEnterpriseProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<AdminSaveEnterpriseProfilePrestataireMutation, AdminSaveEnterpriseProfilePrestataireMutationVariables>;
export const SaveFreelanceProfilePrestataireDocument = gql`
    mutation saveFreelanceProfilePrestataire($input: SaveProfilePrestataireInput!) {
  saveFreelanceProfilePrestataire(input: $input)
}
    `;
export type SaveFreelanceProfilePrestataireMutationFn = Apollo.MutationFunction<SaveFreelanceProfilePrestataireMutation, SaveFreelanceProfilePrestataireMutationVariables>;

/**
 * __useSaveFreelanceProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useSaveFreelanceProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFreelanceProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFreelanceProfilePrestataireMutation, { data, loading, error }] = useSaveFreelanceProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveFreelanceProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<SaveFreelanceProfilePrestataireMutation, SaveFreelanceProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<SaveFreelanceProfilePrestataireMutation, SaveFreelanceProfilePrestataireMutationVariables>(SaveFreelanceProfilePrestataireDocument, baseOptions);
      }
export type SaveFreelanceProfilePrestataireMutationHookResult = ReturnType<typeof useSaveFreelanceProfilePrestataireMutation>;
export type SaveFreelanceProfilePrestataireMutationResult = Apollo.MutationResult<SaveFreelanceProfilePrestataireMutation>;
export type SaveFreelanceProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<SaveFreelanceProfilePrestataireMutation, SaveFreelanceProfilePrestataireMutationVariables>;
export const SaveEnterpriseProfilePrestataireDocument = gql`
    mutation saveEnterpriseProfilePrestataire($input: SaveProfilePrestataireInput!) {
  saveEnterpriseProfilePrestataire(input: $input)
}
    `;
export type SaveEnterpriseProfilePrestataireMutationFn = Apollo.MutationFunction<SaveEnterpriseProfilePrestataireMutation, SaveEnterpriseProfilePrestataireMutationVariables>;

/**
 * __useSaveEnterpriseProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useSaveEnterpriseProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEnterpriseProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEnterpriseProfilePrestataireMutation, { data, loading, error }] = useSaveEnterpriseProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEnterpriseProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<SaveEnterpriseProfilePrestataireMutation, SaveEnterpriseProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<SaveEnterpriseProfilePrestataireMutation, SaveEnterpriseProfilePrestataireMutationVariables>(SaveEnterpriseProfilePrestataireDocument, baseOptions);
      }
export type SaveEnterpriseProfilePrestataireMutationHookResult = ReturnType<typeof useSaveEnterpriseProfilePrestataireMutation>;
export type SaveEnterpriseProfilePrestataireMutationResult = Apollo.MutationResult<SaveEnterpriseProfilePrestataireMutation>;
export type SaveEnterpriseProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<SaveEnterpriseProfilePrestataireMutation, SaveEnterpriseProfilePrestataireMutationVariables>;
export const CreateEnterpriseProfilePrestataireDocument = gql`
    mutation createEnterpriseProfilePrestataire($input: SaveProfilePrestataireInput!) {
  createEnterpriseProfilePrestataire(input: $input)
}
    `;
export type CreateEnterpriseProfilePrestataireMutationFn = Apollo.MutationFunction<CreateEnterpriseProfilePrestataireMutation, CreateEnterpriseProfilePrestataireMutationVariables>;

/**
 * __useCreateEnterpriseProfilePrestataireMutation__
 *
 * To run a mutation, you first call `useCreateEnterpriseProfilePrestataireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnterpriseProfilePrestataireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnterpriseProfilePrestataireMutation, { data, loading, error }] = useCreateEnterpriseProfilePrestataireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnterpriseProfilePrestataireMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnterpriseProfilePrestataireMutation, CreateEnterpriseProfilePrestataireMutationVariables>) {
        return Apollo.useMutation<CreateEnterpriseProfilePrestataireMutation, CreateEnterpriseProfilePrestataireMutationVariables>(CreateEnterpriseProfilePrestataireDocument, baseOptions);
      }
export type CreateEnterpriseProfilePrestataireMutationHookResult = ReturnType<typeof useCreateEnterpriseProfilePrestataireMutation>;
export type CreateEnterpriseProfilePrestataireMutationResult = Apollo.MutationResult<CreateEnterpriseProfilePrestataireMutation>;
export type CreateEnterpriseProfilePrestataireMutationOptions = Apollo.BaseMutationOptions<CreateEnterpriseProfilePrestataireMutation, CreateEnterpriseProfilePrestataireMutationVariables>;
export const SignupClientDocument = gql`
    mutation signupClient($firstName: String!, $lastName: String!, $email: String!, $password: String, $company: String!, $phone: String!, $captchaToken: String!) {
  signUpClient(
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
    company: $company
    phone: $phone
    captchaToken: $captchaToken
  ) {
    user {
      email
    }
    errors
  }
}
    `;
export type SignupClientMutationFn = Apollo.MutationFunction<SignupClientMutation, SignupClientMutationVariables>;

/**
 * __useSignupClientMutation__
 *
 * To run a mutation, you first call `useSignupClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupClientMutation, { data, loading, error }] = useSignupClientMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      company: // value for 'company'
 *      phone: // value for 'phone'
 *      captchaToken: // value for 'captchaToken'
 *   },
 * });
 */
export function useSignupClientMutation(baseOptions?: Apollo.MutationHookOptions<SignupClientMutation, SignupClientMutationVariables>) {
        return Apollo.useMutation<SignupClientMutation, SignupClientMutationVariables>(SignupClientDocument, baseOptions);
      }
export type SignupClientMutationHookResult = ReturnType<typeof useSignupClientMutation>;
export type SignupClientMutationResult = Apollo.MutationResult<SignupClientMutation>;
export type SignupClientMutationOptions = Apollo.BaseMutationOptions<SignupClientMutation, SignupClientMutationVariables>;
export const SignupEsnDocument = gql`
    mutation signupEsn($firstName: String!, $lastName: String!, $email: String!, $password: String, $company: String!, $phone: String!, $captchaToken: String!) {
  signUpEsn(
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
    company: $company
    phone: $phone
    captchaToken: $captchaToken
  ) {
    user {
      email
    }
    errors
  }
}
    `;
export type SignupEsnMutationFn = Apollo.MutationFunction<SignupEsnMutation, SignupEsnMutationVariables>;

/**
 * __useSignupEsnMutation__
 *
 * To run a mutation, you first call `useSignupEsnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupEsnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupEsnMutation, { data, loading, error }] = useSignupEsnMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      company: // value for 'company'
 *      phone: // value for 'phone'
 *      captchaToken: // value for 'captchaToken'
 *   },
 * });
 */
export function useSignupEsnMutation(baseOptions?: Apollo.MutationHookOptions<SignupEsnMutation, SignupEsnMutationVariables>) {
        return Apollo.useMutation<SignupEsnMutation, SignupEsnMutationVariables>(SignupEsnDocument, baseOptions);
      }
export type SignupEsnMutationHookResult = ReturnType<typeof useSignupEsnMutation>;
export type SignupEsnMutationResult = Apollo.MutationResult<SignupEsnMutation>;
export type SignupEsnMutationOptions = Apollo.BaseMutationOptions<SignupEsnMutation, SignupEsnMutationVariables>;
export const SignupFreelanceDocument = gql`
    mutation signupFreelance($firstName: String!, $lastName: String!, $email: String!, $password: String, $captchaToken: String!) {
  signUpFreelance(
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
    captchaToken: $captchaToken
  ) {
    user {
      email
    }
    errors
  }
}
    `;
export type SignupFreelanceMutationFn = Apollo.MutationFunction<SignupFreelanceMutation, SignupFreelanceMutationVariables>;

/**
 * __useSignupFreelanceMutation__
 *
 * To run a mutation, you first call `useSignupFreelanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupFreelanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupFreelanceMutation, { data, loading, error }] = useSignupFreelanceMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      captchaToken: // value for 'captchaToken'
 *   },
 * });
 */
export function useSignupFreelanceMutation(baseOptions?: Apollo.MutationHookOptions<SignupFreelanceMutation, SignupFreelanceMutationVariables>) {
        return Apollo.useMutation<SignupFreelanceMutation, SignupFreelanceMutationVariables>(SignupFreelanceDocument, baseOptions);
      }
export type SignupFreelanceMutationHookResult = ReturnType<typeof useSignupFreelanceMutation>;
export type SignupFreelanceMutationResult = Apollo.MutationResult<SignupFreelanceMutation>;
export type SignupFreelanceMutationOptions = Apollo.BaseMutationOptions<SignupFreelanceMutation, SignupFreelanceMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    success
    errors
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const SendEmailVerificationDocument = gql`
    mutation sendEmailVerification($email: String!) {
  sendEmailVerification(email: $email) {
    success
    errors
  }
}
    `;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>) {
        return Apollo.useMutation<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>(SendEmailVerificationDocument, baseOptions);
      }
export type SendEmailVerificationMutationHookResult = ReturnType<typeof useSendEmailVerificationMutation>;
export type SendEmailVerificationMutationResult = Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export const AdminUsersDocument = gql`
    query adminUsers($role: ROLE, $email: String, $after: String) {
  users(role: $role, email: $email, first: 10, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      cursor
      node {
        name
        id
        email
        role
        profilePrestataire {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *      role: // value for 'role'
 *      email: // value for 'email'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
        return Apollo.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, baseOptions);
      }
export function useAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
          return Apollo.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, baseOptions);
        }
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = Apollo.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const AdminMissionsDocument = gql`
    query adminMissions($state: MISSION_STATE, $after: String) {
  missions(after: $after, state: $state) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        createdAt
        remote
        state
        id
        tjm
        title
        experience
        createdAt
        remote
        owner {
          id
          companyInformations {
            companyContainer {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminMissionsQuery__
 *
 * To run a query within a React component, call `useAdminMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMissionsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAdminMissionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminMissionsQuery, AdminMissionsQueryVariables>) {
        return Apollo.useQuery<AdminMissionsQuery, AdminMissionsQueryVariables>(AdminMissionsDocument, baseOptions);
      }
export function useAdminMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMissionsQuery, AdminMissionsQueryVariables>) {
          return Apollo.useLazyQuery<AdminMissionsQuery, AdminMissionsQueryVariables>(AdminMissionsDocument, baseOptions);
        }
export type AdminMissionsQueryHookResult = ReturnType<typeof useAdminMissionsQuery>;
export type AdminMissionsLazyQueryHookResult = ReturnType<typeof useAdminMissionsLazyQuery>;
export type AdminMissionsQueryResult = Apollo.QueryResult<AdminMissionsQuery, AdminMissionsQueryVariables>;
export const AdminMissionDocument = gql`
    query adminMission($id: UUID!) {
  mission(id: $id) {
    description
    startDate
    endDate
    title
    tjm
    valid
    id
    postalCode
    experience
    meta
    needMoving
    remote
    area
    state
    salesEmail
    owner {
      firstName
      lastName
      email
      phone
      companyInformations {
        companyContainer {
          name
        }
      }
    }
    associatedProfilesPrestataires {
      profilePrestataire {
        availabilityInMonths
        id
        available
        tjm
        maximumDistance
        minimumMonths
        postalCode
        state
        owner {
          email
          role
          name
        }
        skills {
          name
          weight
        }
        title
        city {
          name
          postalCode
        }
      }
      state
      weight
    }
  }
}
    `;

/**
 * __useAdminMissionQuery__
 *
 * To run a query within a React component, call `useAdminMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminMissionQuery(baseOptions: Apollo.QueryHookOptions<AdminMissionQuery, AdminMissionQueryVariables>) {
        return Apollo.useQuery<AdminMissionQuery, AdminMissionQueryVariables>(AdminMissionDocument, baseOptions);
      }
export function useAdminMissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMissionQuery, AdminMissionQueryVariables>) {
          return Apollo.useLazyQuery<AdminMissionQuery, AdminMissionQueryVariables>(AdminMissionDocument, baseOptions);
        }
export type AdminMissionQueryHookResult = ReturnType<typeof useAdminMissionQuery>;
export type AdminMissionLazyQueryHookResult = ReturnType<typeof useAdminMissionLazyQuery>;
export type AdminMissionQueryResult = Apollo.QueryResult<AdminMissionQuery, AdminMissionQueryVariables>;
export const AdminDashboardDocument = gql`
    query adminDashboard {
  dashboard {
    totalMissions
    validatedMissions
    refusedMissions
    waitingValidationMissions
    startedMissions
    endedMissions
    totalProfilesPrestataires
    validatedProfilesPrestataires
    refusedProfilesPrestataires
    waitingValidationProfilesPrestataires
    incompleteProfilesPrestataires
  }
}
    `;

/**
 * __useAdminDashboardQuery__
 *
 * To run a query within a React component, call `useAdminDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDashboardQuery(baseOptions?: Apollo.QueryHookOptions<AdminDashboardQuery, AdminDashboardQueryVariables>) {
        return Apollo.useQuery<AdminDashboardQuery, AdminDashboardQueryVariables>(AdminDashboardDocument, baseOptions);
      }
export function useAdminDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDashboardQuery, AdminDashboardQueryVariables>) {
          return Apollo.useLazyQuery<AdminDashboardQuery, AdminDashboardQueryVariables>(AdminDashboardDocument, baseOptions);
        }
export type AdminDashboardQueryHookResult = ReturnType<typeof useAdminDashboardQuery>;
export type AdminDashboardLazyQueryHookResult = ReturnType<typeof useAdminDashboardLazyQuery>;
export type AdminDashboardQueryResult = Apollo.QueryResult<AdminDashboardQuery, AdminDashboardQueryVariables>;
export const AdminProfilesPrestatairesDocument = gql`
    query adminProfilesPrestataires($after: String, $name: String, $tjmMax: Int, $title: String, $availabilityInMonths: Int, $location: String, $role: ROLE, $state: PROFILE_STATE, $ownerId: String, $skillsOr: [String!], $skillsAnd: [String!]) {
  profilesPrestataires(
    first: 10
    after: $after
    name: $name
    tjmMax: $tjmMax
    title: $title
    availabilityInMonths: $availabilityInMonths
    location: $location
    role: $role
    state: $state
    ownerId: $ownerId
    skillsOr: $skillsOr
    skillsAnd: $skillsAnd
  ) {
    total
    edges {
      node {
        availabilityInMonths
        id
        state
        availabilityDate
        owner {
          name
          role
          createdAt
          id
        }
        tjm
        title
        firstName
        lastName
        name
        postalCode
        available
      }
    }
  }
}
    `;

/**
 * __useAdminProfilesPrestatairesQuery__
 *
 * To run a query within a React component, call `useAdminProfilesPrestatairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProfilesPrestatairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProfilesPrestatairesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      name: // value for 'name'
 *      tjmMax: // value for 'tjmMax'
 *      title: // value for 'title'
 *      availabilityInMonths: // value for 'availabilityInMonths'
 *      location: // value for 'location'
 *      role: // value for 'role'
 *      state: // value for 'state'
 *      ownerId: // value for 'ownerId'
 *      skillsOr: // value for 'skillsOr'
 *      skillsAnd: // value for 'skillsAnd'
 *   },
 * });
 */
export function useAdminProfilesPrestatairesQuery(baseOptions?: Apollo.QueryHookOptions<AdminProfilesPrestatairesQuery, AdminProfilesPrestatairesQueryVariables>) {
        return Apollo.useQuery<AdminProfilesPrestatairesQuery, AdminProfilesPrestatairesQueryVariables>(AdminProfilesPrestatairesDocument, baseOptions);
      }
export function useAdminProfilesPrestatairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProfilesPrestatairesQuery, AdminProfilesPrestatairesQueryVariables>) {
          return Apollo.useLazyQuery<AdminProfilesPrestatairesQuery, AdminProfilesPrestatairesQueryVariables>(AdminProfilesPrestatairesDocument, baseOptions);
        }
export type AdminProfilesPrestatairesQueryHookResult = ReturnType<typeof useAdminProfilesPrestatairesQuery>;
export type AdminProfilesPrestatairesLazyQueryHookResult = ReturnType<typeof useAdminProfilesPrestatairesLazyQuery>;
export type AdminProfilesPrestatairesQueryResult = Apollo.QueryResult<AdminProfilesPrestatairesQuery, AdminProfilesPrestatairesQueryVariables>;
export const AdminProfilePrestataireDocument = gql`
    query adminProfilePrestataire($id: UUID!) {
  profilePrestataire(id: $id) {
    id
    availabilityDate
    availabilityInMonths
    state
    owner {
      name
      phone
      email
      id
      role
      createdAt
      companyInformations {
        companyContainer {
          name
          billingContactPhone
        }
      }
    }
    tjm
    title
    firstName
    lastName
    name
    postalCode
    available
    availabilityDate
    tjm
    cvName
    title
    maximumDistance
    minimumMonths
    available
    valid
    postalCode
    parsedCv
    trigram
    firstName
    lastName
    salesEmail
  }
}
    `;

/**
 * __useAdminProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useAdminProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProfilePrestataireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminProfilePrestataireQuery(baseOptions: Apollo.QueryHookOptions<AdminProfilePrestataireQuery, AdminProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<AdminProfilePrestataireQuery, AdminProfilePrestataireQueryVariables>(AdminProfilePrestataireDocument, baseOptions);
      }
export function useAdminProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProfilePrestataireQuery, AdminProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<AdminProfilePrestataireQuery, AdminProfilePrestataireQueryVariables>(AdminProfilePrestataireDocument, baseOptions);
        }
export type AdminProfilePrestataireQueryHookResult = ReturnType<typeof useAdminProfilePrestataireQuery>;
export type AdminProfilePrestataireLazyQueryHookResult = ReturnType<typeof useAdminProfilePrestataireLazyQuery>;
export type AdminProfilePrestataireQueryResult = Apollo.QueryResult<AdminProfilePrestataireQuery, AdminProfilePrestataireQueryVariables>;
export const AdminCompaniesDocument = gql`
    query adminCompanies($after: String, $billingContactEmail: String, $name: String, $usersCount: Int) {
  companies(
    after: $after
    first: 10
    billingContactEmail: $billingContactEmail
    name: $name
    usersCount: $usersCount
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      node {
        id
        usersCount
        companyIsFilled
        billingContactIsFilled
        billingContactFirstName
        billingContactLastName
        billingContactEmail
        billingContactPhone
        companyPostalCode
        name
      }
    }
  }
}
    `;

/**
 * __useAdminCompaniesQuery__
 *
 * To run a query within a React component, call `useAdminCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCompaniesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      billingContactEmail: // value for 'billingContactEmail'
 *      name: // value for 'name'
 *      usersCount: // value for 'usersCount'
 *   },
 * });
 */
export function useAdminCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<AdminCompaniesQuery, AdminCompaniesQueryVariables>) {
        return Apollo.useQuery<AdminCompaniesQuery, AdminCompaniesQueryVariables>(AdminCompaniesDocument, baseOptions);
      }
export function useAdminCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCompaniesQuery, AdminCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<AdminCompaniesQuery, AdminCompaniesQueryVariables>(AdminCompaniesDocument, baseOptions);
        }
export type AdminCompaniesQueryHookResult = ReturnType<typeof useAdminCompaniesQuery>;
export type AdminCompaniesLazyQueryHookResult = ReturnType<typeof useAdminCompaniesLazyQuery>;
export type AdminCompaniesQueryResult = Apollo.QueryResult<AdminCompaniesQuery, AdminCompaniesQueryVariables>;
export const AdminUsersForCompaniesAssociationDocument = gql`
    query adminUsersForCompaniesAssociation($role: ROLE, $email: String, $companyContainerId: UUID!, $after: String) {
  users(role: $role, email: $email, first: 10, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      cursor
      node {
        name
        id
        email
        role
      }
    }
  }
  usersAssociatedToCompany(companyContainerId: $companyContainerId) {
    canEdit
    userId
    user {
      name
      id
      email
      role
    }
  }
}
    `;

/**
 * __useAdminUsersForCompaniesAssociationQuery__
 *
 * To run a query within a React component, call `useAdminUsersForCompaniesAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersForCompaniesAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersForCompaniesAssociationQuery({
 *   variables: {
 *      role: // value for 'role'
 *      email: // value for 'email'
 *      companyContainerId: // value for 'companyContainerId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAdminUsersForCompaniesAssociationQuery(baseOptions: Apollo.QueryHookOptions<AdminUsersForCompaniesAssociationQuery, AdminUsersForCompaniesAssociationQueryVariables>) {
        return Apollo.useQuery<AdminUsersForCompaniesAssociationQuery, AdminUsersForCompaniesAssociationQueryVariables>(AdminUsersForCompaniesAssociationDocument, baseOptions);
      }
export function useAdminUsersForCompaniesAssociationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersForCompaniesAssociationQuery, AdminUsersForCompaniesAssociationQueryVariables>) {
          return Apollo.useLazyQuery<AdminUsersForCompaniesAssociationQuery, AdminUsersForCompaniesAssociationQueryVariables>(AdminUsersForCompaniesAssociationDocument, baseOptions);
        }
export type AdminUsersForCompaniesAssociationQueryHookResult = ReturnType<typeof useAdminUsersForCompaniesAssociationQuery>;
export type AdminUsersForCompaniesAssociationLazyQueryHookResult = ReturnType<typeof useAdminUsersForCompaniesAssociationLazyQuery>;
export type AdminUsersForCompaniesAssociationQueryResult = Apollo.QueryResult<AdminUsersForCompaniesAssociationQuery, AdminUsersForCompaniesAssociationQueryVariables>;
export const AdminUserByIdDocument = gql`
    query adminUserById($userId: String!) {
  user(id: $userId) {
    firstName
    lastName
    email
    isFilled
    jobTitle
    phone
    companyInformations {
      canEdit
      companyContainer {
        billingContactFirstName
        billingContactLastName
        billingContactPhone
        billingContactJobTitle
        billingContactEmail
        name
        companyInCreation
        siret
        size
        area
        companyAddress1
        companyAddress2
        companyPostalCode
        companyCity
      }
    }
  }
}
    `;

/**
 * __useAdminUserByIdQuery__
 *
 * To run a query within a React component, call `useAdminUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminUserByIdQuery, AdminUserByIdQueryVariables>) {
        return Apollo.useQuery<AdminUserByIdQuery, AdminUserByIdQueryVariables>(AdminUserByIdDocument, baseOptions);
      }
export function useAdminUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserByIdQuery, AdminUserByIdQueryVariables>) {
          return Apollo.useLazyQuery<AdminUserByIdQuery, AdminUserByIdQueryVariables>(AdminUserByIdDocument, baseOptions);
        }
export type AdminUserByIdQueryHookResult = ReturnType<typeof useAdminUserByIdQuery>;
export type AdminUserByIdLazyQueryHookResult = ReturnType<typeof useAdminUserByIdLazyQuery>;
export type AdminUserByIdQueryResult = Apollo.QueryResult<AdminUserByIdQuery, AdminUserByIdQueryVariables>;
export const AdminGetFreelanceProfilePrestataireDocument = gql`
    query adminGetFreelanceProfilePrestataire($id: UUID!) {
  profilePrestataire(id: $id) {
    postalCode
    cvName
    isAvailable
    availabilityDate
    maximumDistance
    minimumMonths
    tjm
    title
    owner {
      id
    }
  }
}
    `;

/**
 * __useAdminGetFreelanceProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useAdminGetFreelanceProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetFreelanceProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetFreelanceProfilePrestataireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetFreelanceProfilePrestataireQuery(baseOptions: Apollo.QueryHookOptions<AdminGetFreelanceProfilePrestataireQuery, AdminGetFreelanceProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<AdminGetFreelanceProfilePrestataireQuery, AdminGetFreelanceProfilePrestataireQueryVariables>(AdminGetFreelanceProfilePrestataireDocument, baseOptions);
      }
export function useAdminGetFreelanceProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetFreelanceProfilePrestataireQuery, AdminGetFreelanceProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<AdminGetFreelanceProfilePrestataireQuery, AdminGetFreelanceProfilePrestataireQueryVariables>(AdminGetFreelanceProfilePrestataireDocument, baseOptions);
        }
export type AdminGetFreelanceProfilePrestataireQueryHookResult = ReturnType<typeof useAdminGetFreelanceProfilePrestataireQuery>;
export type AdminGetFreelanceProfilePrestataireLazyQueryHookResult = ReturnType<typeof useAdminGetFreelanceProfilePrestataireLazyQuery>;
export type AdminGetFreelanceProfilePrestataireQueryResult = Apollo.QueryResult<AdminGetFreelanceProfilePrestataireQuery, AdminGetFreelanceProfilePrestataireQueryVariables>;
export const AdminConfigurationDocument = gql`
    query adminConfiguration {
  adminConfiguration {
    isFitnetIntegrationEnabled
  }
}
    `;

/**
 * __useAdminConfigurationQuery__
 *
 * To run a query within a React component, call `useAdminConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<AdminConfigurationQuery, AdminConfigurationQueryVariables>) {
        return Apollo.useQuery<AdminConfigurationQuery, AdminConfigurationQueryVariables>(AdminConfigurationDocument, baseOptions);
      }
export function useAdminConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminConfigurationQuery, AdminConfigurationQueryVariables>) {
          return Apollo.useLazyQuery<AdminConfigurationQuery, AdminConfigurationQueryVariables>(AdminConfigurationDocument, baseOptions);
        }
export type AdminConfigurationQueryHookResult = ReturnType<typeof useAdminConfigurationQuery>;
export type AdminConfigurationLazyQueryHookResult = ReturnType<typeof useAdminConfigurationLazyQuery>;
export type AdminConfigurationQueryResult = Apollo.QueryResult<AdminConfigurationQuery, AdminConfigurationQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    firstName
    lastName
    email
    isFilled
    jobTitle
    phone
    companyInformations {
      canEdit
      companyContainer {
        billingContactFirstName
        billingContactLastName
        billingContactPhone
        billingContactJobTitle
        billingContactEmail
        name
        companyInCreation
        siret
        size
        area
        companyAddress1
        companyAddress2
        companyPostalCode
        companyCity
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MissionsDocument = gql`
    query missions($state: MISSION_STATE, $after: String) {
  missions(first: 10, after: $after, state: $state) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      node {
        id
        tjm
        title
        experience
      }
    }
  }
}
    `;

/**
 * __useMissionsQuery__
 *
 * To run a query within a React component, call `useMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMissionsQuery(baseOptions?: Apollo.QueryHookOptions<MissionsQuery, MissionsQueryVariables>) {
        return Apollo.useQuery<MissionsQuery, MissionsQueryVariables>(MissionsDocument, baseOptions);
      }
export function useMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionsQuery, MissionsQueryVariables>) {
          return Apollo.useLazyQuery<MissionsQuery, MissionsQueryVariables>(MissionsDocument, baseOptions);
        }
export type MissionsQueryHookResult = ReturnType<typeof useMissionsQuery>;
export type MissionsLazyQueryHookResult = ReturnType<typeof useMissionsLazyQuery>;
export type MissionsQueryResult = Apollo.QueryResult<MissionsQuery, MissionsQueryVariables>;
export const MissionDocument = gql`
    query mission($id: UUID!) {
  mission(id: $id) {
    description
    startDate
    endDate
    title
    tjm
    valid
    id
    postalCode
    experience
    meta
    needMoving
    remote
    area
    state
    associatedProfilesPrestataires {
      profilePrestataire {
        availabilityInMonths
        id
        available
        tjm
        maximumDistance
        minimumMonths
        postalCode
        state
        skills {
          name
          weight
        }
        title
        city {
          name
          postalCode
        }
      }
      state
      weight
    }
  }
}
    `;

/**
 * __useMissionQuery__
 *
 * To run a query within a React component, call `useMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMissionQuery(baseOptions: Apollo.QueryHookOptions<MissionQuery, MissionQueryVariables>) {
        return Apollo.useQuery<MissionQuery, MissionQueryVariables>(MissionDocument, baseOptions);
      }
export function useMissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionQuery, MissionQueryVariables>) {
          return Apollo.useLazyQuery<MissionQuery, MissionQueryVariables>(MissionDocument, baseOptions);
        }
export type MissionQueryHookResult = ReturnType<typeof useMissionQuery>;
export type MissionLazyQueryHookResult = ReturnType<typeof useMissionLazyQuery>;
export type MissionQueryResult = Apollo.QueryResult<MissionQuery, MissionQueryVariables>;
export const MissionWithCraDocument = gql`
    query missionWithCra($id: UUID!, $month: Int!, $year: Int!) {
  mission(id: $id) {
    id
    title
    craState(month: $month, year: $year) {
      date
      state
      cras {
        id
        date
        value
        disabled
      }
    }
  }
}
    `;

/**
 * __useMissionWithCraQuery__
 *
 * To run a query within a React component, call `useMissionWithCraQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionWithCraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionWithCraQuery({
 *   variables: {
 *      id: // value for 'id'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useMissionWithCraQuery(baseOptions: Apollo.QueryHookOptions<MissionWithCraQuery, MissionWithCraQueryVariables>) {
        return Apollo.useQuery<MissionWithCraQuery, MissionWithCraQueryVariables>(MissionWithCraDocument, baseOptions);
      }
export function useMissionWithCraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionWithCraQuery, MissionWithCraQueryVariables>) {
          return Apollo.useLazyQuery<MissionWithCraQuery, MissionWithCraQueryVariables>(MissionWithCraDocument, baseOptions);
        }
export type MissionWithCraQueryHookResult = ReturnType<typeof useMissionWithCraQuery>;
export type MissionWithCraLazyQueryHookResult = ReturnType<typeof useMissionWithCraLazyQuery>;
export type MissionWithCraQueryResult = Apollo.QueryResult<MissionWithCraQuery, MissionWithCraQueryVariables>;
export const MyMissionsDocument = gql`
    query myMissions {
  me {
    profilePrestataire {
      validatedAssociatedMissions {
        mission {
          id
          experience
          remote
          title
        }
        state
        weight
      }
    }
  }
}
    `;

/**
 * __useMyMissionsQuery__
 *
 * To run a query within a React component, call `useMyMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyMissionsQuery(baseOptions?: Apollo.QueryHookOptions<MyMissionsQuery, MyMissionsQueryVariables>) {
        return Apollo.useQuery<MyMissionsQuery, MyMissionsQueryVariables>(MyMissionsDocument, baseOptions);
      }
export function useMyMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMissionsQuery, MyMissionsQueryVariables>) {
          return Apollo.useLazyQuery<MyMissionsQuery, MyMissionsQueryVariables>(MyMissionsDocument, baseOptions);
        }
export type MyMissionsQueryHookResult = ReturnType<typeof useMyMissionsQuery>;
export type MyMissionsLazyQueryHookResult = ReturnType<typeof useMyMissionsLazyQuery>;
export type MyMissionsQueryResult = Apollo.QueryResult<MyMissionsQuery, MyMissionsQueryVariables>;
export const MyMissionDocument = gql`
    query myMission($id: UUID!, $month: Int!, $year: Int!, $profilePrestataireId: UUID) {
  me {
    profilePrestataire(id: $profilePrestataireId) {
      validatedAssociatedMission(id: $id) {
        mission {
          id
          title
          craState(month: $month, year: $year) {
            date
            state
            cras {
              id
              date
              value
              disabled
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyMissionQuery__
 *
 * To run a query within a React component, call `useMyMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *   },
 * });
 */
export function useMyMissionQuery(baseOptions: Apollo.QueryHookOptions<MyMissionQuery, MyMissionQueryVariables>) {
        return Apollo.useQuery<MyMissionQuery, MyMissionQueryVariables>(MyMissionDocument, baseOptions);
      }
export function useMyMissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMissionQuery, MyMissionQueryVariables>) {
          return Apollo.useLazyQuery<MyMissionQuery, MyMissionQueryVariables>(MyMissionDocument, baseOptions);
        }
export type MyMissionQueryHookResult = ReturnType<typeof useMyMissionQuery>;
export type MyMissionLazyQueryHookResult = ReturnType<typeof useMyMissionLazyQuery>;
export type MyMissionQueryResult = Apollo.QueryResult<MyMissionQuery, MyMissionQueryVariables>;
export const MatchMissionDocument = gql`
    query matchMission($title: String!, $description: String!) {
  matchMission(description: $description, title: $title) {
    associatedProfilesPrestataires {
      availabilityInMonths
      id
      state
      availabilityDate
      owner {
        name
        role
        createdAt
        id
      }
      tjm
      title
      firstName
      lastName
      name
      postalCode
      available
    }
    score
  }
}
    `;

/**
 * __useMatchMissionQuery__
 *
 * To run a query within a React component, call `useMatchMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchMissionQuery({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useMatchMissionQuery(baseOptions: Apollo.QueryHookOptions<MatchMissionQuery, MatchMissionQueryVariables>) {
        return Apollo.useQuery<MatchMissionQuery, MatchMissionQueryVariables>(MatchMissionDocument, baseOptions);
      }
export function useMatchMissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchMissionQuery, MatchMissionQueryVariables>) {
          return Apollo.useLazyQuery<MatchMissionQuery, MatchMissionQueryVariables>(MatchMissionDocument, baseOptions);
        }
export type MatchMissionQueryHookResult = ReturnType<typeof useMatchMissionQuery>;
export type MatchMissionLazyQueryHookResult = ReturnType<typeof useMatchMissionLazyQuery>;
export type MatchMissionQueryResult = Apollo.QueryResult<MatchMissionQuery, MatchMissionQueryVariables>;
export const ProfilesPrestatairesDocument = gql`
    query profilesPrestataires($after: String) {
  profilesPrestataires(first: 10, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      node {
        name
        id
        cvName
        tjm
        available
        maximumDistance
        minimumMonths
        title
        postalCode
        availabilityDate
        trigram
        firstName
        lastName
        availabilityInMonths
      }
    }
  }
}
    `;

/**
 * __useProfilesPrestatairesQuery__
 *
 * To run a query within a React component, call `useProfilesPrestatairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesPrestatairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesPrestatairesQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProfilesPrestatairesQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesPrestatairesQuery, ProfilesPrestatairesQueryVariables>) {
        return Apollo.useQuery<ProfilesPrestatairesQuery, ProfilesPrestatairesQueryVariables>(ProfilesPrestatairesDocument, baseOptions);
      }
export function useProfilesPrestatairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesPrestatairesQuery, ProfilesPrestatairesQueryVariables>) {
          return Apollo.useLazyQuery<ProfilesPrestatairesQuery, ProfilesPrestatairesQueryVariables>(ProfilesPrestatairesDocument, baseOptions);
        }
export type ProfilesPrestatairesQueryHookResult = ReturnType<typeof useProfilesPrestatairesQuery>;
export type ProfilesPrestatairesLazyQueryHookResult = ReturnType<typeof useProfilesPrestatairesLazyQuery>;
export type ProfilesPrestatairesQueryResult = Apollo.QueryResult<ProfilesPrestatairesQuery, ProfilesPrestatairesQueryVariables>;
export const AnonymousProfilesPrestatairesDocument = gql`
    query anonymousProfilesPrestataires($after: String) {
  anonymousProfilesPrestataires(first: 10, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      node {
        name
        id
        cvName
        tjm
        available
        maximumDistance
        minimumMonths
        title
        postalCode
        availabilityDate
        trigram
        firstName
        lastName
        availabilityInMonths
        skills {
          name
          weight
        }
        city {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAnonymousProfilesPrestatairesQuery__
 *
 * To run a query within a React component, call `useAnonymousProfilesPrestatairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousProfilesPrestatairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousProfilesPrestatairesQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAnonymousProfilesPrestatairesQuery(baseOptions?: Apollo.QueryHookOptions<AnonymousProfilesPrestatairesQuery, AnonymousProfilesPrestatairesQueryVariables>) {
        return Apollo.useQuery<AnonymousProfilesPrestatairesQuery, AnonymousProfilesPrestatairesQueryVariables>(AnonymousProfilesPrestatairesDocument, baseOptions);
      }
export function useAnonymousProfilesPrestatairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnonymousProfilesPrestatairesQuery, AnonymousProfilesPrestatairesQueryVariables>) {
          return Apollo.useLazyQuery<AnonymousProfilesPrestatairesQuery, AnonymousProfilesPrestatairesQueryVariables>(AnonymousProfilesPrestatairesDocument, baseOptions);
        }
export type AnonymousProfilesPrestatairesQueryHookResult = ReturnType<typeof useAnonymousProfilesPrestatairesQuery>;
export type AnonymousProfilesPrestatairesLazyQueryHookResult = ReturnType<typeof useAnonymousProfilesPrestatairesLazyQuery>;
export type AnonymousProfilesPrestatairesQueryResult = Apollo.QueryResult<AnonymousProfilesPrestatairesQuery, AnonymousProfilesPrestatairesQueryVariables>;
export const AnonymousProfilePrestataireDetailsDocument = gql`
    query anonymousProfilePrestataireDetails($id: UUID!) {
  anonymousProfilePrestataire(id: $id) {
    name
    id
    cvName
    tjm
    available
    maximumDistance
    minimumMonths
    title
    postalCode
    availabilityDate
    trigram
    firstName
    lastName
    availabilityInMonths
    skills {
      name
      weight
    }
    city {
      name
    }
    parsedCv
  }
}
    `;

/**
 * __useAnonymousProfilePrestataireDetailsQuery__
 *
 * To run a query within a React component, call `useAnonymousProfilePrestataireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousProfilePrestataireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousProfilePrestataireDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnonymousProfilePrestataireDetailsQuery(baseOptions: Apollo.QueryHookOptions<AnonymousProfilePrestataireDetailsQuery, AnonymousProfilePrestataireDetailsQueryVariables>) {
        return Apollo.useQuery<AnonymousProfilePrestataireDetailsQuery, AnonymousProfilePrestataireDetailsQueryVariables>(AnonymousProfilePrestataireDetailsDocument, baseOptions);
      }
export function useAnonymousProfilePrestataireDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnonymousProfilePrestataireDetailsQuery, AnonymousProfilePrestataireDetailsQueryVariables>) {
          return Apollo.useLazyQuery<AnonymousProfilePrestataireDetailsQuery, AnonymousProfilePrestataireDetailsQueryVariables>(AnonymousProfilePrestataireDetailsDocument, baseOptions);
        }
export type AnonymousProfilePrestataireDetailsQueryHookResult = ReturnType<typeof useAnonymousProfilePrestataireDetailsQuery>;
export type AnonymousProfilePrestataireDetailsLazyQueryHookResult = ReturnType<typeof useAnonymousProfilePrestataireDetailsLazyQuery>;
export type AnonymousProfilePrestataireDetailsQueryResult = Apollo.QueryResult<AnonymousProfilePrestataireDetailsQuery, AnonymousProfilePrestataireDetailsQueryVariables>;
export const ProfilesPrestatairesWithValidatedMissionsDocument = gql`
    query profilesPrestatairesWithValidatedMissions {
  profilesPrestataires(onlyWithValidatedMissions: true) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
    edges {
      node {
        name
        id
        cvName
        tjm
        available
        maximumDistance
        minimumMonths
        title
        postalCode
        availabilityDate
        trigram
        firstName
        lastName
        availabilityInMonths
        validatedAssociatedMissions {
          mission {
            id
            experience
            remote
            title
          }
          state
          weight
        }
      }
    }
  }
}
    `;

/**
 * __useProfilesPrestatairesWithValidatedMissionsQuery__
 *
 * To run a query within a React component, call `useProfilesPrestatairesWithValidatedMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesPrestatairesWithValidatedMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesPrestatairesWithValidatedMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesPrestatairesWithValidatedMissionsQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesPrestatairesWithValidatedMissionsQuery, ProfilesPrestatairesWithValidatedMissionsQueryVariables>) {
        return Apollo.useQuery<ProfilesPrestatairesWithValidatedMissionsQuery, ProfilesPrestatairesWithValidatedMissionsQueryVariables>(ProfilesPrestatairesWithValidatedMissionsDocument, baseOptions);
      }
export function useProfilesPrestatairesWithValidatedMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesPrestatairesWithValidatedMissionsQuery, ProfilesPrestatairesWithValidatedMissionsQueryVariables>) {
          return Apollo.useLazyQuery<ProfilesPrestatairesWithValidatedMissionsQuery, ProfilesPrestatairesWithValidatedMissionsQueryVariables>(ProfilesPrestatairesWithValidatedMissionsDocument, baseOptions);
        }
export type ProfilesPrestatairesWithValidatedMissionsQueryHookResult = ReturnType<typeof useProfilesPrestatairesWithValidatedMissionsQuery>;
export type ProfilesPrestatairesWithValidatedMissionsLazyQueryHookResult = ReturnType<typeof useProfilesPrestatairesWithValidatedMissionsLazyQuery>;
export type ProfilesPrestatairesWithValidatedMissionsQueryResult = Apollo.QueryResult<ProfilesPrestatairesWithValidatedMissionsQuery, ProfilesPrestatairesWithValidatedMissionsQueryVariables>;
export const ProfilePrestataireDocument = gql`
    query profilePrestataire($id: UUID!) {
  profilePrestataire(id: $id) {
    postalCode
    cvName
    available
    availabilityDate
    maximumDistance
    minimumMonths
    tjm
    title
    firstName
    lastName
    trigram
    availabilityInMonths
    id
    state
    skills {
      name
      weight
    }
    title
    city {
      name
      postalCode
    }
  }
}
    `;

/**
 * __useProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePrestataireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfilePrestataireQuery(baseOptions: Apollo.QueryHookOptions<ProfilePrestataireQuery, ProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<ProfilePrestataireQuery, ProfilePrestataireQueryVariables>(ProfilePrestataireDocument, baseOptions);
      }
export function useProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePrestataireQuery, ProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<ProfilePrestataireQuery, ProfilePrestataireQueryVariables>(ProfilePrestataireDocument, baseOptions);
        }
export type ProfilePrestataireQueryHookResult = ReturnType<typeof useProfilePrestataireQuery>;
export type ProfilePrestataireLazyQueryHookResult = ReturnType<typeof useProfilePrestataireLazyQuery>;
export type ProfilePrestataireQueryResult = Apollo.QueryResult<ProfilePrestataireQuery, ProfilePrestataireQueryVariables>;
export const MissionAssociatedProfilePrestataireDocument = gql`
    query missionAssociatedProfilePrestataire($missionId: UUID!, $profilePrestataireId: UUID!) {
  mission(id: $missionId) {
    associatedProfilesPrestataires(id: $profilePrestataireId) {
      profilePrestataire {
        availabilityInMonths
        id
        available
        tjm
        maximumDistance
        minimumMonths
        postalCode
        state
        parsedCv
        skills {
          name
          weight
        }
        title
        city {
          name
          postalCode
        }
      }
      state
      weight
    }
  }
}
    `;

/**
 * __useMissionAssociatedProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useMissionAssociatedProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionAssociatedProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionAssociatedProfilePrestataireQuery({
 *   variables: {
 *      missionId: // value for 'missionId'
 *      profilePrestataireId: // value for 'profilePrestataireId'
 *   },
 * });
 */
export function useMissionAssociatedProfilePrestataireQuery(baseOptions: Apollo.QueryHookOptions<MissionAssociatedProfilePrestataireQuery, MissionAssociatedProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<MissionAssociatedProfilePrestataireQuery, MissionAssociatedProfilePrestataireQueryVariables>(MissionAssociatedProfilePrestataireDocument, baseOptions);
      }
export function useMissionAssociatedProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionAssociatedProfilePrestataireQuery, MissionAssociatedProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<MissionAssociatedProfilePrestataireQuery, MissionAssociatedProfilePrestataireQueryVariables>(MissionAssociatedProfilePrestataireDocument, baseOptions);
        }
export type MissionAssociatedProfilePrestataireQueryHookResult = ReturnType<typeof useMissionAssociatedProfilePrestataireQuery>;
export type MissionAssociatedProfilePrestataireLazyQueryHookResult = ReturnType<typeof useMissionAssociatedProfilePrestataireLazyQuery>;
export type MissionAssociatedProfilePrestataireQueryResult = Apollo.QueryResult<MissionAssociatedProfilePrestataireQuery, MissionAssociatedProfilePrestataireQueryVariables>;
export const GetFreelanceProfilePrestataireDocument = gql`
    query getFreelanceProfilePrestataire {
  getProfilePrestataire {
    postalCode
    cvName
    isFilled
    isAvailable
    availabilityDate
    maximumDistance
    minimumMonths
    tjm
    title
  }
}
    `;

/**
 * __useGetFreelanceProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useGetFreelanceProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreelanceProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreelanceProfilePrestataireQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFreelanceProfilePrestataireQuery(baseOptions?: Apollo.QueryHookOptions<GetFreelanceProfilePrestataireQuery, GetFreelanceProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<GetFreelanceProfilePrestataireQuery, GetFreelanceProfilePrestataireQueryVariables>(GetFreelanceProfilePrestataireDocument, baseOptions);
      }
export function useGetFreelanceProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFreelanceProfilePrestataireQuery, GetFreelanceProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<GetFreelanceProfilePrestataireQuery, GetFreelanceProfilePrestataireQueryVariables>(GetFreelanceProfilePrestataireDocument, baseOptions);
        }
export type GetFreelanceProfilePrestataireQueryHookResult = ReturnType<typeof useGetFreelanceProfilePrestataireQuery>;
export type GetFreelanceProfilePrestataireLazyQueryHookResult = ReturnType<typeof useGetFreelanceProfilePrestataireLazyQuery>;
export type GetFreelanceProfilePrestataireQueryResult = Apollo.QueryResult<GetFreelanceProfilePrestataireQuery, GetFreelanceProfilePrestataireQueryVariables>;
export const GetEsnProfilePrestataireDocument = gql`
    query getEsnProfilePrestataire($id: String!) {
  getProfilePrestataire(id: $id) {
    postalCode
    cvName
    isFilled
    isAvailable
    availabilityDate
    maximumDistance
    minimumMonths
    tjm
    title
    firstName
    lastName
    trigram
  }
}
    `;

/**
 * __useGetEsnProfilePrestataireQuery__
 *
 * To run a query within a React component, call `useGetEsnProfilePrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEsnProfilePrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEsnProfilePrestataireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEsnProfilePrestataireQuery(baseOptions: Apollo.QueryHookOptions<GetEsnProfilePrestataireQuery, GetEsnProfilePrestataireQueryVariables>) {
        return Apollo.useQuery<GetEsnProfilePrestataireQuery, GetEsnProfilePrestataireQueryVariables>(GetEsnProfilePrestataireDocument, baseOptions);
      }
export function useGetEsnProfilePrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEsnProfilePrestataireQuery, GetEsnProfilePrestataireQueryVariables>) {
          return Apollo.useLazyQuery<GetEsnProfilePrestataireQuery, GetEsnProfilePrestataireQueryVariables>(GetEsnProfilePrestataireDocument, baseOptions);
        }
export type GetEsnProfilePrestataireQueryHookResult = ReturnType<typeof useGetEsnProfilePrestataireQuery>;
export type GetEsnProfilePrestataireLazyQueryHookResult = ReturnType<typeof useGetEsnProfilePrestataireLazyQuery>;
export type GetEsnProfilePrestataireQueryResult = Apollo.QueryResult<GetEsnProfilePrestataireQuery, GetEsnProfilePrestataireQueryVariables>;