
import React, { useCallback, useMemo } from 'react';
import { PrincipalLayoutBox, Title, Tabs, LinkTab, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import { translate } from '../../../translate';
import { useAdminDeleteCompanyMutation, useAdminCompaniesQuery, useAdminAssociateUsersToCompanyMutation } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Pagination } from '../../../components/Pagination/Pagination';
import $ from './AdminCompaniesListing.module.scss';
import { Field } from '../../../components/Form/Field/Field';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { IconButton } from '../../../components/Button/Button';
import { offsetToCursor } from 'graphql-relay';
import ReactTooltip from 'react-tooltip';
import { BsTrash } from 'react-icons/bs';
import { Input } from '../../../components/Form/Input/Input';
import { positiveNumberValidator, useFilters } from '../../../hooks/useFilters';
import { UsersListingModal } from '../components/UsersListingModal';

type Filters = {
  billingContactEmail: string | null;
  name: string | null;
  usersCount: number | null;
  page: number;
}
const defaultFilters: Filters = {
  billingContactEmail: '',
  name: '',
  usersCount: null,
  page: 0
};
export const AdminCompaniesListing = () => {
  const [filters, setFilters, debouncedFilters] = useFilters<Filters>(defaultFilters, { usersCount: positiveNumberValidator });
  const offset = useMemo(() => filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1), [filters.page]);
  const [associateUsers, associateUsersResponse] = useAdminAssociateUsersToCompanyMutation();
  const list = useAdminCompaniesQuery({
    variables: {
      after: offset,
      billingContactEmail: debouncedFilters.billingContactEmail,
      name: debouncedFilters.name,
      usersCount: debouncedFilters.usersCount,
    }
  });
  const data = useMemo(() => {
    const d = list.data?.companies?.edges! || [];
    return d.map((company) => ({
      name: company?.node?.name,
      email: company?.node?.billingContactEmail,
      phone: company?.node?.billingContactPhone,
      postalCode: company?.node?.companyPostalCode,
      usersCount: company?.node?.usersCount,
      isFilled: company?.node?.companyIsFilled && company?.node?.billingContactIsFilled,
      id: company?.node?.id!,
    }));
  }, [list.data]);

  const [deleteCompanyFromHook] = useAdminDeleteCompanyMutation();

  const deleteCompany = useCallback((id: string) => {
    // eslint-disable-next-line no-restricted-globals
    const shouldDelete = confirm('Êtes vous sûr de vouloir supprimer cette entreprise ?');
    if (!shouldDelete) {
      return;
    }

    deleteCompanyFromHook({
      variables: {
        id,
      }
    }).then(() => {
      list.refetch();
    });
  }, [deleteCompanyFromHook, list]);

  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox>
      <Title>{translate('userProfileFirstName')}</Title>
      <Tabs>
        <LinkTab to="/admin/companies/">Entreprises</LinkTab>
      </Tabs>
      <Content>
        <div className={$.Filters}>
          <FormZone>
            <FormRow>
              <Field>
                <Input label="Nom" value={filters.name!} onChange={(e: any) => setFilters('name', e.target.value)} />
              </Field>
              <Field>
                <Input label="Email du contact de facturation" value={filters.billingContactEmail!} onChange={(e: any) => setFilters('billingContactEmail', e.target.value)} />
              </Field>
              <Field>
                <Input label="Utilisateurs associés" value={filters.usersCount!} onChange={(e: any) => setFilters('usersCount', e.target.value)} />
              </Field>
            </FormRow>
          </FormZone>
        </div>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <>
            <Table<TableData> columns={[
              {
                Header: 'Complète',
                accessor: (row) => (
                  <span>
                    {row.isFilled ? 'oui' : 'non'}
                  </span>
                ),
                width: 10,
              },
              {
                Header: 'Nom',
                accessor: (row) => (
                  <span>
                    {row.name}
                  </span>
                ),
                width: 20,
              },
              {
                Header: 'Code postal',
                accessor: (row) => (
                  <span>
                    {row.postalCode}
                  </span>
                ),
                width: 20,
              },
              {
                Header: 'Utilisateurs associés',
                accessor: (row) => (
                  <span>
                    {row.usersCount}
                  </span>
                ),
                width: 20,
              },
              {
                Header: 'Contact de facturation',
                accessor: (row) => (
                  <span>
                    {row.email}
                    <br />
                    {row.phone}
                  </span>
                ),
                width: 20,
              },
              {
                Header: 'Actions',
                accessor: row => {
                  const deleteId = `${row.id}-delete`;
                  const associateUsersId = `${row.id}-associate-users`;
                  const actions = [
                    (
                      <span key={associateUsersId}>
                        <UsersListingModal isLoading={associateUsersResponse.loading} onSubmit={(usersIds) => {
                          associateUsers({
                            variables: {
                              companyId: row.id,
                              users: usersIds.reduce<{ id: string, canEdit: boolean }[]>((acc, userId) => {
                                acc.push({ id: userId, canEdit: true });
                                return acc;
                              }, []),
                            }
                          }).then(() => {
                            list.refetch();
                          });
                        }} dataForId={associateUsersId} companyContainerId={row.id} />
                        <ReactTooltip id={associateUsersId} place="top" effect="solid">
                          Associer un utilisateur
                        </ReactTooltip>
                      </span>
                    )
                  ];

                  if (row.usersCount === 0) {
                    actions.push(
                      (
                        <span key={deleteId}>
                          <IconButton data-tip data-for={deleteId} onClick={() => {
                            deleteCompany(row.id);
                          }}>
                            <BsTrash size={25} />
                          </IconButton>
                          <ReactTooltip id={deleteId} place="top" effect="solid">
                            Supprimer
                          </ReactTooltip>
                        </span>
                      )
                    );
                  }

                  return actions;
                },
                width: 10,
              },
            ]} data={data} />
            <Pagination current={filters.page} pageSize={10} total={list.data?.companies?.total!} onClick={page => setFilters('page', page)} />
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
