import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Content, LinkTab, PrincipalLayoutBox, Tabs, Title, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './AdminProfilePrestataireListing.module.scss';
import { Table } from '../../../components/Table/Table';
import { useAdminProfilesPrestatairesLazyQuery, Profile_State, Role, useMatchMissionLazyQuery, useAdminDeleteUserMutation, useAdminDeleteProfilePrestataireMutation } from '../../../generated/graphql';
import { Link } from '../../../components/Link/Link';
import { availabilityToText } from '../AdminProfilePrestatairePreview/AdminProfilePrestatairePreview';
import { Label, LabelColor, OuiOuNonIcon, RoleLabel } from '../../../components/DataPresentation/Presentation/Presentation';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';
import { Input } from '../../../components/Form/Input/Input';
import { Field } from '../../../components/Form/Field/Field';
import { Select } from '../../../components/Form/Select/Select';
import { offsetToCursor } from 'graphql-relay';
import { Pagination } from '../../../components/Pagination/Pagination';
import { arrayValidator, positiveNumberValidator, useFilters } from '../../../hooks/useFilters';
import { SearchByMissionModal } from '../components/SearchByMissionModal';
import { RefModal } from '../../../components/Modal/Modal';
import { IconButton } from '../../../components/Button/Button';
import { CgUserList } from 'react-icons/cg';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const format = new Intl.DateTimeFormat('fr', { year: 'numeric', month: 'short', day: 'numeric' });
const stateToWord = (state: Profile_State) => {
  switch (state) {
    case Profile_State.Validated:
      return 'Validé';
    case Profile_State.Waiting:
      return 'À valider';
    case Profile_State.Incomplete:
      return 'Incomplet';
    case Profile_State.Refused:
    default:
      return 'Refusé';
  }
}

type StateProps = {
  state: Profile_State;
};
const State = ({ state }: StateProps) => {
  switch (state) {
    case Profile_State.Validated:
      return <Label revert color={LabelColor.Green}>{stateToWord(state)}</Label>;
    case Profile_State.Waiting:
      return <Label revert color={LabelColor.Orange}>{stateToWord(state)}</Label>;
    case Profile_State.Incomplete:
      return <Label revert color={LabelColor.Red}>{stateToWord(state)}</Label>;
    case Profile_State.Refused:
    default:
      return <Label revert color={LabelColor.Yellow}>{stateToWord(state)}</Label>;
  }
}

type Filters = {
  state: string | null;
  title: string | null;
  name: string | null;
  tjmMax: number | null;
  postalCode: string | null;
  role: string | null;
  availability: number | null;
  ownerId: string | null;
  skillsOr: string[] | null;
  skillsAnd: string[] | null;
  missionTitle: string | null,
  missionDescription: string | null,
  page: number;
}
const defaultFilters: Filters = {
  state: Profile_State.Waiting,
  title: null,
  name: null,
  tjmMax: null,
  postalCode: null,
  role: null,
  availability: null,
  ownerId: null,
  skillsOr: null,
  skillsAnd: null,
  missionTitle: null,
  missionDescription: null,
  page: 0
};
export const AdminProfilePrestataireListing = () => {
  const history = useHistory();
  const [filters, setFilters, debouncedFilters] = useFilters<Filters>(defaultFilters, { tjmMax: positiveNumberValidator, skillsOr: arrayValidator, skillsAnd: arrayValidator });
  const offset = useMemo(() => filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1), [filters.page]);
  const [fetchList, list] = useAdminProfilesPrestatairesLazyQuery();
  const [matchMission, matchMissionResponse] = useMatchMissionLazyQuery();
  const [deleteProfilePrestataire, deleteProfilePrestataireResponse] = useAdminDeleteProfilePrestataireMutation();
  const [deleteUser, deleteUserResponse] = useAdminDeleteUserMutation();

  useEffect(() => {
    if (debouncedFilters.missionTitle && debouncedFilters.missionDescription) {
      matchMission({
        variables: {
          description: debouncedFilters.missionDescription,
          title: debouncedFilters.missionTitle,
        },
      });

      return;
    }

    fetchList({
      variables: {
        after: offset,
        name: debouncedFilters.name,
        title: debouncedFilters.title,
        tjmMax: debouncedFilters.tjmMax,
        availabilityInMonths: debouncedFilters.availability,
        location: debouncedFilters.postalCode,
        role: debouncedFilters.role as any,
        state: debouncedFilters.state as any,
        ownerId: debouncedFilters.ownerId,
        skillsOr: debouncedFilters.skillsOr as string[],
        skillsAnd: debouncedFilters.skillsAnd as string[],
      },
    });

  }, [debouncedFilters, fetchList, matchMission, offset, deleteProfilePrestataireResponse.loading, deleteUserResponse.loading]);


  const data = useMemo(() => {
    if (matchMissionResponse?.data?.matchMission) {
      return (matchMissionResponse?.data?.matchMission || []).map((profile) => ({
        id: profile!.associatedProfilesPrestataires!.id!,
        tjm: profile!.associatedProfilesPrestataires!.tjm!,
        title: profile!.associatedProfilesPrestataires!.title!,
        state: profile!.associatedProfilesPrestataires!.state!,
        available: profile!.associatedProfilesPrestataires!.available!,
        role: profile!.associatedProfilesPrestataires!.owner!.role!,
        postalCode: profile!.associatedProfilesPrestataires!.postalCode!,
        name: profile!.associatedProfilesPrestataires!.name! || profile!.associatedProfilesPrestataires!.owner!.name,
        signupDate: profile!.associatedProfilesPrestataires!.owner!.createdAt!,
        availabilityInMonths: profile!.associatedProfilesPrestataires!.availabilityInMonths!,
        userId: profile!.associatedProfilesPrestataires!.owner?.id!
      }));
    }

    return (list.data?.profilesPrestataires?.edges || []).map((profile) => ({
      id: profile!.node!.id!,
      tjm: profile!.node!.tjm!,
      title: profile!.node!.title!,
      state: profile!.node!.state!,
      available: profile!.node!.available!,
      role: profile!.node!.owner!.role!,
      postalCode: profile!.node!.postalCode!,
      name: profile!.node!.name! || profile!.node!.owner!.name,
      signupDate: profile!.node!.owner!.createdAt!,
      availabilityInMonths: profile!.node!.availabilityInMonths!,
      userId: profile!.node?.owner?.id!
    }));
  }, [list.data?.profilesPrestataires?.edges, matchMissionResponse.data?.matchMission]);

  const paginationData = useMemo(() => {
    if (matchMissionResponse?.data?.matchMission) {
      return {
        total: (matchMissionResponse?.data?.matchMission || []).length,
        pageSize: 100
      };
    }

    return {
      total: list.data?.profilesPrestataires?.total!,
      pageSize: 10
    };
  }, [list.data?.profilesPrestataires?.total, matchMissionResponse?.data?.matchMission]);

  type TableData = (typeof data)[number];

  const matchMissionsToGetProfilesPrestataires = useCallback(async (description: string, title: string) => {
    setFilters('missionTitle', title, 'missionDescription', description);
    searchByMiddionModalRef?.current?.close();

  }, [setFilters]);

  const searchByMiddionModalRef = useRef<RefModal | null>(null);
  return (
    <PrincipalLayoutBox className={classnames($.ProfilePrestataire)}>
      <Title>Administration</Title>
      <Tabs>
        <LinkTab to="/admin/prestataires">Prestataires</LinkTab>
      </Tabs>
      <Content>
        <div className={$.Filters}>
          <FormZone>
            <FormRow>
              <Field>
                <Select
                  emptyLabel="État"
                  onChange={e => setFilters('state', e.target.value)}
                  value={filters.state!}
                  options={[
                    { value: Profile_State.Waiting, label: stateToWord(Profile_State.Waiting) },
                    { value: Profile_State.Validated, label: stateToWord(Profile_State.Validated) },
                    { value: Profile_State.Incomplete, label: stateToWord(Profile_State.Incomplete) },
                    { value: Profile_State.Refused, label: stateToWord(Profile_State.Refused) },
                  ]}
                />
              </Field>
              <Input label="Titre" placeholder="Filtrer" value={filters.title!} onChange={(e: any) => setFilters('title', e.target.value)} />
              <Input label="Nom" placeholder="Filtrer" value={filters.name!} onChange={(e: any) => setFilters('name', e.target.value)} />
              <Field>
                <Select
                  emptyLabel="Role"
                  onChange={e => setFilters('role', e.target.value)}
                  value={filters.role!}
                  options={[
                    { value: Role.Esn, label: 'ESN' },
                    { value: Role.Freelance, label: 'Freelance' },
                    { value: Role.Enterprise, label: 'Entreprise' },
                  ]}
                />
              </Field>
            </FormRow>
          </FormZone>
          <FormZone>
            <FormRow>
              <Field>
                <Input label="TJM Max" placeholder="Filtrer" value={filters.tjmMax!} onChange={(e: any) => setFilters('tjmMax', e.target.value)} />
              </Field>
              <Field>
                <Input label="Localisation" placeholder="Filtrer" value={filters.postalCode!} onChange={(e: any) => setFilters('postalCode', e.target.value)} />
              </Field>
              <Field>
                <Input label="Disponibilité" placeholder="Filtrer" value={filters.availability!} onChange={(e: any) => setFilters('availability', e.target.value)} />
              </Field>
            </FormRow>
          </FormZone>
          <FormZone>
            <FormRow>
              <Field>
                <Input label="Compétences (OU)" placeholder={'Utiliser la "," pour plusieurs'} value={(filters.skillsOr || []).join(',')} onChange={(e: any) => setFilters('skillsOr', (e.target.value || '').split(','))} />
              </Field>
              <Field>
                <Input label="Compétences (ET)" placeholder={'Utiliser la "," pour plusieurs'} value={(filters.skillsAnd || []).join(',')} onChange={(e: any) => setFilters('skillsAnd', (e.target.value || '').split(','))} />
              </Field>
            </FormRow>
          </FormZone>
          <FormZone>
            <FormRow>
              <div style={{ textAlign: 'right' }}>
                <SearchByMissionModal ref={searchByMiddionModalRef} isLoading={matchMissionResponse.loading} onSubmit={(title, description) => matchMissionsToGetProfilesPrestataires(description, title)} />
              </div>
            </FormRow>
          </FormZone>
        </div>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <>
            <Table<TableData> columns={[
              {
                Header: 'État',
                accessor: row => <State state={row.state} />,
                width: 10,
              },
              {
                Header: 'Titre & Nom',
                accessor: row => (
                  <div>
                    <Link to={`/admin/prestataire/${row.id}`}>
                      <p>{row.title ?? <OuiOuNonIcon value={false} />}</p>
                      <p>{row.name}</p>
                    </Link>
                  </div>
                ),
                width: 15,
              },
              {
                Header: 'TJM',
                accessor: row => row.tjm === null ? <OuiOuNonIcon value={false} /> : `${row.tjm} €`,
                width: 5,
              },
              {
                Header: 'Code postal',
                accessor: row => row.postalCode === null ? <OuiOuNonIcon value={false} /> : row.postalCode,
                width: 5,
              },
              {
                Header: 'Role',
                accessor: row => <RoleLabel role={row.role} />,
                width: 10,
              },
              {
                Header: 'Disponibilité',
                accessor: (row) => row.available === null ? <OuiOuNonIcon value={false} /> : availabilityToText(row.available, row.availabilityInMonths),
                width: 10,
              },
              {
                Header: 'Inscription',
                accessor: (row) => format.format(new Date(row.signupDate)),
                width: 10,
              },
              {
                Header: 'Actions',
                accessor: row => {
                  const actions = [];
                  const id = `${row.id}-editppfreelance`;
                  const redirectRoute = row.role === Role.Freelance ? `/admin/prestataires/freelance/edit/${row.id}` : `/admin/prestataires/enterprise/${row.userId}/${row.id}`;
                  actions.push(
                    <span key={id}>
                      <IconButton data-tip data-for={id} onClick={() => {
                        history.push(redirectRoute);
                      }}>
                        <CgUserList size={25} />
                      </IconButton>
                      <ReactTooltip id={id} place="top" effect="solid">
                        Editer le profil prestataire
                      </ReactTooltip>
                    </span>
                  );
                  const deleteId = `${row.id}-delete`;
                  actions.push(
                    <span key={deleteId}>
                      <IconButton data-tip data-for={deleteId} onClick={() => {
                        if (row.role === Role.Freelance) {
                          // eslint-disable-next-line no-restricted-globals
                          const result = confirm(`Vous êtes sur le point de supprimer un profile Freelance, cela supprimera automatiquement l'utilisateur associé ainsi que son compte. Êtes vous sûr ?`);
                          if (result) {
                            deleteUser({
                              variables: {
                                id: row.userId,
                              }
                            });
                          }
                        } else {
                          // eslint-disable-next-line no-restricted-globals
                          const result = confirm(`Vous êtes sur le point de supprimer le profile d'un collaborateur, cela supprimera automatiquement toutes ses missions associées.`);
                          if (result) {
                            deleteProfilePrestataire({
                              variables: {
                                id: row.id,
                              }
                            });
                          }
                        }
                      }}>
                        <BsTrash size={25} />
                      </IconButton>
                      <ReactTooltip id={deleteId} place="top" effect="solid">
                        Supprimer le profil prestataire
                      </ReactTooltip>
                    </span>
                  );

                  return actions;
                },
                width: 5,
              },
            ]} data={data} />
            <Pagination current={filters.page} pageSize={paginationData.pageSize} total={paginationData.total} onClick={page => setFilters('page', page)} />
          </>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
