import { useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';
import $ from './CraAgenda.module.scss';
import daysOff from './DaysOff';
import { Checkbox } from '../Form/Checkbox/Checkbox';
import { useEffect } from 'react';

const dayToLetter = (day: Date) => {
  return ["D", "L", "M", "M", "J", "V", "S"][day.getDay()];
};

const isWeekEnd = (day: Date) => {
  const letter = dayToLetter(day);
  return letter === "S" || letter === "D";
};

const isFerie = (day: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const formatted = formatter.format(day);
  return daysOff.includes(formatted);
};

type DayInformation = {
  value: number;
  date: Date;
  disabled: boolean;
};
export type CraAgendaProps = {
  month: number;
  year: number;
  data: DayInformation[];
  onValueChange: (day: Date, value: number) => void;
  enforceDisabled?: boolean;
};
export const CraAgenda = ({
  month,
  year,
  data,
  onValueChange,
  enforceDisabled,
}: CraAgendaProps) => {
  const daysArray = useMemo(() => {
    const daysCount = new Date(year, month, 0).getDate();
    const dates = [...new Array(daysCount)].map((_, i) => new Date(Date.UTC(year, month - 1, i + 1)));
    const res: Date[][] = [];
    for (let i = 0; i < dates.length; i += 7) {
      const chunk = dates.slice(i, i + 7);
      res.push(chunk);
    }
    return res;
  }, [month, year]);

  const refs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    data.forEach(day => {
      const ref = refs.current[day.date.getDate()];
      if (ref) {
        if (day.value === 0) {
          ref.checked = false;
          ref.indeterminate = false;
        } else if (day.value === 0.5) {
          ref.checked = false;
          ref.indeterminate = true;
        } else if (day.value === 1) {
          ref.checked = true;
          ref.indeterminate = false;
        }

        if (day.disabled || enforceDisabled) {
          ref.disabled = true;
        } else {
          ref.disabled = false;
        }
      }
    });
  }, [refs.current.length, data, enforceDisabled]);

  const days = useMemo(() => data.reduce((count, day) => count + day.value, 0), [data]);

  const saveRef = useCallback((ref: HTMLInputElement | null) => {
    if (ref) {
      const day = new Date(ref.value);
      refs.current[day.getDate()] = ref;
      refs.current = [...refs.current];
    }
  }, [refs]);

  const onClick = useCallback((ev: React.MouseEvent<any, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if ((ev.target as any).disabled) {
      return;
    }

    const day = new Date((ev.target as any).value);
    const input = refs.current[day.getDate()]!;
    setTimeout(() => {
      let value = 0;
      if (input.checked) {
        value = 0.5;
      } else if (input.indeterminate) {
        value = 0;
      } else {
        value = 1;
      }

      onValueChange(day, value);
    }, 0);
  }, [onValueChange]);

  return (
    <div className={classnames($.CraAgenda)}>
      <p>Compte rendu d'activité du <b>{`${month}`.padStart(2, '0')}/{year}</b>:</p>
      <div>
        <p>Vous avez travaillé : <b>{days} jour{days > 1 ? 's' : ''}</b>.</p>
      </div>
      <div className={$.Legend}>
        <div>Légende</div>
        <div style={{ display: 'flex' }}>
          <Checkbox name="legenddisabled" onClick={e => e.preventDefault()} children="non éditable" ref={(node) => {
            if (node) {
              node.disabled = true;
            }
          }} />
          <Checkbox name="legend0" onClick={e => e.preventDefault()} children="0 jour travaillé" ref={(node) => {
            if (node) {
              node.indeterminate = false;
            }
          }} />
          <Checkbox name="legend05" onClick={e => e.preventDefault()} children="0.5 jour travaillé" ref={(node) => {
            if (node) {
              setTimeout(() => {
                node.checked = false;
                node.indeterminate = true;
              }, 0);
            }
          }} />
          <Checkbox name="legend1" onClick={e => e.preventDefault()} children="1 jour travaillé" ref={(node) => {
            if (node) {
              node.checked = true;
            }
          }} />
        </div>
      </div>
      <div className={$.Weeks}>
        {daysArray.map((dates, i) => {
          return (
            <div className={$.Week} key={i}>
              <div className={$.WeekTitle}>S{i + 1}</div>
              <div className={$.Days}>
                {dates.map((date) => {
                  return (
                    <div className={classnames($.Day, { [$.Weekend]: isWeekEnd(date), [$.Ferie]: isFerie(date) })} key={date.toISOString()}>
                      <div className={$.DayNumber}>{date.getDate()}</div>
                      <div className={$.DayLetter}>{dayToLetter(date)}</div>
                      <div className={$.DayCheckbox}>
                        <Checkbox small onClick={onClick} value={date.toISOString()} ref={saveRef} children={""} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
