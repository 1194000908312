
import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTypedController } from '@hookform/strictly-typed';
import { Zone, ZoneContent, WarningZone } from '../../layout/PrincipalLayout/PrincipalLayout';
import { translate, translateRich } from '../../../translate';
import { Company_Area2, Company_Size2 } from '../../../generated/graphql';
import { Field } from '../../../components/Form/Field/Field';
import { Input } from '../../../components/Form/Input/Input';
import { Button } from '../../../components/Button/Button';
import { useForm, useWatch } from 'react-hook-form';
import { Checkbox } from '../../../components/Form/Checkbox/Checkbox';
import { Select } from '../../../components/Form/Select/Select';
import { useFieldAndInput } from '../../../components/Form/FieldAndInput/FieldAndInput';
import { FormRow, FormZone } from '../../layout/PrincipalLayout/FormLayout';

export type ProfileFormValues = {
  billingContactEmail: string;
  billingContactFirstName: string;
  billingContactLastName: string;
  billingContactJobTitle: string;
  billingContactPhone: string;
  companyName: string;
  companyCompanyInCreation: boolean;
  companySiret: string;
  companySize: Company_Size2;
  companyArea: Company_Area2;
  companyAddress1: string;
  companyAddress2: string;
  companyPostalCode: string;
  companyCity: string;
  userProfileEmail: string;
  userProfileFirstName: string;
  userProfileLastName: string;
  userProfileJobTitle: string;
  userProfilePhone: string;
};

export type RefProfileForm = { reset: (values?: ProfileFormValues) => void };
export type ProfileFormProps = {
  onSubmit: (profileFormValues: ProfileFormValues) => void;
  defaultProfileFormValues: Partial<ProfileFormValues>;
  isSaving: boolean;
  shouldDisplayProfileIncompleteWarning?: boolean;
  impersonateLabels?: boolean; // This props is used to transform every translated labels to an impersonated version (for admin purpose)
};
export const ProfileForm = React.forwardRef<RefProfileForm, ProfileFormProps>(({ impersonateLabels, defaultProfileFormValues, onSubmit, isSaving, shouldDisplayProfileIncompleteWarning }: ProfileFormProps, ref) => {
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const { register, handleSubmit, errors, reset, control } = useForm<ProfileFormValues>({
    defaultValues: {
      companyCompanyInCreation: false,
      companySize: '' as any,
    }
  });
  const TypedController = useTypedController<ProfileFormValues>({ control });
  const FieldAndInput = useFieldAndInput<ProfileFormValues>({ register, errors });
  const imperativeHandle = useMemo(() => ({ reset }), [reset]);
  useImperativeHandle(ref, () => imperativeHandle);

  useEffect(() => {
    if (defaultProfileFormValues && !didLoad) {
      reset(defaultProfileFormValues);
      setDidLoad(true);
    }
  }, [defaultProfileFormValues, didLoad, reset]);

  const companyInCreationValue = useWatch<boolean>({
    control,
    name: 'companyCompanyInCreation',
  });

  const billingContactSameAsProfileValue = useWatch<boolean>({
    control,
    name: 'billingContactSameAsProfile',
  });

  const { userProfileEmail, userProfileFirstName, userProfileJobTitle, userProfileLastName, userProfilePhone } = useWatch<ProfileFormValues>({
    control,
    name: ['userProfileEmail', 'userProfileFirstName', 'userProfileLastName', 'userProfileJobTitle', 'userProfilePhone'],
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {shouldDisplayProfileIncompleteWarning && (
        <WarningZone title="Profil incomplet">
          Votre profil est incomplet, veuillez le remplir afin de valider votre compte et d'accéder à la plateforme.
        </WarningZone>
      )}
      <Zone
        title={impersonateLabels ? translate('admin_myProfile') : translate('myProfile')}
        description={impersonateLabels ? translateRich('admin_myProfileDescription') : translateRich('myProfileDescription')}>
        <ZoneContent>
          <FormZone>
            <FormRow>
              <FieldAndInput
                name="userProfileFirstName"
                registerOptions={{ required: true }}
                labelKey="userProfileFirstName"
                errorKey="errorUserProfileFirstName"
              />
              <FieldAndInput
                name="userProfileLastName"
                registerOptions={{ required: true }}
                labelKey="userProfileLastName"
                errorKey="errorUserProfileLastName"
              />
            </FormRow>
            <FormRow>
              <FieldAndInput
                name="userProfileEmail"
                registerOptions={{ required: true }}
                labelKey="userProfileEmail"
                disabled={true}
              />
              <FieldAndInput
                name="userProfilePhone"
                registerOptions={{ required: true }}
                labelKey="userProfilePhone"
                errorKey="errorUserProfilePhone"
              />
            </FormRow>
            <FormRow>
              <FieldAndInput
                name="userProfileJobTitle"
                registerOptions={{ required: true }}
                labelKey="userProfileJobTitle"
                errorKey="errorUserProfileJobTitle"
              />
              <div />
            </FormRow>
          </FormZone>
        </ZoneContent>
      </Zone>
      <Zone title={impersonateLabels ? translate('admin_myCompany') : translate('myCompany')} description={impersonateLabels ? translateRich('admin_myCompanyDescription') : translateRich('myCompanyDescription')}>
        <ZoneContent>
          <FormZone>
            <FormRow>
              <Field>
                <TypedController
                  name="companyCompanyInCreation"
                  render={({ onChange, value, ...rest }) => (
                    <Checkbox
                      {...rest}
                      onChange={(e: any) => onChange(e.target.checked)}
                      checked={value}
                    >{translate('companyInCreation')}</Checkbox>
                  )}
                />
              </Field>
            </FormRow>
            <FormRow>
              <FieldAndInput
                name="companyName"
                registerOptions={{ required: true }}
                labelKey="companyName"
                errorKey="errorCompanyName"
              />
              <Field>
                {!companyInCreationValue && (
                  <Input disabled={companyInCreationValue} label={translate('companySiret') + ' *'} errored={!!errors.companySiret} ref={register({ required: true })} name="companySiret"></Input>
                )}
                {companyInCreationValue && (
                  <Input disabled={true} label={translate('companySiret')} value="EN COURS"></Input>
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field>
                <Select
                  name="companySize"
                  ref={register({ required: true, validate: value => value !== '' })}
                  errored={!!errors.companySize}
                  emptyLabel={translate('companySize') + '*'}
                  options={[
                    { value: 's200', label: translate('companySize200') },
                    { value: 's500', label: translate('companySize500') },
                    { value: 's1000', label: translate('companySize1000') },
                    { value: 's1001', label: translate('companySize1001') },
                  ]}
                />
              </Field>
              <Field>
                <Select
                  name="companyArea"
                  ref={register({ required: true, validate: value => value !== '' })}
                  errored={!!errors.companyArea}
                  emptyLabel={translate('companyArea') + '*'}
                  options={[
                    { value: 'banqueAssurance', label: translate('companyAreaBanqueAssurance') },
                    { value: 'btp', label: translate('companyAreaBtp') },
                    { value: 'chimiePharmacie', label: translate('companyAreaChimiePharmacie') },
                    { value: 'commerceDistribution', label: translate('companyAreaCommerceDistribution') },
                    { value: 'editionCommunication', label: translate('companyAreaEditionCommunication') },
                    { value: 'electroniqueIndustrie', label: translate('companyAreaElectroniqueIndustrie') },
                    { value: 'industrie', label: translate('companyAreaIndustrie') },
                    { value: 'informatiqueTelecoms', label: translate('companyAreaInformatiqueTelecoms') },
                    { value: 'santeSocial', label: translate('companyAreaSanteSocial') },
                    { value: 'servicePublic', label: translate('companyAreaServicePublic') },
                    { value: 'servicesAuxEntreprisesBToB', label: translate('companyAreaServicesAuxEntreprisesBToB') },
                    { value: 'modeLuxe', label: translate('companyAreaModeLuxe') },
                    { value: 'transportsLogistique', label: translate('companyAreaTransportsLogistique') },
                    { value: 'autre', label: translate('companyAreaAutre') },
                  ]}
                />
              </Field>
            </FormRow>
            <FormRow>
              <Field>
                <Input label={translate('companyAddress1') + '*'} errored={!!errors.companyAddress1} ref={register({ required: true })} name="companyAddress1"></Input>
              </Field>
              <Field>
                <Input label={translate('companyAddress2')} ref={register()} name="companyAddress2"></Input>
              </Field>
            </FormRow>
            <FormRow>
              <Field>
                <Input label={translate('companyPostalCode') + '*'} errored={!!errors.companyPostalCode} ref={register({ required: true })} name="companyPostalCode"></Input>
              </Field>
              <Field>
                <Input label={translate('companyCity') + '*'} errored={!!errors.companyCity} ref={register({ required: true })} name="companyCity"></Input>
              </Field>
            </FormRow>
          </FormZone>
        </ZoneContent>
      </Zone>
      <Zone title={impersonateLabels ? translate("admin_billingContactTitle") : translate("billingContactTitle")} description={impersonateLabels ? translate("admin_billingContactDescription") : translate("billingContactDescription")}>
        <ZoneContent>
          <FormZone>
            {billingContactSameAsProfileValue ? (
              <>
                <FormRow>
                  <Field>
                    <Input
                      disabled={true}
                      value={userProfileFirstName}
                      label={translate('billingContactFirstName')} />
                  </Field>
                  <Field>
                    <Input
                      disabled={true}
                      value={userProfileLastName}
                      label={translate('billingContactLastName')} />
                  </Field>
                </FormRow>
                <FormRow>
                  <Field>
                    <Input
                      disabled={true}
                      value={userProfileEmail}
                      label={translate('billingContactEmail')} />
                  </Field>
                  <Field>
                    <Input
                      disabled={true}
                      value={userProfilePhone}
                      label={translate('billingContactPhone')} />
                  </Field>
                </FormRow>
                <FormRow>
                  <Field>
                    <Input
                      disabled={true}
                      value={userProfileJobTitle}
                      label={translate('billingContactJobTitle')} />
                  </Field>
                  <div />
                </FormRow>
              </>
            ) : (
              <>
                <FormRow>
                  <Field>
                    <Input
                      label={translate('billingContactFirstName') + '*'}
                      errored={!!errors.billingContactFirstName}
                      ref={register({ required: true })}
                      name="billingContactFirstName" />
                  </Field>
                  <Field>
                    <Input
                      label={translate('billingContactLastName') + '*'}
                      errored={!!errors.billingContactLastName}
                      ref={register({ required: true })}
                      name="billingContactLastName" />
                  </Field>
                </FormRow>
                <FormRow>
                  <Field>
                    <Input
                      label={translate('billingContactEmail') + '*'}
                      errored={!!errors.billingContactEmail}
                      ref={register({ required: true })}
                      name="billingContactEmail" />
                  </Field>
                  <Field>
                    <Input
                      label={translate('billingContactPhone') + '*'}
                      errored={!!errors.billingContactPhone}
                      ref={register({ required: true })}
                      name="billingContactPhone" />
                  </Field>
                </FormRow>
                <FormRow>
                  <Field>
                    <Input
                      label={translate('billingContactJobTitle') + '*'}
                      errored={!!errors.billingContactJobTitle}
                      ref={register({ required: true })}
                      name="billingContactJobTitle" />
                  </Field>
                  <div />
                </FormRow>
              </>
            )}
          </FormZone>
        </ZoneContent>
      </Zone>
      <Zone hollow={true}>
        <Field align="right">
          <Button isLoading={isSaving} type="submit">{translate('save')}</Button>
        </Field>
      </Zone>
    </form >
  );
});
