import React from 'react';
import classnames from 'classnames';
import { Column, useTable, useFilters, UseFiltersInstanceProps } from 'react-table';
import $ from './Table.module.scss';

declare module 'react-table' {
  interface TableInstance<D extends object> extends UseFiltersInstanceProps<D> { }
}

export type TableProps<Data extends object> = {
  columns: Column<Data>[];
  data: Data[];
};
export const Table = <Data extends object,>({ data, columns }: TableProps<Data>) => {
  const memoizedData = React.useMemo(() => data, [data]);
  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const tableInstance = useTable({ columns: memoizedColumns, data: memoizedData }, useFilters);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className={$.Table}>
      <div className={$.TableContainer}>
        <div className={$.TableHead}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className={$.head}>
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps()}
                      className={classnames($['Column-' + column.width], { [$['Column-first']]: index === 0 })}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
        </div>
        <div className={$.TableBody}>
          <table {...getTableProps()}>
            <tbody  {...getTableBodyProps()}>

              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={$.body}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classnames($['Column-' + cell.column.width], { [$['Column-first']]: index === 0 })}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
