import React, { useState } from 'react';
import $ from './Forgot.module.scss';
import { ForgotPanel } from '../components/AuthPanel/ForgotPanel';
import { AuthLayout } from '../../layout/AuthLayout/AuthLayout';
import { useHistory } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../../generated/graphql';
import { useNotification } from '../../../hooks/notifications';

const Forgot = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [forgotPassword] = useForgotPasswordMutation();
  const { addNotification } = useNotification();

  const Forgot = (email: string) => {
    setError(undefined);
    setIsSubmitting(true);
    setTimeout(async () => {
      try {
        await forgotPassword({
          variables: {
            email,
          },
        });
      } catch (error) {
        const { code } = error;
        if (code === 'auth/wrong-password') {
          setError('Mot de passe invalide.');
        } else if (code === 'auth/user-not-found') {
          setError('Ce compte n\'existe pas.');
        } else {
          setError('Une erreur est survenue. Veuillez réessayer.')
        }

        setIsSubmitting(false);
      }

      addNotification(false, 'Un email vous a été envoyé.');
      history.push('/');
    }, 1000);
  };

  return (
    <AuthLayout className={$.Forgot}>
      <ForgotPanel
        error={error}
        isLoading={isSubmitting}
        onSubmit={Forgot}
      />
    </AuthLayout>
  );
};

export default Forgot;
