import React from 'react';
import { Field, FieldError } from '../Field/Field';
import { Input, InputProps } from '../Input/Input';
import { translate, Translations } from '../../../translate';
import { FieldElement, FieldErrors, Ref, RegisterOptions } from 'react-hook-form';

export type FieldAndInputProps<TFieldValues> = Omit<InputProps, 'placeholder' | 'name' | 'label'> & {
  name: keyof TFieldValues;
  placeholderKey?: Translations
  placeholder?: string;
  registerOptions: RegisterOptions;
  errorKey?: Translations;
  labelKey?: Translations;
  label?: string;
  errors: FieldErrors<TFieldValues>;
  register<TFieldElement extends FieldElement<any>>(rules?: RegisterOptions): (ref: (TFieldElement & Ref) | null) => void;
};
export const FieldAndInput = <TFieldValues extends {} = {},>({ name, placeholder: placeholderStr, labelKey, label: labelStr, placeholderKey, errors, register, errorKey, registerOptions, displayLabelOnlyWhenFilled, ...props }: FieldAndInputProps<TFieldValues>) => {
  let label = labelKey !== undefined ? translate(labelKey) : labelStr!;
  if (registerOptions && registerOptions.required) {
    label = `${label} *`;
  }

  let placeholder = placeholderKey !== undefined ? translate(placeholderKey) : placeholderStr || '';
  if (registerOptions && registerOptions.required && placeholder && displayLabelOnlyWhenFilled) {
    placeholder = `${placeholder} *`;
  }

  return (
    <Field>
      <Input {...props} label={label} placeholder={placeholder} errored={!!errors[name]} ref={register(registerOptions)} name={name as string}></Input>
      {errorKey && errors[name] && <FieldError>{translate(errorKey)}</FieldError>}
    </Field>
  );
};

type UseFieldAndInputOptions<TFieldValues> = {
  register<TFieldElement extends FieldElement<TFieldValues>>(rules?: RegisterOptions): (ref: (TFieldElement & Ref) | null) => void;
  errors: FieldErrors<TFieldValues>;
}
export const useFieldAndInput = <TFieldValues,>(options: UseFieldAndInputOptions<TFieldValues>) => {
  const errorsRef = React.useRef(options.errors);
  errorsRef.current = options.errors;
  const registerRef = React.useRef(options.register);
  registerRef.current = options.register;

  const TypedFieldAndInput = React.useCallback(
    (props: Omit<FieldAndInputProps<TFieldValues>, 'errors' | 'register'>) => (
      <FieldAndInput<TFieldValues> {...props} errors={options.errors} register={options.register} />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return TypedFieldAndInput;
};
