
import React, { useMemo } from 'react';
import { Content, PrincipalLayoutBox, SubTitle, Title, Zone, ZoneContent, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
// import $ from './AdminMissionPreview.module.scss';
import { useAdminDashboardQuery } from '../../../generated/graphql';
import { Link } from 'react-router-dom';

type DataLabelProps = { to: string, label: string; children: string | number };
const DataLabel = (props: DataLabelProps) => (
  <div style={{ marginLeft: '10px', height: '180px', width: '180px', borderRadius: '15px', background: 'var(--primary-color)', color: 'white', fontSize: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px 20px' }}>
    <Link to={props.to} style={{ color: 'white', textDecoration: 'none', fontSize: '25px' }}>
      <p style={{ margin: '0', marginBottom: '20px', padding: '0' }}>{props.label}</p>
    </Link>
    {props.children}
  </div>
);
const displayValue = (value: number | null | undefined) => typeof value === 'number' ? value : '?';
export const AdminDashboard = () => {
  const dashboard = useAdminDashboardQuery();

  const isLoading = useMemo(() => {
    return dashboard.loading && !!dashboard.data
  }, [dashboard.data, dashboard.loading]);

  const data = dashboard.data?.dashboard;

  return (
    <PrincipalLayoutBox fullWidth={true}>
      <Title>Administration</Title>
      <SubTitle>Dashboard</SubTitle>
      <Content>
        {isLoading && <ZoneLoadingState />}
        {!isLoading && (
          <Zone>
            <ZoneContent title="Prestataires">
              <DataLabel to="/admin/prestataires?filters={%22state%22:%22%22}" label="Total">{displayValue(data?.totalProfilesPrestataires)}</DataLabel>
              <DataLabel to="/admin/prestataires?filters={%22state%22:%22waiting%22}" label="Attente">{displayValue(data?.waitingValidationProfilesPrestataires)}</DataLabel>
              <DataLabel to="/admin/prestataires?filters={%22state%22:%22validated%22}" label="Validés">{displayValue(data?.validatedProfilesPrestataires)}</DataLabel>
              <DataLabel to="/admin/prestataires?filters={%22state%22:%22incomplete%22}" label="Incomplets">{displayValue(data?.incompleteProfilesPrestataires)}</DataLabel>
              <DataLabel to="/admin/prestataires?filters={%22state%22:%22refused%22}" label="Refusés">{displayValue(data?.refusedProfilesPrestataires)}</DataLabel>
            </ZoneContent>
            <ZoneContent title="Missions">
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22%22}" label="Total">{displayValue(data?.totalMissions)}</DataLabel>
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22WAITING_VALIDATION%22}" label="Attente">{displayValue(data?.waitingValidationMissions)}</DataLabel>
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22VALIDATED%22}" label="Validées">{displayValue(data?.validatedMissions)}</DataLabel>
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22REFUSED%22}" label="Refusées">{displayValue(data?.refusedMissions)}</DataLabel>
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22STARTED%22}" label="Démarrées">{displayValue(data?.startedMissions)}</DataLabel>
              <DataLabel to="/admin/missions?filters={%22stateFilterValue%22:%22%22}" label="Terminées">{displayValue(data?.endedMissions)}</DataLabel>
            </ZoneContent>
          </Zone>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
