import React, { useImperativeHandle, useMemo } from 'react';
import $ from './Modal.module.scss';
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogBackdrop,
} from "reakit/Dialog";
import { Button } from '../Button/Button';
import classnames from 'classnames';

export type RefModal = { close: () => void };
export type ModalProps = {
  children: React.ReactNode;
  title: string;
  description: string;
  buttonLabel?: string;
  disclosure?: any;
  full?: boolean;
};
export const Modal = React.forwardRef<RefModal, ModalProps>(({ buttonLabel, disclosure, children, title, description, full }: ModalProps, ref) => {
  const dialog = useDialogState();
  const imperativeHandle = useMemo(() => ({ close: () => dialog.setVisible(false) }), [dialog]);
  useImperativeHandle(ref, () => imperativeHandle);

  return (
    <>
      {
        disclosure ? (
          <DialogDisclosure {...dialog} ref={disclosure.ref} {...disclosure.props}>{(props) => React.cloneElement(disclosure, props)}</DialogDisclosure>
        ) : (
          <DialogDisclosure {...dialog} as={Button}>{buttonLabel}</DialogDisclosure>
        )
      }
      <DialogBackdrop {...dialog} className={$.ModalBackdrop}>
        <Dialog aria-label={title} {...dialog} className={classnames($.ModalContent, { [$.ModalFull]: full })}>
          <div className={$.ModalTitle}>{title}</div>
          <div className={$.ModalDescription}>{description}</div>
          {children}
        </Dialog>
      </DialogBackdrop>
    </>
  );
});