
import classnames from 'classnames';
import { Content, SubTitle, Title, Zone, PrincipalLayoutBox, ZoneContent } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './Home.module.scss';
import { useUserType } from '../../../hooks/auth';
import { translate } from '../../../translate';

export const Home = () => {
  const { FreelanceContainer, ClientContainer, ESNContainer, EnterpriseContainer, isEnterprise } = useUserType();
  return (
    <PrincipalLayoutBox className={classnames($.Home)}>
      <Title>{translate('homeTitle')}</Title>
      <SubTitle>{translate('homeSubTitle')}</SubTitle>
      <Content>
        <FreelanceContainer>
          <Zone title={translate('homeThankYouTitle')} >
            <ZoneContent>
              {translate('homeThankYouDescription')}
            </ZoneContent>
          </Zone>
        </FreelanceContainer>
        {!isEnterprise && (
          <ClientContainer>
            <Zone>
              Bienvenu sur MyDataSpecialist, votre compte est correctement configuré. Nous vous contacterons lorsque votre compte sera approuvé.
            </Zone>
          </ClientContainer>
        )}
        {!isEnterprise && (
          <ESNContainer>
            <Zone>
              Bienvenu sur MyDataSpecialist, votre compte est correctement configuré. Nous vous contacterons lorsque votre compte sera approuvé.
            </Zone>
          </ESNContainer>
        )}
        <EnterpriseContainer>
          <Zone>
            Bienvenu sur MyDataSpecialist, votre compte est correctement configuré. Nous vous contacterons lorsque votre compte sera approuvé.
            </Zone>
        </EnterpriseContainer>
      </Content>
    </PrincipalLayoutBox>
  );
};
