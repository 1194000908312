import React, { useMemo } from 'react';
import classnames from 'classnames';
import $ from './Pagination.module.scss';

export type PaginationProps = {
  total: number;
  pageSize: number;
  current: number;
  onClick: (page: number) => void;
};
export const Pagination = ({ total = 0, pageSize, current, onClick }: PaginationProps) => {
  const {
    totalArray,
    totalPages,
  } = useMemo(() => {
    const arr = [...new Array(Math.ceil(total / pageSize))];
    return {
      totalPages: arr.length,
      totalArray: arr.map((_, i) => {
        let isLastOrFirst = i <= 1 || i >= arr.length - 2;
        if (isLastOrFirst) {
          return { page: i, skip: false };
        }

        if (arr.length > 6) {
          if (current === i) {
            return { page: i, skip: false };
          }

          return { page: i, skip: true };
        }

        return { page: i, skip: false };
      }).filter((page, index, arr) => {
        if (!page.skip) {
          return true;
        }

        if (arr[index - 1].skip && page.skip) {
          return false;
        }

        return true;
      })
    };
  }, [total, pageSize, current]);

  return (
    <div className={$.Pagination}>
      <div className={classnames($.Total)}>Total de {total} élément{total === 0 || total === 1 ? '' : 's'}</div>
      {current === 0 ? (
        <div className={classnames($.Page)}>
          {'<'}
        </div>
      ) : (
        <div className={classnames($.Page)} role="button" onClick={() => onClick(current - 1)}>
          {'<'}
        </div>
      )}
      {totalArray.map((page) => <div key={page.page} className={classnames($.Page, { [$.Current]: page.page === current })} onClick={() => !page.skip && onClick(page.page)}>
        {page.skip ? (
          <span>...</span>
        ) : (
          page.page + 1
        )}
      </div>)}
      {current === totalPages - 1 ? (
        <div className={classnames($.Page)}>
          {'>'}
        </div>
      ) : (
        <div className={classnames($.Page)} onClick={() => onClick(current + 1)}>
          {'>'}
        </div>
      )}
    </div>
  );
};
