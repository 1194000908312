import React from 'react';
import classnames from 'classnames';
import { Input as ReakitInput, InputProps as ReakitInputProps } from 'reakit/Input';
import $ from './Input.module.scss';

export type InputProps = ReakitInputProps & { errored?: boolean, label: string; displayLabelOnlyWhenFilled?: boolean; };
export const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, errored, placeholder, displayLabelOnlyWhenFilled = false, label, ...props }, ref) => {
  return (
    <div className={classnames($.InputContainer, { [$.InputFilled]: !displayLabelOnlyWhenFilled })}>
      { !!label && (
        <div className={$.InputLabel}>
          <span>{label}</span>
        </div>
      )}
      <ReakitInput {...props} placeholder={placeholder} ref={ref} className={classnames(className, $.Input, { [$.Disabled]: props.disabled, [$.Errored]: errored })} />
    </div>
  );
});
