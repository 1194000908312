import React from 'react';
import classnames from 'classnames';
import $ from './AuthPanel.module.scss';


export type AuthPanelProps = {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  children: React.ReactNode;
};
export const AuthPanel = ({ title, subTitle, children }: AuthPanelProps) => (
  <div className={classnames($.AuthPanel)}>
    <h1>{title}</h1>
    <h2>{subTitle}</h2>
    <div className={$.Content}>{children}</div>
  </div>
);
