import React from 'react';
import { BlankLink, Link, MailTo } from './components/Link/Link';

type ToString<T extends string> = `${T}`;
type EventName<T extends string, B extends string> = `${ToString<B>}${T}`;
type PrefixedTranslations<T, B extends string> = {
  [K in keyof T & string as EventName<K, B>]: string;
};
const prefix = <T extends Record<string, string>, B extends string,>(translations: T, prefix: B) => {
  const result: PrefixedTranslations<T, B> = Object.keys(translations).reduce((acc, key) => {
    const newKey = `${prefix}${key}`;
    (acc as Record<string, string>)[newKey] = translations[key];
    return acc;
  }, {} as PrefixedTranslations<T, B>);

  return {
    translations: result,
    translate: (key: keyof T) => translations[key],
  }
};

export const prestatairesListingTranslations = prefix({
  Title: 'Prestataires',
}, 'profilePrestataire');

const profilePrestataireTranslations = prefix({
  MaximumDistance: 'Je peux me déplacer jusqu\'à',
  MinimumMonths: 'Durée minimum de la mission',
  Title: 'Titre de votre profil',
  TitleDescription: 'Ingenieur Data, spécialiste Kafka',
  Available: 'Disponible immédiatement',
  TJM: 'TJM',
  PostalCode: 'Votre code postal'
}, 'profilePrestataire');

const profileTranslations = {
  companyName: 'Raison sociale',
  companyInCreation: 'Entreprise en cours de création',
  companySiret: 'Numéro de siret',
  companySize: 'Nombre de salariés',
  companyArea: 'Secteur',
  companyAddress1: 'Adresse',
  companyAddress2: 'Complément d\'adresse',
  companyPostalCode: 'Code postal',
  companyCity: 'Ville',
  userProfileFirstName: 'Prénom',
  userProfileLastName: 'Nom',
  userProfileJobTitle: 'Poste',
  userProfileEmail: 'Adresse e-mail',
  userProfilePhone: 'Téléphone',
  billingContactSameAsProfile: 'Je suis le contact de facturation.',
  billingContactEmail: 'Adresse e-mail',
  billingContactFirstName: 'Prénom',
  billingContactLastName: 'Nom',
  billingContactJobTitle: 'Poste',
  billingContactPhone: 'Téléphone',
  companySize200: '1 - 200 salariés',
  companySize500: '200 - 500 salariés',
  companySize1000: '500 - 1000 salariés',
  companySize1001: '+ de 1000 salariés',
  companyAreaBanqueAssurance: 'Banque / Assurance',
  companyAreaBtp: 'BTP / Matériaux de construction',
  companyAreaChimiePharmacie: 'Chimie / Pharmacie',
  companyAreaCommerceDistribution: 'Commerce / Distribution',
  companyAreaEditionCommunication: 'Édition / Communication',
  companyAreaElectroniqueIndustrie: 'Électronique / Industrie',
  companyAreaIndustrie: 'Industrie',
  companyAreaInformatiqueTelecoms: 'Informatique / Télécoms',
  companyAreaSanteSocial: 'Santé / Social',
  companyAreaServicePublic: 'Service Public',
  companyAreaServicesAuxEntreprisesBToB: 'Services aux entreprises (B to B)',
  companyAreaModeLuxe: 'Mode / Luxe',
  companyAreaTransportsLogistique: 'Transports / Logistique',
  companyAreaAutre: 'Autre',

  errorUserProfileFirstName: 'Veuillez renseigner votre prénom',
  errorUserProfileLastName: 'Veuillez renseigner votre nom',
  errorUserProfileJobTitle: 'Veuillez renseigner le poste que vous occupez actuellement',
  errorUserProfilePhone: 'Veuillez renseigner un numéro de téléphone valide',

  errorCompanyName: 'Veuillez renseigner la raison sociale',
};

export const companyTranslations = prefix({
  Area: 'Secteur',
  AreaBanqueAssurance: 'Banque / Assurance',
  AreaBtp: 'BTP / Matériaux de construction',
  AreaChimiePharmacie: 'Chimie / Pharmacie',
  AreaCommerceDistribution: 'Commerce / Distribution',
  AreaEditionCommunication: 'Édition / Communication',
  AreaElectroniqueIndustrie: 'Électronique / Industrie',
  AreaIndustrie: 'Industrie',
  AreaInformatiqueTelecoms: 'Informatique / Télécoms',
  AreaSanteSocial: 'Santé / Social',
  AreaServicePublic: 'Service Public',
  AreaServicesAuxEntreprisesBToB: 'Services aux entreprises (B to B)',
  AreaModeLuxe: 'Mode / Luxe',
  AreaTransportsLogistique: 'Transports / Logistique',
  AreaAutre: 'Autre',
}, 'company');

const authSigninTranslations = prefix({
  Title: 'Bienvenue !',
  SubTitle: 'Pas encore de compte ?',
  LinkSignup: 'Inscrivez-vous.',
  ForgotPassword: 'Mot de passe oublié',
  SigninButton: 'Se connecter',
}, 'authSignin');

const authSignupTranslations = prefix({
  Title: 'Quelle est votre situation ?',
  SubTitle: 'Vous êtes déjà inscrit ?',
  LinkSignin: 'Connectez vous.',
  SignupButton: 'Je m\'inscris',
  SignupButtonForAdmin: 'Créer le compte'
}, 'authSignup');

const resetPasswordTranslations = prefix({
  Title: 'Modifiez votre mot de passe',
  SubTitle: 'Saisissez votre nouveau mot de passe pour vous connecter'
}, 'resetPassword');

const homeTranslations = prefix({
  Title: 'Accueil',
  SubTitle: 'Tableau de bord',
  ThankYouTitle: 'Bienvenue !',
  ThankYouDescription: `Nous vous contacterons dès qu'une mission sera disponible pour vous.`
}, 'home');

export const profilesPrestatairesEsnTranslations = prefix({
  Title: 'Collaborateurs',
  SubTitle: 'Mes collaborateurs'
}, 'profilesPrestatairesEsn');

export const profilePrestataireEsnTranslations = prefix({
  TitleNew: 'Ajouter un collaborateur',
  TitleExisting: 'Éditer un collaborateur',
  ProfilePrestataire: 'Profil prestataire',
  ProfilePrestataireDescription: 'Ces informations sont utilisées afin de trouver les meilleurs missions pour vos collaborateurs.',

  Form_FirstName: 'Prénom',
  Form_Error_FirstName: 'Vous devez renseigner un prénom.',
  Form_LastName: 'Nom',
  Form_Error_LastName: 'Vous devez renseigner un nom.',
  Form_Trigram: 'Trigramme',

  Form_Available: 'Ce collaborateur est-il disponible pour des missions ?',
  Form_AvailableAria: 'Disponible',
  Form_WhenAvailable: 'Dans combien de temps sera t-il disponible ?',
  Form_WhenAvailableAria: 'Disponibilité dans le temps',

  Form_MaximumDistance: 'Ce collaborateur peux me déplacer jusqu\'à',
  Form_MinimumMonths: 'Durée minimum de la mission',
  Form_Title: 'Titre du profil',
  Form_TitlePlaceholder: 'Ingenieur Data, spécialiste Kafka',
  Form_TJM: 'TJM',
  Form_PostalCode: 'Code postal',

  save: 'Sauvegarder',
}, 'profilePrestataireEsn');

export const menuTranslations = prefix({
  Home: 'Accueil',
  Profile: 'Profil',
  EsnProfiles: 'Collaborateurs',
  Help: 'Aide',
  Settings: 'Paramètres',
  ClientMissions: 'Missions',
  Prestataires: 'Prestataires'
}, 'menu');

export const missionsListingTranslations = prefix({
  Title: 'Mes missions',
  SubTitle: 'Listing',
  ListingToValidateTab: 'En cours de validation',
  ListingValidatedTab: 'Missions validées',
  ListingToStartedTab: 'Missions démarrées'
}, 'menu');

export const missionTranslations = prefix({
  TitleNew: 'Nouvelle mission',
  TitleExisting: 'Éditer une mission',
  TitleValidated: 'Mission validée',
  TitleStarted: 'Mission démarrée',

  Form_Title: 'Titre de la mission',
  Form_TJM: 'TJM',
  Form_PostalCode: 'Code postal',
  Form_StartDate: 'Date de démarrage',
  Form_EndDate: 'Date de fin',
  Form_NeedMoving: 'Déplacement',
  Form_Remote: 'Télétravail',
  Form_Description: 'Description de la mission (5000 caractères maximum)',
  Form_Meta: 'Reference & informations privées (Ce champ ne sera visible que par vous)',
  Form_Experience: 'Expériences du prestataire',
  SaveNew: 'Créer la mission',
  SaveExisting: 'Sauvegarder la mission',
}, 'menu');

const translations = {
  ...profilePrestataireTranslations.translations,
  ...profileTranslations,
  ...authSigninTranslations.translations,
  ...authSignupTranslations.translations,
  ...resetPasswordTranslations.translations,
  ...homeTranslations.translations,
  ...menuTranslations.translations,
  ...profilesPrestatairesEsnTranslations.translations,
  ...profilePrestataireEsnTranslations.translations,
  ...prestatairesListingTranslations.translations,
  prenom: 'Prénom',
  nom: 'Nom',
  email: 'Adresse e-mail',
  password: 'Mot de passe',
  cgu: 'J\'accepte les [CGU](https://mydataspecialist.com/politique-de-confidentialite/) et la [politique de protection des données](https://mydataspecialist.com/politique-de-confidentialite/) de MyDataSpecialist',
  Freelance: 'Freelance',
  ESN: 'ESN',
  errorLengthAndRequired: 'Veuillez renseigner ce champs, taille maximal de 250 caractères.',
  errorEmail: 'Format d\'email invalide.',
  company: 'Société',
  phone: 'Téléphone',
  notVerified: 'Votre compte n\'est pas vérifié.',
  checkEmailForVerified: 'Consultez votre boîte e-mail afin de valider votre adresse e-mail. Si vous ne recevez aucun email, consultez vos spams.',
  clickHereToGoToSignin: 'Cliquez ici pour retourner à la page de connexion',
  demandeOk: 'Votre demande a bien été prise en compte.',
  Profil: 'Mon profil',
  ProfilPrestataire: 'Mon profil prestataire',
  save: 'Sauvegarder',
  congrats: 'Félicitations',
  emailVerified: 'Votre adresse e-mail est désormais vérifiée.',
  myProfile: 'Mes informations',
  admin_myProfile: 'Informations',
  myProfileDescription: `Ces informations seront utilisées pour vous contacter. Elles n'apparaîtront nulle part ailleurs.`,
  admin_myProfileDescription: `Ces informations sont utilisés afin de contacter l'utilisateur`,
  myCompany: 'Mon entreprise',
  admin_myCompany: 'Entreprise liée à cet utilisateur',
  myCompanyDescription: `Ces informations sont nécessaires à l’édition des contrats. Elles n'apparaîtront nulle part ailleurs. \nVous êtes freelance sans structure ? Veuillez remplir "Freelance" dans le champs "Raison sociale"`,
  admin_myCompanyDescription: `Ces informations ne sont pas liées directement à l'utilisateur mais à l'entreprise qui lui est associée.`,
  myProfilePresta: 'Mon profil prestataire',
  myProfilePrestaDescription: `Ces informations seront utilisées afin de trouver les missions qui vous correspondent le mieux.`,
  billingContactTitle: 'Contact de facturation',
  admin_billingContactTitle: 'Contact de facturation lié à cet utilisateur',
  billingContactDescription: `Ces informations sont nécessaires à l’édition des factures. Elles n'apparaîtront nulle part ailleurs.`,
  admin_billingContactDescription: `Ces informations ne sont pas liées directement à l'utilisateur mais à l'entreprise qui lui est associée.`,
  Home: 'Accueil',
  Settings: 'Paramètres',
  Help: 'Aide',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  newPasswordRepeat: 'Répetez le nouveau mot de passe',
  valid: 'Valider',
  errorOldPassword: 'Vous devez renseigner votre ancien mot de passe.',
  settingsTitle: 'Changement de mot de passe',
  settingsDescription: `Veuillez remplir ce formulaire afin de changer votre mot de passe.
Si vous souhaitez changer votre adresse email, veuillez nous envoyer un email à [contact@mydataspecialist.com](mailto:contact@mydataspecialist.com).`
};
export type Translations = keyof typeof translations;
export const translate = (key: keyof typeof translations): string => translations[key];

export const translateRich = (key: keyof typeof translations): string | React.ReactNode[] => {
  const translation = translations[key];
  const regex = /\[.+?\]\(.+?\)/g;
  const matches = translation.match(regex) || [];
  const splitted = translation.split(regex);

  const aggregated = splitted.map((str, index) => {
    if (!matches[index]) {
      return [str];
    }

    const [rawText, rawHref] = matches[index].split('](');
    const text = rawText.slice(1);
    const href = rawHref.slice(0, rawHref.length - 1);
    if (href.indexOf('mailto:') === 0) {
      return [str, <MailTo key={href + text} href={href}>{text}</MailTo>];
    } else if (href.indexOf('https:') === 0) {
      return [str, <BlankLink key={href + text} href={href}>{text}</BlankLink>];
    }

    return [str, <Link key={href + text} to={href}>{text}</Link>];
  }).flat();

  return aggregated;
};
