import React from 'react';
import classnames from 'classnames';
import $ from './Select.module.scss';
import { ReactComponent as ArrowDownIcon } from '../../../assets/arrow-down.svg';

export type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> & {
  options: { value: string; label: string; }[];
  errored?: boolean;
  emptyLabel: string;
};
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(({ className, options, emptyLabel, errored, ...props }, ref) => (
  <div className={$.Select}>
    <div className={$.SelectLabel}>
      <span>{emptyLabel}</span>
    </div>
    <div className={$.SelectIcon}>
      <ArrowDownIcon />
    </div>
    <select {...props} ref={ref} className={classnames(className, $.SelectInput, { [$.Disabled]: props.disabled, [$.Errored]: errored })}>
      <option value="">---</option>
      {options.map(o => (<option key={o.value} value={o.value}>{o.label}</option>))}
    </select>
  </div>
));
