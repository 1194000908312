import React, { useState } from 'react';
import classnames from 'classnames';
import $ from './SignupPanel.module.scss';
import { AuthPanel } from './AuthPanel';
import { Link } from '../../../../components/Link/Link';
import { Tab, TabList, TabPanel } from '../../../../components/Form/Tab/Tab';
import { useRadioState, useTabState } from 'reakit';
import { translate } from '../../../../translate';
import { FreelanceSignupForm, FreelanceSignupFormSubmitValues } from '../FreelanceSignupForm/FreelanceSignupForm';
import { Field } from '../../../../components/Form/Field/Field';
import { Radio, RadioGroup } from '../../../../components/Form/Radio/Radio';
import { EsnSignupForm, EsnSignupFormSubmitValues } from '../EsnSignupForm/EsnSignupForm';
import { ClientSignupForm, ClientSignupFormSubmitValues } from '../ClientSignupForm/ClientSignupForm';

export type SignupPanelProps = {
  onSignupFreelance: (payload: FreelanceSignupFormSubmitValues) => Promise<any>;
  onSignupEsn: (payload: EsnSignupFormSubmitValues) => Promise<any>;
  onSignupClient: (payload: ClientSignupFormSubmitValues) => Promise<any>;
  error?: string;
};
export const SignupPanel = ({
  onSignupClient,
  onSignupEsn,
  onSignupFreelance,
  error,
}: SignupPanelProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState<'freelance' | 'esn'>('freelance');
  const tab = useTabState();
  const radio = useRadioState({ state: 'freelance' });

  const preSubmit = () => setIsSubmitting(true);
  const postSubmit = () => setIsSubmitting(false);

  return (
    <div className={classnames($.SignupPanel)}>
      <AuthPanel
        title={translate('authSignupTitle')}
        subTitle={<span>{translate('authSignupSubTitle') + ' '}<Link to="/signin">{translate('authSignupLinkSignin')}</Link></span>}
      >
        <div className={$.SignupPanelContent}>
          <TabList {...tab} aria-label="My tabs">
            <Tab {...tab} id="presta">Prestataire</Tab>
            <Tab {...tab} id="client">Client</Tab>
          </TabList>
          <TabPanel {...tab}>
            <>
              <Field>
                <RadioGroup {...radio} aria-label="type de compte" name="type" onChange={(e: any) => setType(e.target.value)}>
                  <Radio {...radio} value="freelance">{translate('Freelance')}</Radio>
                  <Radio {...radio} value="esn">{translate('ESN')}</Radio>
                </RadioGroup>
              </Field>
              {type === 'freelance' && <FreelanceSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupFreelance} />}
              {type === 'esn' && <EsnSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupEsn} />}
            </>
          </TabPanel>
          <TabPanel {...tab}>
            <ClientSignupForm error={error} preSubmit={preSubmit} postSubmit={postSubmit} isLoading={isSubmitting} onSubmit={onSignupClient} />
          </TabPanel>
        </div>
      </AuthPanel>
    </div>
  );
};
