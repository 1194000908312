
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Tabs, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './FreelanceMissionsListing.module.scss';
import { useMyMissionsQuery } from '../../../generated/graphql';
import { Table } from '../../../components/Table/Table';
import { Link } from '../../../components/Link/Link';
import * as MissionHelpers from '../../../helpers/mission';

export const FreelanceMissionsListing = () => {
  const myMissions = useMyMissionsQuery();
  const data = useMemo(() => {
    const d = myMissions.data?.me?.profilePrestataire?.validatedAssociatedMissions! || [];
    return d.map((mission) => ({
      id: mission?.mission?.id!,
      experience: mission?.mission?.experience!,
      title: mission?.mission?.title!,
      remote: mission?.mission?.remote!,
    }));
  }, [myMissions.data]);
  type TableData = (typeof data)[number];

  return (
    <PrincipalLayoutBox className={classnames($.missionListing)}>
      <Title>Mes missions</Title>
      <Tabs>
      </Tabs>
      <Content>
        {(myMissions.loading) && <ZoneLoadingState />}
        {!myMissions.loading && myMissions.data?.me?.profilePrestataire?.validatedAssociatedMissions?.length !== 0 &&  (
          <Table<TableData> columns={[
            {
              Header: 'Titre',
              accessor: (row) => <Link to={`/my/cra/${row.id}`}>{row.title}</Link>,
              width: 30,
            },
            {
              Header: 'Experience',
              accessor: row => MissionHelpers.experienceToText(row.experience!),
              width: 20,
            },
            {
              Header: 'Télétravail',
              accessor: row => MissionHelpers.remoteToText(row.remote!),
              width: 20,
            },
          ]} data={data} />
        )}
        {!myMissions.loading && myMissions.data?.me?.profilePrestataire?.validatedAssociatedMissions?.length === 0 && (
          <p>Vous n'avez pas encore de mission associée</p>
        )}
      </Content>
    </PrincipalLayoutBox>
  );
};
