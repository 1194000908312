import React from 'react';
import classnames from 'classnames';
import $ from './AuthLayout.module.scss';
import AuthBackdrop from '../../../assets/auth-background.svg';
import Logo from '../../../assets/logo.svg';
import { BaseLayout } from '../BaseLayout/BaseLayout';


export type AuthLayoutProps = {
  children: React.ReactNode;
  className?: string;
};
export const AuthLayout = ({ children, className }: AuthLayoutProps) => (
  <BaseLayout>
    <div className={$.AuthLayout}>
      <div className={$.Left}>
        <img src={Logo} alt="" />
        <div className={classnames($.ChildrenContainer, className)}>
          {children}
        </div>
      </div>
      <div className={$.Right}>
        <img src={AuthBackdrop} alt="" />
      </div>
    </div>
  </BaseLayout>
);
