import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Form/Input/Input";
import { Modal } from "../../../components/Modal/Modal";
import $ from './ValidateModal.module.scss';
const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

type ValidateModalProps = {
  salesEmail: string | null | undefined;
  onSubmit: (salesEmail?: string) => void;
  isLoading: boolean;
};
export const ValidateModal = ({ salesEmail, onSubmit, isLoading }: ValidateModalProps) => {
  const [inputSalesEmail, setInputSalesEmail] = useState('Ludovic.ETIENNE@MyDataSpecialist.com');
  let text = salesEmail ?
    `Le commercial référent pour ce compte est ${salesEmail}` :
    `Veuillez renseigner l'email du commercial référent avant de valider.`;

  return (
    <div className={$.ValidButton}>
      <Modal
        buttonLabel="Valider"
        title="Validation"
        description={text}
      >
        <>
          {!salesEmail && <Input tabIndex={0} label="Email commercial" value={inputSalesEmail} onChange={(e: any) => setInputSalesEmail(e.target.value)} />}
          <div className={$.ModalValidationButton}>
            <Button isLoading={isLoading} onClick={() => salesEmail ? onSubmit() : onSubmit(inputSalesEmail)} disabled={!salesEmail && !emailRegex.test(inputSalesEmail)}>Valider</Button>
          </div>
        </>
      </Modal>
    </div>
  );
};
