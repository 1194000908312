
import { useMemo } from 'react';
import classnames from 'classnames';
import { PrincipalLayoutBox, Title, Content, ZoneLoadingState } from '../../layout/PrincipalLayout/PrincipalLayout';
import $ from './PrestatairesListing.module.scss';
import { prestatairesListingTranslations } from '../../../translate';
import { Profile_Prestataire_Associated_To_Mission_State, useAnonymousProfilePrestataireDetailsLazyQuery, useAnonymousProfilesPrestatairesQuery } from '../../../generated/graphql';
import { PrestataireList } from '../../../components/PrestataireList/PrestataireList';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { offsetToCursor } from 'graphql-relay';

const { translate } = prestatairesListingTranslations;
type Filters = {
  page: number;
}
const defaultFilters: Filters = {
  page: 0
};
const getFiltersFromUrl = (searchParams: string | null) => {
  const sanitized = searchParams || '{}';
  const parsed = JSON.parse(sanitized);
  const cleaned = {
    ...defaultFilters,
    ...parsed,
  } as Filters;

  return cleaned;
};
export const PrestatairesListing = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const filters = getFiltersFromUrl(query.get('filters'));
  const updateQueryParam = <T extends keyof Filters,>(name: T, value: Filters[T]) => {
    const newFilters = { ...filters, [name]: value };
    const stringified = JSON.stringify(newFilters);

    history.push({ search: `?filters=${stringified}` });
  };
  const offset = filters.page === 0 ? null : offsetToCursor((filters.page * 10) - 1);
  const list = useAnonymousProfilesPrestatairesQuery({
    variables: {
      after: offset,
    }
  });
  const data = useMemo(() => {
    if (!list.data) {
      return [];
    }

    const d = list.data?.anonymousProfilesPrestataires?.edges || [];
    return d.map((presta) => ({
      id: presta?.node?.id!,
      available: presta?.node?.available!,
      availabilityDate: presta?.node?.availabilityDate!,
      availabilityInMonths: presta?.node?.availabilityInMonths!,
      tjm: presta?.node?.tjm!,
      skills: (presta?.node?.skills! || []).map(a => ({ name: a?.name!, weight: a?.weight! })),
      maximumDistance: presta?.node?.maximumDistance!,
      minimumMonths: presta?.node?.minimumMonths!,
      postalCode: presta?.node?.postalCode!,
      title: presta?.node?.title!,
      cityName: presta?.node?.city?.name!,
      state: Profile_Prestataire_Associated_To_Mission_State.WaitingSelection,
    }));
  }, [list.data]);


  const [anonymousProfilePrestataireDetails, rawProfilePrestataireDetails] = useAnonymousProfilePrestataireDetailsLazyQuery();
  const profilePrestataireDetails = useMemo(() => {
    const profilePrestataire = rawProfilePrestataireDetails.data?.anonymousProfilePrestataire;
    return profilePrestataire || null;
  }, [rawProfilePrestataireDetails.data]);


  const onClickDetails = (profilePrestataireId: string) => {
    anonymousProfilePrestataireDetails({
      variables: {
        id: profilePrestataireId,
      },
    });
  };

  return (
    <PrincipalLayoutBox className={classnames($.missionListing)}>
      <Title>{translate('Title')}</Title>
      <Content>
        {(list.loading) && <ZoneLoadingState />}
        {!list.loading && (
          <PrestataireList
            isLoadingDetails={false}
            profileDetails={profilePrestataireDetails}
            kind="ANONYMOUS_LISTING"
            missionId={null}
            list={data}
            onClickDetails={onClickDetails}
            onSelect={() => { }} />
        )}
        <Pagination current={filters.page} pageSize={10} total={list.data?.anonymousProfilesPrestataires?.total!} onClick={page => updateQueryParam('page', page)} />
      </Content>
    </PrincipalLayoutBox>
  );
};
