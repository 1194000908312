import $ from './PrestataireList.module.scss';
import { Spinner } from '../Spinner/Spinner';
import { ReactComponent as MapMarker } from '../../assets/map-marker.svg';
import { Dialog, DialogBackdrop, DialogDisclosure, useDialogState } from 'reakit';
import { useSovrenParser } from '../../hooks/sovren';
import { availabilityToText, distanceToText, monthsToText } from '../../views/admin/AdminProfilePrestatairePreview/AdminProfilePrestatairePreview';
import { DataContainer, DataDateContainer, DataLabelContainer, DataRowContainer, experienceToText, Presentation } from '../DataPresentation/Presentation/Presentation';
import { Button } from '../Button/Button';
import classNames from 'classnames';
import { Profile_Prestataire_Associated_To_Mission_State } from '../../generated/graphql';

// const isAnonymousListing = (kind: Kind) => kind === 'ANONYMOUS_LISTING';
const isClientMission = (kind: Kind) => kind === 'CLIENT_MISSION';
const isAdminMission = (kind: Kind) => kind === 'ADMIN_MISSION';
type Kind = 'ADMIN_MISSION' | 'ANONYMOUS_LISTING' | 'CLIENT_MISSION';
export type BaseProfile = {
  id: string;
  available: boolean;
  availabilityInMonths: number | null;
  tjm: number;
  skills: { name: string; weight: number; }[];
  maximumDistance: number;
  minimumMonths: number;
  postalCode: string;
  title: string;
  cityName: string;
  name?: string | null;
  state: Profile_Prestataire_Associated_To_Mission_State;
};
type ProfileDetails = BaseProfile & {
  parsedCv: string;
};
type ProfileDetailsProps = {
  profile?: ProfileDetails | null;
  loading: boolean;
  onSelect?: (id: string) => void;
  onAppoint?: (id: string) => void;
  onValidate?: (id: string) => void;
  kind: Kind;
};
const ProfileDetailsComponent = ({ profile, onValidate = () => { }, loading, onSelect = () => { }, onAppoint = () => { }, kind }: ProfileDetailsProps) => {
  const [formattedCv] = useSovrenParser(profile?.parsedCv || '');

  if (loading) {
    return (
      <div className={$.DetailsSpinner}>
        <Spinner />
      </div>
    )
  }
  if (!profile) {
    return <div>Unknown</div>;
  }

  return (
    <div className={$.ProfileDetails}>
      <div className={$.Title}>{profile.title}</div>
      <div className={$.Tjm}>{profile.tjm}€/jour</div>
      {isAdminMission(kind) && profile?.state === Profile_Prestataire_Associated_To_Mission_State.Selected &&
        <div className={$.ChooseProfile}><Button onClick={() => onAppoint(profile?.id)}>Préparer un rendez-vous</Button></div>
      }
      {isAdminMission(kind) && profile?.state === Profile_Prestataire_Associated_To_Mission_State.Appointed &&
        <div className={$.ChooseProfile}>
          <Button onClick={() => onValidate(profile?.id)}>Valider ce profil</Button>
        </div>
      }
      {isClientMission(kind) && profile?.state === Profile_Prestataire_Associated_To_Mission_State.WaitingSelection &&
        <div className={$.ChooseProfile}><Button onClick={() => onSelect(profile?.id)}>Choisir ce profil</Button></div>
      }
      {isClientMission(kind) && (profile?.state === Profile_Prestataire_Associated_To_Mission_State.Selected || profile?.state === Profile_Prestataire_Associated_To_Mission_State.Appointed) &&
        <div className={$.ChooseProfile}>
          <b>Vous avez choisi ce profil</b>
        </div>
      }
      <div className={$.Spacer}>
        <Presentation>
          <DataRowContainer>
            <DataLabelContainer>Experience</DataLabelContainer>
            <DataContainer>{experienceToText(formattedCv?.data?.monthsOfWorkExperience)}</DataContainer>
          </DataRowContainer>
          {experienceToText(formattedCv?.data?.monthsOfManagementExperience) !== 'inconnue' && (
            <DataRowContainer>
              <DataLabelContainer>Experience manageriale</DataLabelContainer>
              <DataContainer>{experienceToText(formattedCv?.data?.monthsOfManagementExperience)}</DataContainer>
            </DataRowContainer>
          )}
        </Presentation>
      </div>
      <div className={$.Skills}>
        {(profile.skills || []).slice(0, 15).map((sk, i) => <span key={i} className={$.Skill}>
          <span className={$.InternalSkill}>{sk.name}</span>
          <span className={$.InternalSkill}>{sk.weight}</span>
        </span>)}
      </div>
      <div className={$.Spacer}>
        <DataRowContainer>
          <DataLabelContainer>Disponibilité</DataLabelContainer>
          <DataContainer>{availabilityToText(profile.available, profile.availabilityInMonths)}</DataContainer>
        </DataRowContainer>
        <DataRowContainer>
          <DataLabelContainer>Durée minimum de mission</DataLabelContainer>
          <DataContainer>{monthsToText(profile?.minimumMonths)}</DataContainer>
        </DataRowContainer>
        <div className={$.Missions}>
          <DataRowContainer>
            <DataLabelContainer>Missions</DataLabelContainer>
          </DataRowContainer>
          {formattedCv.data?.positions.map(position => {
            return (
              <div className={$.DataMissionContainer} key={position.id}>
                <div>
                  <b>{position.name}</b> chez <b>{position.employer}</b>
                </div>
                <DataDateContainer from={position.start} to={position.end} withGap />
                <div>Spécialité: <b>{position.principalSkill}, {position.subSkill}</b></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={$.City} title={profile.postalCode}><span style={{ color: 'var(--primary-color)' }}><MapMarker /></span> {(profile.cityName || '').replace(' L ', ` l'`).toLowerCase()} ({distanceToText(profile?.maximumDistance)})</div>
    </div>
  );
};

export type PrestataireListProps = {
  missionId: string | null;
  list: BaseProfile[];
  onSelect?: (id: string) => void;
  onAppoint?: (id: string) => void;
  onValidate?: (id: string) => void;
  onClickDetails: (id: string) => void;
  kind: Kind;
  isLoadingDetails: boolean;
  profileDetails: any;
};
export const PrestataireList = ({ list, missionId, onSelect, onAppoint, onValidate, isLoadingDetails, profileDetails, onClickDetails, kind }: PrestataireListProps) => {
  const dialog = useDialogState();

  return (
    <div className={$.PrestataireList}>
      <div className={$.Container}>
        <DialogBackdrop {...dialog} className={$.ModalBackdrop}>
          <Dialog aria-label="Prestataire" {...dialog} className={$.ModalContent}>
            {profileDetails && <ProfileDetailsComponent kind={kind} onAppoint={onAppoint} onValidate={onValidate} onSelect={onSelect} loading={isLoadingDetails} profile={profileDetails as any} />}
          </Dialog>
        </DialogBackdrop>
        {list.map(({
          title,
          tjm,
          skills,
          available,
          availabilityInMonths,
          postalCode,
          cityName,
          id,
          name
        }) => (
          <DialogDisclosure {...dialog} as="div" key={id} className={classNames($.PrestataireListItem, { [$.PrestataireListItemTall]: isAdminMission(kind) })} role="button" onClick={() => onClickDetails(id)}>
            <div className={$.Title}>{title}</div>
            {isAdminMission(kind) && <div className={$.Name}>{name}</div>}
            <div className={$.Tjm}>{tjm}€/jour</div>
            <div className={$.Skills}>
              {skills.slice(0, 15).map((sk, i) => <span key={i} className={$.Skill}>
                <span className={$.InternalSkill}>{sk.name}</span>
                <span className={$.InternalSkill}>{sk.weight}</span>
              </span>)}
            </div>
            <div className={$.Availability}>Dispo. {availabilityToText(available, availabilityInMonths).toLowerCase()}</div>
            <div className={$.City} title={postalCode}><span style={{ color: 'var(--primary-color)' }}><MapMarker /></span> {(cityName || '').replace(' L ', ` l'`).toLowerCase()}</div>
          </DialogDisclosure>
        ))}
      </div>
    </div>
  );
};
