import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Form/Input/Input";
import { Modal } from "../../../components/Modal/Modal";
import $ from './StartModal.module.scss';

type StartModalProps = {
  startingDate: string;
  endingDate: string;
  tjm: number;
  onSubmit: (startingDate: string, endingDate: string, tjm: number) => void;
  isLoading: boolean;
};
export const StartModal = ({ startingDate, endingDate, tjm, onSubmit, isLoading }: StartModalProps) => {
  const [startingDateState, setStartingDateState] = useState(startingDate);
  const [endingDateState, setEndingDateState] = useState(endingDate);
  const [tjmState, setTjmState] = useState(tjm);

  return (
    <div className={$.StartButton}>
      <Modal
        buttonLabel="Valider"
        title="Validation"
        description="Veuillez renseigner les dates de démarrage et de fin de la mission"
      >
        <>
          <Input
            type="date"
            name="startDate"
            label="Date de démarrage"
            value={startingDateState?.toString().split('T')}
            onChange={(e: any) => setStartingDateState(e.target.value)}
          />
          <br />
          <Input
            type="date"
            name="endDate"
            label="Date de fin"
            value={endingDateState?.toString().split('T')}
            onChange={(e: any) => setEndingDateState(e.target.value)}
          />
          <br />
          <Input
            type="number"
            name="tjm"
            label="TJM"
            value={tjmState}
            onChange={(e: any) => setTjmState(e.target.value)}
          />
          <br />
          <div className={$.ModalStartingButton}>
            <Button isLoading={isLoading} onClick={() => onSubmit(startingDateState!, endingDateState!, tjmState!)} disabled={!startingDateState || !endingDateState || !tjmState}>Démarrer</Button>
          </div>
        </>
      </Modal>
    </div >
  );
};
