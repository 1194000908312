import React from 'react';
import { useAuth, UserTypePayload, useUserType } from '../hooks/auth';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { Roles } from '../store/domains/auth/Authentication.actions';

export const PrivateRoute = ({ children, forceIdentity, ...rest }: RouteProps & { forceIdentity?: (userType: UserTypePayload) => boolean }) => {
  const auth = useAuth();
  const userType = useUserType();
  const isAlreadyOnProfile = useHistory().location.pathname === '/profile';
  const isAlreadyOnProfilePrestataire = useHistory().location.pathname === '/profile/prestataire';
  const continueUrlSearch = useHistory<{ from: { search: string; } }>().location.state?.from?.search || '?';
  const continueUrl = decodeURIComponent(new URLSearchParams(continueUrlSearch).get('continue') || '');

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => {
        if (auth === null) {
          return (
            <Redirect
              to={{
                pathname: '/signin',
                search: `continue=${encodeURIComponent(location.pathname)}`,
                state: { from: location },
              }}
            />
          );
        } else if (forceIdentity && !forceIdentity(userType)) {
          return (
            <Redirect
              to={{
                pathname: '/not-found',
                state: { from: location },
              }}
            />
          )
        } else if (!auth.firebaseUser.emailVerified) {
          return (
            <Redirect
              to={{
                pathname: '/not-verified',
                state: { from: location },
              }}
            />
          );
        } else if (!auth.isProfileFilled) {
          if (isAlreadyOnProfile) {
            return children;
          }

          return (
            <Redirect
              to={{
                pathname: '/profile',
                state: { from: location },
              }}
            />
          );
        } else if (auth.role === Roles.FREELANCE && !auth.isProfilePrestataireFilled) {
          if (isAlreadyOnProfilePrestataire) {
            return children;
          }

          return (
            <Redirect
              to={{
                pathname: '/profile/prestataire',
                state: { from: location },
              }}
            />
          );
        } else if (continueUrl) {
          return (
            <Redirect
              to={{
                pathname: continueUrl,
                state: { from: location }
              }}
            />
          )
        } else {
          return children;
        }
      }}
    />
  );
};

export const NotAuthenticatedRoute = ({ children, ...rest }: RouteProps) => {
  const auth = useAuth();
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        auth !== null ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};
