import React from 'react';
import classnames from 'classnames';
import $ from './BaseLayout.module.scss';


export type BaseLayoutProps = {
  children: React.ReactNode;
  className?: string;
};
export const BaseLayout = ({ children, className }: BaseLayoutProps) => (
  <div className={classnames($.BaseLayout, className)}>
    {children}
  </div>
);
