import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { NewMissionPreview } from './NewMissionPreview';
import { ExistingMissionPreview } from './ExistingMissionPreview';

const useGetIdOrNew = () => {
  const { id } = useRouteMatch<{ id: string; }>().params;

  return {
    id,
    isNew: id === 'new'
  };
};

export const MissionPreview = () => {
  const { isNew } = useGetIdOrNew();

  return (
    isNew ? (
      <NewMissionPreview />
    ) : (
        <ExistingMissionPreview />
      )
  );
};
