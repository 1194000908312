import React from 'react';
import classnames from 'classnames';
import $ from './Spinner.module.scss';


export const Spinner = () => (
  <div className={classnames($.Spinner)}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="currentColor" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </svg>
  </div>
);
